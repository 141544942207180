import React from 'react';

// material-ui core
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material';

// components
import { Box } from 'components/common/Box';

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  spinner: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: '0px',
    left: '0px',
    background: '#fefefe8a',
    zIndex: '1000',
  },
  fixed: {
    position: 'fixed',
  },
  colorWhite: {
    color: '#fff',
  },
  darkBackground: {
    position: 'fixed',
    background: theme.palette.accent.main,
    zIndex: '2000',
  },
  transparentBackground: {
    background: 'unset',
  },
}));

export interface ISpinnerProps {
  table?: boolean;
  fixed?: boolean;
  size?: number;
  main?: boolean;
  label?: string;
  transparentBackground?: boolean;
  whiteSpinner?: boolean;
}

export const Spinner = (props: ISpinnerProps) => {
  const {
    table,
    fixed,
    size = 40,
    main = false,
    label,
    transparentBackground,
    whiteSpinner,
  } = props;

  // styles
  const { classes, cx } = useStyles();

  return (
    <div
      className={cx(
        !table && classes.spinner,
        fixed && classes.fixed,
        main && classes.darkBackground,
        transparentBackground && classes.transparentBackground,
      )}
      data-testid="c-spinner"
    >
      <Box className={classes.root}>
        <CircularProgress
          className={main || whiteSpinner ? classes.colorWhite : ''}
          color="secondary"
          size={main ? 70 : size}
          disableShrink={true}
        />
        {label && (
          <Typography color="textSecondary" variant="subtitle1">
            <p>{label}</p>
          </Typography>
        )}
      </Box>
    </div>
  );
};
