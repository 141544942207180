import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import Divider from '@mui/material/Divider';
import { Box } from 'components/common/Box';
import { LocationSummaryPopupProps, ICON_MAP } from './LocationSummaryPopup.model';

export const LocationSummaryPopup: React.FC<LocationSummaryPopupProps> = ({
  locationName,
  onClose,
  status,
  statusLabel = status,
  ...rest
}) => (
  <Stack
    sx={{
      width: 300,
      gap: 1,
      padding: 2,
    }}
  >
    <Stack minWidth={240} direction="row" alignItems="baseline">
      <Typography variant="h6">{locationName}</Typography>
      <IconButton
        aria-label="close"
        sx={{
          marginLeft: 'auto',
        }}
        onClick={onClose}
      >
        <CloseIcon />
      </IconButton>
    </Stack>
    <Stack direction="row" gap={1} alignItems="center">
      {ICON_MAP[status]}
      <Typography variant="body2">{statusLabel}</Typography>
    </Stack>
    {'details' in rest ? (
      <>
        <Divider variant="middle" />
        {rest.details.map(({ title, color, items }) => (
          <Stack gap={1} key={title}>
            <Stack direction="row" alignItems="baseline" gap={1}>
              <Box
                component="span"
                sx={{
                  display: 'inline-block',
                  backgroundColor: color,
                  width: 12,
                  height: 12,
                  borderRadius: '50%',
                }}
              />
              <Typography variant="body2">{title}</Typography>
            </Stack>
            <Stack gap={1} flexDirection="row" flexWrap="wrap">
              {items.map((item) => (
                <Box
                  key={item}
                  sx={(theme) => ({
                    borderRadius: 1,
                    backgroundColor: theme.palette.grey['100'],
                    padding: 1,
                  })}
                >
                  <Typography>{item}</Typography>
                </Box>
              ))}
            </Stack>
          </Stack>
        ))}
      </>
    ) : null}
  </Stack>
);

LocationSummaryPopup.displayName = 'LocationSummaryPopup';
