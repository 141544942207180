// store and services
import { reportServices } from 'udb/inventory/features/reports/api/ReportServices';
import { IRequestController } from '../../hooks';
import { IFacilityModalsState } from '../../store/Modals/facilityModals/IFacilityModalsStore';
import { FacilityModalsActionTypes } from '../../store/Modals/types';

import { uploadStore } from '../../store/UploadStore';

// variables, functions, configurations

export const handleAbortReport = (
  facilityModalsState: IFacilityModalsState,
  dispatchFacilityModals: any,
  requestController: IRequestController,
  systemId: string | null | undefined,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  requestController.doRequest({
    request: reportServices.abortReport,
    requestParams: [systemId, facilityModalsState.abortReportModalData.reportId],
    callbackBeforeSend: () => {
      setLoading(true);
    },
    callbackSuccess: () => {
      Object.keys(facilityModalsState.refreshData).forEach((func) =>
        facilityModalsState.refreshData[func](),
      );
    },
    messageSuccess: 'Report successfully aborted',
    messageErrorFallback: 'Report could not be aborted',
    callbackFinally: () => {
      setLoading(false);
      dispatchFacilityModals({ type: FacilityModalsActionTypes.REPORT_ABORT, payload: {} });
    },
  });
};

export const handleUpdateReport = (
  facilityModalsState: IFacilityModalsState,
  dispatchFacilityModals: any,
  requestController: IRequestController,
  systemId: string | null | undefined,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  requestController.doRequest({
    request: reportServices.updateReport,
    requestParams: [systemId, facilityModalsState.updateReportModalData.reportId],
    callbackBeforeSend: () => {
      setLoading(true);
    },
    callbackSuccess: () => {
      Object.keys(facilityModalsState.refreshData).forEach((func) =>
        facilityModalsState.refreshData[func](),
      );
    },
    messageSuccess: 'Report successfully updated',
    messageErrorFallback: 'Report could not be updated',
    callbackFinally: () => {
      setLoading(false);
      dispatchFacilityModals({ type: FacilityModalsActionTypes.REPORT_UPDATE, payload: {} });
    },
  });
};

export const handleArchiveReport = (
  facilityModalsState: IFacilityModalsState,
  dispatchFacilityModals: any,
  requestController: IRequestController,
  systemId: string | null | undefined,
  enqueueSnackbar: any,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  requestController.doRequest({
    request: reportServices.archiveReports,
    requestParams: [systemId, facilityModalsState.archiveReportModalData.reportIds],
    callbackBeforeSend: () => {
      setLoading(true);
    },
    callbackSuccess: (r) => {
      let message;
      if (r.data.failed_reports_ids.length === 0) {
        message = r.data?.message || 'Report successfully archived';
      } else {
        message = r.data?.message || 'Not all the reports have been archived';
        message += `. ${r.data.failed_reports_ids.length} ${
          r.data.failed_reports_ids.length > 1 ? 'reports' : 'report'
        } failed to archive.`;
      }
      enqueueSnackbar(message, { variant: 'success' });
      Object.keys(facilityModalsState.refreshData).forEach((func) =>
        facilityModalsState.refreshData[func](),
      );
    },
    messageErrorFallback: 'Report could not be archived.',
    callbackFinally: () => {
      setLoading(false);
      dispatchFacilityModals({ type: FacilityModalsActionTypes.REPORT_ARCHIVE, payload: {} });
    },
  });
};

export const handleRestoreReport = (
  facilityModalsState: IFacilityModalsState,
  dispatchFacilityModals: any,
  requestController: IRequestController,
  systemId: string | null | undefined,
  enqueueSnackbar: any,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  requestController.doRequest({
    request: reportServices.restoreReports,
    requestParams: [systemId, facilityModalsState.restoreReportModalData.reportIds],
    callbackBeforeSend: () => {
      setLoading(true);
    },
    callbackSuccess: (r) => {
      let message;
      if (r.data.failed_reports_ids.length === 0) {
        message = r.data?.message || 'Reports restored correctly';
      } else {
        message = r.data?.message || 'Not all the reports have been restored';
        message += `. ${r.data.failed_reports_ids.length} ${
          r.data.failed_reports_ids.length > 1 ? 'reports' : 'report'
        } failed to restore.`;
      }
      enqueueSnackbar(message, { variant: 'success' });
      Object.keys(facilityModalsState.refreshData).forEach((func) =>
        facilityModalsState.refreshData[func](),
      );
    },
    messageErrorFallback: 'Reports could not be restored',
    callbackFinally: () => {
      setLoading(false);
      dispatchFacilityModals({ type: FacilityModalsActionTypes.REPORT_RESTORE, payload: {} });
    },
  });
};

export const handleDeleteReportSpec = (
  facilityModalsState: IFacilityModalsState,
  dispatchFacilityModals: any,
  requestController: IRequestController,
  systemId: string | null | undefined,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  requestController.doRequest({
    request: uploadStore.deleteReportSpecification,
    requestParams: [systemId, facilityModalsState.deleteReportSpecData.id],
    callbackBeforeSend: () => {
      setLoading(true);
    },
    messageSuccess: 'Report specification deleted',
    messageErrorFallback: 'Report sepcification could not be deleted',
    callbackSuccess: () => {
      Object.keys(facilityModalsState.refreshData).forEach((func) =>
        facilityModalsState.refreshData[func](),
      );
    },
    callbackFinally: () => {
      setLoading(false);
      dispatchFacilityModals({
        type: FacilityModalsActionTypes.DELETE_REPORT_SPECIFICATION,
        payload: {},
      });
    },
  });
};
