import Container from '@mui/material/Container';
import Card from '@mui/material/Card';
import { useParams } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';
import { useMapStore } from 'shared/map-container/reducer/3DmapStore';
import { MapContainer } from 'shared/map-container/MapContainer';
import { Vec6 } from 'shared/map-container/MapContainer.model';
import { webSocket } from 'shared/web-socket/web-socket';
import { OpenFlyZonesDTO } from 'shared/map/model/open-fly-zone.model';
import { ControlledZonesDTO } from 'shared/map/model/controlled-zone.model';
import { DroneZoneDetailsPanel } from 'shared/map/features/drone-zone-details-panel/drone-zone-details-panel';
import { NoFlyZone, NoFlyZonesDTO } from 'shared/map/model/no-fly-zone.model';
import { mapSortDetailsZones } from 'shared/map/utils/map-sort-details-zones/mapSortDetailsZones.util';
import { DroneZoneMapLegend } from 'shared/map/features/drone-zone-map-legend/DroneZoneMapLegend';
import { DroneZonesMap, wrapperId } from './features/Map/DroneZonesMap';
import { useComponentDidMount } from '../../../hooks/useComponentDidMount';
import { useRequestController } from '../../../hooks';
import { DeleteNoFlyZoneModal } from './features/DeleteNoFlyZoneModal/DeleteNoFlyZoneModal';
import { DroneZoneControls } from './features/DroneZoneControls/DroneZoneControls';
import { getDroneZones } from './api/getDroneZones';
import { FlowState } from './reducer/zoneStatusInitialState';
import { useStyles } from './DroneZonesPage.styles';
import { DroneZoneHeader } from './features/DroneZoneHeader';
import { DEFAULT_VISIBLE_ZONES, VisibleZones } from './defaults/visibleZones.default';

import { subscribers } from './webSockets/droneZonesSubscribers';
import { subscribeToNoFlyZoneSocket } from './webSockets/subscribeToNoFlyZoneSocket';
import { subscribeToOpenFlyZoneSocket } from './webSockets/subscribeToOpenFlyZoneSocket';
import { subscribeToControlledZoneSocket } from './webSockets/subscribeToControlledZoneSocket';
import { PERMISSION } from '../../../features/permissions/permissions.model';
import { userHasPermission } from '../../../features/permissions/userHasPermission';
import { mapZoneIsSelectedAndIsEditing } from './features/helpers/mapSelectedZone';
import { ClientDroneZones, ClientDroneZoneType } from './model/clientDroneZones.model';
import { useDroneZoneContext } from './reducer/DroneZonePageContext';
import {
  mapLegendFlightArea,
  mapLegendPlanning,
} from './features/Map/defaults/droneZoneMapLegends.defaults';
import { useMapViewParams } from './hooks/use-map-view-params/useMapViewParams';
import { MapViewOptions } from './model/DroneZonesPage.model';
import { FlightAreasMap } from './features/Map/FlightAreasMap';
import { subscribeToFlightAreas } from './webSockets/subscribeToFlightAreas';
import { FlightAreasDTO } from './model/flightAreas.model';
import { DroneZonePageHeaderControls } from './features/DroneZonePageHeaderControls/DroneZonePageHeaderControls';
import { FlightAreasApi } from './api/flightAreas.index';
import { IGetFlightAreasResponseST } from '../../../codegen/flight_areas';

export const DroneZonesPage = () => {
  const { systemId = '', flightDomainId = '' } = useParams();
  const { classes, cx } = useStyles();
  const { mapState } = useMapStore();
  const { requestController } = useRequestController('DroneZones');
  const socket = webSocket();

  const { currentMapView, setMapViewParam } = useMapViewParams();

  const [visibleZones, setVisibleZones] = useState<VisibleZones>(DEFAULT_VISIBLE_ZONES);

  const { droneZonePageState, dispatchDroneZone: dispatchZoneStatus } = useDroneZoneContext();

  const {
    openFlyZones,
    controlledZones,
    noFlyZones,
    flightAreas,
    detailsZones,
    flowState,
    currentlyEditingZone,
    currentlySelectedZone,
    currentlyDeletingZone,
  } = droneZonePageState;

  const mapHeight = mapState.map?.box?.[5] ?? 0;
  const isDetailsPanelOpen = !!(detailsZones?.length && mapHeight);
  const sortedDetailsZones = mapSortDetailsZones(
    { ...controlledZones, ...openFlyZones, ...noFlyZones },
    detailsZones,
  );

  const isCurrentViewPlanning = currentMapView === MapViewOptions.PLANNING;
  const currentMapViewLegend = isCurrentViewPlanning ? mapLegendPlanning : mapLegendFlightArea;

  const handleToggleVisibleZones = (zoneType: ClientDroneZoneType) =>
    setVisibleZones((prevState) => ({ ...prevState, [zoneType]: !prevState[zoneType] }));

  useComponentDidMount(() => {
    getDroneZones({
      requestController,
      flightDomainId,
      systemId,
      onBeforeSend: () =>
        dispatchZoneStatus({ type: 'SET_FLOW_STATE', payload: FlowState.LOADING }),
      onSuccess: (payload) => {
        dispatchZoneStatus({ type: 'SET_DRONE_ZONES', payload });
      },
      onFinally: () => dispatchZoneStatus({ type: 'SET_FLOW_STATE', payload: FlowState.LIST }),
    });
  });

  useComponentDidMount(() => {
    const { signal } = requestController.reserveSlotForRequest();
    requestController.doRequest({
      request: FlightAreasApi.getFlightAreas,
      requestParams: [{ flightDomainId, systemId, signal }],
      callbackSuccess: ({ data }: { data: IGetFlightAreasResponseST }) =>
        dispatchZoneStatus({ type: 'SET_FLIGHT_AREAS', payload: data.flight_areas }),
      messageErrorFallback: 'Something went wrong while fetching the flight area!',
    });
  });

  useEffect(() => {
    if (userHasPermission(PERMISSION.NO_FLY_ZONE_MANAGEMENT)) {
      subscribeToNoFlyZoneSocket(
        flightDomainId,
        (noFlyZonesDTO: NoFlyZonesDTO) =>
          dispatchZoneStatus({
            type: 'SET_NO_FLY_ZONES',
            payload: noFlyZonesDTO,
          }),
        socket,
      );
    }

    subscribeToOpenFlyZoneSocket(
      flightDomainId,
      (openFlyZonesDTO: OpenFlyZonesDTO) =>
        dispatchZoneStatus({
          type: 'SET_OPEN_FLY_ZONES',
          payload: openFlyZonesDTO,
        }),
      socket,
    );

    subscribeToControlledZoneSocket(
      flightDomainId,
      (controlledZonesDTO: ControlledZonesDTO) =>
        dispatchZoneStatus({
          type: 'SET_CONTROLLED_ZONES',
          payload: controlledZonesDTO,
        }),
      socket,
    );

    subscribeToFlightAreas(
      flightDomainId,
      (flightAreasDTO: FlightAreasDTO) => {
        dispatchZoneStatus({
          type: 'SET_FLIGHT_AREAS',
          payload: flightAreasDTO,
        });
      },
      socket,
    );

    return () => {
      Object.values(subscribers).forEach((subscriber) => {
        socket.unsubscribe(subscriber);
      });
    };
  }, [socket, flightDomainId, dispatchZoneStatus]);

  const isDeletionModalVisible: boolean = flowState === 'DELETE' && currentlyDeletingZone !== null;

  const mappedNoFlyZones = useMemo(
    () =>
      mapZoneIsSelectedAndIsEditing({
        zones: noFlyZones,
        selectedZoneId: currentlySelectedZone?.id,
        editingZoneId: currentlyEditingZone?.id,
      }),
    [noFlyZones, currentlySelectedZone?.id, currentlyEditingZone?.id],
  );

  const mappedControlledZones = useMemo(
    () =>
      mapZoneIsSelectedAndIsEditing({
        zones: controlledZones,
        selectedZoneId: currentlySelectedZone?.id,
      }),
    [controlledZones, currentlySelectedZone?.id],
  );

  const handleSelectDroneZone = (droneZone: ClientDroneZones | null) => {
    const isCurrentlySelected = droneZone?.id === currentlySelectedZone?.id;
    const payload = isCurrentlySelected ? null : droneZone;

    dispatchZoneStatus({
      type: 'SELECT_SINGLE_DRONE_ZONE',
      payload,
    });
  };

  const handleSelectFromDroneZoneGroup = (droneZone: ClientDroneZones) => {
    dispatchZoneStatus({
      type: 'SELECT_SINGLE_FROM_DRONE_ZONE_GROUP',
      payload: droneZone,
    });
  };

  useEffect(() => {
    if (currentMapView !== MapViewOptions.PLANNING) {
      dispatchZoneStatus({
        type: 'SELECT_SINGLE_DRONE_ZONE',
        payload: null,
      });
    }
  }, [dispatchZoneStatus, currentMapView]);

  return (
    <>
      <DroneZoneHeader />

      <Container maxWidth="xl" style={{ paddingTop: '32px' }}>
        <div className={cx(classes.mapWrapper, classes.mapWrapperCoexistence)}>
          <div className={classes.controlBarMap}>
            <DroneZonePageHeaderControls
              mapView={currentMapView}
              visibleZones={visibleZones}
              isFlightAreaDisabled={flowState !== FlowState.LIST}
              showVisibilityToggles={isCurrentViewPlanning}
              onToggleVisibleZones={handleToggleVisibleZones}
              onMapViewChange={setMapViewParam}
            />
          </div>

          <div id={wrapperId} className={classes.map}>
            <MapContainer systemId={systemId} flightDomainId={flightDomainId}>
              {isCurrentViewPlanning ? (
                <DroneZonesMap
                  controlledZones={mappedControlledZones}
                  noFlyZones={mappedNoFlyZones}
                  openFlyZones={openFlyZones}
                  visibleZones={visibleZones}
                  onDroneZoneClick={handleSelectDroneZone}
                  isDrawingNewNoFlyZone={flowState === FlowState.DRAW}
                />
              ) : (
                <FlightAreasMap flightAreas={flightAreas} />
              )}
            </MapContainer>

            <div className={classes.legend}>
              <DroneZoneMapLegend entries={currentMapViewLegend} />
            </div>

            {isDetailsPanelOpen && (
              <div className={classes.details}>
                <DroneZoneDetailsPanel
                  showDronesFlyingBanner
                  zones={sortedDetailsZones}
                  areaHeight={mapHeight}
                  selectedZone={currentlySelectedZone}
                  onClose={() => handleSelectDroneZone(null)}
                  onSelect={handleSelectFromDroneZoneGroup}
                />
              </div>
            )}
          </div>

          <div className={classes.sidemenu}>
            <Card>
              <DroneZoneControls
                areActionsEnabled={isCurrentViewPlanning}
                systemId={systemId}
                flightDomainId={flightDomainId}
                flowState={flowState}
                currentlySelectedZone={currentlySelectedZone}
                currentlyEditingZone={currentlyEditingZone}
                noFlyZones={mappedNoFlyZones}
                controlledZones={mappedControlledZones}
                worldBox={mapState.map?.box as Vec6}
                onSelectDroneZone={handleSelectDroneZone}
              />
            </Card>
          </div>
        </div>
      </Container>

      {isDeletionModalVisible && (
        <DeleteNoFlyZoneModal
          noFlyZone={currentlyDeletingZone as NoFlyZone}
          onClose={() => {
            dispatchZoneStatus({ type: 'CLOSE_NO_FLY_ZONE_DELETION_MODAL', payload: null });
          }}
          onConfirm={() => {
            dispatchZoneStatus({ type: 'CONFIRM_NO_FLY_ZONE_DELETION_MODAL', payload: null });
          }}
        />
      )}
    </>
  );
};
