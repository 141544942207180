import { Theme } from '@mui/material';
import { IBarcodeSTStatusEnum } from 'codegen/location_information';
import { muiColorToTheme } from 'common/functions/issues/issueColorFunctions';

export const getBarcodeOnImageColor = (
  isStale: boolean,
  barcodeMetadataStatus: IBarcodeSTStatusEnum,
  themeColor: {
    main: string;
  },
  theme: Theme,
) => {
  if (isStale) {
    return 'GrayText';
  }
  if (barcodeMetadataStatus === 'BARCODE_RECOGNIZED') {
    return themeColor.main;
  }
  if (barcodeMetadataStatus === 'BARCODE_PATTERN_MISMATCH') {
    const red = muiColorToTheme('error', theme);
    return red.main;
  }
  const red = muiColorToTheme('error', theme);
  return red.main;
};

export const getBarcodeOnImageBorderColor = (
  isStale: boolean,
  barcodeMetadataStatus: IBarcodeSTStatusEnum,
  themeColor: {
    main: string;
  },
  theme: Theme,
) => `0.5px solid ${getBarcodeOnImageColor(isStale, barcodeMetadataStatus, themeColor, theme)}`;
