import { IFacilitySettingsST } from 'codegen/facility_settings';
import { SettingsItem } from '../models/settingsCard.model';

export const dualCheckOnUnlockOptions = (
  facilitySettings: IFacilitySettingsST,
  handleValuesChange: (prop: string, value: string | boolean) => void,
): SettingsItem[] => [
  {
    type: 'switch',
    label: 'Show Dual Check on System Unlock',
    testId: 'c-show-dual-check-on-unlock',
    description: 'If enabled, the dual check on system unlock will be shown.',
    value: facilitySettings.enable_dual_check_on_unlock,
    action: (e: React.ChangeEvent<HTMLInputElement>) =>
      handleValuesChange('enable_dual_check_on_unlock', e.target.checked),
  },
  {
    type: 'text',
    multiline: true,
    rows: 3,
    label: 'Disclaimer for the Dual Check on System unlock',
    width: 'larger',
    testId: 'c-dual-check-disclaimer',
    description: 'Disclaimer being shown when displaying the dual check on System Unlock.',
    value: facilitySettings.dual_check_on_unlock_disclaimer,
    action: (e: React.ChangeEvent<HTMLInputElement>) =>
      handleValuesChange('dual_check_on_unlock_disclaimer', e.target.value),
  },
];
