import { createContext, useContext, useReducer } from 'react';
import { ZoneStatusActions } from './zoneStatusActions';
import { ZoneStatusState, zoneStatusInitialState } from './zoneStatusInitialState';
import { zoneStatusReducer } from './zoneStatusActionsReducer';

type DroneZonePage = {
  droneZonePageState: ZoneStatusState;
  dispatchDroneZone: React.Dispatch<ZoneStatusActions>;
};

const DroneZoneContext = createContext<DroneZonePage | undefined>(undefined);

export const useDroneZoneContext = () => {
  const droneZoneContext = useContext(DroneZoneContext);

  if (droneZoneContext === undefined) {
    throw new Error('useDroneZoneContext has to be used within <DroneZoneContext.Provider>');
  }

  return droneZoneContext;
};

export const DroneZonePageContext = ({ children }: { children: React.ReactNode }) => {
  const [droneZonePageState, dispatchDroneZone] = useReducer(
    zoneStatusReducer,
    zoneStatusInitialState,
  );

  return (
    <DroneZoneContext.Provider
      value={{
        droneZonePageState,
        dispatchDroneZone,
      }}
    >
      {children}
    </DroneZoneContext.Provider>
  );
};
