import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Bin3DProps } from '../../bin3D/model/bin3DProps.model';
import { aisleSummaryDefaults } from '../defaults/aisleSummary.defaults';

export const useSummaryStyles = makeStyles()((theme: Theme) => ({
  paper: {
    display: 'flex',
    alignItems: 'baseline',
    gap: theme.spacing(1.5),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
    textWrap: 'nowrap',
  },
  label: {
    paddingRight: theme.spacing(1.5),
    position: 'relative',
    '::after': {
      content: '""',
      position: 'absolute',
      width: '1px',
      height: '100%',
      background: 'hsla(0, 0%, 88%, 1)',
      right: 0,
      top: 0,
    },
  },
  issue: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    paddingInline: theme.spacing(1.5),
    [theme.breakpoints.down('lg')]: {
      flexBasis: '30%',
    },
  },
}));

export const useBadgeStyles = makeStyles<{ status: Bin3DProps['status'] }>()((_, { status }) => ({
  badge: {
    borderRadius: '50%',
    borderColor: aisleSummaryDefaults[status].borderColor,
    background: aisleSummaryDefaults[status].color,
    borderWidth: '2px',
    borderStyle: 'solid',
    width: 12,
    height: 12,
    minWidth: 12,
    minHeight: 12,
  },
}));
