import { createContext, useContext, useReducer } from 'react';
import { initialGroundControlModalsState } from './groundControlModalInitialState';
import { modalsReducer } from './groundControlModalsReducer';
import { IModalsStoreContext } from './IGroundControlModalsStore';

const GroundControlModalsStore = createContext<IModalsStoreContext | null>(null);

export const GroundControlModalsStoreProvider = ({ children }: { children: React.ReactNode }) => {
  const [stateGroundControlModals, dispatchGroundControlModals] = useReducer(
    modalsReducer,
    initialGroundControlModalsState,
  );

  return (
    <GroundControlModalsStore.Provider
      value={{ stateGroundControlModals, dispatchGroundControlModals }}
    >
      {children}
    </GroundControlModalsStore.Provider>
  );
};

export const useGroundControlModalsStore = () => {
  const groundControlModalsContext = useContext(GroundControlModalsStore);

  if (!groundControlModalsContext) {
    throw new Error(
      'useGroundControlModalsStore has to be used within <GroundControlModalsStore.Provider>',
    );
  }

  return groundControlModalsContext;
};
