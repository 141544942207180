import Skeleton from '@mui/material/Skeleton';
import { ILocationData1ST } from 'codegen/report';
import { BaseCard } from 'components/BaseCard/BaseCard';
import { Box } from 'components/common/Box';
import { AisleSummary } from 'shared/map-3d/aisle-summary/AisleSummary';
import { AisleView } from 'shared/map-3d/aisle-view/AisleView';
import { useFacilityMap } from 'shared/map-3d/aisle-view/api/useFacilityMap';
import { useAisleView } from 'shared/map-3d/aisle-view/hooks/useAisleView';
import { Map3DCanvas } from 'shared/map-3d/map-3d-canvas/Map3DCanvas';
import { useCallback, useMemo, useRef, useState } from 'react';
import { ThreeEvent } from '@react-three/fiber';
import { Drawer } from '@mui/material';
import { getRowForFullReportTable } from 'common/functions/locationRows/locationRowsFunctions';
import { ZoomControls } from 'shared/map-3d/zoom-controls/ZoomControls';
import { AisleViewProps } from 'shared/map-3d/aisle-view/model/aisleViewProps.model';
import { ZoomDirection } from 'shared/map-3d/zoom-controls/ZoomControls.model';
import { LocationSummaryPopup } from '../location-summary-popup/LocationSummaryPopup';
import { transformBarcodeRows } from '../barcodes/utils/transformBarcodeRows.util';
import { LocationSummaryPopupDetail } from '../location-summary-popup/LocationSummaryPopup.model';
import { barcodeStatusEnum } from '../barcodes/models/BarcodeRow.model';
import { useMap3DCard } from './map3DCard.styles';

export const Map3DCard = ({
  allLocations,
  systemId,
  currentLocationName,
}: {
  allLocations?: ILocationData1ST[];
  systemId: string;
  currentLocationName: string;
}) => {
  const { classes } = useMap3DCard();
  const { data: facilityMap, isLoading } = useFacilityMap(systemId ?? '');
  const {
    aisleBins,
    current,
    summary = [],
  } = useAisleView({
    facilityMap,
    allLocations,
    currentLocationName,
  });

  const [activeBinName, setActiveBinName] = useState('');

  const aisleBinsWithHandlers = useMemo(
    () =>
      aisleBins.map((bin) => ({
        ...bin,
        onClick: (event: ThreeEvent<MouseEvent>) => {
          setActiveBinName(event.object.parent?.name ?? 'Unknown');
        },
      })),
    [aisleBins],
  );

  const closePopup = useCallback(() => {
    setActiveBinName('');
  }, []);

  const selectedLocation = useMemo(() => {
    if (!activeBinName) {
      return {};
    }

    const location = allLocations?.find((location) => location.slot_label === activeBinName);
    const selectedLocation = getRowForFullReportTable(
      location?.slot_label ?? '',
      location!,
      location?.issues ?? [],
    );
    const barcodeRows = selectedLocation
      ? transformBarcodeRows(selectedLocation.actions.data)
      : null;
    const missing: LocationSummaryPopupDetail = {
      title: 'Missing',
      color: 'error.main',
      items:
        barcodeRows
          ?.filter((row) => row.barcodeStatus === barcodeStatusEnum.Missing)
          .map((row) => row.expectedContent) ?? [],
    };
    const unexpected: LocationSummaryPopupDetail = {
      title: 'Unexpected',
      color: 'warning.main',
      items:
        barcodeRows
          ?.filter((row) => row.barcodeStatus === barcodeStatusEnum.Unexpected)
          .map((row) => row.contentFound) ?? [],
    };

    return {
      bin3d: aisleBins.find((bin) => bin.name === activeBinName),
      details: [missing, unexpected].filter((detail) => detail.items.length),
    };
  }, [activeBinName, allLocations, aisleBins]);

  const [zoomType, setZoomType] = useState<AisleViewProps['fit']>('height');
  const handleZoomChange = useCallback((action: ZoomDirection) => {
    setZoomType(action === 'in' ? 'height' : 'width');
  }, []);

  const contentWrapperRef = useRef(null);
  return (
    <BaseCard
      cardFor="neighboring locations"
      title="Neighboring locations"
      subtitle="See surrounding issues and navigate to other locations"
    >
      <Box
        ref={contentWrapperRef}
        sx={{
          minHeight: '30vh',
          height: 500,
          position: 'relative',
          containerType: 'size',
        }}
      >
        {!allLocations?.length || isLoading ? (
          <Skeleton height="100%" />
        ) : (
          <>
            <Map3DCanvas className={classes.canvas}>
              <AisleView bins={aisleBinsWithHandlers} current={current} fit={zoomType} />
            </Map3DCanvas>

            <AisleSummary className={classes.aisleSummary} issues={summary} />
            <ZoomControls className={classes.zoomControls} onZoom={handleZoomChange} />
            <Drawer
              keepMounted
              variant="persistent"
              open={!!activeBinName && !!selectedLocation}
              anchor="right"
              container={contentWrapperRef.current}
            >
              <LocationSummaryPopup
                locationName={activeBinName}
                status={selectedLocation?.bin3d?.status ?? 'NOT_SCANNED'}
                details={selectedLocation?.details ?? []}
                onClose={closePopup}
              />
            </Drawer>
          </>
        )}
      </Box>
    </BaseCard>
  );
};

Map3DCard.displayName = 'Map3DCard';
