import { PERMISSION } from 'features/permissions/permissions.model';
import { userHasPermission } from 'features/permissions/userHasPermission';
import { ILocationDataST } from 'codegen/warehouse_status';
import { Grid } from '@mui/material';
import { GridInitialStatePremium } from '@mui/x-data-grid-premium/models/gridStatePremium';
import { WarehouseOccupancyCard } from './WarehouseOccupancyCard';
import { WarehouseStatusCard } from './WarehouseStatusCard';
import { WarehouseOccupancy } from './warehouse-status-overview-widgets/WarehouseOccupancy';
import { WarehouseCoverage } from './warehouse-status-overview-widgets/WarehouseCoverage';
import { WarehouseIssues } from './warehouse-status-overview-widgets/WarehouseIssues';
import { WarehouseStatusOverviewLoader } from './WarehouseStatusOverviewLoader';

export const WarehouseStatusOverview = ({
  systemId,
  locationData,
  isLoadingLocations,
  onGridStateSelect,
  slots,
}: {
  systemId?: string;
  locationData?: ILocationDataST[];
  slots?: string[];
  isLoadingLocations?: boolean;
  onGridStateSelect?: (state: GridInitialStatePremium) => void;
}) => (
  <Grid container sx={{ gridColumn: '1 / -1' }} spacing={2}>
    <WarehouseStatusOverviewLoader
      isLoadingLocations={isLoadingLocations}
      alreadyLoaded={locationData?.length}
      total={slots?.length}
    />
    {!isLoadingLocations && locationData && (
      <>
        <Grid item xl={4} lg={6} md={12} sm={12} xs={12}>
          <WarehouseIssues locationData={locationData} onGridStateSelect={onGridStateSelect} />
        </Grid>
        <Grid item xl={4} lg={6} md={12} sm={12} xs={12}>
          <WarehouseOccupancy locationData={locationData} onGridStateSelect={onGridStateSelect} />
        </Grid>
        <Grid item xl={4} lg={6} md={12} sm={12} xs={12}>
          <WarehouseCoverage locationData={locationData} onGridStateSelect={onGridStateSelect} />
        </Grid>
      </>
    )}
    {!locationData && systemId && (
      <>
        <Grid item md={6}>
          {userHasPermission(PERMISSION.VIEW_WAREHOUSE_STATUS) && (
            <WarehouseStatusCard systemId={systemId} />
          )}
        </Grid>
        <Grid item md={6}>
          <WarehouseOccupancyCard systemId={systemId} />
        </Grid>
      </>
    )}
  </Grid>
);
