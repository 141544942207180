import { Link } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
  cell: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    '&:hover a': {
      textDecoration: 'underline',
    },
  },
  link: {
    color: 'black',
    textDecoration: 'none',
  },
}));

export const LinkCell = ({ location }: { location: string }) => {
  const { classes } = useStyles();
  return (
    <div className={classes.cell}>
      <Link
        href={`warehouse-status?location=${location}`}
        target="_blank"
        rel="noopener noreferrer"
        className={classes.link}
      >
        {location}
      </Link>
    </div>
  );
};
