// api

// variables, configurations
import { REQUESTS } from 'common/settings';
import { REQUEST_STATES } from 'common/requestStates';
import {
  IInventoryRequestPostRequestST,
  IInventoryRequestPutRequestST,
} from 'codegen/inventory_request';
import { getInventoryRequestApiService } from './services';

const getRequests = (systemId: string, signal: AbortSignal) => {
  const states = REQUESTS.SHOW_EXPIRED_INVENTORY_REQUESTS
    ? [REQUEST_STATES.ENABLED, REQUEST_STATES.DISABLED, REQUEST_STATES.EXPIRED]
    : [REQUEST_STATES.ENABLED, REQUEST_STATES.DISABLED];

  return getInventoryRequestApiService().getAllInventoryRequests(systemId, states, {
    signal,
  });
};

const getRequest = (systemId: string, id: string) =>
  getInventoryRequestApiService().getInventoryRequest(systemId, id);

const addRequest = (systemId: string, data: IInventoryRequestPostRequestST) =>
  getInventoryRequestApiService().createInventoryRequest(systemId, data);

const deleteRequest = (systemId: string, id: string) =>
  getInventoryRequestApiService().deleteInventoryRequest(systemId, id);

const updateRequest = (systemId: string, id: string, data: IInventoryRequestPutRequestST) =>
  getInventoryRequestApiService().editInventoryRequest(systemId, id, data);

const toggleRequestState = (systemId: string, id: string, enable: boolean) =>
  getInventoryRequestApiService().editInventoryRequest(
    systemId,
    id,
    {} as IInventoryRequestPutRequestST,
    enable.toString() as 'true' | 'false',
  );

const countOccurrences = (systemId: string, rrule: string) =>
  getInventoryRequestApiService().getRRuleInfo(systemId, rrule);

const getNextOccurrence = (
  systemId: string,
  rrule: string,
  after: string | undefined,
  include: boolean | undefined,
) => getInventoryRequestApiService().getNextOccurrence(systemId, rrule, after, include);

export const requestServices = {
  getRequests,
  getRequest,
  addRequest,
  deleteRequest,
  updateRequest,
  toggleRequestState,
  countOccurrences,
  getNextOccurrence,
};
