import { getLogPrefixForType } from 'common/functions/logFunctions';
import { IGetSpaceReservationsResponseST } from 'codegen/nav_simulation';
import { groundControlServices } from '../../../services/GroundControlServices';
import { GetSpaceReservationsParams } from './getSpaceReservations.model';

const logPrefix = getLogPrefixForType('FUNCTION', 'getEstimatedObstacles');

export const getSpaceReservations = ({
  systemId,
  flightDomainId,
  requestController,
  onSuccess,
}: GetSpaceReservationsParams): Promise<{ data: IGetSpaceReservationsResponseST }> => {
  const reservation = requestController.reserveSlotForRequest();
  console.debug(logPrefix, `getSpaceReservations ${requestController.componentName || ''}`);
  return requestController.doRequest({
    request: groundControlServices.getSpaceReservations,
    requestParams: [systemId, flightDomainId, reservation.signal],
    callbackSuccess: ({ data }: { data: IGetSpaceReservationsResponseST }) => onSuccess(data),
  });
};
