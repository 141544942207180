import * as Sentry from '@sentry/react';
import React from 'react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import { rolesFromUserGroups } from 'features/permissions/roleFromUserGroups';
import { Role, ROLE } from 'features/permissions/role.model';
import { isProductionEnvironment } from './functions/environments';
import { USER_GROUPS } from './userGroups';
import { TokenManager } from './tokenManager';
import { getVersionIfAvailable } from './functions/otherFunctions';

const INTERNAL_ROLES: Role[] = [ROLE.SUPER_ADMIN, ROLE.VERITY_USER];

type SentryTags = {
  [key in ROLE]: boolean;
} & {
  IS_INTERNAL: boolean;
  CLIENT: string;
};

export const initSentry = () => {
  if (!Sentry?.isInitialized()) return;
  Sentry.init({
    dsn: 'https://a7ff9e286ea54c9784838477c4825e81@o4504365836271616.ingest.sentry.io/4504367082635264',
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
    ],
    release: getVersionIfAvailable().version,
    environment: import.meta.env.VITE_APP_ENV || '',

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.7,

    enabled: isProductionEnvironment(),
  });
};

export function setSentryUserTags(client?: string) {
  const tokenManager = TokenManager.getInstance();

  const userGroups: USER_GROUPS[] | undefined = tokenManager.userGroupsFromAccessToken();
  const roles: Role[] = rolesFromUserGroups(userGroups!);

  const sentryTags = Object.values(ROLE).reduce(
    (acc, role) => {
      acc[role] = roles.includes(role);
      return acc;
    },
    { CLIENT: client ?? 'undefined' } as SentryTags,
  );

  const isInternal = roles.some((role) => INTERNAL_ROLES.includes(role));
  sentryTags.IS_INTERNAL = isInternal;

  console.debug('Setting Sentry Attributes', sentryTags);

  if (Sentry?.isInitialized()) {
    Sentry.setTags({
      ...sentryTags,
    });
  }
}

export function setSentryClient(client?: string) {
  setSentryUserTags(client);
}
