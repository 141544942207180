import { IUserGetAllResponseST, IUserResponseST } from 'codegen/user_admin';
import { AxiosResponse } from 'axios';
import { User } from './user.model';

const transformUserFromDTO: (user: IUserResponseST) => User = (user) => ({
  id: user.username,
  email: user.email,
  usernameHashed: user.username,
  firstName: user.given_name,
  lastName: user.family_name,
  phone: user.phone_number,
  systemIds: user.system_ids,
  userGroups: user.user_groups ?? [],
  status: user.status,
});

export const transformUsersFromDTO: (
  usersResponse: AxiosResponse<IUserGetAllResponseST, any>,
) => User[] = (usersResponse) => usersResponse.data.users.map(transformUserFromDTO);
