import { Theme, Tooltip } from '@mui/material';
import {
  GridToolbarColumnsButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from '@mui/x-data-grid-premium';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => ({
  tableToolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    padding: theme.spacing(1),
    gap: theme.spacing(2),
    backgroundColor: '#F9F9F9',
    filter: 'grayscale(1)',
  },
  exportTableData: {
    flex: '0 0 auto',
  },
}));

export const ReportTableToolbar = () => {
  const { classes } = useStyles();

  return (
    <div className={classes.tableToolbar}>
      <div>
        <GridToolbarColumnsButton />

        <GridToolbarFilterButton />

        <GridToolbarDensitySelector />
      </div>

      <Tooltip
        title="The export will contain only the visible fields including filters, sorting, etc.
          If you want to export the full content of the report, use the download button on top of the page."
        placement="top"
      >
        <div className={classes.exportTableData}>
          <GridToolbarExport printOptions={{ disableToolbarButton: true }} />
        </div>
      </Tooltip>
    </div>
  );
};
