import { getUserAdminService } from 'services/services';
import { IUserPostRequestST, IUserPostResponseST } from 'codegen/user_admin';
import { AxiosResponse } from 'axios';

export const addUser: ({
  systemId,
  data,
}: {
  systemId: string;
  data: IUserPostRequestST;
}) => Promise<AxiosResponse<IUserPostResponseST, any>> = ({ systemId, data }) =>
  getUserAdminService().createUser(systemId, data);
