import { AlertTitle } from '@mui/material';
import Alert from '@mui/material/Alert';
import moment from 'moment-timezone';

export const SystemWillUpdateAlert = ({ updateDate }: { updateDate: moment.Moment }) => {
  const twoHoursFromUpdateDate = updateDate.clone().add(2, 'hours').format('HH:mm DD/MM/YYYY');

  return (
    <Alert severity="warning">
      <AlertTitle>System update planned</AlertTitle>
      Between {updateDate.format('HH:mm')} and {twoHoursFromUpdateDate} the system will be down for
      an update
    </Alert>
  );
};
