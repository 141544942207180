import { requestStore } from 'store/RequestStore/RequestStore';
import { Reports } from '../../Reports.model';
import { GetScheduledReportsParams } from './getScheduledReportsParams.model';

export const getScheduledReports = ({
  setData,
  setSpinner,
  systemId,
  requestController,
}: GetScheduledReportsParams) => {
  const scheduledReportReservation = requestController.reserveSlotForRequest();
  return requestController.doRequest({
    callbackBeforeSend: () => setSpinner(() => 1),
    request: requestStore.getRequests,
    requestParams: [systemId, scheduledReportReservation.signal],
    callbackSuccess: (r: Reports) => {
      setData(r.reportsScheduledAll);
    },
    messageErrorFallback: 'Something went wrong while getting the scheduled reports',
    callbackFinally: () => setSpinner(() => 0),
  });
};
