import React, { useEffect, forwardRef, useImperativeHandle, useCallback } from 'react';
import moment from 'moment-timezone';
import isEmpty from 'lodash/isEmpty';
import { useSnackbar } from 'notistack';

import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import Collapse from '@mui/material/Collapse';
import Switch from '@mui/material/Switch';
import Grid from '@mui/material/Grid';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDateTimePicker } from '@mui/x-date-pickers/DesktopDateTimePicker';

import {
  IInventoryRequestST,
  IInventoryRequestPostRequestST,
  IReportParameterUniqueNameST,
} from 'codegen/inventory_request/api';
import { IReportSpecificationST } from 'codegen/report_specification/api';
import {
  validateReportName,
  validateAllParamValues,
  validateStartDate,
  validateEndDate,
  validateOccurrenceInterval,
  validateRecurrenceInterval,
  generateRruleFromUIComponents,
  prepareParamValue,
  getParamSpec,
} from 'common/functionsReportSpec';
import { fixNumericInputFields } from 'common/functions/domFunctions';
import { TRIGGERING_SOURCE, INPUT_MODES } from 'common/reportSpecifications';
import {
  INTERVAL_OPTIONS,
  PICKER_DATETIME_FORMAT,
  WeekDayShort,
  getWeekIndexByDay,
  getWeekDayByIndex,
} from 'common/datetimeFormats';
import { Box } from 'components/common/Box';
import { ModalConfirm } from 'components/ModalsAndPopups/ModalConfirm';
import { getLogPrefixForType } from 'common/functions/logFunctions';
import { useFacilityLevelStore } from 'store/FacilityLevelStore/facilityLevelStore';
import { userHasPermission } from 'features/permissions/userHasPermission';
import { PERMISSION } from 'features/permissions/permissions.model';
import { schedulerFormStyles } from './styles';
import './customStyles.css';
import { Recurrence } from './features/Recurrence/Recurrence';
import { NameField, ParamForm } from './features/NameAndParamsForm';
import { ScheduleFormAction, ScheduleFormState } from './reducers/ScheduleFormReducer';
import { ReportPriorityPicker } from './features/ReportPriorityPicker';
import { ReportDeadlines } from './features/ReportDeadlines';
import { ReportPriorityInput } from './features/ReportPriorityInput';
import { IntervalLabel } from './features/Recurrence/recurrence.model';

// make week start on "Monday"
moment.updateLocale('en', { week: { dow: 1 } });

/**
 * Schedule form ref type
 */
export type ScheduleFormRef = {
  validateAndReschedule: () => void;
};

interface IScheduleFormProps {
  /**
   * System ID
   */
  systemId: string;
  /**
   * Report specification for the report to be scheduled.
   */
  reportSpec: IReportSpecificationST;
  /**
   * Used only when editing an existing request.
   */
  originalRequest?: IInventoryRequestST;
  /**
   * Timezone of the facility opened.
   */
  timezone: string;
  /**
   * State of the form.
   */
  state: ScheduleFormState;
  /**
   * Dispatcher for ScheduleFormReducer.
   */
  dispatch: React.Dispatch<ScheduleFormAction>;
  /**
   * Submit request to the server.
   */
  onSubmit: (request: IInventoryRequestPostRequestST) => void;
}

const logPrefix = getLogPrefixForType('COMPONENT', 'ScheduleForm');

export const ScheduleForm = forwardRef(
  (props: IScheduleFormProps, ref: React.ForwardedRef<ScheduleFormRef>) => {
    const {
      systemId,
      reportSpec,
      timezone,
      originalRequest = null,
      state,
      dispatch,
      onSubmit,
    } = props;

    const { classes } = schedulerFormStyles();

    const showReportDeadlines =
      useFacilityLevelStore().stateFacilityLevel.facilitySettings.show_report_deadlines;

    moment.tz.setDefault(timezone);

    // allow start date to be on the past when editing an existing request
    const allowPastStartDate = Boolean(originalRequest);

    const { enqueueSnackbar } = useSnackbar();

    /**
     * Display snackbar with error
     */
    const displayOnSubmitValidationError = () => {
      console.debug(logPrefix, 'displayOnSubmitValidationError');
      enqueueSnackbar('Please ensure that all fields are correct.', {
        variant: 'error',
        preventDuplicate: true,
      });
    };

    /**
     * Validates all fields, sets errors in state and returns true if all fields are valid.
     * @returns are all fields valid?
     */
    const validateAllBeforeSubmit = () => {
      console.debug(logPrefix, 'validateAllBeforeSubmit');
      const validReportName = validateReportName(state.reportName);
      const validParams = validateAllParamValues(reportSpec, state.reportParamValues);
      const validDateFrom = validateStartDate(
        reportSpec,
        state.isRecurring,
        state.dateFrom,
        state.dateUntil,
        allowPastStartDate,
      );
      const validDateUntil = validateEndDate(
        reportSpec,
        state.isRecurring,
        state.dateFrom,
        state.dateUntil,
      );
      const validOccInterval = validateOccurrenceInterval(
        state.isRecurring,
        state.occurrenceInterval,
        state.allowedOccurrenceIntervals,
      );
      const validInterval = validateRecurrenceInterval(state.interval, state.isRecurring);

      dispatch({
        type: 'UPDATE_ERRORS',
        payload: {
          validReportName: validReportName.errorMsg,
          validParams: validParams.errorMessages,
          validDateFrom: validDateFrom.errorMsg,
          validDateUntil: validDateUntil.errorMsg,
          validOccurrence: validOccInterval.errorMsg,
          validInterval: validInterval.errorMsg,
        },
      });

      const valid = [
        validReportName.valid,
        validParams.valid,
        validDateFrom.valid,
        validDateUntil.valid,
        validOccInterval.valid,
        validInterval.valid,
      ].every((inputValid) => inputValid);

      return valid;
    };

    /**
     * Clear all input fields
     */
    const clearInputs = () => {
      console.debug(logPrefix, 'clearInputs', 'CLEAR_INPUTS');
      dispatch({ type: 'CLEAR_INPUTS' });
    };

    const handleRecurring = (event: React.ChangeEvent<HTMLInputElement>) => {
      console.debug(logPrefix, 'handleRecurring', 'SET_IS_RECURRING', event.target.checked);
      dispatch({ type: 'SET_IS_RECURRING', payload: event.target.checked });
    };
    const setDays: (days: WeekDayShort[]) => void = (days) => {
      console.debug(logPrefix, 'setDays', 'SET_DAYS', days);
      dispatch({ type: 'SET_DAYS', payload: days });
    };
    /**
     * Validate form and Schedule Report
     */
    const validateAndSchedule = () => {
      console.debug(logPrefix, 'validateAndSchedule');
      if (validateAllBeforeSubmit()) {
        dispatch({ type: 'SET_MODAL_CONFIRM', payload: true });
      } else {
        displayOnSubmitValidationError();
      }
    };

    /**
     * Submit report
     */
    const handleSubmit = () => {
      // Generate rRule string, text and all occurrence dates
      const { rString } = generateRruleFromUIComponents({
        days: state.days,
        setDays,
        dateFrom: state.dateFrom,
        dateUntil: state.dateUntil,
        isRecurring: state.isRecurring,
        occurrenceInterval: state.occurrenceInterval,
        interval: state.interval,
        timezone,
        reportSpecTriggering: reportSpec.scheduling.triggering_source,
      });

      const isReportStartingNow =
        state.recurrenceState.occurrencesCount <= 1 &&
        moment(state.dateFrom) <= moment().add(1, 'minutes');

      // Payload item
      const item = {
        report_spec_id: reportSpec.id,
        report_spec_version: reportSpec.version,
        report_name: state.reportName,
        params: state.reportParamValues,
        priority: state.priority,
        rrule: rString,
        timeout: state.reportDeadlineTimeoutInMinutes,
        // Start an instant report if it is scheduled for now.
        instant_report: isReportStartingNow,
      };

      // call the submit method received in the props
      // can be either a create or an update
      onSubmit(item);
    };

    /**
     * Validate form and RE-Schedule Report
     */
    const validateAndReschedule = () => {
      console.debug(logPrefix, 'validateAndReschedule');
      if (validateAllBeforeSubmit()) {
        handleSubmit();
      } else {
        displayOnSubmitValidationError();
      }
    };

    // useImperativeHandle allows this component to expose methods to its parent components
    useImperativeHandle(ref, () => ({
      clearInputs,
      validateAndSchedule,
      validateAndReschedule,
    }));

    // Update dateFrom and dateUntil state on mount and timezone change.
    // By updating dateTime states this way we make sure that changing facility
    // which may be in different timezone, converts input values in appropriate timezone.
    useEffect(() => {
      if (timezone) {
        dispatch({
          type: 'SET_DATE_FROM_AND_UNTIL',
          payload: {
            dateFrom: moment().add(1, 'minutes'),
            dateUntil: moment(state.dateFrom).add(1, 'month'),
          },
        });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [timezone]);

    // Invoke form value initialization ONLY upon reportSpec and timezone change.
    useEffect(() => {
      if (!reportSpec) return;
      // If we're editing an existing inventory request
      // we initialize all form fields with the correspondent data
      if (originalRequest) {
        dispatch({
          type: 'INITIALIZE_EXISTING_REQUEST',
          payload: { reportSpec, originalRequest },
        });
      } else {
        // When creating a new inventory request we just set the dateFrom to now
        dispatch({
          type: 'INITIALIZE_NEW_REQUEST',
          payload: { reportSpec },
        });
      }
    }, [dispatch, originalRequest, reportSpec]);

    // This function updates the values of ONE given report param name, if it exists on the reportSpec
    // Input - the parameter to be updated and its new value
    // TODO:MK:20.5.'22: this function should end up in reducer
    const updateReportParamValues = (
      paramUniqueName: IReportParameterUniqueNameST,
      value: any,
    ): void => {
      const lp = getLogPrefixForType('FUNCTION', 'updateReportParamValues');
      console.debug(lp, 'paramUniqueName:', paramUniqueName, 'value:', value);
      const paramSpec = getParamSpec(reportSpec, paramUniqueName);
      if (paramSpec) {
        /**
         * Custom values need to be held in separate state for inputs.
         */
        if (paramSpec.input_mode === INPUT_MODES.CUSTOM_VALUES) {
          dispatch({
            type: 'ADD_CUSTOM_PARAM_VALUES',
            payload: { paramUniqueName, values: value },
          });
          const newValue = prepareParamValue(
            paramUniqueName,
            Array.isArray(value) ? value[0] : value,
          );
          dispatch({
            type: 'SET_REPORT_PARAM_VALUE',
            payload: { paramUniqueName, value: newValue },
          });
        } else {
          dispatch({ type: 'SET_REPORT_PARAM_VALUE', payload: { paramUniqueName, value } });
        }
        dispatch({ type: 'VALIDATE_PARAM_VALUE', payload: { reportSpec, paramUniqueName } });
      } else {
        console.error(
          lp,
          `Error => ScheduleForm.js:updateReportParamValues(): param ${paramUniqueName} does not exist on reportSpec: ${reportSpec.report_spec_name}`,
        );
      }
    };

    /**
     * Remove param values from internal state (for ONE given param name)
     */
    const clearReportParamValues = (paramUniqueName: string) => {
      const lp = getLogPrefixForType('FUNCTION', 'clearReportParamValues');
      console.debug(lp, 'paramUniqueName', paramUniqueName);
      const paramAux = reportSpec?.params?.find((el) => el.unique_name === paramUniqueName);
      if (paramAux) {
        // Filter out the affected parameter, removing it form the internal state
        const auxReportParams = state.reportParamValues.filter(
          (el: any) => el.unique_name !== paramUniqueName,
        );

        dispatch({ type: 'SET_REPORT_PARAM_VALUES', payload: auxReportParams });
      } else {
        console.error(
          lp,
          `Error => ScheduleForm.js:clearReportParamValues(): param ${paramUniqueName} does not exist on reportSpec: ${reportSpec.report_spec_name}`,
        );
      }
    };

    /**
     * Get maximum allowed nRecurrence number
     * based on date from, until and selected interval
     */
    const getAllowedOccurrenceIntervals = useCallback(() => {
      console.debug(logPrefix, 'getAllowedOccurrenceIntervals');
      if (state.isRecurring && state.dateFrom && state.dateUntil) {
        const difference = moment(state.dateFrom).diff(
          state.dateUntil,
          INTERVAL_OPTIONS[state.interval as IntervalLabel] as moment.unitOfTime.Diff,
        );
        dispatch({ type: 'SET_ALLOWED_OCCURRENCE_INTERVALS', payload: Math.abs(difference) });
      }
    }, [state.dateFrom, state.dateUntil, state.interval, state.isRecurring, dispatch]);

    useEffect(() => {
      getAllowedOccurrenceIntervals();
    }, [getAllowedOccurrenceIntervals]);

    useEffect(() => {
      fixNumericInputFields();
    }, [reportSpec]);

    // Auxiliary Rendering functions

    // Render inputs for report name and user
    // facing parameters defined by the report spec
    const renderInputsForReportNameAndParams = () =>
      !isEmpty(reportSpec) && (
        <form className={classes.form} noValidate>
          <Grid className={classes.gridItem} xs={12} item>
            <NameField
              // classes={classes}
              reportName={state.reportName}
              isInputInvalid={!!state.errors.validReportName}
              helperText={state.errors.validReportName as string}
              onChangeOrBlur={(e) => dispatch({ type: 'SET_REPORT_NAME', payload: e.target.value })}
            />
            <ParamForm
              classes={classes}
              params={reportSpec.params}
              customParams={state.customParamValues}
              reportParamValues={state.reportParamValues}
              errors={state.errors}
              updateReportParamValues={updateReportParamValues}
              clearReportParamValues={clearReportParamValues}
              onBlur={(paramUniqueName) =>
                dispatch({ type: 'VALIDATE_PARAM_VALUE', payload: { reportSpec, paramUniqueName } })
              }
            />
            {userHasPermission(PERMISSION.PRIORITY_PICKER_INTERNAL) ? (
              <ReportPriorityInput dispatch={dispatch} priority={state.priority} />
            ) : (
              <ReportPriorityPicker dispatch={dispatch} priority={state.priority} />
            )}
          </Grid>
        </form>
      );

    const handleStartTimeChange = (dateFrom: moment.MomentInput): void => {
      console.debug(logPrefix, 'handleStartTimeChange', 'SET_DATE_FROM', dateFrom);
      moment(dateFrom).isValid() &&
        dispatch({
          type: 'SET_DATE_FROM',
          payload: { dateFrom, reportSpec, allowPastStartDate },
        });
    };

    const handleEndTimeChange = (dateUntil: moment.MomentInput): void => {
      console.debug(logPrefix, 'handleEndTimeChange', 'SET_DATE_UNTIL', dateUntil);
      moment(dateUntil).isValid() &&
        dispatch({
          type: 'SET_DATE_UNTIL',
          payload: { dateUntil, reportSpec, allowPastStartDate },
        });
    };

    /**
     * Render inputs for dates and recurrence
     */
    const renderDatesAndRecurrence = () =>
      !isEmpty(reportSpec) && (
        <form className={classes.form} noValidate>
          <Box className={!state.isRecurring ? '' : classes.formFlexWrapper}>
            <FormControl className={classes.formControl} variant="outlined">
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DesktopDateTimePicker
                  label="Start"
                  ampm={false}
                  disablePast={true}
                  inputFormat={PICKER_DATETIME_FORMAT}
                  mask="____/__/__ __:__"
                  value={state.dateFrom}
                  onChange={handleStartTimeChange}
                  PopperProps={{
                    placement: 'top-end',
                  }}
                  renderInput={(params: TextFieldProps) => (
                    <TextField
                      error={Boolean(state.errors.validDateFrom)}
                      helperText={state.errors.validDateFrom?.toString()}
                      data-testid="c-datetime-from-input"
                      {...params}
                    />
                  )}
                />
              </LocalizationProvider>
            </FormControl>

            {reportSpec.scheduling.triggering_source === TRIGGERING_SOURCE.EXTERNAL_EVENT && (
              <FormControl className={classes.formControl} variant="outlined">
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DesktopDateTimePicker
                    label="End"
                    minDate={moment(state.dateFrom)}
                    ampm={false}
                    inputFormat={PICKER_DATETIME_FORMAT}
                    mask="____/__/__ __:__"
                    value={state.dateUntil}
                    onChange={handleEndTimeChange}
                    PopperProps={{
                      placement: 'top-end',
                    }}
                    renderInput={(params: TextFieldProps) => (
                      <TextField
                        error={Boolean(state.errors.validDateUntil)}
                        helperText={state.errors.validDateUntil?.toString()}
                        data-testid="c-datetime-until-input"
                        {...params}
                      />
                    )}
                  />
                </LocalizationProvider>
              </FormControl>
            )}
            <Collapse className={classes.expandContent} in={state.isRecurring}>
              {state.isRecurring &&
                reportSpec.scheduling.triggering_source ===
                  TRIGGERING_SOURCE.TIME_BASED_SCHEDULE && (
                  <FormControl className={classes.formControl} variant="outlined">
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DesktopDateTimePicker
                        label="End"
                        disablePast={!allowPastStartDate}
                        ampm={false}
                        inputFormat={PICKER_DATETIME_FORMAT}
                        mask="____/__/__ __:__"
                        value={state.dateUntil}
                        onChange={handleEndTimeChange}
                        PopperProps={{
                          placement: 'top-end',
                        }}
                        renderInput={(params: TextFieldProps) => (
                          <TextField
                            error={Boolean(state.errors.validDateUntil)}
                            helperText={state.errors.validDateUntil?.toString()}
                            data-testid="c-datetime-until-input"
                            {...params}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </FormControl>
                )}
            </Collapse>
          </Box>
          <Divider className={classes.divider} />
          {reportSpec.scheduling.allows_recurrence ? (
            <Box display="flex" flexDirection="column" justifyContent="center">
              <FormControlLabel
                style={{ textAlign: 'center' }}
                control={
                  <Switch
                    data-testid="c-recurring-checkbox"
                    checked={state.isRecurring}
                    onChange={handleRecurring}
                    name="recurring"
                  />
                }
                label="Make recurring"
              />
              <Collapse className={classes.expandContent} in={state.isRecurring}>
                <Recurrence
                  systemId={systemId}
                  scheduleFormState={state}
                  setDays={setDays}
                  dispatch={dispatch}
                  reportSpecTriggering={reportSpec.scheduling.triggering_source}
                  timezone={timezone}
                />
              </Collapse>
            </Box>
          ) : null}
        </form>
      );

    const { rString: rStringDeadline } = generateRruleFromUIComponents({
      days: state.days.map((weekDayShort: WeekDayShort) => {
        const date1 = moment(state.dateFrom).startOf('day');
        const date2 = moment(state.dateFrom)
          .add(state.reportDeadlineTimeoutInMinutes ?? 0, 'minutes')
          .startOf('day');

        const daysDiff = date2.diff(date1, 'days');
        const weekIndex = getWeekIndexByDay(weekDayShort) + daysDiff;

        return getWeekDayByIndex(weekIndex);
      }),
      setDays,
      isRecurring: state.isRecurring,
      dateFrom: moment(state.dateFrom).add(state.reportDeadlineTimeoutInMinutes ?? 0, 'minutes'),
      dateUntil: moment(state.dateUntil).add(state.reportDeadlineTimeoutInMinutes ?? 0, 'minutes'),
      occurrenceInterval: state.occurrenceInterval,
      timezone,
      reportSpecTriggering: '',
      interval: state.interval,
    });

    const renderReportDeadlinesParams = () =>
      !isEmpty(reportSpec) && (
        <form className={classes.form} noValidate>
          <Grid className={classes.gridItem} xs={12} item>
            <ReportDeadlines
              systemId={systemId}
              reportDeadline={state.reportDeadline}
              reportDeadlineTimeoutInMinutes={state.reportDeadlineTimeoutInMinutes}
              reportFromDate={state.dateFrom}
              reportUntilDate={state.dateUntil}
              reportDeadlineText={state.isRecurring ? state.reportDeadlineText : ''}
              rString={rStringDeadline}
              dispatch={dispatch}
            />
          </Grid>
        </form>
      );

    /**
     * Render create report UI variation
     * @returns rendered JSX Element
     */
    const renderUIForReportCreation = () =>
      !isEmpty(reportSpec) ? (
        <Grid direction="column" spacing={3} container>
          <Grid
            data-testid="c-request-details-card"
            className={classes.gridItem}
            xs={12}
            sm={6}
            item
          >
            {/* TODO: fix CY - data-testid="c-request-details-card" */}
            {renderInputsForReportNameAndParams()}
            {renderDatesAndRecurrence()}
            <Divider className={classes.divider} />
            {showReportDeadlines && renderReportDeadlinesParams()}
          </Grid>
          <Grid
            data-testid="c-request-dates-card"
            className={classes.gridItem}
            xs={12}
            md={12}
            item
          >
            {/* TODO: fix CY - data-testid="c-request-dates-card" */}
            <Button
              data-testid="c-schedule-report-button"
              fullWidth
              style={{ marginTop: '16px' }}
              variant="contained"
              color="primary"
              onClick={validateAndSchedule}
            >
              Schedule report
            </Button>
          </Grid>

          <ModalConfirm
            opened={state.modalConfirm}
            title="Schedule Report"
            message="Are you sure?"
            handleClose={() => dispatch({ type: 'SET_MODAL_CONFIRM', payload: false })}
            onConfirm={() => {
              dispatch({ type: 'SET_MODAL_CONFIRM', payload: false });
              handleSubmit();
            }}
          />
        </Grid>
      ) : null;

    /**
     * Render create report UI variation
     */
    const renderUIForReportEditing = () =>
      !isEmpty(reportSpec) && originalRequest ? (
        <>
          {renderInputsForReportNameAndParams()}
          <Divider className={classes.divider} />
          {renderDatesAndRecurrence()}
          <Divider className={classes.divider} />
          {showReportDeadlines && renderReportDeadlinesParams()}
        </>
      ) : null;

    // Main Rendering - Render the right UI component variation,
    // depending on whether an originalRequest already exists
    return originalRequest && state.reportParamValues
      ? renderUIForReportEditing()
      : renderUIForReportCreation();
  },
);
