import { reportServices } from '../../../api/ReportServices';

/**
 * Get single report specification
 * @param systemId facility ID
 * @param id report ID
 * @returns fullResponse - route raw response
 */
export const getReportSpecification = (systemId: string, id: string) =>
  reportServices.getReportSpecification(systemId, id).then((r) => ({
    fullResponse: r,
  }));
