import { Button, Typography } from '@mui/material';
import { ModalBase } from 'components/ModalsAndPopups/ModalBase';
import { Box } from 'components/common/Box';
import { ControlledZonesToggleAction } from '../controlledZoneToggleAll.model';
import { ACTION_STATE } from '../defaults/controlledZoneDialogActionState.defaults';

const ControlledZoneDialogMessage = ({
  action,
  numberOfFailedZones,
}: {
  action: ControlledZonesToggleAction;
  numberOfFailedZones: number;
}) => {
  const state = ACTION_STATE[action];

  let message = (
    <Typography>
      {state.action} of all zones was unsuccessful due to the system issue.
      <strong> {numberOfFailedZones}</strong> zones have not been {state.verb}. Please click on the
      “{state.buttonLabel}” button again or try to {action} them manually.
    </Typography>
  );

  if (numberOfFailedZones === 1) {
    message = (
      <Typography>
        {state.action} of all zones was unsuccessful due to the system issue.
        <strong> {numberOfFailedZones}</strong> zone have not been {state.verb}. Please click on the
        “{state.buttonLabel}” button again or try to {action} it manually.
      </Typography>
    );
  }

  return message;
};

export const ControlledZoneToggleAllModal = ({
  action,
  numberOfFailedZones,
  onClick,
  onClose,
}: {
  action: ControlledZonesToggleAction;
  numberOfFailedZones: number;
  onClick: (action: ControlledZonesToggleAction) => void;
  onClose: () => void;
}) => {
  const state = ACTION_STATE[action];

  const titleData = <Box p={2}>{state.action} unsuccessful</Box>;

  const handleClick = () => {
    onClick(action);
    onClose();
  };

  const actionButtons = (
    <Box width="100%" display="flex">
      <Button variant="outlined" onClick={onClose} fullWidth sx={{ mr: 1 }}>
        Cancel
      </Button>
      <Button
        onClick={handleClick}
        variant="contained"
        aria-label={`${state.buttonLabel} zones`}
        fullWidth
      >
        {state.buttonLabel}
      </Button>
    </Box>
  );

  return (
    <ModalBase
      testId="c-location-management-modal"
      opened
      handleClose={onClose}
      title={titleData}
      closeButton
      actionButtons={actionButtons}
    >
      <ControlledZoneDialogMessage action={action} numberOfFailedZones={numberOfFailedZones} />
    </ModalBase>
  );
};
