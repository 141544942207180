import { DroneZones } from '../../../../../../model/drone-zones.model';
import { ZoneSideViewBaseZone } from '../zone-side-view-base-zone/ZoneSideViewBaseZone';
import { useStyles } from './ZoneSideViewOpenFlyZone.styles';

export const ZoneSideViewOpenFlyZone = ({
  zone,
  areaHeight,
  isDisabled,
  onClick,
}: {
  zone: DroneZones;
  areaHeight: number;
  isDisabled: boolean;
  onClick: (zone: DroneZones) => void;
}) => {
  const { classes, cx } = useStyles();

  return (
    <ZoneSideViewBaseZone
      classNames={cx(classes.openFlyZone, {
        [classes.openFlyZoneActive]: zone.isActive,
      })}
      isDisabled={isDisabled}
      zone={zone}
      areaHeight={areaHeight}
      onClick={onClick}
    />
  );
};
