import { ILocationDataST } from 'codegen/warehouse_status';
import { formatShortDate } from '../formatShortDate';

export const foundDateGroupingValueGetter = (row: ILocationDataST) => {
  const dateTimeString = typeof row.verity_status !== 'string' && row.verity_status?.collected_at;
  if (!dateTimeString) {
    return 'N/A';
  }

  const dateTime = new Date(dateTimeString);
  return formatShortDate(dateTime);
};
