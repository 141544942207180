import { ThreeEvent } from '@react-three/fiber';
import { Vector3 } from 'three';

const MAP_3D_FOLDER = '/map-3d';

export const colorMap = {
  ISSUE: {
    color: '#F79E98',
    hover: '#FB8078',
    iconUrl: `${MAP_3D_FOLDER}/error-outline.svg`,
  },
  POTENTIAL_ISSUE: {
    color: '#F3B37E',
    hover: '#F59E56',
    iconUrl: `${MAP_3D_FOLDER}/warning-amber.svg`,
  },
  MATCH: {
    color: '#A3D5A5',
    hover: '#7CC17F',
  },
  EMPTY: {
    color: '#F0EFFD',
    hover: '#E3E2FB',
  },
  EXCLUDED: {
    color: '#EEEEEE',
    hover: '#E0E0E0',
    iconUrl: `${MAP_3D_FOLDER}/stop-circle.svg`,
  },
  NOT_SCANNED: {
    color: '#EEEEEE',
    hover: '#EEEEEE',
    iconUrl: `${MAP_3D_FOLDER}/no-photography.svg`,
  },
};

export type BinStatus = keyof typeof colorMap;

export type ModelsMap = Record<string, React.ElementType>;

export type Vec6 = [
  minX: number,
  minY: number,
  minZ: number,
  maxX: number,
  maxY: number,
  maxZ: number,
];

export interface Bin3DProps {
  status: BinStatus;
  position: Vector3;
  dimensions: Vector3;
  models: ModelsMap;
  id?: string;
  name?: string;
  type?: string;
  box?: Vec6;
  current?: boolean;
  normal?: Vector3;
  onClick?: (event: ThreeEvent<MouseEvent>) => void;
}

export interface Bin3DContainerProps {
  /**
   * Used to pre-compute the buffer size, can be
   * loaded to be equal to the number of locations
   * flightdomain/facility has
   */
  limit?: number;
  bins: Array<Omit<Bin3DProps, 'models'>>;
}
