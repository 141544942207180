import moment from 'moment';
import { LocalStore } from 'common/functions/storageFunctions';
import { IInventoryRequestInternal } from 'interfaces';
import { REPORT_STATES } from 'common/reportStates';
import { PERMISSION } from '../../../../features/permissions/permissions.model';
import {
  userHasPermission,
  userHasSomePermissions,
} from '../../../../features/permissions/userHasPermission';
import { IRequestController } from '../../../../hooks';
import { getArchivedReports } from './utils/get-archived-reports/getArchivedReports';
import { getFinishedReports } from './utils/get-finished-reports/getFinishedReports';
import { getOngoingAndToReviewReports } from './utils/get-ongoing-reports/getOngoingAndToReviewReports';
import { getScheduledReports } from './utils/get-scheduled-reports/getScheduledReports';
import { Dates, Report } from './Reports.model';

/**
 * interface for getReportsAndInventory function
 */
interface ReportsAndInventory {
  dates: Dates;
  shouldLoad: boolean;
  setReportsFinished: (params: Report[]) => void;
  setReportsOngoing: (params: Report[]) => void;
  setReportsToReview: (params: Report[]) => void;
  setReportsScheduled: (params: Report[]) => void;
  setReportsArchived: (params: Report[]) => void;
  setSpinnerFinishedReports: (params: () => number) => void;
  requestController: IRequestController;
  systemId: string;
  setSpinnerOngoingAndToReviewReports: (params: () => number) => void;
  setSpinnerScheduledReports: (params: () => number) => void;
  setSpinnerArchivedReports: (params: () => number) => void;
}

/**
 * Get reports and inventory function
 * @param param0 ReportsAndInventory params
 */
export const getReportsAndInventory = async ({
  dates,
  shouldLoad,
  setReportsFinished,
  setReportsOngoing,
  setReportsToReview,
  setReportsScheduled,
  setReportsArchived,
  setSpinnerFinishedReports,
  requestController,
  systemId,
  setSpinnerOngoingAndToReviewReports,
  setSpinnerScheduledReports,
  setSpinnerArchivedReports,
}: ReportsAndInventory) => {
  if (userHasPermission(PERMISSION.VIEW_FINISHED_REPORTS)) {
    /**
     *  Get finished reports scheduled for a date in the specified date range
     * */
    await getFinishedReports({
      setData: setReportsFinished,
      dates,
      requestController,
      setSpinner: setSpinnerFinishedReports,
      systemId,
    });
  }

  if (shouldLoad) {
    const shouldLoadOngoingAndToReviewReports = userHasSomePermissions([
      PERMISSION.VIEW_ONGOING_REPORTS,
      PERMISSION.REPORTS_FOR_REVIEW_MANAGEMENT,
    ]);
    if (shouldLoadOngoingAndToReviewReports) {
      /**
       * Get all ongoing and "to review" reports, irrespective of scheduled date
       */
      await getOngoingAndToReviewReports({
        setReportsOngoing,
        setReportsToReview,
        setSpinner: setSpinnerOngoingAndToReviewReports,
        requestController,
        systemId,
      });
    }

    if (userHasPermission(PERMISSION.SCHEDULED_REPORTS_MANAGEMENT)) {
      /**
       * Get all scheduled reports, irrespective of scheduled dates
       */
      await getScheduledReports({
        requestController,
        setData: setReportsScheduled,
        setSpinner: setSpinnerScheduledReports,
        systemId,
      });
    }
  }

  if (userHasPermission(PERMISSION.ARCHIVED_REPORTS_MANAGEMENT)) {
    /**
     * Get all archived reports, irrespective of scheduled dates
     */
    await getArchivedReports({
      dates,
      requestController,
      setData: setReportsArchived,
      setSpinner: setSpinnerArchivedReports,
      systemId,
    });
  }
};

/**
 * Format report progress
 * @param progress number
 * @returns formated number
 */
export const formatReportProgress = (progress: number): number =>
  parseFloat((progress * 100).toFixed(2));

/**
 * Verify whether the given report is auto expired
 * @param report Report Request
 * @returns true if the reports is auto expired
 */
export const isReportAutoExpired = (report: IInventoryRequestInternal) => {
  const timezone: string = LocalStore.getTimezone();

  return (
    report?.recurrence === 'Auto' &&
    report?.requestExpiresAt !== '-' &&
    moment(report?.requestExpiresAt).isBefore(moment.tz(timezone))
  );
};

/**
 * Verify whether the given report is the latest recurrence of a recurring one
 * @param report Report Request
 * @returns true if it is the last recurrence
 */
export const isLastRecurrenceOfReport = (report: IInventoryRequestInternal) => {
  const timezone: string = LocalStore.getTimezone();

  return (
    report?.recurrence !== 'Auto' &&
    report?.requestExpiresAt !== '-' &&
    moment(report?.requestExpiresAt).isBefore(moment.tz(timezone))
  );
};

/**
 * Verify whether the report is non-recurring
 * @param report Report Request
 * @returns true if it is not recurring
 */
export const isReportNonRecurring = (report: IInventoryRequestInternal) =>
  // NOTE: is it correct to define a report as non recurring IFF it has no expires at
  // would a non-recurring report with report specific deadline be considered as recurring here
  // eno 2024-04-28
  report?.requestExpiresAt === '-';

/**
 * Verify whether the scheduled report has in the meanwhile been started
 * @param report Report
 * @returns true it it has started
 */
export const isScheduledReportStarted = (report: Report) =>
  report.status === REPORT_STATES.SCHEDULED && report.progress === 0;
