import * as tables from 'common/Tables';

import { useSettingsStore } from '../../store/Settings/settingsStore';

import './style.css';

import { ScheduledReportsCard } from './ScheduledReportsCard';
import { LatestReportsCard } from './LatestReportsCard';

import {
  userHasPermission,
  userHasSomePermissions,
} from '../../features/permissions/userHasPermission';
import { PERMISSION } from '../../features/permissions/permissions.model';
import { OngoingAndToReviewReportsCards } from './OngoingAndToReviewReportsCards';

// Note 1: issueTab are not dependent on the user / user role and, hence, they
// can be calculated as a constant when the home page is instantiated for the
// first time (that is, unlike report tabs, the do not become stale).
// Note 2: it seems that deconstructing a function, automatically invokes it.

export const HomeReportsGrid = () => {
  const { REPORTS_TABLE_TABS } = useSettingsStore();

  // tabs that will be active when navigating to
  // Reports/Report/WHS and WHS Uploads pages
  const { finishedTab, ongoingTab, scheduledTab, toReviewTab } =
    tables.getReportsTabsMappings(REPORTS_TABLE_TABS);

  const isVisibleOngoingOrToReviewCards = userHasSomePermissions([
    PERMISSION.REPORTS_FOR_REVIEW_MANAGEMENT,
    PERMISSION.VIEW_ONGOING_REPORTS,
  ]);
  const isVisibleScheduledCard = userHasPermission(PERMISSION.SCHEDULED_REPORTS_MANAGEMENT);
  const isVisibleFinishedCard = userHasPermission(PERMISSION.VIEW_FINISHED_REPORTS);

  return (
    <>
      {isVisibleOngoingOrToReviewCards && (
        <OngoingAndToReviewReportsCards toReviewTab={toReviewTab} ongoingTab={ongoingTab} />
      )}

      {isVisibleFinishedCard && <LatestReportsCard activeTab={finishedTab} />}

      {isVisibleScheduledCard && <ScheduledReportsCard activeTab={scheduledTab} />}
    </>
  );
};
