import { sortRows, filterRowsBySearchTerm } from 'common/Tables/tableFunctions';
import { HeadCellProp } from '../types/cell';
import { OrderValue } from '../types/rows';

export const filterOrderTableRows = <T extends object>(
  rows: T[],
  searchTerm: string,
  orderBy: keyof T,
  order: OrderValue,
  headCells: Array<HeadCellProp>,
): T[] => sortRows<T>(filterRowsBySearchTerm<T>(rows, searchTerm, headCells), order, orderBy);
