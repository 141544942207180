import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import { TooltipedIcon } from 'components/common/TooltipedIcon';
import { InventoryActionNames } from '../../store/FacilityLevelStore/facilityLevelActions';
import { useFacilityLevelStore } from '../../store/FacilityLevelStore/facilityLevelStore';

import { DeveloperDrawerButton } from './DeveloperDrawerButton';

/**
 * Facility payload type
 */
type FacilityPayload = {
  data: {
    status?: string;
    wms_upload_type?: string;
    updated_locations?: number;
    user?: string;
    systemId: number;
    presigned_url?: string;
    report_name?: string | null;
  };
};

/**
 *
 * @returns Web socket notifications component
 */
export function WebSocketNotifications() {
  // state - global
  const { dispatchFacilityLevel, stateFacilityLevel } = useFacilityLevelStore();
  const systemId = stateFacilityLevel.facilityData.system_id;

  /**
   * warehouse export data payload
   */
  const WH_EXPORT = {
    data: {
      presigned_url:
        'http://localhost:9090/verity-udb-verity-local/c0000s0001/report-download/warehouse-status/20220905-0550_24c07521-dd83-4b68-b883-42aaf5571d5a/warehouse-status_20220905-0550.xlsx?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=testing%2F20220905%2Feu-west-1%2Fs3%2Faws4_request&X-Amz-Date=20220905T095058Z&X-Amz-Expires=7200&X-Amz-SignedHeaders=host&X-Amz-Signature=dfa2a64eb14a4e7c2cd8b6be97af5bb035c3b1b80800470fb697757c8e121de8',
      report_name: null,
      systemId,
    },
  };

  /**
   * warehouse data payload
   */
  const REPORT_EXPORT = {
    data: {
      presigned_url:
        'http://localhost:9090/verity-udb-verity-local/c0000s0001/report-download/report/20220905-0613-00aea774-d8c6-4333-b644-2b93fe3b6aec/20220905_area-scan-report_area-34_20220905-0613_9070d28c-4f30-4895-8172-e1cf69468b52.xlsx?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=testing%2F20220905%2Feu-west-1%2Fs3%2Faws4_request&X-Amz-Date=20220905T101347Z&X-Amz-Expires=7200&X-Amz-SignedHeaders=host&X-Amz-Signature=c61831454c82d582b08ebc14dc9bb5ac4e1134f71fe0c72046eec0909b42dabd',
      report_name: 'Area 34',
      systemId,
    },
  };

  /**
   *
   * @param type Inventory action name
   * @param payload data
   * @returns action dispatch
   */
  const runNotification = (type: InventoryActionNames, payload: FacilityPayload) =>
    dispatchFacilityLevel({
      type,
      payload,
    });

  const runAtInterval = (func: () => void, interval: number, times: number) => {
    let count = 0;
    const intervalId = setInterval(() => {
      if (count >= times) {
        clearInterval(intervalId);
      } else {
        func();
        count += 1;
      }
    }, interval);
  };

  /**
   * Run a notification burst for other facilities
   */
  const runNotificationsBurst = (
    type: InventoryActionNames,
    payload: FacilityPayload,
    systemId: number,
    numberOfNotifications: number,
  ) => {
    // increase the systemId by 1 so, that the notifications will not relate to the current one
    payload.data.systemId = systemId + 1;

    runAtInterval(
      () => {
        payload.data.presigned_url = `http://i-am-a-url/${crypto.randomUUID()}`;
        payload.data.report_name = payload.data.report_name ? `report ${crypto.randomUUID()}` : '';
        dispatchFacilityLevel({
          type,
          payload,
        });
      },
      2000,
      numberOfNotifications,
    );
  };

  const buttons = [
    {
      label: 'Warehouse export',
      onClick: () => runNotification(InventoryActionNames.WS_WAREHOUSE_EXPORT, WH_EXPORT),
      Icon: InfoOutlinedIcon,
      testId: 'wh-export',
      tooltip: 'Initiate warehouse export notification',
    },
    {
      label: 'Report export',
      onClick: () => runNotification(InventoryActionNames.WS_REPORT_EXPORT, REPORT_EXPORT),
      Icon: InfoOutlinedIcon,
      testId: 'report-export',
      tooltip: 'Initiate report export notification',
    },
    {
      label: 'Burst of 10 Warehouse export in other facilities',
      onClick: () =>
        runNotificationsBurst(InventoryActionNames.WS_WAREHOUSE_EXPORT, WH_EXPORT, systemId, 10),
      Icon: InfoOutlinedIcon,
      testId: 'wh-export-burst',
      tooltip: 'Initiate burst of export on other facilities',
    },
    {
      label: 'Burst of 10 Report export in other facilities',
      onClick: () =>
        runNotificationsBurst(InventoryActionNames.WS_REPORT_EXPORT, REPORT_EXPORT, systemId, 10),
      Icon: InfoOutlinedIcon,
      testId: 'report-export-burst',
      tooltip: 'Initiate burst of export on other facilities',
    },
  ];
  return (
    <>
      {buttons.map((i) => (
        <DeveloperDrawerButton
          key={i.testId}
          testId={`c-dev-tools-${i.testId}`}
          buttonLabel={i.label}
          clickHandler={i.onClick}
          content={<TooltipedIcon tooltip={i.tooltip} icon={<i.Icon fontSize="small" />} />}
        />
      ))}
    </>
  );
}
