/* eslint-disable react/no-unknown-property */
import { GroupProps, useFrame, useLoader } from '@react-three/fiber';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader';
import { forwardRef, useRef } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { AlwaysDepth, Euler, Group, Mesh, MeshBasicMaterial, Vector3 } from 'three';
import { getLogPrefixForType } from 'common/functions/logFunctions';
import { MutableState } from 'shared/map-container/reducer/MutableState';
import { Easing } from 'shared/map-container/utils/TweenNumber';
import { droneModelSerialPrefixes } from 'delivery/features/fleet/defaults/fleetColumns.default';
import { DroneMesh } from './DroneMesh';
import { DroneData, LiveMapMutable, MapFleet } from './liveMap.model';
import { TweenEuler } from '../../../../shared/map-container/utils/TweenEuler';
import { TweenVector3 } from '../../../../shared/map-container/utils/TweenVector3';

/**
 * meshName is defined within the .obj file respective of the exported mesh name.
 *  */
const meshURL = {
  PUP: {
    meshName: 'IID_vehicle_mesh',
    url: '/drone_PUP.obj',
  },
  SPUP: {
    meshName: 'IID_vehicle_mesh',
    url: '/drone_SPUP.obj',
  },
  Unknown: {
    meshName: 'IID_ufo',
    url: '/drone_ufo.obj',
  },
};

const serialMatches = (serial: string, matchList: string[]) =>
  matchList.some((entry) => serial.startsWith(entry));

const placeholderPositionAndYaw = { x: 0, y: 0, z: 0, yaw: 0 };

type PosAndYaw = typeof placeholderPositionAndYaw;

const droneMeshScale = [2, 2, 2];

type Pose = { pos: Vector3; rot: Euler };

const flightTime = 1;

const equalPAY = (pay1?: PosAndYaw | null, pay2?: PosAndYaw | null) =>
  pay1 &&
  pay2 &&
  pay1.x === pay2.x &&
  pay1.y === pay2.y &&
  pay1.z === pay2.z &&
  pay1.yaw === pay2.yaw;

const equalStatus = (ds1?: DroneData | null, ds2?: DroneData | null) =>
  ds1 && ds2 && equalPAY(ds1.position_and_yaw as PosAndYaw, ds2.position_and_yaw as PosAndYaw);

const printPose = (p: Pose): string => `pos: ${p.pos.toArray()} rot: ${p.rot.toArray()}`;

const getDroneByName = (n: string, data: MapFleet): DroneData | undefined =>
  Object.values(data).find((d) => d.drone_name === n);

const getMeshDetails = (serial: string): { meshUrl: string; meshName: string } => {
  if (serialMatches(serial, droneModelSerialPrefixes.SPUP)) {
    return {
      meshUrl: meshURL.SPUP.url,
      meshName: meshURL.SPUP.meshName,
    };
  }

  if (serialMatches(serial, droneModelSerialPrefixes.PUP)) {
    return {
      meshUrl: meshURL.PUP.url,
      meshName: meshURL.PUP.meshName,
    };
  }

  return {
    meshUrl: meshURL.Unknown.url,
    meshName: meshURL.Unknown.meshName,
  };
};

const DroneMeshOBJ = forwardRef(
  (
    { meshName, meshUrl, ...props }: GroupProps & { meshUrl: string; meshName: string },
    ref: React.Ref<Group>,
  ) => {
    const obj = useLoader(OBJLoader, meshUrl);
    const mesh = obj.getObjectByName(meshName) as Mesh;

    return (
      <group ref={ref} {...props}>
        <mesh
          geometry={mesh.geometry}
          material={new MeshBasicMaterial({ color: 0xff0000, depthFunc: AlwaysDepth })}
        />
      </group>
    );
  },
);

/**
 * Drone 3D component
 */
export const Drone = (props: { droneStatus: DroneData }) => {
  const { droneStatus } = props;
  const mutableState = () => MutableState.getState<LiveMapMutable>();
  const ref = useRef<Group>(null);
  const lastStatus = useRef<DroneData>(droneStatus);
  const tweenPos = useRef<TweenVector3 | null>(null);
  const tweenRot = useRef<TweenEuler | null>(null);
  const targetPoses = useRef<Pose[]>([]);
  const targetPose = useRef<Pose | null>(null);

  const lp = getLogPrefixForType('COMPONENT', `3D Drone: ${droneStatus.drone_name}`);

  const { meshUrl, meshName } = getMeshDetails(droneStatus.drone_serial || '');

  let posAndYaw: PosAndYaw | undefined | null = droneStatus.position_and_yaw as
    | PosAndYaw
    | undefined
    | null;
  if (!posAndYaw) {
    posAndYaw = placeholderPositionAndYaw;
  }

  const position = new Vector3(posAndYaw.x, posAndYaw.y, posAndYaw.z);

  const rotation = new Euler(0, 0, posAndYaw.yaw - Math.PI / 4);

  const posAndYawToPose = (pay: PosAndYaw): Pose => {
    const pos = new Vector3(pay.x, pay.y, pay.z);
    const rot = new Euler(0, 0, pay.yaw - Math.PI / 4);
    return {
      pos,
      rot,
    };
  };

  /**
   * Checks if drone should take new pose
   * @returns boolean
   */
  const shouldChangePose = (): boolean => {
    const isNotMoving = tweenPos.current == null && tweenRot.current == null;

    return isNotMoving;
  };

  /**
   * Starts up Tweens for position and rotation
   */
  const startChangingPose = (newPos: Vector3, newRot: Euler) => {
    tweenPos.current = new TweenVector3(
      ref.current?.position.clone() ?? new Vector3(),
      newPos.clone(),
      flightTime,
      Easing.EaseOutQuad,
      () => {
        console.debug(
          lp,
          `${
            droneStatus.drone_name
          } reached final position [${ref.current?.position.toArray()}] removing tween and target ${targetPose.current?.pos.toArray()}`,
        );
        tweenPos.current = null;
        targetPose.current = null;
      },
      droneStatus.drone_name,
    );

    tweenRot.current = new TweenEuler(
      ref.current?.rotation ?? new Euler(),
      newRot,
      flightTime,
      Easing.EaseOutQuad,
      () => {
        console.debug(
          lp,
          `${
            droneStatus.drone_name
          } reached final rotation removing tween and target ${targetPose.current?.rot.toArray()}`,
        );
        tweenRot.current = null;
        targetPose.current = null;
      },
      droneStatus.drone_name,
    );
  };

  const runTweens = (dt: number) => {
    if (tweenPos.current != null) {
      const nextPosToTake = tweenPos.current.getNextValue(dt);
      ref.current?.position.copy(nextPosToTake);
    }
    if (tweenRot.current != null) {
      const nextRotToTake = tweenRot.current.getNextValue(dt);
      ref.current?.rotation.copy(nextRotToTake);
    }
  };

  useFrame((_, dt) => {
    if (!ref.current) return;

    let currentDroneStatus;
    if (mutableState && mutableState().mapData) {
      currentDroneStatus = getDroneByName(droneStatus.drone_name, mutableState().mapData.fleet);
    }

    const needUpdate = !equalStatus(currentDroneStatus, lastStatus.current);
    if (currentDroneStatus && needUpdate) {
      lastStatus.current = currentDroneStatus;

      if (lastStatus.current.position_and_yaw) {
        targetPoses.current.push(posAndYawToPose(lastStatus.current.position_and_yaw as PosAndYaw));
      }

      if (targetPoses.current.length > 0 && shouldChangePose()) {
        targetPose.current = targetPoses.current.shift() as Pose;
        startChangingPose(targetPose.current.pos, targetPose.current.rot);
      }
    }

    runTweens(dt);
  });

  console.debug(lp, `loaded and placed at ${printPose({ pos: position, rot: rotation })}`);

  return (
    <ErrorBoundary
      fallback={
        <DroneMesh
          key={meshName + droneStatus.drone_name}
          ref={ref}
          position={position}
          rotation={rotation}
          scale={new Vector3(droneMeshScale[0], droneMeshScale[1], droneMeshScale[2])}
          renderOrder={10}
        />
      }
    >
      <DroneMeshOBJ
        key={meshName + droneStatus.drone_name}
        meshName={meshName}
        meshUrl={meshUrl}
        ref={ref}
        position={position}
        rotation={rotation}
        scale={new Vector3(droneMeshScale[0], droneMeshScale[1], droneMeshScale[2])}
        renderOrder={10}
      />
    </ErrorBoundary>
  );
};
