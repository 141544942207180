import { getLogPrefixForType } from 'common/functions/logFunctions';
import { ILocationsDataSendFeedbackPostRequestSTFeedbackCategoriesEnum } from 'codegen/warehouse_status';
import { initWarehouseStatusService } from 'services/Warehouse/warehouseStatusService';

export const sendLocationInfoEmail = (
  systemId: string,
  location: string,
  slotStatusVersion: number,
  wmsVersion: number,
  parentPage: string,
  reportId: string,
  feedbackCategories: ILocationsDataSendFeedbackPostRequestSTFeedbackCategoriesEnum[],
  message: string,
  linkToLocation: string,
) => {
  const params = {
    location,
    verity_slot_status_version: slotStatusVersion,
    wms_slot_status_version: wmsVersion,
    context: parentPage,
    report_id: reportId,
    feedback_categories: feedbackCategories,
    message,
    link_to_location: linkToLocation,
  };

  if (!params.context) {
    const lp = getLogPrefixForType('FUNCTION', 'sendLocationInfoEmail');
    console.error(lp, 'context is not defined, params => ', params);
  }

  return initWarehouseStatusService().sendLocationsDataFeedback(systemId, params);
};
