import { Vector3 } from 'three';

/**
 * Tween between two Vector3 using some easing function
 */
export class TweenVector3 {
  private currentX: number = 0;

  private currentY: Vector3;

  private numberOfUpdates: number = 0;

  private lerp;

  /**
   * Creates a Tween
   * @param startY starting value
   * @param endY end value to be reached
   * @param maxX duration in seconds
   * @param easingFunction one of those
   * @param whenDone to be called once duration in reached
   */
  constructor(
    public readonly startY: Vector3,
    public readonly endY: Vector3,
    public readonly maxX: number,
    public readonly easingFunction: (n: number) => number,
    private readonly whenDone: () => void,
    public readonly label?: string,
  ) {
    this.currentY = this.startY;
    this.lerp = (v1: Vector3, v2: Vector3, t: number): Vector3 => v1.clone().lerp(v2, t);
  }

  public get isDone() {
    return this.currentX >= this.maxX;
  }

  private get normalizedX() {
    return this.currentX / this.maxX;
  }

  private updateCurrentX = (delta: number): boolean => {
    this.currentX += delta;

    if (this.currentX >= this.maxX) {
      this.whenDone();
      return false;
    }
    return true;
  };

  public getNextValue = (delta: number): Vector3 => {
    if (!this.updateCurrentX(delta)) return this.endY;

    const easingRatio = this.easingFunction(this.normalizedX);

    this.currentY = this.lerp(this.startY, this.endY, easingRatio);

    this.numberOfUpdates += 1;
    return this.currentY;
  };
}
