// react
import { useState } from 'react';

// material-ui core
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';

// components
import { isFunction } from 'common/functions/otherFunctions';
import { Box } from 'components/common/Box';
import { Spinner } from 'components/common/Spinner';
import { ModalBase } from './ModalBase';
import { useFacilityModalsStore } from '../../store/Modals';
import { useRequestController } from '../../hooks';
import { FacilityModalsActionTypes } from '../../store/Modals/types';
import { warehouseStore } from '../../store/WarehouseStore';
import { useFacilityLevelStore } from '../../store/FacilityLevelStore/facilityLevelStore';

interface IWarehouseStatusResetProps {
  /**
   * Flag indicating whether the modal is open
   */
  opened: boolean;
  /**
   * Title of the modal
   */
  title: React.ReactNode;
  /**
   * Subtitle of the modal
   */
  subtitle: React.ReactNode;
  /**
   * Body of the modal
   */
  bodyText: React.ReactNode;
  confirmationWord: string;
  yesLabel: string;
  noLabel: string;
  closeButton?: boolean;
  /**
   * Sets CTA (call to action) fill color on the yes button if true, on the no button if false
   */
  yesCTA: boolean;
}

export const WarehouseStatusReset = (props: IWarehouseStatusResetProps) => {
  // props
  const {
    opened,
    title,
    subtitle,
    bodyText,
    confirmationWord,
    yesLabel,
    noLabel,
    closeButton,
    yesCTA, // sets CTA (call to action) fill color on the yes button if true, on the no button if false
  } = props;

  const [confirmationValue, setConfirmationValue] = useState('');

  const { facilityModalsState, dispatchFacilityModals } = useFacilityModalsStore();
  // NOTE: this component never gets unmounted and, hence, it doesn't matter
  // whether requests are set as cancellable or not.
  const { requestController } = useRequestController('WarehouseStatusReset');

  const [loading, setLoading] = useState(false);

  const { currentSystemId: systemId } = useFacilityLevelStore().stateFacilityLevel;

  /**
   * Reset Warehouse Status Data
   */
  const onYesFunction = () => {
    requestController.doRequest({
      request: warehouseStore.warehouseStatusReset,
      requestParams: [systemId, requestController.signal],
      callbackBeforeSend: () => setLoading(true),
      messageSuccess: 'System reset initiated successfully.',
      messageErrorFallback: 'System reset could not be initiated.',
      callbackSuccess: () => {
        Object.keys(facilityModalsState.refreshData).forEach((func) =>
          facilityModalsState.refreshData[func](),
        );
        dispatchFacilityModals({ type: FacilityModalsActionTypes.SYSTEM_RESET, payload: {} });
      },
      callbackFinally: () => setLoading(false),
    });
  };

  const onNoFunction = () => {
    isFunction(facilityModalsState.resetSystemData.onNoFunction) &&
      facilityModalsState.resetSystemData.onNoFunction();
    facilityModalsState.resetSystemData.onCloseDispatch &&
      dispatchFacilityModals(facilityModalsState.resetSystemData.onCloseDispatch);
  };

  return (
    <ModalBase
      testId="c-type-confirmation-modal"
      closeButton={closeButton}
      opened={opened}
      title={
        <Box>
          <Box textAlign="left" p={2} mb={1}>
            <Typography
              style={{ fontWeight: 'bold' }}
              data-testid="c-type-confirmation-modal-title"
              color="secondary"
              variant="h5"
            >
              {title}
            </Typography>
            <Typography
              color="secondary"
              data-testid="c-type-confirmation-modal-subtitle"
              variant="subtitle1"
            >
              {subtitle}
            </Typography>
          </Box>
        </Box>
      }
      handleClose={onNoFunction}
      actionButtons={
        <>
          <Button
            data-testid="c-modal-reset-btn"
            onClick={() => onYesFunction()}
            variant={yesCTA ? 'contained' : 'outlined'}
            disabled={confirmationValue !== confirmationWord}
            fullWidth
            color="primary"
          >
            {yesLabel}
          </Button>
          <Button
            data-testid="c-modal-cancel-btn"
            onClick={() => onNoFunction()}
            variant={yesCTA ? 'outlined' : 'contained'}
            fullWidth
            color="primary"
          >
            {noLabel}
          </Button>
        </>
      }
    >
      <Typography
        style={{ margin: '16px 0px' }}
        data-testid="c-type-confirmation-modal-body-text"
        align="left"
        component="div"
      >
        {bodyText}
      </Typography>
      <Box pt={2}>
        <TextField
          placeholder={`Type '${confirmationWord}'`}
          data-testid="c-modal-confirmation-input-field"
          inputProps={{ 'data-testid': 'c-modal-confirmation-inner-input-field' }}
          value={confirmationValue}
          error={Boolean(confirmationValue && confirmationValue !== confirmationWord)}
          onChange={(e) => setConfirmationValue(e.target.value)}
          fullWidth
        />
      </Box>
      {loading && <Spinner />}
    </ModalBase>
  );
};
