import { ILocationDataST } from 'codegen/warehouse_status';
import {
  DISPLAY_ISSUE_TYPES,
  DISPLAY_ISSUE_TYPES_KEYS,
  DISPLAY_ISSUE_TYPES_MESSAGE,
} from 'common/issueTypesAndStates';
import { useMemo } from 'react';
import { GridInitialStatePremium } from '@mui/x-data-grid-premium/models/gridStatePremium';
import { getIssueBreakdown } from './utils/getLocationDataStats';
import { WarehouseStatsWidget } from './WarehouseStatsWidget';
import { getDataSerie } from './utils/getDataSeries';
import { WarehouseStatsDataSerie } from './WarehouseStats.model';

export const WarehouseIssues = ({
  locationData,
  onGridStateSelect,
}: {
  locationData?: ILocationDataST[];
  onGridStateSelect?: (state: GridInitialStatePremium) => void;
}) => {
  const issues = useMemo(
    () => (locationData ? getIssueBreakdown(locationData) : null),
    [locationData],
  );

  const issueData: WarehouseStatsDataSerie[] = [];

  if (issues) {
    Object.keys(issues).forEach((key, index) => {
      if (issues[key] > 0) {
        const serie = getDataSerie(
          index.toString(),
          issues[key],
          `${DISPLAY_ISSUE_TYPES[key as DISPLAY_ISSUE_TYPES_KEYS]}`,
          `${DISPLAY_ISSUE_TYPES_MESSAGE[key as DISPLAY_ISSUE_TYPES_KEYS]}`,
          [
            {
              field: 'issues.0.type',
              operator: 'contains',
              value: `${DISPLAY_ISSUE_TYPES[key as DISPLAY_ISSUE_TYPES_KEYS]}`,
            },
            {
              field: 'issues.0.state',
              operator: 'isAnyOf',
              value: ['new', 'known'],
            },
          ],
        );
        issueData.push(serie);
      }
    });
  }

  const totalIssues = issueData.map((i) => i.value).reduce((acc, curr) => acc + curr, 0);

  const colors = [
    'darkred',
    'Crimson',
    'FireBrick',
    'IndianRed',
    'red',
    'tomato',
    'LightCoral',
    'orange',
    'goldrod',
  ];

  return (
    <WarehouseStatsWidget
      id="issues"
      title="Issues"
      unit="issues"
      unitPrefix="active"
      data={issueData}
      counter={totalIssues}
      colors={colors}
      cols={6}
      onGridStateSelect={onGridStateSelect}
    />
  );
};
