import { initWarehouseStatusService } from './warehouseStatusService';

/**
 * Get the list of Active Issue per Facility
 * @param systemId System ID (of the facility)
 * @param signal Abort Signal
 * @returns Count of the issues for the given facility
 */

export const getActiveIssueCount = (systemId: string, signal: AbortSignal) =>
  initWarehouseStatusService().getActiveIssueCount(systemId, { signal });
