import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { HighlightedItemProps } from './HighlightedItem.model';

export const HighlightedIconItem = ({
  title,
  children,
  amount,
  dataTestId,
}: HighlightedItemProps) => (
  <Box component="div" display="flex" alignItems="center">
    <Box component="div" flexBasis="3rem" flexGrow={0} display="flex">
      {children}
    </Box>

    <Box component="div" flexBasis="auto" flexGrow={1}>
      <Typography fontWeight="bold" color="white">
        {title}
      </Typography>
    </Box>

    <Box component="div" flexBasis="3rem" flexGrow={0}>
      <Typography data-testid={dataTestId} variant="h4" color="white" textAlign="end">
        {amount}
      </Typography>
    </Box>
  </Box>
);
