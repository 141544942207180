import { IFlightDomainStatusST } from 'codegen/flight_domain';
import { FleetStatusSummaryST } from 'store/GroundControl/groundControlLevelStore.model';
import { landedDronesMessage } from '../pages/FlightDomainOverview/FlightDomainOverviewFunctions';

type getStatusBarTextParams = {
  flight_domain_status: IFlightDomainStatusST | null | undefined;
  fleet_status_summary: FleetStatusSummaryST | null | undefined;
  isExecutingEmergencyLanding: boolean;
  isInCoexistenceMode: boolean;
  dronesFlying: boolean;
};
export const getStatusBarText = ({
  flight_domain_status,
  fleet_status_summary,
  isExecutingEmergencyLanding,
  isInCoexistenceMode,
  dronesFlying,
}: getStatusBarTextParams): string => {
  if (!fleet_status_summary) {
    return 'No data available.';
  }
  if (isExecutingEmergencyLanding) {
    return 'WARNING: safety landing in progress.';
  }

  if (flight_domain_status?.locked && fleet_status_summary?.num_drones_landed_in_error > 0) {
    return landedDronesMessage(fleet_status_summary?.num_drones_landed_in_error);
  }

  if (isInCoexistenceMode) {
    if (!flight_domain_status?.locked) {
      if (flight_domain_status?.allow_normal_flight_in_controlled_zone) {
        return 'Drones-only mode: No People allowed';
      }
      return 'Collaboration Mode: Drones enabled';
    }
  }

  if (!flight_domain_status?.locked) {
    return 'ATTENTION: drones enabled.';
  }

  if (flight_domain_status?.locked && dronesFlying) {
    return 'ATTENTION: drones are still flying.';
  }

  return 'Drones are on their chargers.';
};
