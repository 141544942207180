import React, { createContext, useReducer, useContext } from 'react';
import { FacilityMenuAction } from './facilityMenuActions';
import {
  initialFacilityMenuState,
  FacilityMenuReducer,
  IFacilityMenuState,
} from './facilityMenuReducer';

interface IFacilityMenuContext {
  stateFacilityMenu: IFacilityMenuState;
  dispatchFacilityMenu?: React.Dispatch<FacilityMenuAction>;
}

export const FacilityMenuStore = createContext<IFacilityMenuContext | undefined>(undefined);

export const useFacilityMenuStore = () => {
  const facilityMenuContext = useContext(FacilityMenuStore);

  if (facilityMenuContext === undefined) {
    throw new Error('useFacilityMenuStore has to be used within <FacilityMenuStore.Provider>');
  }

  return facilityMenuContext;
};

export const FacilityMenuStoreProvider = ({ children }: { children: React.ReactNode }) => {
  const [stateFacilityMenu, dispatchFacilityMenu] = useReducer(
    FacilityMenuReducer,
    initialFacilityMenuState,
  );

  return (
    <FacilityMenuStore.Provider
      value={{
        stateFacilityMenu,
        dispatchFacilityMenu,
      }}
    >
      {children}
    </FacilityMenuStore.Provider>
  );
};
