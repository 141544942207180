import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

export const useMap3DCard = makeStyles()((theme: Theme) => ({
  canvas: {
    gridTemplateColumns: '1 / -1',
    height: '100%',
    minHeight: 500,
  },
  aisleSummary: {
    position: 'absolute',
    left: theme.spacing(1.5),
    bottom: theme.spacing(1.5),
    maxWidth: '80%',
    [theme.breakpoints.down('lg')]: {
      flexWrap: 'wrap',
    },
  },
  zoomControls: {
    position: 'absolute',
    right: theme.spacing(1.5),
    bottom: theme.spacing(1.5),
  },
}));
