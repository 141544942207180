import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { Box } from 'components/common/Box';
import { Spinner } from 'components/common/Spinner';
import { useFacilityLevelStore } from 'store/FacilityLevelStore/facilityLevelStore';
import { EmergencyLandingTrigger } from './EmergencyLandingTrigger';
import { StatusBar } from './StatusBar/StatusBar';

import { useGroundControlStore } from '../../store/GroundControl/groundControlLevelStore';
import { useHideOnScroll } from '../../hooks/useHideOnScroll';

import { useGroundControlModalsStore } from '../../store/Modals/groundControlModals/groundControlModalsStore';
import { GroundControlModalsActionNames } from '../../store/Modals/groundControlModals/groundControlModalsActions';
import { useLoadGroundControlLevelData } from '../../hooks/useLoadGroundControlLevelData';
import { userHasPermission } from '../../features/permissions/userHasPermission';
import { PERMISSION } from '../../features/permissions/permissions.model';

export const GroundControlPage = () => {
  const stickyElement = useHideOnScroll({ threshold: 50 });

  const { stateFacilityLevel } = useFacilityLevelStore();
  const { flightDomainId } = useLoadGroundControlLevelData();
  const { dispatchGroundControlModals } = useGroundControlModalsStore();
  const { isDataReady: isFlightDomainDataReady, stateGroundControl } = useGroundControlStore();

  const { flightDomain, isExecutingEmergencyLanding, isExecutingReturnHome, dronesFlying } =
    stateGroundControl;

  const isInCoexistenceMode = stateFacilityLevel.facilitySettings?.show_coexistence_map;

  useEffect(
    /**
     * close all ground control level modals on mount and on unmount
     */
    () => {
      dispatchGroundControlModals({ type: GroundControlModalsActionNames.RESET });
      return () => dispatchGroundControlModals({ type: GroundControlModalsActionNames.RESET });
    },
    [dispatchGroundControlModals],
  );

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const shouldRenderPage = isFlightDomainDataReady() && flightDomainId;

  return (
    <Box sx={{ display: 'flex', position: 'relative', minHeight: '80vh' }}>
      {shouldRenderPage ? (
        <>
          <Box component="main" sx={{ flexGrow: 1, p: 0 }}>
            {flightDomain && (
              <StatusBar
                flightDomain={flightDomain}
                webSocketError={stateGroundControl.webSocketError}
                isExecutingEmergencyLanding={isExecutingEmergencyLanding}
                isExecutingReturnHome={isExecutingReturnHome}
                isInCoexistenceMode={!!isInCoexistenceMode}
                dronesFlying={dronesFlying}
                stickyStatusBar={!!stickyElement}
                onLockSystem={() =>
                  dispatchGroundControlModals({
                    type: GroundControlModalsActionNames.LOCK_MODAL,
                    payload: flightDomainId,
                  })
                }
                onUnlockSystem={() =>
                  dispatchGroundControlModals({
                    type: GroundControlModalsActionNames.UNLOCK_MODAL,
                    payload: flightDomainId,
                  })
                }
              />
            )}

            <div>
              <Outlet />
            </div>
          </Box>

          {/* Emergency Landing modal trigger */}
          {userHasPermission(PERMISSION.INITIATE_SAFETY_LANDING) && (
            <EmergencyLandingTrigger
              sticky={stickyElement}
              disabled={stateGroundControl.webSocketError}
              onClick={() =>
                dispatchGroundControlModals({
                  type: GroundControlModalsActionNames.EMERGENCY_LANDING_MODAL,
                  payload: flightDomainId || '',
                })
              }
            />
          )}
        </>
      ) : (
        <Spinner label="loading flight domain data ..." />
      )}
    </Box>
  );
};
