import { useMemo } from 'react';
import Alert, { AlertColor } from '@mui/material/Alert';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import { TooltipAlertIcon } from 'components/common/TooltipAlertIcon';

interface IBaseCardSimpleItemAlertBox {
  status?: AlertColor;
  text?: string;
}

export const BaseCardSimpleItemAlertBox = (props: IBaseCardSimpleItemAlertBox) => {
  const { status, text } = props;

  const icon = useMemo(() => {
    if (status === 'warning') {
      return <WarningAmberOutlinedIcon color="warning" fontSize="small" />;
    }
    return <InfoOutlinedIcon color="disabled" fontSize="small" />;
  }, [status]);

  return (
    <TooltipAlertIcon
      tooltip={
        <Alert
          style={{
            marginLeft: '-8px',
            marginTop: '-4px',
            marginBottom: '-4px',
            marginRight: '-8px',
          }}
          severity={status}
          icon={false}
        >
          {text}
        </Alert>
      }
      icon={icon}
    />
  );
};
