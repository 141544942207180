import { IFacilitySettingsST } from 'codegen/facility_settings';
import { SettingsItem } from '../models/settingsCard.model';

export const getWmsIntegrationOptions = (
  facilitySettings: IFacilitySettingsST,
  handleValuesChange: (prop: string, value: number | boolean | string) => void,
  wmsApiHandlers: { label: string; value: string }[],
  wmsParsers: { label: string; value: string }[],
): SettingsItem[] => [
  {
    type: 'select',
    label: 'WMS Client API Handler name',
    selectOptions: wmsApiHandlers,
    testId: 'c-client-api-handler-name',
    description: 'The name of the API handler used to import client WMS data.',
    value: facilitySettings.wms_client_api_handler_name,
    action: (e: React.ChangeEvent<HTMLInputElement>) =>
      handleValuesChange('wms_client_api_handler_name', e.target.value),
  },
  {
    type: 'select',
    testId: 'c-select-wms-parser',
    label: 'WMS parser name',
    selectOptions: wmsParsers,
    description: 'The name of the parser used to import WMS data.',
    value: facilitySettings.wms_parser_name,
    action: (e: React.ChangeEvent<HTMLInputElement>) =>
      handleValuesChange('wms_parser_name', e.target.value),
  },
  {
    type: 'text',
    label: 'Slot Label Regex',
    width: 'medium',
    testId: 'c-slot-label-regex',
    description:
      'This regex is used to validate slot labels when importing WMS data. Note that it is case-sensitive.',
    value: facilitySettings.wms_slot_label_regex,
    action: (e: React.ChangeEvent<HTMLInputElement>) =>
      handleValuesChange('wms_slot_label_regex', e.target.value),
  },
  {
    type: 'text',
    label: 'Snapshot File Regex',
    width: 'medium',
    testId: 'c-snapshot-file-regex',
    description: `This regex is used to map the WMS File Name to the Snapshot WMS Upload Type.
                    The regex should be a pattern that matches the Snapshot file name used by the client.`,
    value: facilitySettings.wms_file_name_regex_snapshot,
    action: (e: React.ChangeEvent<HTMLInputElement>) =>
      handleValuesChange('wms_file_name_regex_snapshot', e.target.value),
  },
  {
    type: 'text',
    label: 'Stock Check File Regex',
    width: 'medium',
    testId: 'c-stock-check-file-regex',
    description: `This regex is used to map the WMS File Name to the Stock Check WMS Upload Type.
                    The regex should be a pattern that matches the Stock Check file name used by the client.`,
    value: facilitySettings.wms_file_name_regex_stock_check,
    action: (e: React.ChangeEvent<HTMLInputElement>) =>
      handleValuesChange('wms_file_name_regex_stock_check', e.target.value),
  },
  {
    type: 'text',
    label: 'Moved Locations File Regex',
    width: 'medium',
    testId: 'c-moved-locations-file-regex',
    description: `This regex is used to map the WMS File Name to the Moved Locations WMS Upload Type.
                    The regex should be a pattern that matches the Moved Locations file name used by the client.`,
    value: facilitySettings.wms_file_name_regex_moved_locations,
    action: (e: React.ChangeEvent<HTMLInputElement>) =>
      handleValuesChange('wms_file_name_regex_moved_locations', e.target.value),
  },
  {
    type: 'switch',
    testId: 'c-allow-wms-upload',
    label: 'Allow manual upload of WMS files',
    description:
      'If enabled, the user can manually upload WMS files (snapshots, moved locations and stock check).',
    value: facilitySettings.allow_manual_wms_file_upload,
    action: (e: React.ChangeEvent<HTMLInputElement>) =>
      handleValuesChange('allow_manual_wms_file_upload', e.target.checked),
  },
  {
    type: 'number',
    width: 'small',
    inputProps: { min: 1, step: '1' },
    label: 'Maximum number of WMS entries per location',
    testId: 'c-maximum-wms-entries-per-location',
    description: `The maximum number of rows in a WMS excerpt that may reference the same location.
                    If the excerpt contains more rows for a location, the location will be considered invalid.`,
    value: facilitySettings.wms_max_num_entries_per_location,
    action: (e: React.ChangeEvent<HTMLInputElement>) =>
      handleValuesChange('wms_max_num_entries_per_location', Number(e.target.value)),
  },
];
