import { RRule } from 'rrule';

export const RRULE_WEEKDAYS = {
  Mo: RRule.MO,
  Tu: RRule.TU,
  We: RRule.WE,
  Th: RRule.TH,
  Fr: RRule.FR,
  Sa: RRule.SA,
  Su: RRule.SU,
};

export const RRULE_INTERVALS = {
  yearly: RRule.YEARLY,
  monthly: RRule.MONTHLY,
  weekly: RRule.WEEKLY,
  daily: RRule.DAILY,
  hourly: RRule.HOURLY,
};

// This array is used for listing days in dropdown
//   -  value - is needed as a translation from the moment library
//              specification to the BE, starting with 1 - Monday ... 7 - Sunday
export const weekDays = [
  {
    value: 7,
    label: 'Sunday',
  },
  {
    value: 1,
    label: 'Monday',
  },
  {
    value: 2,
    label: 'Tuesday',
  },
  {
    value: 3,
    label: 'Wednesday',
  },
  {
    value: 4,
    label: 'Thursday',
  },
  {
    value: 5,
    label: 'Friday',
  },
  {
    value: 6,
    label: 'Saturday',
  },
];

const DISPLAY_WEEKDAY = {
  0: 'Sun',
  1: 'Mon',
  2: 'Tue',
  3: 'Wed',
  4: 'Thu',
  5: 'Fri',
  6: 'Sat',
};

/**
 * Turns a number (1 - 7)(received from api) into a string (Sun - Sat)
 * @param weekDayIndex - number (1 - 7)
 * @returns Capitalized 3 letter string of the week day
 */
export const weekDayIndexTo3Letter = (weekDayIndex: number) =>
  DISPLAY_WEEKDAY[weekDayIndex === 7 ? 0 : (weekDayIndex as keyof typeof DISPLAY_WEEKDAY)];
