import { ILocationDataST } from 'codegen/warehouse_status';
import { replaceArrayItems } from 'common/functions/arrayFunctions';
import { isArray, isEmpty } from 'lodash';
import { emptyCellString } from './collectionDelayValueGetter';

export const wmsQuantityValueGetter = (row: ILocationDataST) => {
  const values =
    typeof row.wms_status !== 'string' &&
    row.wms_status?.state !== 'EMPTY' &&
    row.wms_status &&
    isArray(row.wms_status.qtys) &&
    !isEmpty(row.wms_status.qtys)
      ? replaceArrayItems(
          row.wms_status.qtys.map((q) => q.toString()),
          null,
          emptyCellString,
        )
      : [emptyCellString];

  return values?.join(', ');
};
