import { Vector3 } from 'three';
import { getLogPrefixForType } from 'common/functions/logFunctions';
import { Vec6 } from '../MapContainer.model';
import { NoFlyZoneDrawPosition } from '../../../udb/ground-control/drone-zones/model/NoFlyZone.model';

/**
 * Mutable camera state.
 */
export type CameraState = {
  lod: 'low' | 'high';
  orthographicFOV: number;
  currentOrthographicFOV: number;
  blockCameraMovement: boolean;
  position: Vector3;
  targetPosition: Vector3 | null;
};

export const initialCameraState: CameraState = {
  lod: 'low',
  currentOrthographicFOV: 1,
  orthographicFOV: 50,
  blockCameraMovement: false,
  position: new Vector3(0.1, 0.1, -100),
  targetPosition: null,
};

/**
 * Mutable interaction state.
 */
type MapInteraction = {
  worldBox: Vec6;
  isFloorHeld: boolean;
  /**
   * Cursor position on the ground
   */
  guidePosition: Vector3;
};

const initialMapInteraction: MapInteraction = {
  worldBox: [0, 0, 0, 0, 0, 0],
  isFloorHeld: false,
  guidePosition: new Vector3(),
};

const logPrefix = getLogPrefixForType('STORE', '3D MutableState');

/**
 * Mutable state of the 3D map.
 */
export class MutableState<MutableData> {
  private static instance: MutableState<any> | null = null;

  /**
   * This will trigger re-render hook for map.
   */
  public static forceUpdateMap: () => void = () => console.error('forceUpdateMap not set');

  /**
   * The constructor is private to prevent direct instantiation.
   * @param cameraState initial CameraState
   * @param interaction initial MapInteraction
   * @param NFZCreationState null can be supplied initially
   */
  private constructor(
    public mapName: string,
    public cameraState: CameraState,
    public interaction: MapInteraction,
    public NFZCreationState: NoFlyZoneDrawPosition | null,
    public mapData: MutableData,
  ) {
    console.debug(logPrefix, `MutableState created for ${mapName} with data: ${mapData}`);
  }

  public static destroyInstance = (): void => {
    MutableState.instance = null;
    MutableState.forceUpdateMap = () => console.error('forceUpdateMap not set');
    console.debug(logPrefix, 'destroyInstance() called');
  };

  /**
   * To be used once to create the state.
   * @param cameraState initial camera state
   * @param interaction initial interaction state
   * @param mapData per map mutable data
   * @returns the mutable state
   */
  public static constructState = <MutableData>(
    mapName: string,
    cameraState: CameraState,
    interaction: MapInteraction,
    mapData: MutableData,
  ): MutableState<MutableData> => {
    console.debug(logPrefix, `constructState() called for ${mapName}`);
    this.instance = new MutableState(mapName, cameraState, interaction, null, mapData);
    return this.instance;
  };

  /**
   * Returns the instance of mutable state if it exists, creates one otherwise.
   * @returns a mutable state
   */
  public static getState = <MD>(): MutableState<MD> => {
    if (this.instance === null) {
      console.warn('Construct MutableState before trying to access it.');
      return new MutableState<any>('DECOY', initialCameraState, initialMapInteraction, null, null);
    }
    return this.instance;
  };
}
