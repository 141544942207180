import { useEffect } from 'react';
import { Container } from '@mui/material';
import { getLogPrefixForType } from 'common/functions/logFunctions';
import { LocalStore } from 'common/functions/storageFunctions';
import { PageHeaderSection } from 'components/Page/PageHeaderSection';
import { CardGrid } from 'components/common/CardGrid/CardGrid';
import { useGroundControlStore } from 'store/GroundControl/groundControlLevelStore';
import { useFacilityLevelStore } from 'store/FacilityLevelStore/facilityLevelStore';
import { DroneFlightHours } from './features/droneFlightHours/DroneFlightHours';
import { ScheduleLock } from './features/scheduleLock/ScheduleLock';
import { FlightHoursCalendar } from './features/flightHoursCalendar/FlightHoursCalendar';
import { userHasPermission } from '../../../features/permissions/userHasPermission';
import { PERMISSION } from '../../../features/permissions/permissions.model';
import { OperatingHoursEventST } from './features/droneFlightHours/model/operatingHours.model';

const logPrefix = getLogPrefixForType('COMPONENT', 'FlightDomainSchedule');

export const Schedule = () => {
  const flightDomainId = LocalStore.getFlightDomainId();
  const { stateGroundControl, populateFlightDomainSchedule, saveFlightDomainSchedule } =
    useGroundControlStore();
  const { stateFacilityLevel } = useFacilityLevelStore();

  const { schedule } = stateGroundControl.flightDomainSchedule.drone_operating_hours;

  useEffect(() => {
    if (flightDomainId) {
      console.debug(logPrefix, 'populate flight domain schedule with f_ID', flightDomainId);
      populateFlightDomainSchedule(flightDomainId);
    }
  }, [flightDomainId, populateFlightDomainSchedule]);

  const updateSchedule = (schedule: OperatingHoursEventST[]): void => {
    console.debug(logPrefix, 'updateSchedule', schedule);
    saveFlightDomainSchedule({
      ...stateGroundControl.flightDomainSchedule,
      drone_operating_hours: { schedule },
    });
  };

  const showScheduleLock =
    stateFacilityLevel.facilitySettings?.show_scheduled_lock &&
    userHasPermission(PERMISSION.SCHEDULE_LOCK);

  return (
    <>
      <PageHeaderSection
        title="Schedule"
        subtitle={`Ground control schedule for ${stateGroundControl.flightDomain.flight_domain_name}.`}
      />

      <Container maxWidth="xl" sx={{ paddingTop: '32px' }}>
        <CardGrid columnCount={2}>
          {userHasPermission(PERMISSION.VIEW_DRONE_FLIGHT_HOURS) && (
            <DroneFlightHours
              schedule={schedule}
              setFlightHours={updateSchedule}
              isReadOnlyMode={!userHasPermission(PERMISSION.EDIT_DRONE_FLIGHT_HOURS)}
            />
          )}

          {showScheduleLock && <ScheduleLock />}

          <div style={{ gridColumn: '1 / -1' }}>
            <FlightHoursCalendar schedule={schedule} />
          </div>
        </CardGrid>
      </Container>
    </>
  );
};
