import { useMutation } from '@tanstack/react-query';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { CHALLENGE_NAME } from 'common/authChallengeNames';
import { checkIfUserHasRolesDefined } from 'common/functions/userFunctions';
import { AUTH_PAGES_URLS } from 'common/pages';
import { authStore } from 'store/AuthStore';
import { UserLevelActionNames } from 'store/UserLevelStore/userLevelActions';
import { useUserLevelStore } from 'store/UserLevelStore/userLevelStore';
import { MutationNames } from 'ts-types/MutationNames';

export function useSignInMutation() {
  const { dispatchUserLevelStore } = useUserLevelStore();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      username: '',
      password: '',
    },
    // disabling because we later on cyclic dependency on when we submit
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    onSubmit: (values) => mutation.mutate(values),
  });

  const mutation = useMutation({
    mutationKey: [MutationNames.SIGN_IN],
    mutationFn: ({ username, password }: { username: string; password: string }) =>
      authStore.signIn(username, password),
    onSuccess(r: {
      authData: { challenge_name?: CHALLENGE_NAME; access_token?: string };
      isNewUser: boolean;
      isMfaAuth: boolean;
      redirectRoute: string;
    }) {
      const { access_token: userAccessToken } = r.authData;
      const userHasRolesDefined = checkIfUserHasRolesDefined(userAccessToken);

      // Do not allow redirection for the user without user roles defined,
      // (except in the case of a new user) because all the routes depend
      // on user having at least one role
      if (userHasRolesDefined || r.isNewUser || r.isMfaAuth) {
        dispatchUserLevelStore({ type: UserLevelActionNames.AUTH_PROCESS, payload: true });
        dispatchUserLevelStore({
          type: UserLevelActionNames.AUTH_DETAILS,
          payload: {
            username: formik.values.username,
            challenge_name: r.authData.challenge_name,
          },
        });
        navigate(r.redirectRoute, { state: r.authData });
      } else {
        enqueueSnackbar("User doesn't have a role defined", { variant: 'error' });
        navigate(AUTH_PAGES_URLS.SIGNOUT);
      }
    },
    onError(e: { response: { status: number; data: { message: string } }; code: string }) {
      if (e.response.status === 400 && e.code === 'ERR_BAD_REQUEST') {
        enqueueSnackbar('E-mail and/or password are incorrect.', { variant: 'error' });
      }
      if (e.response.status === 403) {
        enqueueSnackbar(e.response.data.message, { variant: 'error' });
      } else {
        enqueueSnackbar('Failed to sign in', { variant: 'error' });
      }
    },
  });

  return {
    mutation,
    formik,
  };
}
