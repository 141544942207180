type comparable<T> = {
  [Key in keyof T]: T[Key];
};

/**
 * function comparing two values
 * @param a first value of the comparison
 * @param b second value of the comparison
 * @returns 1, 0, -1 depending on how the two elements compare
 */
const compareValues = <T>(a: T, b: T) => {
  if (b < a) {
    return -1;
  }
  if (b > a) {
    return 1;
  }
  return 0;
};

/**
 * Get the first element of the passed comparable.
 * This is used e.g. to order fields with multiple values
 * e.g. locations containing multiple barcodes.
 */
const getFirstElementOfComparable = <T>(a: comparable<T>[keyof T]) => {
  if (!Array.isArray(a)) {
    return a;
  }
  const el = (a as Array<T>).length > 1 ? (a as Array<T>)[0] : a;
  return el;
};

/**
 * function comparing two elements
 * @param a first element of the comparison
 * @param b second element of the comparison
 * @param orderBy direction of the comparison
 * @returns -1, 0, 1
 */
const descendingComparator = <T>(a: comparable<T>, b: comparable<T>, orderBy: keyof T) => {
  const aVal = getFirstElementOfComparable(a[orderBy]);
  const bVal = getFirstElementOfComparable(b[orderBy]);

  const aNum = Number(aVal);
  const bNum = Number(bVal);

  if (!Number.isNaN(aNum) && !Number.isNaN(bNum)) {
    // both values are numbers
    return compareValues(aNum, bNum);
  }
  return compareValues(aVal, bVal);
};

/**
 *
 * @param order filed on which the elements are ordered
 * @param orderBy direction of the comparison (asc, desc)
 * @returns comparator function
 */
export const getComparator = <T>(order: 'desc' | 'asc' | '', orderBy: keyof T) =>
  order === 'desc'
    ? (a: comparable<T>, b: comparable<T>) => descendingComparator<T>(a, b, orderBy)
    : (a: comparable<T>, b: comparable<T>) => -descendingComparator<T>(a, b, orderBy);

/**
 * Sort the given array (in case of same value the lowest index one comes first)
 * @param array array of element to be sorted
 * @param comparator comparing function
 * @returns sorted array
 */
export const stableSort = <T>(
  array: comparable<T>[],
  comparator: (a: comparable<T>, b: comparable<T>) => number,
) => {
  const stabilizedArray = array.map((element, index) => ({ element, index }));
  stabilizedArray.sort((a, b) => {
    const order = comparator(a.element, b.element);
    if (order !== 0) {
      return order;
    }
    // if the two elements have the same value return the one with the lower index first
    return a.index - b.index;
  });
  return stabilizedArray.map((sortedEl) => sortedEl.element);
};
