import { Box } from '@mui/material';
import { IRuleActionST, IRuleST } from 'codegen/facility_settings';
import { ActionPhrase } from './ActionPhrase';
import { ConditionsPhrase } from './ConditionsPhrase';

export const RulePhrase = ({
  rule,
  isNotFirstRule,
  isLastRule,
}: {
  rule: IRuleST;
  isNotFirstRule: boolean;
  isLastRule: boolean;
}) => (
  <Box component="div">
    <ConditionsPhrase conditions={rule.conditions ?? []} isNotFirstRule={isNotFirstRule} />
    <ActionPhrase action={rule.action as IRuleActionST} isLastRule={isLastRule} />
  </Box>
);
