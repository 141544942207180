import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { makeStyles } from 'tss-react/mui';
import { Theme, useMediaQuery } from '@mui/material';

const useTabStyles = makeStyles()((theme: Theme) => ({
  tabs: {
    backgroundColor: '#f9f9f9',
  },
  tab: {
    color: 'inherit',
    borderBottom: '5px solid transparent',
    minHeight: 65,
    minWidth: 120,
    flex: 1,
    whiteSpace: 'nowrap',
  },
  indicator: {
    backgroundColor: theme.palette.primary.main,
    height: '5px',
  },
  customTabsStyle: {
    borderBottomWidth: '1px',
    borderBottomStyle: 'solid',
    borderBottomColor: theme.palette.divider,

    '& .MuiTabScrollButton-root': {
      color: theme.palette.shades.light,
    },
    '& .MuiTabScrollButton-root.Mui-disabled': {
      display: 'none',
    },
    '& .MuiSvgIcon-root': {
      fontSize: '1.2em',
    },
  },
}));

type EnhancedTableTabsProps = {
  tabs: {
    icon?: JSX.Element;
    label: any;
  }[];
  activeTab: number;
  handleTabChange: (...params: any) => void;
};

export const EnhancedTableTabs = (props: EnhancedTableTabsProps) => {
  // props
  const { tabs, activeTab, handleTabChange } = props;

  // styles
  const { classes } = useTabStyles();

  const isSmallerScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));

  return (
    <Tabs
      className={classes.tabs}
      value={activeTab}
      variant={isSmallerScreen ? 'scrollable' : 'fullWidth'}
      onChange={handleTabChange}
      scrollButtons="auto"
      aria-label="tabs"
      classes={{
        indicator: classes.indicator,
        root: classes.customTabsStyle,
      }}
    >
      {tabs.map((tab) => (
        <Tab
          data-testid="c-table-tab"
          className={classes.tab}
          key={tab.label}
          icon={tab?.icon}
          label={tab.label}
        />
      ))}
    </Tabs>
  );
};
