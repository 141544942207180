import { IconButton, Typography } from '@mui/material';
import { useCallback, useEffect, useRef } from 'react';
import { Close } from '@mui/icons-material';
import { COLOR_PALETTE } from 'common/colors';
import { AlertComponent } from 'components/common/Alert';
import { DroneIcon } from 'components/common/FacilityMenu/Icons/DroneIcon';
import { useStyles } from './drone-zone-details-panel.styles';
import { DroneZoneDetailsPanelOption } from './features/drone-zone-details-panel-option/drone-zone-details-panel-option';
import { ZoneSideView } from './features/zone-side-view/zone-side-view';
import { DroneZones } from '../../model/drone-zones.model';
import { DroneZoneDetailsPanelZoneProperties } from './features/drone-zone-details-panel-zone-properties/DroneZoneDetailsPanelZoneProperties';

export const DroneZoneDetailsPanel = ({
  zones,
  areaHeight,
  selectedZone,
  showDronesFlyingBanner = false,
  onSelect,
  onClose,
}: {
  zones: DroneZones[];
  areaHeight: number;
  selectedZone: DroneZones | null;
  showDronesFlyingBanner?: boolean;
  onSelect: (zone: DroneZones) => void;
  onClose: () => void;
}) => {
  const { classes } = useStyles();

  const wrapperBodyRef = useRef<HTMLDivElement>(null);

  const isDroneAlertVisible =
    showDronesFlyingBanner &&
    zones.some((zone) => 'waitingForDronesToComeHome' in zone && zone.waitingForDronesToComeHome);

  const handleSelectZone = (zone: DroneZones) => {
    onSelect(zone);
  };

  const handleEscKeyClose = useCallback(
    (evt: KeyboardEvent) => {
      if (evt.key === 'Escape') {
        onClose();
      }
    },
    [onClose],
  );

  const handleScrollDetailsToTop = (element: HTMLDivElement | null) => {
    if (element) {
      element.scrollTo({
        top: 0,
        // @ts-expect-error - Once we upgrade TS to latest version this should no longer
        behavior: 'instant',
      });
    }
  };

  useEffect(() => {
    handleScrollDetailsToTop(wrapperBodyRef.current);
  }, [selectedZone]);

  useEffect(() => {
    window.addEventListener('keydown', handleEscKeyClose);

    return () => {
      window.removeEventListener('keydown', handleEscKeyClose);
    };
  }, [handleEscKeyClose]);

  return (
    <div className={classes.wrapper}>
      <div className={classes.wrapperHeader}>
        <div className={classes.wrapperHeaderControls}>
          <Typography className={classes.wrapperHeaderControlsInfo} variant="body1">
            Press ESC to close
          </Typography>

          <div className={classes.wrapperHeaderControlsActions}>
            <IconButton
              className={classes.closeButton}
              aria-label="Close details pane"
              size="small"
              onClick={onClose}
            >
              <Close />
            </IconButton>
          </div>
        </div>

        <AlertComponent
          isActive={isDroneAlertVisible}
          message="Drones still flying"
          icon={<DroneIcon color={COLOR_PALETTE.ORANGE} />}
          preventClose={false}
          status="warning"
        />

        {zones.length > 1 && (
          <div className={classes.wrapperHeaderSelection}>
            <ul className={classes.wrapperHeaderOptions}>
              {zones.map((zone) => (
                <DroneZoneDetailsPanelOption
                  key={zone.id}
                  name={zone.name}
                  minZ={zone.sizeAndPosition.minZ}
                  maxZ={zone.sizeAndPosition.maxZ}
                  isSelected={selectedZone?.id === zone.id}
                  onClick={() => handleSelectZone(zone)}
                />
              ))}
            </ul>
          </div>
        )}
      </div>

      <div ref={wrapperBodyRef} className={classes.wrapperBody}>
        <div ref={wrapperBodyRef} className={classes.wrapperBodyScrollableArea}>
          <div>
            <ZoneSideView
              areaHeight={areaHeight}
              zones={zones}
              selectedZone={selectedZone}
              onSelect={handleSelectZone}
            />
          </div>

          {selectedZone ? (
            <DroneZoneDetailsPanelZoneProperties zone={selectedZone} />
          ) : (
            <div className={classes.detailsPlaceholder}>
              <Typography variant="body1" color="textSecondary">
                Select a zone to see its details
              </Typography>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
