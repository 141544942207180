import { IRequestController } from '../../../../hooks';
import { groundControlServices } from '../../../../services/GroundControlServices';
import { INoFlyZonesResponseST } from '../../../../codegen/no_fly_zone';

export const getNoFlyZones = ({
  requestController,
  onBeforeSend,
  onSuccess,
  onFinally,
  systemId,
  flightDomainId,
}: {
  requestController: IRequestController;
  onBeforeSend: () => void;
  onSuccess: (payload: { data: INoFlyZonesResponseST }) => void;
  onFinally: () => void;
  systemId: string;
  flightDomainId: string;
}) => {
  const noFlyZonesReservation = requestController.reserveSlotForRequest();
  requestController.doRequest({
    request: groundControlServices.getNoFlyZones,
    requestParams: [systemId, flightDomainId, noFlyZonesReservation.signal],
    messageErrorFallback: 'There was a problem fetching "No-fly zones"',
    callbackBeforeSend: onBeforeSend,
    callbackSuccess: onSuccess,
    callbackFinally: onFinally,
  });
};
