import { useQuery } from '@tanstack/react-query';
import Timeline from '@mui/lab/Timeline';
import { timelineItemClasses } from '@mui/lab/TimelineItem';
import { makeStyles } from 'tss-react/mui';
import { useTheme, Button } from '@mui/material';
import { BaseCard } from 'components/BaseCard/BaseCard';
import { Box } from 'components/common/Box';
import { QueryNames } from 'ts-types/QueryNames';
import { useEffect, useRef, useState } from 'react';
import { getLocationHistory } from './api/getLocationHistory';
import { CustomTimeLineItem } from './CustomTimeLineItem';
import { TransformedHistoryData, transformLocationHistory } from './utils/transformLocationHistory';

const useStyles = makeStyles()(() => ({
  timelineDiv: {
    height: '424px',
    marginBottom: '-24px',
    overflowY: 'auto',
    padding: 0,
    paddingTop: 10,
    '&::-webkit-scrollbar': {
      height: 9,
      width: 5,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#BDBDBD',
      borderRadius: 200,
    },
  },

  loadMoreDiv: {
    paddingLeft: '70px',
    paddingTop: '5px',
  },

  timeline: {
    [`& .${timelineItemClasses.root}:before`]: {
      flex: '0 1 auto',
      padding: 0,
    },
  },
}));

export const LocationHistory = ({
  systemId,
  slotLabel,
  timePoint,
}: {
  slotLabel: string;
  systemId: string;
  timePoint?: string;
}) => {
  const { classes } = useStyles();
  const theme = useTheme();

  const nextTimepointRef = useRef<string | null>(timePoint || null);
  const [historyData, setHistoryData] = useState<TransformedHistoryData>({
    items: [],
    next_timepoint: null,
  });

  const {
    isLoading,
    data: newHistoryData = {
      items: [],
      next_timepoint: null,
    },
    refetch,
  } = useQuery({
    queryKey: [QueryNames.LOCATION_MODAL_GET_LOCATION_HISTORY],
    queryFn: () => getLocationHistory(systemId, slotLabel, nextTimepointRef.current),
    select: ({ data }) => transformLocationHistory(data),
  });

  useEffect(() => {
    if (newHistoryData.items.length > 0) {
      setHistoryData((prevHistoryData) => ({
        items: [...prevHistoryData.items, ...newHistoryData.items],
        next_timepoint: newHistoryData.next_timepoint,
      }));
    }
  }, [newHistoryData]);

  const handleClick = () => {
    nextTimepointRef.current = newHistoryData.next_timepoint;
    refetch();
  };

  useEffect(() => {
    nextTimepointRef.current = timePoint || null;
    setHistoryData({
      items: [],
      next_timepoint: null,
    });
    refetch();
  }, [refetch, slotLabel, timePoint]);

  return (
    <BaseCard
      cardFor="location_history"
      showHeader={true}
      showContent={true}
      showActionButtons={false}
      isLoading={isLoading}
      sx={{ border: '1px solid', borderColor: theme.palette.grey[200] }}
      title="History"
      subtitle="Timeline of the location based on WMS Updates and drone reports."
      scrollable
    >
      <Box className={classes.timelineDiv}>
        <Timeline className={classes.timeline}>
          {historyData.items.map((item, i) => (
            <CustomTimeLineItem
              key={slotLabel + crypto.randomUUID()}
              item={item}
              systemId={systemId}
              slotLabel={slotLabel}
              lastItem={i === historyData.items.length - 1 && historyData.next_timepoint === null}
            />
          ))}
        </Timeline>
        {historyData.next_timepoint && (
          <div className={classes.loadMoreDiv}>
            <Button color="primary" variant="outlined" size="small" onClick={handleClick}>
              Load More
            </Button>
          </div>
        )}
      </Box>
    </BaseCard>
  );
};
