import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';

import IconButton from '@mui/material/IconButton';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';

import ChevronLeftOutlinedIcon from '@mui/icons-material/ChevronLeftOutlined';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import MonitorIcon from '@mui/icons-material/Monitor';
import DvrIcon from '@mui/icons-material/Dvr';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import ListAltIcon from '@mui/icons-material/ListAlt';
import UploadIcon from '@mui/icons-material/Upload';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import WysiwygigIcon from '@mui/icons-material/Wysiwyg';
import PersonIcon from '@mui/icons-material/Person';
import CloudOffIcon from '@mui/icons-material/CloudOff';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import ViewCompactIcon from '@mui/icons-material/ViewCompact';
import ScienceRounded from '@mui/icons-material/ScienceRounded';
import MapIcon from '@mui/icons-material/Map';
import AssistantPhotoIcon from '@mui/icons-material/AssistantPhoto';

import {
  DELIVERY_PAGES_URLS,
  FACILITY_SETTINGS_PAGES_URLS,
  INVENTORY_PAGES_URLS,
  WARNING_PAGES_URLS,
  GROUND_CONTROL_PAGES_URLS,
} from 'common/pages.tsx';
import { getFlightDomainStatusColor } from 'common/functions/otherFunctions';
import { GroundControlSideIcon } from './ground-control-side-icon/GroundControlSideIcon';
import { FacilityDetailsIcon } from './Icons/FacilityDetailsIcon';
import { FacilitySettingsIcon } from './Icons/FacilitySettingsIcon';
import { DroneIcon } from './Icons/DroneIcon';
import { NoFlyZoneIcon } from './Icons/NoFlyZoneIcon';

import { useFacilityLevelStore } from '../../../store/FacilityLevelStore/facilityLevelStore';
import { useClientLevelStore } from '../../../store/ClientLevelStore/clientLevelStore';
import { ClientModalsActionTypes } from '../../../store/Modals/types';
import { useClientModalsStore } from '../../../store/Modals';
import { useFacilityMenuStore } from '../../../store/FacilityMenuStore/facilityMenuStore';

import { FacilityMenuActionNames } from '../../../store/FacilityMenuStore/facilityMenuActions';

import { Box } from '../Box';
import { FacilityMenuItem } from './FacilityMenuItem';
import { useComponentDidMount } from '../../../hooks/useComponentDidMount';
import { Drawer } from './StylizedComponents/Drawer';
import { DrawerHeader } from './StylizedComponents/DrawerHeader';
import { DrawerList } from './StylizedComponents/DrawerList';
import { ToggleWrapper } from './StylizedComponents/ToggleWrapper';
import {
  userHasPermission,
  userHasSomePermissions,
} from '../../../features/permissions/userHasPermission';
import {
  PERMISSION,
  overviewPagePermissions,
  reportsPermissions,
  safetyPermissions,
} from '../../../features/permissions/permissions.model';

// when converted to TS use const ExperimentalIconBadge = (parentIcon: JSX.Element) =>
const BetaFeatureIconBadge = (parentIcon) => (
  <div style={{ position: 'relative', display: 'inline-flex' }}>
    {parentIcon}
    <ScienceRounded
      color="warning"
      fontSize="small"
      sx={{ position: 'absolute', top: -7, right: -7 }}
    />
  </div>
);

export const FacilityMenu = (props) => {
  const { systemId, stickyElement = '' } = props;

  const location = useLocation();

  const theme = useTheme();

  const { stateFacilityLevel } = useFacilityLevelStore();
  const { stateClientLevel } = useClientLevelStore();
  const { stateFacilityMenu, dispatchFacilityMenu } = useFacilityMenuStore();
  const { dispatchClientModals } = useClientModalsStore();
  const { expandedItems } = stateFacilityMenu;
  const { facilityData, flightDomains } = stateFacilityLevel;

  // This constant is used as prop value for mui custom styling
  // According to warning that library throws, value can not be a boolean.
  const multipleFacilitiesAccess = stateClientLevel.isMultiFacility ? 1 : 0;

  // facility menu opened state
  const open = stateFacilityMenu.menuOpened;

  /**
   * True when the facility is configured and the data is available in the store
   */
  const isFacilityDataAvailable = useMemo(() => !isEmpty(facilityData), [facilityData]);

  const isAnalyticsMenuItemVisible =
    isFacilityDataAvailable &&
    stateFacilityLevel.facilitySettings?.enable_superset_integration &&
    userHasPermission(PERMISSION.VIEW_ANALYTICS);

  const inventoryLinks = useMemo(
    () => [
      {
        path: INVENTORY_PAGES_URLS.OVERVIEW,
        label: 'Overview',
        visible: isFacilityDataAvailable && userHasSomePermissions(overviewPagePermissions),
        testId: 'c-nav-link-home',
        selected: location.pathname === `/${systemId}${INVENTORY_PAGES_URLS.OVERVIEW}`,
        icon: <MonitorIcon />,
      },
      {
        path: INVENTORY_PAGES_URLS.WAREHOUSE_STATUS,
        label: 'Warehouse status',
        visible: isFacilityDataAvailable && userHasPermission(PERMISSION.VIEW_WAREHOUSE_STATUS),
        testId: 'c-nav-link-warehouse-status',
        selected: location.pathname === `/${systemId}${INVENTORY_PAGES_URLS.WAREHOUSE_STATUS}`,
        icon: <AssignmentTurnedInIcon />,
      },
      {
        path: INVENTORY_PAGES_URLS.WAREHOUSE_STATUS_X,
        label: 'Warehouse status (Beta)',
        visible:
          stateFacilityLevel.facilitySettings?.enable_experimental_features &&
          isFacilityDataAvailable &&
          userHasPermission(PERMISSION.VIEW_WAREHOUSE_STATUS),
        testId: 'c-nav-link-warehouse-status-beta',
        selected: location.pathname === `/${systemId}${INVENTORY_PAGES_URLS.WAREHOUSE_STATUS_X}`,
        icon: BetaFeatureIconBadge(<AssignmentTurnedInIcon />),
      },
      {
        path: INVENTORY_PAGES_URLS.REPORTS,
        label: 'Reports',
        visible: isFacilityDataAvailable && userHasSomePermissions(reportsPermissions),
        testId: 'c-nav-link-reports',
        pointerEvents:
          location.pathname === `/${systemId}${INVENTORY_PAGES_URLS.SCHEDULING}` ||
          location.pathname.includes(`${INVENTORY_PAGES_URLS.REPORT}/`),
        selected:
          location.pathname === `/${systemId}${INVENTORY_PAGES_URLS.REPORTS}` ||
          location.pathname === `/${systemId}${INVENTORY_PAGES_URLS.SCHEDULING}` ||
          location.pathname.includes(`${INVENTORY_PAGES_URLS.REPORT}/`),
        icon: <ListAltIcon />,
      },
      {
        path: INVENTORY_PAGES_URLS.ANALYTICS,
        label: 'Analytics',
        visible: isAnalyticsMenuItemVisible,
        testId: 'c-nav-link-analytics',
        selected: location.pathname === `/${systemId}${INVENTORY_PAGES_URLS.ANALYTICS}`,
        icon: <AnalyticsIcon />,
      },
      {
        path: INVENTORY_PAGES_URLS.LOCATIONS_MANAGEMENT,
        label: 'Locations management',
        visible: isFacilityDataAvailable && userHasPermission(PERMISSION.VIEW_LOCATION_STATUS),
        testId: 'c-nav-link-locations-management',
        selected: location.pathname === `/${systemId}${INVENTORY_PAGES_URLS.LOCATIONS_MANAGEMENT}`,
        icon: <ViewCompactIcon />,
      },
    ],
    [
      isFacilityDataAvailable,
      location.pathname,
      systemId,
      stateFacilityLevel.facilitySettings?.enable_experimental_features,
      isAnalyticsMenuItemVisible,
    ],
  );

  const adminLinks = useMemo(
    () => [
      {
        label: 'System details',
        path: FACILITY_SETTINGS_PAGES_URLS.SYSTEM_DETAILS,
        icon: <WysiwygigIcon />,
        visible: true,
        selected:
          location.pathname === `/${systemId}${FACILITY_SETTINGS_PAGES_URLS.SYSTEM_DETAILS}`,
        testId: 'c-admin-link-system-details',
      },
      {
        label: 'Facility details',
        path: FACILITY_SETTINGS_PAGES_URLS.FACILITY_DETAILS,
        icon: (
          <FacilityDetailsIcon
            theme={theme}
            active={
              location.pathname === `/${systemId}${FACILITY_SETTINGS_PAGES_URLS.FACILITY_DETAILS}`
            }
          />
        ),
        visible: true,
        selected:
          location.pathname === `/${systemId}${FACILITY_SETTINGS_PAGES_URLS.FACILITY_DETAILS}`,
        testId: 'c-admin-link-facility-details',
      },
      {
        label: 'Facility settings',
        path: FACILITY_SETTINGS_PAGES_URLS.FACILITY_SETTINGS,
        icon: (
          <FacilitySettingsIcon
            theme={theme}
            active={
              location.pathname === `/${systemId}${FACILITY_SETTINGS_PAGES_URLS.FACILITY_SETTINGS}`
            }
          />
        ),
        visible: isFacilityDataAvailable,
        selected:
          location.pathname === `/${systemId}${FACILITY_SETTINGS_PAGES_URLS.FACILITY_SETTINGS}`,
        testId: 'c-admin-link-facility-settings',
      },
      {
        label: 'Uploads',
        path: FACILITY_SETTINGS_PAGES_URLS.UPLOADS,
        icon: <UploadIcon />,
        visible:
          stateFacilityLevel.facilitySettings &&
          stateFacilityLevel.facilitySettings.allow_manual_wms_file_upload,
        selected: location.pathname === `/${systemId}${FACILITY_SETTINGS_PAGES_URLS.UPLOADS}`,
        testId: 'c-admin-link-uploads',
      },
      {
        label: 'Users',
        path: FACILITY_SETTINGS_PAGES_URLS.USERS,
        icon: <PersonIcon />,
        visible: userHasPermission(PERMISSION.USER_MANAGEMENT),
        selected: location.pathname === `/${systemId}${FACILITY_SETTINGS_PAGES_URLS.USERS}`,
        testId: 'c-admin-link-users',
      },
    ],
    [
      isFacilityDataAvailable,
      location.pathname,
      stateFacilityLevel.facilitySettings,
      systemId,
      theme,
    ],
  );

  const deliveryFlightDomainsMenuItems = () => {
    const fdLinks = flightDomains.map((flightDomain) => {
      const droneIconColor = 'currentColor';

      return {
        label: flightDomain.flight_domain_name,
        visible: true,
        testId: `delivery_flight_domain_${flightDomain.flight_domain_id}`,
        opened: expandedItems?.includes(`delivery_flight_domain_${flightDomain.flight_domain_id}`),
        items: [
          {
            id: `commissioning_task_${flightDomain.flight_domain_id}`,
            label: 'Commissioning',
            path: DELIVERY_PAGES_URLS(flightDomain.flight_domain_id).COMMISSIONING,
            icon: <DvrIcon />,
            visible: true,
            testId: 'c-delivery-commissioning',
            selected:
              location.pathname ===
              `/${systemId}${DELIVERY_PAGES_URLS(flightDomain.flight_domain_id).COMMISSIONING}`,
          },
          {
            id: `fleet_${flightDomain.flight_domain_id}`,
            label: 'Fleet',
            path: DELIVERY_PAGES_URLS(flightDomain.flight_domain_id).FLEET,
            icon: <DroneIcon color={droneIconColor} />,
            visible: true,
            testId: 'c-delivery-fleet',
            selected:
              location.pathname ===
              `/${systemId}${DELIVERY_PAGES_URLS(flightDomain.flight_domain_id).FLEET}`,
          },
          {
            id: 'droneZones',
            label: 'Drone zones',
            path: DELIVERY_PAGES_URLS(flightDomain.flight_domain_id).DRONE_ZONES,
            icon: <MapIcon />,
            visible:
              userHasPermission(PERMISSION.DRONE_ZONES_MANAGEMENT) &&
              stateFacilityLevel.facilitySettings?.show_coexistence_map,
            testId: 'c-delivery-drone-zones',
            selected:
              location.pathname ===
              `/${systemId}${DELIVERY_PAGES_URLS(flightDomain.flight_domain_id).DRONE_ZONES}`,
          },
        ],
      };
    });

    return [
      {
        path: DELIVERY_PAGES_URLS().PROGRESS,
        label: 'Progress',
        visible: false,
        testId: 'c-delivery-progress',
        selected: location.pathname === `/${systemId}${DELIVERY_PAGES_URLS().PROGRESS}`,
        icon: <AssistantPhotoIcon />,
      },
      ...fdLinks,
    ];
  };

  const flightDomainsMenuItems = () =>
    flightDomains &&
    flightDomains
      .sort((a, b) => (a.flight_domain_name <= b.flight_domain_name ? -1 : 1))
      .map((flightDomain) => {
        const statusColor = getFlightDomainStatusColor({
          locked: flightDomain.flight_domain_status?.locked || false,
          isExecutingEmergencyLanding: flightDomain.isExecutingEmergencyLanding,
          isExecutingReturnHome: flightDomain.isExecutingReturnHome,
        });

        const droneIconColor = 'currentColor';

        return {
          label: flightDomain.flight_domain_name,
          visible: true,
          testId: `flight_domain_${flightDomain.flight_domain_id}`,
          opened: expandedItems?.includes(`flight_domain_${flightDomain.flight_domain_id}`),
          path: `/${systemId}${GROUND_CONTROL_PAGES_URLS(flightDomain.flight_domain_id).OVERVIEW}`,
          statusColor,
          icon: stateFacilityLevel.webSocketError ? (
            <CloudOffIcon />
          ) : (
            <Box
              mr={2}
              display="flex"
              justifyContent="center"
              alignItems="center"
              width="30px"
              height="30px"
              borderRadius={50}
              sx={{
                background: statusColor,
              }}
            >
              <GroundControlSideIcon
                isLocked={flightDomain.flight_domain_status?.locked}
                isInCoexistence={stateFacilityLevel.facilitySettings?.show_coexistence_map}
                isDroneOnly={
                  flightDomain.flight_domain_status?.allow_normal_flight_in_controlled_zone
                }
              />
            </Box>
          ),
          items: [
            {
              id: 'overview',
              label: 'Overview',
              path: GROUND_CONTROL_PAGES_URLS(flightDomain.flight_domain_id).OVERVIEW,
              icon: <MonitorIcon />,
              visible: true,
              testId: 'c-ground-control-overview',
              selected:
                location.pathname ===
                `/${systemId}${GROUND_CONTROL_PAGES_URLS(flightDomain.flight_domain_id).OVERVIEW}`,
            },
            {
              id: 'fleet',
              label: 'Fleet',
              path: GROUND_CONTROL_PAGES_URLS(flightDomain.flight_domain_id).FLEET,
              icon: <DroneIcon color={droneIconColor} />,
              visible: userHasSomePermissions([
                PERMISSION.GENERATE_QR_CODE,
                PERMISSION.VIEW_FLEET_INFO,
              ]),
              testId: 'c-ground-control-fleet',
              selected:
                location.pathname ===
                `/${systemId}${GROUND_CONTROL_PAGES_URLS(flightDomain.flight_domain_id).FLEET}`,
            },

            {
              id: 'droneZones',
              label: 'Drone zones',
              path: GROUND_CONTROL_PAGES_URLS(flightDomain.flight_domain_id).DRONE_ZONES,
              icon: <MapIcon />,
              visible:
                userHasPermission(PERMISSION.DRONE_ZONES_MANAGEMENT) &&
                stateFacilityLevel.facilitySettings?.show_coexistence_map,
              testId: 'c-ground-control-drone-zones',
              selected:
                location.pathname ===
                `/${systemId}${
                  GROUND_CONTROL_PAGES_URLS(flightDomain.flight_domain_id).DRONE_ZONES
                }`,
            },
            {
              id: 'noFlyZones',
              label: 'No-fly zones',
              path: GROUND_CONTROL_PAGES_URLS(flightDomain.flight_domain_id).NO_FLY_ZONES,
              icon: (
                <NoFlyZoneIcon
                  theme={theme}
                  active={
                    location.pathname ===
                    `/${systemId}${
                      GROUND_CONTROL_PAGES_URLS(flightDomain.flight_domain_id).NO_FLY_ZONES
                    }`
                  }
                />
              ),
              visible:
                userHasPermission(PERMISSION.NO_FLY_ZONE_MANAGEMENT) &&
                !stateFacilityLevel.facilitySettings?.show_coexistence_map,
              testId: 'c-ground-control-no-fly-zones',
              selected:
                location.pathname ===
                `/${systemId}${
                  GROUND_CONTROL_PAGES_URLS(flightDomain.flight_domain_id).NO_FLY_ZONES
                }`,
            },
            {
              id: 'flightDomainSettings',
              label: 'Schedule',
              path: GROUND_CONTROL_PAGES_URLS(flightDomain.flight_domain_id).SCHEDULE,
              icon: <AccessTimeIcon />,
              visible: true,
              testId: 'c-ground-control-building-settings',
              selected:
                location.pathname ===
                `/${systemId}${GROUND_CONTROL_PAGES_URLS(flightDomain.flight_domain_id).SCHEDULE}`,
            },
          ],
        };
      });

  const handleAccordions = (key) => {
    let list = expandedItems || [];
    if (expandedItems?.includes(key)) {
      list = expandedItems.filter((acc) => acc !== key);
      dispatchFacilityMenu({ type: FacilityMenuActionNames.SET_EXPANDED_ITEMS, payload: list });
    } else {
      list = [...list, key];
      dispatchFacilityMenu({ type: FacilityMenuActionNames.SET_EXPANDED_ITEMS, payload: list });
    }
  };

  const linksList = [
    {
      id: 'delivery',
      text: 'Delivery',
      visible:
        stateFacilityLevel.facilitySettings?.enable_csfa_task_request_result &&
        stateFacilityLevel.facilitySettings?.show_ground_control_app &&
        userHasPermission(PERMISSION.DELIVERY_MANAGEMENT) &&
        deliveryFlightDomainsMenuItems().some((link) => link.visible),
      items: deliveryFlightDomainsMenuItems(),
    },
    {
      id: 'inventory',
      text: 'Inventory',
      visible: isFacilityDataAvailable && inventoryLinks.some((link) => link.visible),
      items: inventoryLinks,
    },
    {
      id: 'groundControl',
      text: 'Ground control',
      visible:
        stateFacilityLevel.flightDomains.length &&
        userHasSomePermissions(safetyPermissions) &&
        stateFacilityLevel.facilitySettings?.show_ground_control_app &&
        flightDomainsMenuItems().some((link) => link.visible),

      items: flightDomainsMenuItems(),
    },
    {
      id: 'admin',
      text: 'Admin',
      visible:
        userHasPermission(PERMISSION.FACILITY_MANAGEMENT) &&
        adminLinks.some((link) => link.visible),
      items: adminLinks,
    },
  ];

  /**
   * This function will be fired on mount and it covers case
   * with para-shooting on the dashboard and handling of active
   * accordion items based on flightDomainId from URL param
   */
  const onMountSetSelectedMenuItems = () => {
    if (location.pathname.includes('/delivery')) {
      const flightDomainId = location.pathname.split('/')[3];
      const list = [...expandedItems, 'delivery', `delivery_flight_domain_${flightDomainId}`];
      !!dispatchFacilityMenu &&
        dispatchFacilityMenu({
          type: FacilityMenuActionNames.SET_EXPANDED_ITEMS,
          payload: list,
        });
    }

    if (location.pathname.includes('/ground-control/')) {
      const flightDomainId = location.pathname.split('/')[3];
      const list = [...expandedItems, `flight_domain_${flightDomainId}`];
      !!dispatchFacilityMenu &&
        dispatchFacilityMenu({
          type: FacilityMenuActionNames.SET_EXPANDED_ITEMS,
          payload: list ?? [],
        });
    }
  };

  useComponentDidMount(() => {
    onMountSetSelectedMenuItems();
  });

  if (
    location.pathname.includes('not-found') ||
    location.pathname.includes(INVENTORY_PAGES_URLS.SET_MFA_PREFERENCE) ||
    location.pathname.includes(WARNING_PAGES_URLS.NOT_FOUND)
  ) {
    return <> </>;
  }

  return (
    <Drawer anchor="left" variant="permanent" open={open}>
      {Boolean(multipleFacilitiesAccess) && (
        <DrawerHeader stickyelement={stickyElement ? 1 : 0}>
          <ListItemButton
            data-testid="c-select-facility-modal-button"
            sx={{
              height: '60px',
              pointerEvents: multipleFacilitiesAccess ? 'auto' : 'none',
            }}
            onClick={() =>
              dispatchClientModals({ type: ClientModalsActionTypes.SELECT_FACILITY_MODAL })
            }
          >
            <ListItemIcon sx={{ minWidth: 30 }}>
              <LocationOnIcon />
            </ListItemIcon>

            <ListItemText
              primary={
                <Typography
                  sx={{
                    color: theme.palette.grey[600],
                    fontWeight: 500,
                    visibility: open ? 'visible' : 'hidden',
                    textTransform: 'uppercase',
                    textOverflow: 'ellipsis',
                    width: '190px',
                  }}
                  variant="subtitle"
                  noWrap
                  component="div"
                >
                  {facilityData?.name || `System ID: ${systemId}`}
                </Typography>
              }
            />

            <Typography
              sx={{
                color: theme.palette.grey[500],
                fontWeight: 500,
                visibility: open ? 'visible' : 'hidden',
                '&:hover': {
                  textDecoration: 'underline',
                },
              }}
              variant="caption"
              component="p"
            >
              Change
            </Typography>
          </ListItemButton>
        </DrawerHeader>
      )}

      <DrawerList
        open={open}
        stickyelement={stickyElement ? 1 : 0}
        multiplefacilitiesacces={multipleFacilitiesAccess}
      >
        {linksList
          .filter((l) => l.visible)
          .map((link, index) => {
            const lastItem = index === linksList.length - 1;
            return (
              <div key={link.id}>
                <FacilityMenuItem
                  id={link.id}
                  systemId={systemId}
                  visible={link.visible}
                  opened={expandedItems?.includes(link.id)}
                  text={link.text}
                  activeAccordions={expandedItems}
                  handleAccordions={handleAccordions}
                  items={link.items}
                  divider={!lastItem}
                  isDrawerOpened={open}
                />
              </div>
            );
          })}

        <ToggleWrapper
          onClick={() => dispatchFacilityMenu({ type: FacilityMenuActionNames.TOGGLE_MENU_OPENED })}
          disableelevation="true"
          open={open}
        >
          <IconButton
            sx={{
              borderRadius: 1,
              color: theme.palette.grey[600],
              background: theme.palette.grey[300],
            }}
            aria-label="fingerprint"
          >
            {!open ? <ChevronRightOutlinedIcon /> : <ChevronLeftOutlinedIcon />}
          </IconButton>
        </ToggleWrapper>
      </DrawerList>
    </Drawer>
  );
};
