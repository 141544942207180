import { Grid } from '@mui/material';
import { IFacilitySettingsST } from 'codegen/facility_settings';
import { SettingsCard } from '../../SettingsCard';
import { BarcodeTypes } from '../../barcodes/BarcodeTypes';

export const BarcodeTypesSection = ({
  isLoading,
  facilitySettings,
  setFacilitySettings,
}: {
  isLoading: boolean;
  facilitySettings: IFacilitySettingsST;
  setFacilitySettings: (value: React.SetStateAction<IFacilitySettingsST>) => void;
}) => (
  <Grid item>
    <SettingsCard
      title="Barcodes types"
      isLoading={isLoading}
      testId="c-barcodes-types-settings-card"
      content={
        <BarcodeTypes
          barcodeTypes={facilitySettings.barcode_types ? facilitySettings.barcode_types : []}
          onBarcodeTypeChanges={(e: string[]) => {
            setFacilitySettings({
              ...facilitySettings,
              barcode_types: e,
            });
          }}
        />
      }
    />
  </Grid>
);
