import styled from '@emotion/styled';

// mui core
import { Theme, Typography } from '@mui/material';

// mui icons
import ErrorIcon from '@mui/icons-material/Error';

// components
import { Box } from 'components/common/Box';

interface Props {
  sticky?: boolean;
  disabled?: boolean;
  onClick: () => void;
}

const TriggerElement: any = styled(Box)(
  ({ theme, disabled, sticky }: { theme: Theme; disabled: boolean; sticky: boolean }) => ({
    background: !disabled ? theme.palette.error.main : theme.palette.disabled,
    padding: 7,
    paddingRight: 20,
    color: theme.palette.common.white,
    position: 'fixed',
    pointerEvents: !disabled ? 'auto' : 'none',
    zIndex: 1200,
    top: !sticky ? 145 : 85,
    right: -160,
    borderRadius: 50,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    boxSizing: 'border-box',
    border: '2px solid #fff',
    cursor: 'pointer',
    boxShadow: theme.shadows[3],
    transition: theme.transitions.create(['right', 'top'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    '&:hover': {
      background: theme.palette.error.main,
      right: -10,
    },
  }),
);

export const EmergencyLandingTrigger = ({ sticky, onClick, disabled }: Props) => (
  <TriggerElement
    data-testid="c-emergency-landing-btn"
    display="flex"
    alignItems="center"
    onClick={onClick}
    disabled={disabled}
    sticky={sticky ? 1 : 0}
  >
    <ErrorIcon sx={{ marginRight: 1, color: '#fff' }} />
    <Typography variant="body1" fontWeight="bold">
      SAFETY LANDING
    </Typography>
  </TriggerElement>
);
