import { Euler, Quaternion } from 'three';

export class TweenEuler {
  private currentX: number = 0;

  private currentY: Euler;

  private numberOfUpdates: number = 0;

  private lerp;

  /**
   * Creates a Tween
   * @param startY starting value
   * @param endY end value to be reached
   * @param maxX duration in seconds
   * @param easingFunction one of those
   * @param whenDone to be called once duration in reached
   */
  constructor(
    public readonly startY: Euler,
    public readonly endY: Euler,
    public readonly maxX: number,
    public readonly easingFunction: (n: number) => number,
    private readonly whenDone: () => void,
    public readonly label?: string,
  ) {
    this.currentY = this.startY;

    this.lerp = (start: Euler, end: Euler, t: number): Euler => {
      const startQuaternion = new Quaternion().setFromEuler(start);
      const endQuaternion = new Quaternion().setFromEuler(end);
      const lerpedQuaternion = new Quaternion().copy(startQuaternion).slerp(endQuaternion, t);
      return new Euler().setFromQuaternion(lerpedQuaternion);
    };
  }

  public get isDone() {
    return this.currentX >= this.maxX;
  }

  private get normalizedX() {
    return this.currentX / this.maxX;
  }

  private updateCurrentX = (delta: number): boolean => {
    this.currentX += delta;

    if (this.currentX >= this.maxX) {
      this.whenDone();
      return false;
    }
    return true;
  };

  public getNextValue = (delta: number): Euler => {
    if (!this.updateCurrentX(delta)) return this.endY;

    const easingRatio = this.easingFunction(this.normalizedX);

    this.currentY = this.lerp(this.startY, this.endY, easingRatio);

    this.numberOfUpdates += 1;
    return this.currentY;
  };
}
