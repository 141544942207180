import { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { IFacilitySettingsST } from 'codegen/facility_settings';
import { warehouseServices } from 'services/WarehouseServices';
import { getLogPrefixForType } from 'common/functions/logFunctions';
import { useRequestController } from 'hooks';
import { SettingsCard } from '../../SettingsCard';
import { EmailList } from '../../EmailList';
import { getWmsIntegrationOptions } from '../../options/getWmsIntegrationOptions';

const logPrefix = getLogPrefixForType('COMPONENT', 'WmsIntegration');

export const WmsIntegration = ({
  isLoading,
  systemId,
  facilitySettings,
  handleValuesChange,
}: {
  systemId: string;
  isLoading: boolean;
  facilitySettings: IFacilitySettingsST;
  handleValuesChange: (prop: string, value: number | boolean | string) => void;
}) => {
  const [wmsApiHandlers, setWmsApiHandlers] = useState<
    {
      label: string;
      value: string;
    }[]
  >([]);
  const [wmsParsers, setWmsParsers] = useState<
    {
      label: string;
      value: string;
    }[]
  >([]);

  const { requestController } = useRequestController(logPrefix);

  useEffect(() => {
    requestController.doRequest({
      request: warehouseServices.getWmsClientApi,
      requestParams: [systemId],
      messageErrorFallback: 'The WMS Client API could not be fetched.',
      callbackSuccess: (r: { data: { wms_client_api_handler_names: string[] } }) => {
        const handlers = r.data.wms_client_api_handler_names.map((item: string) => ({
          label: item,
          value: item,
        }));

        handlers.push({
          label: 'Disabled',
          value: 'Disabled',
        });
        setWmsApiHandlers(handlers);
      },
    });

    requestController.doRequest({
      request: warehouseServices.getWmsParsers,
      requestParams: [systemId],
      messageErrorFallback: 'The WMS Parsers could not be fetched.',
      callbackSuccess: (r: { data: { wms_parser_names: string[] } }) => {
        const parser = r.data.wms_parser_names.map((item: string) => ({
          label: item,
          value: item,
        }));
        parser.push({
          label: 'Disabled',
          value: 'Disabled',
        });
        setWmsParsers(parser);
      },
    });
  }, [requestController, setWmsApiHandlers, setWmsParsers, systemId]);
  return (
    <Grid md={12} sm={12} xs={12} item>
      <SettingsCard
        title="WMS integration"
        isLoading={isLoading || !wmsApiHandlers.length || !wmsParsers.length}
        items={getWmsIntegrationOptions(
          facilitySettings,
          handleValuesChange,
          wmsApiHandlers,
          wmsParsers,
        )}
        testId="c-wms-integration-settings-card"
        content={
          <EmailList
            title="WMS email sender whitelist"
            tooltip="List of email addresses from which the WMS files are accepted.
                                   If the file is sent from an address that is not on this list, the email is rejected.
                                   Leave the list empty to disable the automatic import."
            currentList={
              facilitySettings.wms_email_senders
                ? facilitySettings.wms_email_senders.split(',')
                : []
            }
            confirmChanges={(e: string[]) => {
              handleValuesChange('wms_email_senders', e.join(','));
            }}
          />
        }
      />
    </Grid>
  );
};
