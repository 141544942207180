import { IRequestController } from '../../../../../hooks';
import { groundControlServices } from '../../../../../services/GroundControlServices';

export const deleteNoFlyZone = ({
  requestController,
  systemId,
  flightDomainId,
  noFlyZoneId,
  onConfirm,
}: {
  requestController: IRequestController;
  systemId: string;
  flightDomainId: string;
  noFlyZoneId: string;
  onConfirm: () => void;
}) => {
  const { signal } = requestController.reserveSlotForRequest();
  requestController.doRequest({
    request: groundControlServices.deleteNoFlyZone,
    requestParams: [{ systemId, flightDomainId, nfzId: noFlyZoneId, signal }],
    messageErrorFallback: 'Something went wrong while trying to delete no fly zone!',
    callbackFinally: onConfirm,
  });
};
