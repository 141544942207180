import isEmpty from 'lodash/isEmpty';
import { Dispatch } from 'react';
import { requestQueueHandler } from 'common/requestHelpers';
import { NETWORK_REQUEST_SETTINGS } from 'common/settings';
import { IReportST } from 'codegen/report/api';
import { IRequestController } from 'hooks';
import { NetworkRequest } from 'ts-types/NetworkRequest';
import { reportStore } from 'udb/inventory/features/reports/reducer/report-store/ReportStore';
import { GetReportData } from '../../../reducer/report-store/ReportStore.model';
import {
  ReportContainerActionNames,
  ReportContainerActions,
} from '../reducer/ReportContainerActions';

type GetReport = {
  fullReportSummary: IReportST;
  locations: Array<string>;
  nLocationsPerRequest: number;
  reportId: string;
  requestController: IRequestController;
  systemId: string;
  dispatch: Dispatch<ReportContainerActions>;
};

/**
 * This function handles get report call
 * @returns report
 */

export const getReport = ({
  dispatch,
  fullReportSummary,
  locations,
  nLocationsPerRequest,
  systemId,
  reportId,
  requestController,
}: GetReport) => {
  dispatch({ type: ReportContainerActionNames.CLEAR_STATE });
  dispatch({
    type: ReportContainerActionNames.SET_ANY,
    payload: { fieldName: 'tableSpinner', value: 1 },
  });

  if (isEmpty(locations)) {
    dispatch({
      type: ReportContainerActionNames.REPORT_LOCATIONS_EMPTY,
      payload: fullReportSummary,
    });

    return;
  }

  const nRequests = Math.ceil(locations.length / nLocationsPerRequest);
  dispatch({
    type: ReportContainerActionNames.SET_ANY,
    payload: { fieldName: 'requestsToSend', value: nRequests },
  });

  let allRequests: NetworkRequest[] = [];

  for (let i = 0; i < nRequests; i += 1) {
    const fromSlot = locations[i * nLocationsPerRequest];
    const untilSlot =
      locations[(i + 1) * nLocationsPerRequest - 1] || locations[locations.length - 1];
    const reservation = requestController.reserveSlotForRequest();
    const networkRequest = {
      requestId: reservation.requestId,
      request: () =>
        requestController.doRequest({
          request: reportStore.getReport,
          requestParams: [
            systemId,
            reportId,
            fullReportSummary,
            fromSlot,
            untilSlot,
            reservation.signal,
          ],
          callbackBeforeSend: () =>
            dispatch({
              type: ReportContainerActionNames.SET_ANY,
              payload: { fieldName: 'reportLoaded', value: false },
            }),
          callbackSuccess: (r: GetReportData) =>
            dispatch({ type: ReportContainerActionNames.GET_REPORT_CALLBACK_SUCCESS, payload: r }),

          messageErrorFallback: 'The Report could not get fetched.',
          // Since this request is called multiple times, we can not use the dispatcher
          // to display snackbars for messageSuccess, messageError, messageFinally
          // so we are using the callback parameters to do so.
          // TR::TODO::2021-04-26:: Allow generic request handler to display
          // snackbars also for looped requests
          callbackFinally: () =>
            dispatch({ type: ReportContainerActionNames.GET_REPORT_CALLBACK_FINALLY }),
        }),
    };

    allRequests = [...allRequests, networkRequest];
  }

  requestQueueHandler(
    allRequests,
    NETWORK_REQUEST_SETTINGS.MAX_CONCURRENT_REQUESTS,
    requestController,
  );
};
