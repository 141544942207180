import React, { useState, useEffect } from 'react';
import { Theme } from '@mui/material';

// material-ui core
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';

import { makeStyles } from 'tss-react/mui';

// components
import { Box } from '../Box';

// variables, functions, configurations
import { ICustomMultiSelectProps, IOption } from '../../../interfaces/inputs';

const useStyles = makeStyles()((theme: Theme) => ({
  formInput: {
    width: '100%',
    '& #logo_url': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  },
  imageWrapper: {
    background: theme.palette.textSecondary.main,
    height: '45px',
    width: '45px',
    overflow: 'hidden',
  },
  menuItem: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  images: {
    height: '45px',
    width: '45px',
    objectFit: 'contain',
  },
}));

export const CustomSelectWithImages = (props: ICustomMultiSelectProps) => {
  // props
  const {
    variant,
    margin,
    id,
    label,
    name,
    onChange,
    value,
    style,
    disabled,
    testId,
    valueOptions,
  } = props;

  // state variables
  const [internalValue, setInternalValue] = useState(value);

  // styles
  const { classes } = useStyles();

  useEffect(() => {
    setInternalValue(() => value);
  }, [value]);

  return (
    <FormControl variant={variant} margin={margin} className={classes.formInput}>
      <InputLabel id="labelId">{label}</InputLabel>
      <Select
        data-testid={testId}
        labelId="labelId"
        id={id}
        name={name}
        label={label}
        style={style}
        disabled={disabled}
        value={internalValue}
        onChange={(e) => {
          setInternalValue(e.target.value);
          onChange(e); // call the onChange method received on the props
        }}
      >
        {valueOptions.map((el: IOption | any) => (
          <MenuItem className={classes.menuItem} value={el.value} key={`${el.value}-${el.label}`}>
            {el.label.toUpperCase()}
            <Box className={classes.imageWrapper}>
              <img className={classes.images} alt="logo" src={el.value} />
            </Box>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
