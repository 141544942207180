import { Box, Grid, LinearProgress, Tooltip } from '@mui/material';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { GridInitialStatePremium } from '@mui/x-data-grid-premium/models/gridStatePremium';

export const WarehouseStatsBar = ({
  label,
  description,
  value,
  unit,
  total,
  color,
  cols,
  showProgressBar,
  gridState,
  onGridStateSelect,
}: {
  label: string;
  description?: string;
  value?: number;
  unit: string;
  total: number;
  color?: string;
  cols?: number;
  showProgressBar?: boolean;
  gridState?: GridInitialStatePremium;
  onGridStateSelect?: (state: GridInitialStatePremium) => void;
}) => {
  const changeGridState = (state: GridInitialStatePremium) => {
    if (onGridStateSelect) {
      onGridStateSelect(state);
    }
  };

  const tooltip = gridState ? (
    <Box component="div" display="flex" flexDirection="column" gap={2} sx={{ fontSize: '14px' }}>
      <Box component="div">{description}</Box>
      <Box component="div">
        {value} {unit}
      </Box>
      <Box component="div">Click to filter the table</Box>
    </Box>
  ) : (
    `${description} (${value} ${unit})`
  );

  return (
    <Grid item md={!cols || cols < 0 || cols > 12 ? 12 : cols} container spacing={1}>
      <Grid item md={12}>
        <Tooltip title={tooltip}>
          <Box
            component="div"
            display="flex"
            flexDirection="row"
            gap={1}
            onClick={() => {
              if (gridState) {
                changeGridState(gridState);
              }
            }}
            sx={{ cursor: gridState ? 'pointer' : 'default' }}
          >
            {!showProgressBar && (
              <Box component="div" color={showProgressBar ? undefined : color}>
                <FiberManualRecordIcon />
              </Box>
            )}
            <Box
              component="div"
              fontWeight="bold"
              textAlign="right"
              data-testid="c-stats-bar-percent"
            >
              {((100 * (value || 0)) / total).toFixed(0)}%
            </Box>
            <Box component="div" textAlign="left">
              {label}
            </Box>
            {showProgressBar && (
              <Box
                paddingLeft="10px"
                component="div"
                marginLeft="auto"
                data-testid="c-stats-bar-value"
              >
                {value}
              </Box>
            )}
          </Box>
        </Tooltip>
      </Grid>
      {showProgressBar && (
        <Grid item md={12}>
          <LinearProgress
            sx={{
              marginRadius: '3px',
              backgroundColor: 'E9F4FE',
              '& .MuiLinearProgress-bar': {
                backgroundColor: color || 'gold',
              },
            }}
            variant="determinate"
            value={(100 * (value || 0)) / total}
          />
        </Grid>
      )}
    </Grid>
  );
};
