import { Fade, InputAdornment, TextField } from '@mui/material';
import { useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import { tableStyles } from '../styles';
import { IconColor } from '../../../ts-types/IconColor';
import { SearchProps } from './SearchProps';

/**
 * Search
 * @param props SearchProps
 * @returns
 */
export const Search = ({
  onChange,
  onClear,
  isDisabled,
  placeholder,
  defaultValue = '',
}: SearchProps) => {
  const { classes } = tableStyles();

  const [value, setValue] = useState(defaultValue);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value } = e.target;
    setValue(value);
    onChange(value);
  };

  const handleClear = () => {
    setValue('');
    onClear();
  };

  const searchIconColor = isDisabled ? ('disabled' as IconColor) : ('secondary' as IconColor);

  return (
    <Fade in={true} mountOnEnter unmountOnExit>
      <TextField
        placeholder={placeholder}
        data-testid="c-search"
        size="small"
        autoFocus
        variant="outlined"
        id="input-with-icon-textfield"
        value={value}
        disabled={isDisabled}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {value ? (
                <CloseIcon
                  onClick={handleClear}
                  data-testid="c-search-clear"
                  className={classes.searchIcon}
                  color="secondary"
                />
              ) : (
                <SearchIcon color={searchIconColor} />
              )}
            </InputAdornment>
          ),
        }}
        onChange={handleChange}
      />
    </Fade>
  );
};
