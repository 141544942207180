import { Box, Typography } from '@mui/material';
import { IConditionOrLogicalOperatorST } from 'codegen/facility_settings';
import { isCatchAllCondition } from './utils/rulesFunctions';

export const ConditionText = ({
  condition,
  ruleConjunctions,
  isNotFirstRule,
}: {
  condition: IConditionOrLogicalOperatorST;
  ruleConjunctions?: string[];
  isNotFirstRule: boolean;
}) => {
  const isMultiValueField =
    condition.field === 'CUSTOMERS' || condition.field === 'EXPECTED_CONTENT';

  const IsCatchAll = isCatchAllCondition(condition);

  const multiValueQuantifier = isMultiValueField ? (
    <Typography variant="h6" paddingRight=".5em" fontStyle="italic" color="darkslategrey">
      {condition.quantifier} OF THE
    </Typography>
  ) : undefined;

  const negationStatement =
    condition.quantifier === 'NONE' && !isMultiValueField ? (
      <Typography variant="h6" paddingRight=".5em" fontStyle="italic" color="darkslategrey">
        NOT
      </Typography>
    ) : undefined;

  const operator = (
    <Typography variant="h6" paddingRight=".5em" fontStyle="italic" color="darkslategrey">
      {condition.operator?.type}
    </Typography>
  );

  const value = (
    <Typography variant="h5" color="darkslategrey" fontWeight="bold">
      {condition.operator?.value ? ` "${condition.operator?.value}"` : ''}
    </Typography>
  );

  if (IsCatchAll) {
    return (
      <Box component="div" paddingTop="2em">
        <Typography fontWeight="bold" color="blue" variant="h5" paddingRight=".5em">
          {isNotFirstRule ? 'FOR ALL OTHER LOCATIONS' : 'FOR ALL LOCATIONS'}
        </Typography>
      </Box>
    );
  }

  return (
    <>
      {isNotFirstRule && (
        <Box component="div" paddingTop="2em" paddingBottom="1em">
          <Typography fontWeight="bold" color="blue" variant="h5" paddingRight=".5em">
            OTHERWISE
          </Typography>
        </Box>
      )}
      <Box component="div" display="flex" flexDirection="row">
        <Typography fontWeight="bold" color="blue" variant="h5" paddingRight=".5em">
          {ruleConjunctions && ruleConjunctions.length ? ruleConjunctions.join(' ') : 'IF'}
        </Typography>
        {multiValueQuantifier}
        <Typography fontWeight="bold" variant="h5" color="black" paddingRight=".5em">
          {condition.field}
        </Typography>{' '}
        {negationStatement}
        {operator}
        {value}
      </Box>
    </>
  );
};
