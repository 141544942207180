import { requestStore } from '../../../store/RequestStore/RequestStore';
import { GetUpcomingReportsParams } from './getUpcomingReportsParams.model';

export const getUpcomingReports = ({
  requestController,
  systemId,
  setIsLoading,
  onSuccess,
}: GetUpcomingReportsParams) => {
  const { signal } = requestController.reserveSlotForRequest();
  requestController.doRequest({
    request: requestStore.getRequests,
    requestParams: [systemId, signal],
    callbackBeforeSend: () => setIsLoading(true),
    callbackFinally: () => setIsLoading(false),
    messageErrorFallback: 'Something went wrong while getting the scheduled reports',
    callbackSuccess: onSuccess,
  });
};
