/* eslint-disable react/jsx-no-useless-fragment */
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk';
import { Box } from '@mui/material';
import { IFlightDomainStatusST } from 'codegen/flight_domain';
import { TooltipedIcon } from 'components/common/TooltipedIcon';
import { NoAccessIcon } from 'components/common/FacilityMenu/Icons/NoAccessIcon';

type AccessAlertIconProps = {
  flight_domain_status: IFlightDomainStatusST | null | undefined;
};

export const AccessAlertIcon = ({ flight_domain_status }: AccessAlertIconProps) => {
  if (!flight_domain_status || flight_domain_status.locked) {
    return <></>;
  }
  if (flight_domain_status.allow_normal_flight_in_controlled_zone) {
    return (
      <Box component="div" paddingRight="1em" data-testid="c-icon-drone-only">
        <TooltipedIcon
          icon={<NoAccessIcon color="white" fontSize="40px" />}
          tooltip="Do not enter"
        />
      </Box>
    );
  }
  return (
    <Box component="div" paddingRight="1em" data-testid="c-icon-coexistence">
      <TooltipedIcon
        icon={<DirectionsWalkIcon sx={{ color: 'white', fontSize: '32px' }} />}
        tooltip="Drones are mixed with people"
      />
    </Box>
  );
};
