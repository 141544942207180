import { Box, Typography } from '@mui/material';
import { IRuleActionST } from 'codegen/facility_settings';

export const ActionPhrase = ({
  action,
  isLastRule,
}: {
  action: IRuleActionST;
  isLastRule: boolean;
}) => (
  <Box component="div" display="flex" flexDirection="row">
    {!isLastRule && (
      <Typography fontWeight="bold" color="blue" variant="h5" paddingRight=".5em">
        THEN
      </Typography>
    )}
    <Typography fontWeight="bold" color="blue" variant="h5" paddingRight=".5em">
      USE THE
    </Typography>
    <Typography fontWeight="bolder" variant="h5" paddingRight=".5em" color="darkgreen">
      {action.issue_logic}
    </Typography>
    <Typography fontWeight="bold" variant="h5" paddingRight=".5em" color="darkslategrey">
      ISSUE LOGIC
    </Typography>
  </Box>
);
