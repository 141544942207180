export const defaultIssueLogicJson = `{
  "rules": [
    {
      "conditions": [
        {
          "field": "LOCATION_NAME",
          "operator": {
            "type": "MATCH",
            "value": ".*"
          },
          "quantifier": "ANY"
        }
      ],
      "action": {
        "issue_logic": "DEFAULT"
      }
    }
  ]
}`;
