import TableCell from '@mui/material/TableCell';
import { handleActionClick } from 'common/Actions/actionHandlers';
import { ActionsMenu } from 'components/common/ActionsMenu';
import { RowProp, HeadCellProp } from '../../../types/cell';
import { useFacilityModalsStore } from '../../../../../store/Modals';

type EnhancedTableCellActionButtonProps = {
  row: RowProp<any>;
  cell: HeadCellProp;
  getAmendingData: any;
  refreshData: any;
};

export const EnhancedTableCellActionButton = ({
  cell,
  row,
  getAmendingData,
  refreshData,
}: EnhancedTableCellActionButtonProps) => {
  const { dispatchFacilityModals } = useFacilityModalsStore();

  let options: any[] = [];

  if (row[cell.id]) {
    row[cell.id].actions.map((action: any) => {
      const actionId = row.requestId || row.issueId || row.id || row.username;

      const handleClick = () => {
        if (action.onClick) {
          action.onClick();
        } else {
          handleActionClick(
            getAmendingData(row),
            action.label,
            dispatchFacilityModals,
            refreshData,
            actionId,
          );
        }
      };

      options = [
        ...options,
        {
          label: action.label,
          disabled: action.disabled,
          clickHandler: handleClick,
        },
      ];

      return action;
    });
  }

  return (
    <TableCell data-testid={`c-${cell.id}`} align={cell.align} key={`cell-${row.id}-${cell.id}`}>
      {row[cell.id] && <ActionsMenu options={options} />}
    </TableCell>
  );
};
