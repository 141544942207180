import { SnackbarKey, SnackbarMessage, OptionsObject } from 'notistack';

// variables and functions
import { IClientLevelStateFacility } from 'store/ClientLevelStore/ClientLevelStore.model';
import { FACILITY_UPDATE_STATUS } from '../facilityUpdateStatus';
import {
  FacilitySettingsActionNames,
  InventoryActionNames,
} from '../../store/FacilityLevelStore/facilityLevelActions';
import { INVENTORY_PAGES_URLS } from '../pages';

interface IDispatchAction {
  type: InventoryActionNames | FacilitySettingsActionNames;
  payload: Record<string, never>;
}

export const mapUpdateNotifications = (
  systemId: string,
  facility: IClientLevelStateFacility | undefined,
  notificationFacilityMapUpdate: {
    status: string;
    progress: number;
    message: string;
    user: string;
  },
  enqueueSnackbar: (message: SnackbarMessage, options?: OptionsObject) => SnackbarKey,
  dispatch: (params: IDispatchAction) => void,
  currentUrlPath: string,
  username: string,
) => {
  const status = notificationFacilityMapUpdate?.status;
  const progress = notificationFacilityMapUpdate?.progress;
  const message = notificationFacilityMapUpdate?.message;
  const uploader = notificationFacilityMapUpdate?.user;

  if (facility !== undefined) {
    // Deal with error messages
    if (status === FACILITY_UPDATE_STATUS.ERROR) {
      if (username === uploader && facility.id === systemId) {
        // show the error message only for the uploading user
        // and only if the currently loaded facility matches the one the notification is for
        enqueueSnackbar(message, { variant: 'error', preventDuplicate: true, persist: true });
      }
    }

    // Inform all users, except the uploader himself
    // that new map file is processing
    if (status === FACILITY_UPDATE_STATUS.ONGOING && progress === 0) {
      let msg;

      if (facility.isConfigured) {
        msg = `The map is currently being updated${
          uploader && username !== uploader ? ` by ${uploader}` : ''
        } in ${facility.name}.`;
      } else {
        msg = `The map is currently being updated${
          uploader && username !== uploader ? ` by ${uploader}` : ''
        } in system with id ${facility.id}.`;
      }

      enqueueSnackbar(msg, {
        variant: 'info',
        preventDuplicate: true,
      });
    }

    // Upload process finished
    if (status === FACILITY_UPDATE_STATUS.FINISHED && progress === 100) {
      let msg;

      if (facility.isConfigured) {
        msg = `A new map was uploaded${
          uploader && username !== uploader ? ` by ${uploader}` : ''
        } in ${facility.name}.`;
      } else {
        msg = `A new map was uploaded${
          uploader && username !== uploader ? ` by ${uploader}` : ''
        } in system with id ${facility.id}.`;
      }

      const refreshLink = (
        <>
          Please{' '}
          <span
            className="notification-link"
            role="button"
            tabIndex={0}
            onClick={() => window.location.reload()}
          >
            refresh
          </span>{' '}
          the page.
        </>
      );

      // If the users are on Warehouse Status page at the moment when
      // the map is being uploaded, a snackbar message will appear.
      // The message will include the refresh link in case the user is in
      // the same facility for which a new map was uploaded
      if (currentUrlPath.includes(INVENTORY_PAGES_URLS.WAREHOUSE_STATUS)) {
        msg = (
          <>
            {msg} {facility.id === systemId && refreshLink}
          </>
        );
      }
      enqueueSnackbar(msg, {
        variant: 'success',
        preventDuplicate: true,
      });
    }

    // Clear state, unless it refers to the upload ongoing progress
    if (status !== FACILITY_UPDATE_STATUS.ONGOING) {
      // Clearing the WS data from the global store right after receiving it will prevent
      // dispatching notifications and alerts on every component mount, since we trigger them
      // listening to the "facilityMapUpdate", as long as it is stored, we get into as infinite loop.
      dispatch({ type: FacilitySettingsActionNames.WS_FACILITY_MAP_UPDATE, payload: {} });
    }
  }
};
