/* eslint-disable jsx-a11y/label-has-associated-control */
import { Dispatch } from 'react';
import Grid from '@mui/material/Grid';
import { Formik, Field, Form, FormikHelpers } from 'formik';
import { ModalBase } from 'components/ModalsAndPopups/ModalBase';
import { Box } from 'components/common/Box';
import { MapAction } from '../../reducer/3DmapReducer';
import {
  cameraTypes,
  isCameraType,
  MapOptions,
  OptionsObject,
} from '../../reducer/MapOptionsState';

const numberField = (name: string, key: string) => (
  <label key={name} style={{ width: '100%', display: 'block' }}>
    {key}
    <Field
      name={name}
      type="number"
      style={{ float: 'right', fontSize: '.7rem', height: '16px', width: '50px' }}
    />
    <br />
  </label>
);

const booleanField = (name: string, key: string) => (
  <label key={name} style={{ width: '100%', display: 'block', margin: 0 }}>
    <Field name={name} type="checkbox" />
    {key}
  </label>
);

const cameraTypeField = (name: string, type: string) => (
  <label key={`${name}-${type}`}>
    <Field name={name} value={type} type="radio" />
    {type}
  </label>
);

const stringField = (name: string, key: string) => (
  <label key={name} style={{ width: '100%', display: 'block' }}>
    {key}
    <Field name={name} type="text" style={{ float: 'right', fontSize: '.7rem', height: '16px' }} />
    <br />
  </label>
);

/**
 * Turns options object into a formik form elements.
 * @param options the map options
 * @param prefix name prefix for item names, used for formik, supplied by itself
 * @returns JSX.Element[]
 */
const optionsToForm = (
  options: OptionsObject,
  prefix = '',
  singleColumn?: boolean,
): JSX.Element[] =>
  Object.keys(options).flatMap((key) => {
    const name = `${prefix}${key}`;
    const value = options[key];
    switch (typeof value) {
      case 'number': {
        return numberField(name, key);
      }
      case 'string': {
        let options: JSX.Element[] = [];
        if (isCameraType(value)) {
          options = cameraTypes.map((type) => cameraTypeField(name, type));
          return (
            <div key={`g-${name}`} role="group">
              {options}
              <br />
            </div>
          );
        }
        return stringField(name, key);
      }
      case 'boolean': {
        return booleanField(name, key);
      }
      default: {
        const options = optionsToForm(value as OptionsObject, `${name}.`, true);
        const xs = singleColumn ? 12 : 6;
        const title = singleColumn ? key : key.toLocaleUpperCase();

        return (
          <Grid container item spacing={0} xs={xs} key={name} style={{ paddingLeft: '.5rem' }}>
            <h4 style={{ marginBottom: '5px', width: '100%' }}>{title}</h4>
            {options}
          </Grid>
        );
      }
    }
  });

/**
 * Modal for changing map options.
 * @param options MapOptions
 * @param mapDispatch MapAction dispatcher
 * @returns JSX.Element
 */
export const MapOptionsModal = (props: {
  options: MapOptions;
  mapDispatch: Dispatch<MapAction>;
}): JSX.Element => {
  const { options, mapDispatch } = props;

  const submitButton = (
    <button type="submit" style={{ float: 'right' }}>
      Submit
    </button>
  );

  const form = (
    <Formik
      initialValues={{ ...options }}
      onSubmit={(values: MapOptions, actions: FormikHelpers<MapOptions>) => {
        mapDispatch({ type: 'setOptions', payload: values });
        actions.setSubmitting(false);
      }}
    >
      <Form>
        {submitButton}
        <Grid
          container
          spacing={2}
          style={{
            fontSize: '.7rem',
          }}
        >
          {optionsToForm(options)}
        </Grid>
        {submitButton}
      </Form>
    </Formik>
  );

  return (
    <ModalBase
      testId="c-3dmap-experiment-modal"
      opened={true}
      handleClose={() => mapDispatch({ type: 'toggleOptionsModal' })}
      title={<Box p={2}>3D Map Experiment</Box>}
    >
      {form}
    </ModalBase>
  );
};
