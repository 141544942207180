import { IUserPostRequestSTUserGroupsEnum } from 'codegen/user_admin';
import { IUpdateUserRestRequestParamST } from '../interfaces';

import { getSystemService, getUserAdminService } from './services';

const getAllUsers = (systemId: string, signal: AbortSignal) =>
  getUserAdminService().getAllUsers(systemId, 'true', { signal });

const updateUser = (
  systemId: string,
  { user_id, rest }: { user_id: string; rest: IUpdateUserRestRequestParamST },
  signal: AbortSignal,
) => getUserAdminService().updateUser(systemId, user_id, rest, { signal });

const updateUserGroups = (
  systemId: string,
  { user_id, user_groups }: { user_id: string; user_groups: IUserPostRequestSTUserGroupsEnum[] },
  signal: AbortSignal,
) =>
  getUserAdminService().updateUserGroups(
    systemId,
    user_id,
    { new_user_groups: user_groups },
    { signal },
  );

const deleteUser = (systemId: string, user_id: string, signal: AbortSignal) =>
  getUserAdminService().deleteUser(systemId, user_id, { signal });

/**
 * Get supported system IDs.
 * @returns {object} <{ [key: string]: ISystemIDsGetResponseValueST }>
 */
const getFacilities = (signal: AbortSignal) => getSystemService().systemSystemIdsGet({ signal });

/**
 * Get user system IDs.
 * @returns {object} <{ [key: string]: ISystemIDsGetResponseValueST }>
 */
const getUserFacilities = (signal: AbortSignal) =>
  getSystemService().systemUserSystemIdsGet({ signal });

const getUserGroups = (systemId: string, signal: AbortSignal) =>
  getUserAdminService().getUserGroups(systemId, { signal });

export const UserServices = {
  getAllUsers,
  updateUser,
  updateUserGroups,
  deleteUser,
  getUserGroups,
  getFacilities,
  getUserFacilities,
};
