import {
  IIssueSTTypeEnum,
  IIssueSTStateEnum,
  ILocationDataST,
  ISlotSettingsSTExclusionStatusEnum,
} from 'codegen/warehouse_status';

export const getFilteredLocationData = (
  locationData: ILocationDataST[],
  issueTypeFilters?: IIssueSTTypeEnum[],
  issueStateFilters?: IIssueSTStateEnum[],
  slotSettingFilter?: ISlotSettingsSTExclusionStatusEnum,
  filter?: (location: ILocationDataST) => boolean,
) => {
  const locationDataFilteredByEnums = locationData.filter((location: ILocationDataST) => {
    if (slotSettingFilter && location.slot_settings?.exclusion_status !== slotSettingFilter) {
      return false;
    }

    if (!issueTypeFilters && !issueStateFilters) {
      return true;
    }

    if (location.issues[0]) {
      if (issueTypeFilters && issueStateFilters) {
        return (
          issueTypeFilters.includes(location.issues[0].type) &&
          issueStateFilters.includes(location.issues[0].state)
        );
      }

      if (issueTypeFilters) {
        return issueTypeFilters.includes(location.issues[0].type);
      }

      if (issueStateFilters) {
        return issueStateFilters.includes(location.issues[0].state);
      }
    }

    return false;
  });

  return filter ? locationDataFilteredByEnums.filter(filter) : locationDataFilteredByEnums;
};
