import React, { useEffect } from 'react';

import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

// material-ui icons
import CloseIcon from '@mui/icons-material/Close';

// components
import { Box } from 'components/common/Box';
import { BasicAlert, IBasicAlertProps } from './BasicAlert';

interface IAlertComponent extends IBasicAlertProps {
  handleClose?: () => void;
  /**
   * Auto-closing timeout in seconds
   */
  autoClose?: number;
  percentage?: number;
  preventClose: boolean;
}

export const AlertComponent = ({
  isActive,
  status,
  icon,
  title,
  message,
  handleClose,
  percentage,
  preventClose,
  testId,
  iconMapping,
  autoClose,
}: IAlertComponent) => {
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (autoClose) {
      const timeId = setTimeout(() => {
        handleClose && handleClose();
      }, autoClose * 1000);

      return () => {
        clearTimeout(timeId);
      };
    }
  }, [autoClose, handleClose]);

  const renderAction = () => {
    let action = handleClose && (
      <IconButton size="small" onClick={handleClose}>
        <CloseIcon />
      </IconButton>
    );

    if (preventClose) {
      action = (
        <Box minWidth={35}>
          <Typography variant="body2" color="textSecondary">
            {percentage ? `${percentage}%` : ''}
          </Typography>
        </Box>
      );
    }

    return action;
  };

  return (
    <BasicAlert
      action={renderAction()}
      isActive={isActive}
      message={message}
      status={status}
      icon={icon}
      iconMapping={iconMapping}
      testId={testId}
      title={title}
    />
  );
};
