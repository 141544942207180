export enum DISPLAY_WMS_STATES {
  EMPTY = 'Empty location',
  BARCODE = 'Barcode',
  INVALID = 'Invalid',
}

export enum DISPLAY_VERITY_STATES {
  EMPTY = 'Empty location',
  BARCODE = 'Barcode',
  NOT_EMPTY = 'Non-empty (no barcode)',
  NO_RESULT = 'Inconclusive',
  UNREACHABLE = 'Unreachable Location',
  ABORTED = 'Out of Time',
  FAILED = 'Failed to Scan',
  EXCLUDED = 'Excluded Location',
  TO_BE_EXECUTED = 'To Be Executed',
  PENDING_BACKUP = 'Pending Backup',
  SCANNED = 'Scanned',
}
