import { Theme } from '@mui/material';

export const baseCardStyle = (theme: Theme) => ({
  card: {
    height: '100%',
    marginBottom: theme.spacing(3) as string,
    position: 'relative' as const,
    display: 'flex',
    flexDirection: 'column' as const,
    justifyContent: 'space-between',
  },
  cardSection: {
    padding: theme.spacing(2),
  },
  cardContentSection: {
    position: 'relative' as const,
    flex: '1',
    padding: 0,
  },
  emptyCard: {
    textAlign: 'center' as const,
    margin: theme.spacing(5, 0),
    minHeight: theme.spacing(4),
  },
  cardContentList: {
    paddingTop: theme.spacing(1),
  },
  alertInfo: {
    marginTop: theme.spacing(2),
  },
  title: {
    fontSize: '1.7rem',
    fontWeight: 'bold',
    color: theme.palette.textSecondary.main,
  },
  subheader: {
    fontSize: '1rem',
    wordBreak: 'break-all' as const,
    marginRight: theme.spacing(4),
  },
  actionContainer: {
    marginRight: '0.5rem',
  },
  action: {
    fontWeight: 'bold',
    color: theme.palette.textSecondary.main,
  },
  widthLarger: {
    '& textarea': {
      width: '320px',
      fontSize: '15px',
      textAlign: 'left' as const,
    },
    '& input': {
      width: '320px',
      fontSize: '15px',
      textAlign: 'right' as const,
    },
  },
  widthLarge: {
    '& textarea': {
      width: '280px',
      fontSize: '15px',
      textAlign: 'left' as const,
    },
    '& input': {
      width: '280px',
      fontSize: '15px',
      textAlign: 'right' as const,
    },
  },
  widthMedium: {
    '& textarea': {
      width: '190px',
      fontSize: '17px',
      textAlign: 'left' as const,
    },
    '& input': {
      width: '190px',
      fontSize: '17px',
      textAlign: 'right' as const,
    },
  },
  widthSmall: {
    '& textarea': {
      width: '100px',
      fontSize: '17px',
      textAlign: 'left' as const,
    },
    '& input': {
      width: '100px',
      fontSize: '17px',
      textAlign: 'right' as const,
    },
  },
});

export const timePickerStyle = (width: string) => ({
  marginLeft: '4px',
  width,
  '& .MuiInputBase-formControl': {
    borderRadius: '0 !important',
  },
  '& .MuiInputBase-formControl input': {
    padding: '18px 0 7px',
  },
  '& .MuiOutlinedInput-root fieldset': {
    border: 'none',
    borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
  },
  '& .MuiOutlinedInput-root .MuiInputAdornment-root.MuiInputAdornment-positionEnd button': {
    padding: '6px',
    marginTop: '4px',
  },
  '& .MuiInputLabel-formControl': {
    marginLeft: '-50px',
  },
  '& .Mui-error fieldset': {
    borderBottom: '1px solid rgba(0, 0, 0, 0.42) !important',
  },
});
