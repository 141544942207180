// libraries
import moment from 'moment-timezone';

// variables
import { USER_GROUPS } from '../userGroups';
import { DATETIME_FORMAT } from '../datetimeFormats';
import { getLogPrefixForType } from './logFunctions';

/**
 * Get access and id token expiration time
 * @returns {string} expiration time
 */
const getAccessAndIdTokenExpiration = () => localStorage.getItem('accessAndIdTokensExpireAt');

/**
 * Get refresh token expiration time
 * @returns {string} refresh token expiration time
 */
const getRefreshTokenExpiration = () => localStorage.getItem('refreshTokenExpiresAt');

/**
 * Function that checks if access and id tokens has expired
 * @returns {boolean}
 */
export const areAccessAndIdTokensExpired = () => {
  const lp = getLogPrefixForType('FUNCTION', 'areAccessAndIdTokensExpired');

  const expiresAt = getAccessAndIdTokenExpiration();

  const currentDate = moment().utc().format(DATETIME_FORMAT);
  const areTokensExpired = moment(currentDate).isAfter(expiresAt);
  if (areTokensExpired) {
    console.debug(lp, `tokens expired at: ${expiresAt}, current time: ${currentDate}`);
  } else {
    console.debug(lp, `tokens valid until: ${expiresAt}, current time: ${currentDate}`);
  }

  return areTokensExpired;
};

/**
 * Functions that checks if refresh_token has expired
 * @param refreshTokenExpiresAt time of refresh token expiration
 * @returns {boolean}
 */
export const isRefreshTokenExpired = () => {
  const refreshTokenExpiresAt = getRefreshTokenExpiration();
  const currentDate = moment().utc().format(DATETIME_FORMAT);
  const refreshTokenExpired = moment(currentDate).isAfter(refreshTokenExpiresAt);
  return refreshTokenExpired;
};

/**
 * Extract user data from accessToken
 * @param accessToken
 * @returns user data
 */
export const getUserDataFromAccessToken = (accessToken?: string | null) => {
  let userGroupsList = [];
  const userGroups = {
    [USER_GROUPS.VERITY_USER]: false,
    [USER_GROUPS.INVENTORY_MANAGER]: false,
    [USER_GROUPS.REVIEWER]: false,
    [USER_GROUPS.GROUND_CONTROL_OFFICER]: false,
    [USER_GROUPS.SAFETY_CONTROLS]: false,
    [USER_GROUPS.ANALYTICS]: false,
    [USER_GROUPS.SUPER_ADMIN]: false,
  };
  let usernameHashed = '';

  if (accessToken) {
    const jwtData = accessToken.split('.')[1];
    const decodedJwtJsonData = window.atob(jwtData);
    const decodedJwtData = JSON.parse(decodedJwtJsonData);
    userGroupsList = decodedJwtData['cognito:groups'];
    usernameHashed = decodedJwtData.username;

    userGroupsList.forEach((group: USER_GROUPS) => {
      userGroups[group] = true;
    });
  }

  return {
    userGroups,
    usernameHashed,
  };
};

/**
 * Get the access and id tokens remaining lifetime, in milliseconds
 * @returns ms to the expiration of the tokens (0 if already expired, -1 if tokens are not available)
 */
export const getAccessTokenExpirationTimeInMs = () => {
  const currentDate = moment().utc().format(DATETIME_FORMAT);
  const accessAndIdTokensExpirationDate = getAccessAndIdTokenExpiration();

  if (!accessAndIdTokensExpirationDate) {
    return -1;
  }

  const accessAndIdTokensExpireIn =
    moment(accessAndIdTokensExpirationDate).diff(currentDate) > 0
      ? moment(accessAndIdTokensExpirationDate).diff(currentDate)
      : 0;

  return accessAndIdTokensExpireIn;
};
