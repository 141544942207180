import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { YesNoModal } from 'components/ModalsAndPopups/YesNoModal';
import { userStore } from 'store/UserStore';
import { useRequestController } from '../../../../../hooks';
import { UserWithFacilities } from '../../model/user.model';

export const DeleteUserModal = ({
  user,
  onClose,
  onDeleted,
}: {
  user: UserWithFacilities;
  onClose: () => void;
  onDeleted: () => void;
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { requestController } = useRequestController('DeleteUserModal');
  const { systemId } = useParams();
  const onDelete = () => {
    requestController.doRequest({
      request: userStore.deleteUser,
      requestParams: [systemId, user.id, requestController.signal],
      callbackBeforeSend: () => {
        setIsLoading(true);
      },
      messageSuccess: 'User deleted.',
      messageErrorFallback: 'User could not be deleted.',
      callbackSuccess: onDeleted,
      callbackFinally: () => {
        setIsLoading(false);
        onClose();
      },
    });
  };

  return (
    <YesNoModal
      closeButton={false}
      opened={true}
      title="Delete user?"
      subtitle={`You are about to delete user ${user.email}`}
      bodyText="When you delete a user, you are also removing this user’s access to all the facilities the user is associated with. This operation cannot be undone, are you sure you want to proceed?"
      yesLabel="Delete user"
      noLabel="Cancel"
      loading={isLoading}
      onYesFunction={onDelete}
      onNoFunction={onClose}
      yesCTA={true}
    />
  );
};
