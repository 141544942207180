import { NewUser } from './user.model';
import { IUserPostRequestST } from '../../../../codegen/user_admin';

export const transformNewUserToDTO: (user: NewUser) => IUserPostRequestST = (user) => ({
  system_ids: user.systemIds.join(','),
  user_email: user.email,
  family_name: user.lastName,
  given_name: user.firstName,
  phone_number: user.phone
    .replaceAll(' ', '')
    .replaceAll('-', '')
    .replaceAll('(', '')
    .replaceAll(')', ''),
  user_groups: user.userGroups,
  access_to_all_facilities: user.accessToAllFacilities,
});
