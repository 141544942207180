import { IFacilitySettingsST } from 'codegen/facility_settings';
import { SettingsItem } from '../models/settingsCard.model';

export const getReportsAutoUpdateOption = (
  facilitySettings: IFacilitySettingsST,
  handleValuesChange: (prop: string, value: number) => void,
  validateInputs: () => void,
  errors: any,
  setErrors: (value: any) => void,
  autoUpdateDisabled: boolean,
  setAutoUpdateDisabled: (value: React.SetStateAction<boolean>) => void,
  autoUpdateRef: React.MutableRefObject<any>,
): SettingsItem => ({
  type: 'number with activation',
  width: 'medium',
  inputProps: { min: 1, step: '1' },
  label: 'Auto update (in minutes)',
  testId: 'c-auto-update',
  description: `If enabled, specifies when the automatic update should start.
                    The automatic update window starts N minutes before the start of the automatic abort and lasts
                    1 minute. If not set, the report is not automatically updated.`,
  disableItemControls: autoUpdateDisabled,
  value: facilitySettings.auto_update_period_multiplier,
  errors: errors.validUpdate,
  ref: autoUpdateRef,
  onChange: () => {
    !autoUpdateDisabled
      ? setErrors((prevState: any) => ({ ...prevState, validUpdate: '' }))
      : validateInputs();
    setAutoUpdateDisabled(!autoUpdateDisabled);
  },
  action: (e: React.ChangeEvent<HTMLInputElement>) =>
    handleValuesChange('auto_update_period_multiplier', Number(e.target.value)),
});

export const getReportsAutoAbortOptions = (
  facilitySettings: IFacilitySettingsST,
  handleValuesChange: (prop: string, value: number) => void,
  validateInputs: () => void,
  errors: any,
  setErrors: (value: any) => void,
  autoAbortDisabled: boolean,
  setAutoAbortDisabled: (value: React.SetStateAction<boolean>) => void,
  autoAbortRef: React.MutableRefObject<any>,
): SettingsItem => ({
  type: 'number with activation',
  width: 'medium',
  inputProps: { min: 1, step: '1' },
  label: 'Auto abort (in minutes)',
  testId: 'c-auto-abort',
  description: `If enabled, specifies when the automatic abort should start.
                    The automatic abort window starts N minutes before the start of the automatic approval and lasts
                    1 minute. If not set, the report is not automatically aborted.`,
  disableItemControls: autoAbortDisabled,
  value: facilitySettings.auto_abort_period_multiplier,
  errors: errors.validAbort,
  ref: autoAbortRef,
  onChange: () => {
    !autoAbortDisabled
      ? setErrors((prevState: any) => ({ ...prevState, validAbort: '' }))
      : validateInputs();
    setAutoAbortDisabled(!autoAbortDisabled);
  },
  action: (e: React.ChangeEvent<HTMLInputElement>) =>
    handleValuesChange('auto_abort_period_multiplier', Number(e.target.value)),
});

export const getReportsAutoApprovalOptions = (
  facilitySettings: IFacilitySettingsST,
  handleValuesChange: (prop: string, value: number) => void,
  validateInputs: () => void,
  errors: any,
  setErrors: (value: any) => void,
  autoApprovalDisabled: boolean,
  setAutoApprovalDisabled: (value: React.SetStateAction<boolean>) => void,
  autoApprovalRef: React.MutableRefObject<any>,
): SettingsItem => ({
  type: 'number with activation',
  width: 'medium',
  inputProps: { min: 1, step: '1' },
  label: 'Auto approval (in minutes)',
  testId: 'c-auto-approval',
  description: `If enabled, specifies when the automatic approval of the locations in review should start.
                    The automatic approval window starts N+1 minutes before the end of the Drone Flight Hours and lasts
                    1 minute. If not set, the report is not automatically approved.`,
  disableItemControls: autoApprovalDisabled,
  value: facilitySettings.auto_approval_period_multiplier,
  errors: !autoApprovalDisabled && errors.validApproval,
  ref: autoApprovalRef,
  onChange: () => {
    !autoApprovalDisabled
      ? setErrors((prevState: any) => ({ ...prevState, validApproval: '' }))
      : validateInputs();
    setAutoApprovalDisabled(!autoApprovalDisabled);
  },
  action: (e: React.ChangeEvent<HTMLInputElement>) =>
    handleValuesChange('auto_approval_period_multiplier', Number(e.target.value)),
});
