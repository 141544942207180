import { notEver } from 'common/functions/otherFunctions';
import { NoFlyZone } from 'shared/map/model/no-fly-zone.model';
import { transformApiDroneZone } from '../model/transformApiDroneZone';
import { transformIntoOpenFlyZone } from '../model/transformIntoOpenFlyZone';
import { transformIntoControlledZone } from '../model/transformIntoControlledZone';
import { FlowState, ZoneStatusState } from './zoneStatusInitialState';
import { transformIntoNoFlyZone } from '../model/transformIntoNoFlyZone';
import { updateNoFlyZone } from './updateNoFlyZone';
import { deleteNoFlyZone } from './deleteNoFlyZone';
import { NEW_DRONE_ZONE_ID } from '../features/Map/defaults/newDroneZoneId';
import { ZoneStatusActions } from './zoneStatusActions';
import { transformIntoFlightArea } from '../model/transformIntoFlightArea';

export const zoneStatusReducer = (
  state: ZoneStatusState,
  action: ZoneStatusActions,
): ZoneStatusState => {
  switch (action.type) {
    case 'SET_FLOW_STATE':
      return {
        ...state,
        flowState: action.payload,
      };
    case 'SET_DRONE_ZONES':
      return {
        ...state,
        noFlyZones: transformApiDroneZone(action.payload.noFlyZones, transformIntoNoFlyZone),
        openFlyZones: transformApiDroneZone(action.payload.openFlyZones, transformIntoOpenFlyZone),
        controlledZones: transformApiDroneZone(
          action.payload.controlledZones,
          transformIntoControlledZone,
        ),
      };
    case 'SET_OPEN_FLY_ZONES':
      return {
        ...state,
        openFlyZones: transformApiDroneZone(action.payload, transformIntoOpenFlyZone),
      };
    case 'SET_NO_FLY_ZONES':
      return {
        ...state,
        noFlyZones: transformApiDroneZone(action.payload, transformIntoNoFlyZone),
      };
    case 'SET_CONTROLLED_ZONES':
      return {
        ...state,
        controlledZones: transformApiDroneZone(action.payload, transformIntoControlledZone),
      };
    case 'SET_FLIGHT_AREAS':
      return {
        ...state,
        flightAreas: transformApiDroneZone(action.payload, transformIntoFlightArea),
      };

    case 'START_EDITING_NO_FLY_ZONE': {
      return {
        ...state,
        flowState: FlowState.CREATE,
        currentlySelectedZone: null,
        detailsZones: [],
        currentlyEditingZone: action.payload,
      };
    }
    case 'CANCEL_EDITING_NO_FLY_ZONE': {
      let noFlyZones;
      if (action.payload.id === NEW_DRONE_ZONE_ID) {
        noFlyZones = deleteNoFlyZone(action.payload, state.noFlyZones);
      } else {
        noFlyZones = updateNoFlyZone(action.payload, state.noFlyZones);
      }

      return {
        ...state,
        currentlyEditingZone: null,
        noFlyZones,
        flowState: FlowState.LIST,
      };
    }
    case 'SHOW_NO_FLY_ZONE_DELETION_MODAL': {
      return {
        ...state,
        currentlyDeletingZone: action.payload,
        flowState: FlowState.DELETE,
      };
    }
    case 'CLOSE_NO_FLY_ZONE_DELETION_MODAL': {
      return {
        ...state,
        currentlyDeletingZone: null,
        flowState: FlowState.LIST,
      };
    }
    case 'SAVE_NO_FLY_ZONE_CHANGES': {
      return {
        ...state,
        currentlyEditingZone: null,
        flowState: FlowState.LIST,
      };
    }
    case 'CONFIRM_NO_FLY_ZONE_DELETION_MODAL': {
      return {
        ...state,
        noFlyZones: deleteNoFlyZone(state.currentlyDeletingZone as NoFlyZone, state.noFlyZones),
        detailsZones: state.detailsZones.filter((dz) => dz.id !== state.currentlyDeletingZone?.id),
        currentlyDeletingZone: null,
        flowState: FlowState.LIST,
      };
    }
    case 'UPDATE_NO_FLY_ZONE': {
      return {
        ...state,
        noFlyZones: updateNoFlyZone(action.payload, state.noFlyZones),
      };
    }
    case 'END_DRAGGING_DRONE_ZONE': {
      return {
        ...state,
        currentlyEditingZone: action.payload,
        noFlyZones: updateNoFlyZone(action.payload, state.noFlyZones),
      };
    }
    case 'DELETE_NO_FLY_ZONE': {
      return {
        ...state,
        noFlyZones: deleteNoFlyZone(action.payload, state.noFlyZones),
      };
    }

    case 'CREATION_OF_ZONE_DRAWING_START': {
      return {
        ...state,
        flowState: FlowState.DRAW,
        currentlySelectedZone: null,
        detailsZones: [],
      };
    }

    case 'SHOW_NO_FLY_ZONE_CREATION_FORM': {
      return {
        ...state,
        flowState: FlowState.CREATE,
        detailsZones: [],
        currentlyEditingZone: action.payload,
        currentlySelectedZone: action.payload,
        noFlyZones: updateNoFlyZone(action.payload, state.noFlyZones),
      };
    }

    case 'SELECT_SINGLE_DRONE_ZONE': {
      const isEditing = [FlowState.CREATE, FlowState.DRAW].includes(state.flowState);

      if (action.payload && !isEditing) {
        return {
          ...state,
          detailsZones: [action.payload],
          currentlySelectedZone: action.payload,
        };
      }

      return {
        ...state,
        detailsZones: [],
        currentlySelectedZone: null,
      };
    }

    case 'SELECT_SINGLE_FROM_DRONE_ZONE_GROUP': {
      return {
        ...state,
        currentlySelectedZone: action.payload,
      };
    }

    case 'SELECT_MULTIPLE_DRONE_ZONE': {
      const isEditing = [FlowState.CREATE, FlowState.DRAW].includes(state.flowState);

      return {
        ...state,
        detailsZones: isEditing ? [] : action.payload,
        currentlySelectedZone: null,
      };
    }

    default:
      notEver(action);
      return { ...state };
  }
};
