import { IReportST } from 'codegen/report';
import { getReportExecutionSection } from './getReportExecutionSection';
import { ReportSummaryData } from '../ReportStore.model';

export const getReportExecutionData = (
  requestParams: {
    label: string;
    value: string;
  }[],
  reportSummary: IReportST,
): ReportSummaryData => {
  const title = 'Details';
  const subtitle = `Scheduled by ${
    reportSummary.request && reportSummary.request.requesting_user_email
      ? reportSummary.request.requesting_user_email
      : 'n/a'
  }`;
  const sections = getReportExecutionSection(requestParams, reportSummary);

  return {
    title,
    counterSubject: '',
    subtitle,
    sections,
  };
};
