import { ILocationDataST } from 'codegen/warehouse_status';

export const foundDateValueGetter = (row: ILocationDataST) => {
  const dateTime = typeof row.verity_status !== 'string' && row.verity_status?.collected_at;
  if (!dateTime) {
    return null;
  }

  return new Date(dateTime);
};
