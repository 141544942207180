import { Typography, IconButton, Tooltip, useTheme } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Badge } from 'components/common/Badge';
import { ISSUE_BADGE_COLORS } from 'common/colors';
import { ILocationData } from 'store/Modals/facilityModals/IFacilityModalsStore';
import { DISPLAY_ISSUE_TYPES } from 'common/issueTypesAndStates';
import { getIssueType, muiColorToTheme } from 'common/functions/issues/issueColorFunctions';
import { Dispatch, SetStateAction } from 'react';
import { Box } from 'components/common/Box';
import { IFacilitySettingsST } from 'codegen/facility_settings';
import { NavigationButtons } from '../navigation/NavigationButtons';
import { locationStyles } from '../styles';
import { ThreeDotsMenu } from './ThreeDotsMenu';
import { locationImagesType } from '../hooks/useLocationImages';

export const LocationModalHeader = ({
  locationData,
  filteredLocationList,
  refreshLocationData,
  currentLocationIndex,
  setCurrentLocationIndex,
  enableSnooze,
  facilitySettings,
  locationImages,
}: {
  locationData: ILocationData;
  filteredLocationList: ILocationData[];
  refreshLocationData: (locationLabel: string) => void;
  currentLocationIndex: number;
  setCurrentLocationIndex: Dispatch<SetStateAction<number>>;
  enableSnooze: boolean;
  facilitySettings: IFacilitySettingsST;
  locationImages: locationImagesType;
}) => {
  const { classes } = locationStyles();
  const theme = useTheme();

  const showNavigation = filteredLocationList.length > 1;

  const title = locationData.location;
  const issue = DISPLAY_ISSUE_TYPES[locationData.issueType]
    ? DISPLAY_ISSUE_TYPES[locationData.issueType]
    : '';
  const issueType = getIssueType(locationData.issueType);

  return (
    <Box textAlign="left" p={1} paddingTop="6px" paddingBottom="6px">
      <Box display="flex" justifyContent="space-between">
        <Box display="flex" alignItems="center" gap={2}>
          <Typography
            className={classes.modalTitle}
            color="secondary"
            variant="h5"
            data-testid="title"
            minWidth="90px"
          >
            {title}
          </Typography>
          {issue && (
            <Badge
              bgcolor={muiColorToTheme(issueType.color, theme).main}
              color={muiColorToTheme(issueType.color, theme).text}
              bigBadge={false}
              sx={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              <Typography variant="body1">{issue}</Typography>
            </Badge>
          )}
          <Badge
            bgcolor={ISSUE_BADGE_COLORS[locationData?.state ?? 'NONE']?.background}
            color={ISSUE_BADGE_COLORS[locationData?.state ?? 'NONE']?.text}
            bigBadge={false}
            sx={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            <Typography variant="body1">
              {(locationData?.state && `${locationData.state} ISSUE`) || 'NO ISSUE'}
            </Typography>
          </Badge>

          <ThreeDotsMenu
            locationData={locationData}
            enableSnooze={enableSnooze}
            refreshLocationData={refreshLocationData}
            locationImages={locationImages}
            facilitySettings={facilitySettings}
          />
        </Box>
        <Box display="flex" paddingRight="6em" marginTop="-3px" alignItems="center" gap={3}>
          <IconButton
            aria-label="refresh"
            onClick={() => refreshLocationData(locationData?.location)}
            size="large"
          >
            <Tooltip title="Refresh">
              <RefreshIcon />
            </Tooltip>
          </IconButton>
          {showNavigation && (
            <NavigationButtons
              locationsData={filteredLocationList}
              currentLocationIndex={currentLocationIndex}
              setCurrentLocationIndex={setCurrentLocationIndex}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};
