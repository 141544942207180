import React, { forwardRef, useImperativeHandle } from 'react';
import { FormHelperText, FormLabel } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import Switch from '@mui/material/Switch';
import { ICustomSwitchProps } from '../../../interfaces/inputs';

const useStyles = makeStyles()(() => ({
  switchContainer: {
    flex: '0 1 auto',
    minWidth: 0,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

export const CustomSwitch = forwardRef(
  (props: ICustomSwitchProps, ref: React.ForwardedRef<void>) => {
    const {
      id,
      label,
      name,
      checked,
      onChange,
      style,
      disabled = false,
      testId = '',
      sx,
      helperText = '',
    } = props;

    const { classes } = useStyles();

    // This is needed so that this component can expose methods to its parent components
    useImperativeHandle(ref, () => ({}));

    const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      onChange(event.target.checked);
    };

    return (
      <div className={classes.switchContainer}>
        <FormLabel id={`label-for-${id}`} style={{ fontSize: '0.75rem' }}>
          {label}
        </FormLabel>
        {helperText.length > 0 && <FormHelperText sx={{ mt: 0 }}>{helperText}</FormHelperText>}
        <Switch
          id={id}
          aria-labelledby={`label-for-${id}`}
          name={name}
          checked={checked}
          onChange={handleSwitchChange}
          disabled={disabled}
          data-testid={testId}
          sx={sx}
          style={style}
        />
      </div>
    );
  },
);

CustomSwitch.displayName = 'CustomSwitch';
