import { LinearProgress, Typography } from '@mui/material';
import { useState } from 'react';
import { ModalBase } from 'components/ModalsAndPopups/ModalBase';
import { Box } from 'components/common/Box';

type LocationsUpdateModalProps = {
  numOfLocations: number;
  pendingRequests: number;
  totalRequests: number;
};

/**
 * Locations update modal: report on the progress of the updating of the
 * exclusion status of the modals
 * @param props LocationsModalProps
 * @returns JSX.Element
 */
export const LocationsUpdateModal = ({
  numOfLocations,
  pendingRequests,
  totalRequests,
}: LocationsUpdateModalProps) => {
  const titleData = <Box p={2}>Locations updated in progress</Box>;
  const locationsText = numOfLocations === 1 ? 'location is' : 'locations are';
  const [showDoNotCloseAlert, setShowDoNotCloseAlert] = useState(false);

  console.debug(
    'LocationsUpdateModal',
    `pendingRequests => ${pendingRequests}, totalRequests => ${totalRequests}`,
  );

  const progress = ((totalRequests - pendingRequests) / totalRequests) * 100;

  return (
    <ModalBase
      testId="c-location-management-modal"
      opened
      title={titleData}
      handleClose={() => setShowDoNotCloseAlert(true)}
    >
      <Typography mb={2}>
        <strong>{numOfLocations}</strong> {locationsText} being updated. Please do not close this
        dialog or page. page
      </Typography>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgress variant="determinate" value={progress} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">
            {`${Math.round(progress)}%`}
          </Typography>
        </Box>
      </Box>
      {showDoNotCloseAlert && (
        <Typography mb={2} mt={1}>
          <strong>Please DO NOT CLOSE this dialog until the update is finished.</strong>
        </Typography>
      )}
    </ModalBase>
  );
};
