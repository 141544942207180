// mui
import { SelectChangeEvent, TextField } from '@mui/material';
// components
import { VALUE_TYPES } from 'common/reportSpecifications';
import {
  isParamMultiChoiceSelect,
  isParamSingleChoiceSelect,
  isParamTextInput,
} from 'common/functionsReportSpec';
import { CustomMultiSelect } from 'components/common/CustomFormComponents/CustomMultiSelect';
import { CustomSelect } from 'components/common/CustomFormComponents/CustomSelect';
// types
import {
  IInventoryRequestParameterST,
  IReportParameterST,
  IReportParameterUniqueNameST,
} from 'codegen/inventory_request/api';
import {
  DropDownInputSettingsST,
  InputSettingsOptionsST,
  ReportParamInputError,
} from 'interfaces/reportsInterfaces';
import { Errors } from '../reducers/ScheduleFormReducer';
// functions

/**
 *
 * @param param IReportParameterST params
 * @returns param
 */
const getTypeOfParam = (
  param: IReportParameterST,
): 'TEXT' | 'SINGLE_CHOICE' | 'MULTI_CHOICE' | 'unknown' => {
  if (isParamTextInput(param)) return 'TEXT';
  if (isParamSingleChoiceSelect(param)) return 'SINGLE_CHOICE';
  if (isParamMultiChoiceSelect(param)) return 'MULTI_CHOICE';
  return 'unknown';
};

/**
 * Name form props type
 */
type NameFormProps = {
  reportName: string;
  isInputInvalid: boolean;
  helperText?: string;
  onChangeOrBlur: (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
};

/**
 *
 * @param props NameFormProps params
 * @returns component
 */
export const NameField: React.FC<NameFormProps> = (props: NameFormProps): JSX.Element => (
  <TextField
    id="report_name"
    data-testid="c-report-name"
    label="Report Name"
    variant="outlined"
    fullWidth={true}
    value={props.reportName}
    error={props.isInputInvalid}
    helperText={props.helperText}
    onBlur={(e) => props.onChangeOrBlur(e)}
    onChange={(e: React.ChangeEvent<HTMLInputElement>) => props.onChangeOrBlur(e)}
  />
);

type ParamFieldProps = {
  param: IReportParameterST;
  index: number;
  paramValue: string;
  isInvalidParamValue: boolean;
  helperText: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

type TextParamFieldProps = ParamFieldProps & {
  type: 'text' | 'number';
};

/**
 *
 * @param props TextParamFieldProps params
 * @returns component
 */
const TextParamField = (props: TextParamFieldProps) => {
  const isMultiline = props.param.value_type === VALUE_TYPES.STRING;
  return (
    <TextField
      data-testid={`c-text-field-${props.index}`}
      variant="outlined"
      margin="normal"
      fullWidth
      label={props.param.user_facing_name}
      value={props.paramValue || ''}
      multiline={isMultiline}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => props.onChange(e)}
      error={props.isInvalidParamValue}
      helperText={props.helperText}
      type={props.type}
    />
  );
};

/**
 * Single choice param field props type
 */
type SingleChoiceParamFieldProps = Omit<ParamFieldProps, 'onChange'> & {
  valueOptions: InputSettingsOptionsST[];
  onChange: (e: SelectChangeEvent<string>) => void;
};

const SingleChoiceParamField = (props: SingleChoiceParamFieldProps) => (
  <CustomSelect
    testId={`c-single-choice-dropdown-${props.index}`}
    variant="outlined"
    margin="normal"
    disabled={false}
    id={props.param.unique_name}
    label={props.param.user_facing_name}
    name={props.param.unique_name}
    value={props.paramValue}
    defaultValue=""
    valueOptions={props.valueOptions}
    onChange={(e) => props.onChange(e)}
    error={props.isInvalidParamValue}
    errorMessage={props.helperText}
  />
);

/**
 * Multi choice param field props type
 */
type MultiChoiceParamFieldProps = Omit<SingleChoiceParamFieldProps, 'value' | 'paramValue'> & {
  values: string[];
  onBlur: () => void;
  onClear: () => void;
};

const MultiChoiceParamField = (props: MultiChoiceParamFieldProps) => (
  <CustomMultiSelect
    id={props.param.unique_name}
    testId={`c-multi-choice-dropdown-${props.index}`}
    variant="outlined"
    margin="normal"
    label={props.param.user_facing_name}
    name={props.param.unique_name}
    values={props.values}
    valueOptions={props.valueOptions}
    onChange={(e) => props.onChange(e)}
    onBlur={() => props.onBlur()}
    onClear={() => props.onClear()}
    error={props.isInvalidParamValue}
    errorMessage={props.helperText}
  />
);

/**
 * Params form props type
 */
type ParamsFormProps = {
  classes: any;
  params: IReportParameterST[];
  customParams: Record<IReportParameterUniqueNameST, string>;
  reportParamValues: IInventoryRequestParameterST[];
  errors: Errors;
  updateReportParamValues: (paramUniqueName: IReportParameterUniqueNameST, value: any) => void;
  clearReportParamValues: (paramUniqueName: IReportParameterUniqueNameST) => void;
  onBlur: (paramName: IReportParameterUniqueNameST) => void;
};

const getValueOptions = (param: IReportParameterST) =>
  (param.input_settings as DropDownInputSettingsST).options?.map(
    (option: InputSettingsOptionsST) => ({
      label: option.label,
      value: option.value,
    }),
  );

/**
 *
 * @param param0 ParamsFormProps params
 * @returns component
 */
export const ParamForm = ({
  classes,
  params,
  customParams,
  reportParamValues,
  errors,
  updateReportParamValues,
  clearReportParamValues,
  onBlur,
}: ParamsFormProps) => {
  const getMarkupForParam = (param: IReportParameterST, index: number) => {
    const isInvalidParamValue = !!(errors.validParams as ReportParamInputError[])?.find(
      (el) => el.unique_name === param.unique_name,
    );

    const helperText =
      (errors.validParams as ReportParamInputError[])?.find(
        (el) => el.unique_name === param.unique_name,
      )?.error || '';

    const key = `key-${param.unique_name}-param`;

    const typeOfParam = getTypeOfParam(param);

    if (typeOfParam === 'TEXT') {
      const paramType = [VALUE_TYPES.INTEGER, VALUE_TYPES.DECIMAL].includes(param.value_type)
        ? 'number'
        : 'text';
      return (
        <TextParamField
          key={key}
          param={param}
          index={index}
          paramValue={customParams[param.unique_name]}
          isInvalidParamValue={isInvalidParamValue}
          helperText={helperText}
          type={paramType}
          onChange={(e) => updateReportParamValues(param.unique_name, e.target.value)}
        />
      );
    }

    if (typeOfParam === 'SINGLE_CHOICE') {
      const valueOptions = getValueOptions(param);
      const paramValue =
        reportParamValues.find((el) => el.unique_name === param.unique_name)?.values.toString() ||
        '';
      return (
        <SingleChoiceParamField
          key={key}
          param={param}
          index={index}
          paramValue={paramValue}
          valueOptions={valueOptions}
          onChange={(e) => updateReportParamValues(param.unique_name, [e.target.value])}
          isInvalidParamValue={isInvalidParamValue}
          helperText={helperText}
        />
      );
    }

    if (typeOfParam === 'MULTI_CHOICE') {
      const valueOptions = getValueOptions(param);
      const paramValues =
        reportParamValues.find((el) => el.unique_name === param.unique_name)?.values || [];
      return (
        <MultiChoiceParamField
          key={key}
          param={param}
          index={index}
          values={paramValues}
          valueOptions={valueOptions}
          onChange={(e) => updateReportParamValues(param.unique_name, e.target.value)}
          isInvalidParamValue={isInvalidParamValue}
          helperText={helperText}
          onBlur={() => onBlur(param.unique_name)}
          onClear={() => clearReportParamValues(param.unique_name)}
        />
      );
    }

    return null;
  };

  const paramFields = params.map(getMarkupForParam);

  return <div className={classes.formControl}>{paramFields}</div>;
};
