import { Typography } from '@mui/material';
import CloudOffIcon from '@mui/icons-material/CloudOff';
import { Box } from './Box';

export const NoConnection = ({ color = 'inherit', text }: { color?: string; text: string }) => (
  <Box display="flex" justifyContent="center" alignItems="center">
    <CloudOffIcon sx={{ color }} fontSize="medium" />

    <Typography color={color} ml={1}>
      {text}
    </Typography>
  </Box>
);
