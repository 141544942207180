import {
  ICreateEstimatedObstacleRequestST,
  ICreateSpaceReservationRequestST,
} from 'codegen/nav_simulation';
import {
  getDevToolsService,
  getOperationsSimulatorService,
  getSfeSimulatorService,
  getVersionService,
} from './services';

const postTaskExecutor = (systemId: string, issueRate: string, nrLocationsToSimulate: string) => {
  const params = {
    issue_rate: Number(issueRate),
    num_locations_to_simulate:
      nrLocationsToSimulate === '' ? undefined : Number(nrLocationsToSimulate),
  };
  return getDevToolsService().runTaskExecutor(systemId, { params });
};

const postCSUploadImages = (systemId: string) => getDevToolsService().imagesUpload(systemId);

const getAppVersions = (systemId: string) => getVersionService().getServicesVersion(systemId);

const postSFESimulator = (systemId: string, flightDomainId: string, nrObjectives: number) =>
  getSfeSimulatorService().simulatorCreateRawTask(systemId, flightDomainId, {
    nr_objectives: nrObjectives,
  });

const postCreateEstimatedObstacle = (
  systemId: string,
  flightDomainId: string,
  createRequest: ICreateEstimatedObstacleRequestST,
) =>
  getOperationsSimulatorService().createOperationsSimulatorEstimatedObstacles(
    systemId,
    flightDomainId,
    createRequest,
  );

const deleteEstimatedObstacles = (systemId: string, flightDomainId: string) =>
  getOperationsSimulatorService().deleteOperationsSimulatorEstimatedObstacles(
    systemId,
    flightDomainId,
  );

const postCreateSpaceReservation = (
  systemId: string,
  flightDomainId: string,
  createRequest: ICreateSpaceReservationRequestST,
) =>
  getOperationsSimulatorService().createOperationsSimulatorSpaceReservation(
    systemId,
    flightDomainId,
    createRequest,
  );

const deleteSpaceReservations = (systemId: string, flightDomainId: string) =>
  getOperationsSimulatorService().deleteOperationsSimulatorSpaceReservation(
    systemId,
    flightDomainId,
  );

export const developerServices = {
  postTaskExecutor,
  postCSUploadImages,
  getAppVersions,
  postSFESimulator,
  postCreateEstimatedObstacle,
  deleteEstimatedObstacles,
  postCreateSpaceReservation,
  deleteSpaceReservations,
};
