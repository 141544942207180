// libraries
import React, { useState } from 'react';
import moment from 'moment';

// material-ui core
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

// variables, functions, configurations
import { ISSUE_STATES } from 'common/issueTypesAndStates';
import { REPORT_ISSUES_DATETIME_FORMAT } from 'common/datetimeFormats';

// components
import { ISSUE_ACTIONS } from 'common/Actions/actionTypes';
import { Spinner } from 'components/common/Spinner';
import { Box } from 'components/common/Box';
import { ModalBase } from './ModalBase';
import { useRequestController } from '../../hooks';
import { issuesStore } from '../../store/IssuesStore';
import { useFacilityModalsStore } from '../../store/Modals';
import { FacilityModalsActionTypes } from '../../store/Modals/types';
import { useFacilityLevelStore } from '../../store/FacilityLevelStore/facilityLevelStore';

interface ISnoozeIssueModalProps {
  opened: boolean;
  issueToSnooze: {
    id: string;
    state: ISSUE_STATES;
    snoozedByUserName: string;
    snoozedOn: string;
  };
}

export const SnoozeIssueModal = (props: ISnoozeIssueModalProps) => {
  const { opened, issueToSnooze } = props;

  const { facilityModalsState, dispatchFacilityModals } = useFacilityModalsStore();
  // NOTE: this component never gets unmounted and, hence, it doesn't matter
  // whether requests are set as cancellable or not.
  const { requestController } = useRequestController('SnoozeIssueModal');

  const [loading, setLoading] = useState(false);
  const { currentSystemId: systemId } = useFacilityLevelStore().stateFacilityLevel;

  const toggleSnoozeState = (
    systemId: string | null | undefined,
    issueId: string,
    action: string,
  ) => {
    setLoading(true);
    switch (action) {
      case 'snooze':
        requestController.doRequest({
          request: issuesStore.toggleSnoozeIssue,
          requestParams: [systemId, issueId, ISSUE_ACTIONS.SNOOZE],
          callbackSuccess: () => {
            dispatchFacilityModals({
              type: FacilityModalsActionTypes.TOGGLE_SNOOZE_ISSUE,
              payload: {},
            });
            Object.keys(facilityModalsState.refreshData).forEach((func) =>
              facilityModalsState.refreshData[func](),
            );
          },
          messageSuccess: 'Issue snoozed!',
          messageErrorFallback: 'Issue could not be snoozed!',
          callbackFinally: () => {
            // disable the spinner
            setLoading(false);
            // close the modal
            dispatchFacilityModals({
              type: FacilityModalsActionTypes.TOGGLE_SNOOZE_ISSUE,
              payload: {},
            });
          },
        });
        break;

      case 'unsnooze':
        requestController.doRequest({
          request: issuesStore.toggleSnoozeIssue,
          requestParams: [systemId, issueId, ISSUE_ACTIONS.UNSNOOZE],
          callbackSuccess: () => {
            dispatchFacilityModals({
              type: FacilityModalsActionTypes.TOGGLE_SNOOZE_ISSUE,
              payload: {},
            });
            Object.keys(facilityModalsState.refreshData).forEach((func) =>
              facilityModalsState.refreshData[func](),
            );
          },
          messageSuccess: 'Issue unsnoozed!',
          messageErrorFallback: 'Issue could not be unsnoozed!',
          callbackFinally: () => setLoading(false),
        });
        break;

      default:
        break;
    }
  };

  const closeModal = () =>
    dispatchFacilityModals({
      type: FacilityModalsActionTypes.TOGGLE_SNOOZE_ISSUE,
      payload: {},
    });

  return (
    <ModalBase
      testId="c-snooze-issue-modal"
      opened={opened}
      title={
        <Box data-testid="c-snooze-issue-modal-title">
          <Box textAlign="left" p={2} mb={1}>
            <Typography style={{ fontWeight: 'bold' }} color="secondary" variant="h5">
              {issueToSnooze.state === ISSUE_STATES.SNOOZED ? 'Unsnooze Issue?' : 'Snooze Issue?'}
            </Typography>
            <Typography color="secondary" variant="subtitle1">
              {issueToSnooze.state === ISSUE_STATES.SNOOZED &&
                issueToSnooze.snoozedByUserName &&
                issueToSnooze.snoozedOn &&
                `Snoozed by ${issueToSnooze.snoozedByUserName} on ${moment(
                  issueToSnooze.snoozedOn,
                ).format(REPORT_ISSUES_DATETIME_FORMAT)}`}
            </Typography>
          </Box>
        </Box>
      }
      handleClose={closeModal}
      actionButtons={
        <>
          {issueToSnooze.state === ISSUE_STATES.SNOOZED ? (
            <Button
              onClick={() => toggleSnoozeState(systemId, issueToSnooze.id, 'unsnooze')}
              variant="outlined"
              fullWidth
              color="primary"
              data-testid="c-unsnooze-button"
            >
              Unsnooze
            </Button>
          ) : (
            <Button
              onClick={() => toggleSnoozeState(systemId, issueToSnooze.id, 'snooze')}
              variant="outlined"
              fullWidth
              color="primary"
              data-testid="c-snooze-button"
            >
              Snooze
            </Button>
          )}
          <Button
            onClick={closeModal}
            variant="contained"
            data-testid="c-cancel-button"
            fullWidth
            color="primary"
          >
            Cancel
          </Button>
        </>
      }
    >
      <Typography style={{ margin: '20px 0px' }} align="left">
        {`Are you sure you want to
          ${issueToSnooze.state === ISSUE_STATES.SNOOZED ? 'unsnooze' : 'snooze'}
          this issue?`}
      </Typography>
      {loading && <Spinner />}
    </ModalBase>
  );
};
