import moment from 'moment-timezone';
import { useCallback, useEffect, useState } from 'react';

import { AlertColor } from '@mui/material';

import { getFlightDomainStatusColor } from 'common/functions/otherFunctions';
import { Box } from 'components/common/Box';
import { BaseCard } from 'components/BaseCard/BaseCard';
import { BaseCardSimpleItem } from 'components/BaseCard/BaseCardSimpleItem';
import { AlertComponent } from 'components/common/Alert';
import { LocalStore } from 'common/functions/storageFunctions';
import { IDroneOperatingHoursST } from 'codegen/nav_simulation';
import { LockUnlockSwitch } from 'pages/GroundControl/StatusBar/LockUnlockSwitch';

import { useGroundControlStore } from '../../../../store/GroundControl/groundControlLevelStore';

import {
  IFlightDomainData,
  GroundControlActionNames,
} from '../../../../store/GroundControl/groundControlLevelStore.model';
import { useGroundControlModalsStore } from '../../../../store/Modals/groundControlModals/groundControlModalsStore';
import { GroundControlModalsActionNames } from '../../../../store/Modals/groundControlModals/groundControlModalsActions';

export const FlightDomainStatusCard = ({
  flightDomain,
  dronesFlying,
  flightDomainId,
  flightDomainStatusData,
  isExecutingEmergencyLanding,
  isExecutingReturnHome,
  noData,
}: {
  dronesFlying: boolean;
  isExecutingEmergencyLanding: boolean;
  flightDomainId: string;
  isExecutingReturnHome: boolean;
  flightDomain: IFlightDomainData;
  flightDomainStatusData: any;
  noData: boolean;
}) => {
  const { stateGroundControl, dispatchGroundControlLevel } = useGroundControlStore();
  const { dispatchGroundControlModals } = useGroundControlModalsStore();

  const { isOpened, message } = stateGroundControl.alertInfo.statusCard;

  const statusColor = getFlightDomainStatusColor({
    locked: flightDomain.flight_domain_status?.locked || false,
    isExecutingReturnHome,
    isExecutingEmergencyLanding,
  });

  const [subtitle, setSubtitle] = useState('');

  const isLockSwitchVisible = flightDomain.flight_domain_status;

  /**
   * Function that will process current flight domain summary and status
   */
  const handleCardSubtitle = useCallback(() => {
    const timezone = LocalStore.getTimezone();

    const locked = flightDomain.flight_domain_status?.locked || false;
    const currentShiftStart = (
      flightDomain.flight_domain_status?.current_drone_operating_hours as IDroneOperatingHoursST
    )?.start;

    if (isExecutingEmergencyLanding) {
      setSubtitle('Safety landing requested - Flying drones will attempt to land in place.');
      return;
    }

    if (isExecutingReturnHome) {
      setSubtitle(
        'Return home requested - Flying drones will attempt to return to their chargers.',
      );
      return;
    }

    const isCurrentShiftStarted =
      currentShiftStart && moment(currentShiftStart).tz(timezone).isBefore(moment.tz(timezone));

    if (isCurrentShiftStarted) {
      setSubtitle('System can be unlocked.');

      if (!locked) {
        setSubtitle('System is unlocked.');
      }
      return;
    }

    if (!dronesFlying) {
      setSubtitle('System cannot be unlocked until the next drone flight hours start.');
    }
  }, [
    dronesFlying,
    flightDomain.flight_domain_status,
    isExecutingEmergencyLanding,
    isExecutingReturnHome,
  ]);

  useEffect(() => {
    handleCardSubtitle();
  }, [handleCardSubtitle]);

  return (
    <BaseCard
      cardFor="Flight Domain Status"
      dataTestId={flightDomainStatusData.testId}
      title={flightDomainStatusData.title}
      subtitle={subtitle}
      noData={noData}
      counter={
        <Box mt={1}>
          {isLockSwitchVisible && (
            <LockUnlockSwitch
              flight_domain_status={flightDomain.flight_domain_status}
              onLockSystem={() =>
                dispatchGroundControlModals({
                  type: GroundControlModalsActionNames.LOCK_MODAL,
                  payload: flightDomainId,
                })
              }
              onUnlockSystem={() =>
                dispatchGroundControlModals({
                  type: GroundControlModalsActionNames.UNLOCK_MODAL,
                  payload: flightDomainId,
                })
              }
              statusColor={statusColor}
            />
          )}
        </Box>
      }
      alertElement={
        <AlertComponent
          isActive={!noData && isOpened}
          icon={false}
          status={stateGroundControl.activeAlertVariant as AlertColor}
          message={message || ''}
          handleClose={() =>
            dispatchGroundControlLevel({
              type: GroundControlActionNames.SET_ALERT_INFO,
              payload: {
                key: 'statusCard',
              },
            })
          }
          preventClose={false}
          testId="c-fligh-domain-status-card-info-box"
        />
      }
    >
      {flightDomainStatusData.sections.map((item: any) => (
        <div key={`item-${item.label}`}>
          <BaseCardSimpleItem sectionItems={item.sectionItems} noData={noData} />
        </div>
      ))}
    </BaseCard>
  );
};
