import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

type WarehouseStatusBetaTabsProps = {
  tabs: {
    label: string;
    show: boolean;
  }[];
  activeTab: number;
  handleTabChange: (...params: any) => void;
  tabCounts: {
    [key: string]: number;
  };
};

export const WarehouseStatusBetaTabs = (props: WarehouseStatusBetaTabsProps) => {
  const { tabs, activeTab, handleTabChange, tabCounts } = props;

  return (
    <Tabs
      aria-label="tabs"
      value={activeTab}
      scrollButtons="auto"
      variant="scrollable"
      onChange={handleTabChange}
    >
      {tabs.map((tab) => {
        const tabCount =
          typeof tabCounts[tab.label] === 'number' ? ` (${tabCounts[tab.label]})` : '';
        return <Tab data-testid="c-table-tab" key={tab.label} label={`${tab.label}${tabCount}`} />;
      })}
    </Tabs>
  );
};
