import moment from 'moment-timezone';
import { IntervalOption, RecurrenceState } from './recurrence.model';
import { notEver } from '../../../../../../../../common/functions/otherFunctions';

export type RecurrenceAction =
  | { type: 'SET_IS_LOADING'; isLoading: boolean }
  | {
      type: 'SET_RECURRENCE_DATA';
      payload: {
        occurrencesCount: number;
        countCapped: boolean;
        rText: string;
        nextOccurrence: moment.Moment;
      };
    }
  | { type: 'SET_INTERVAL_OPTIONS'; intervalOptions: IntervalOption[] };

export const recurrenceReducer = (
  state: RecurrenceState,
  action: RecurrenceAction,
): RecurrenceState => {
  switch (action.type) {
    case 'SET_IS_LOADING':
      return { ...state, isLoading: action.isLoading };
    case 'SET_RECURRENCE_DATA':
      return { ...state, ...action.payload };
    case 'SET_INTERVAL_OPTIONS':
      return { ...state, intervalOptions: action.intervalOptions };
    default:
      notEver(action);
      return state;
  }
};
