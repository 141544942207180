import { ILocationDataST } from 'codegen/warehouse_status';

export const firstFoundOnValueGetter = (row: ILocationDataST) => {
  const dateTime = row.issues && row.issues[0]?.first_found_on;
  if (!dateTime) {
    return null;
  }

  return new Date(dateTime);
};
