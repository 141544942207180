import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import { Tooltip, Typography } from '@mui/material';
import { ILocationDataST, IWMSSlotStatusST } from 'codegen/warehouse_status';
import {
  ILocationDataForReviewSlotSettingsSTExclusionStatusEnum,
  IWMSSlotStatusSTExclusionStatusEnum,
} from 'codegen/report';

type ContentCellProps = {
  row: ILocationDataST;
};

const EXCLUSION_ICONS = {
  INCLUDE: <CheckIcon />,
  EXCLUDE: <ClearIcon />,
  NOT_SET: <>-</>,
} as const;

const getCellContent = ({
  exclusionStatusWMS,
  exclusionStatusVerity,
}: {
  exclusionStatusWMS: IWMSSlotStatusSTExclusionStatusEnum | undefined;
  exclusionStatusVerity: ILocationDataForReviewSlotSettingsSTExclusionStatusEnum | undefined;
}): {
  content: typeof EXCLUSION_ICONS[keyof typeof EXCLUSION_ICONS];
  tooltip: string;
} => {
  if (!exclusionStatusWMS && !exclusionStatusVerity) {
    return {
      content: EXCLUSION_ICONS.NOT_SET,
      tooltip: 'There is no WMS data available for this location',
    };
  }

  /*
  Input Mapping:
  exclusionStatusVerity overwrites any exclusion status set by the WMS
  Notation (exclusionStatusVerity, exclusionStatusWMS) * = ANY
  n | INPUT                 | OUTPUT  Icon       | OUTPUT Tooltip
  1 | (EXCLUDE, *)          | EXCLUDE            | Drones will not visit this location (User)
  2 | (INCLUDE, *)          | INCLUDE            | Drones will visit this location (User)
  3 | (NOT_SET, EXCLUDE)    | EXCLUDE            | Drones will not visit this location (WMS)
  4 | (NOT_SET, INCLUDE)    | INCLUDE            | Drones will visit this location (WMS)
  5 | (NOT_SET, undefined)  | NOT_SET            | There is no WMS data available for this location
  6 | (undefined, undefined)| NOT_SET            | There is no WMS data available for this location
  */

  if (exclusionStatusVerity && exclusionStatusVerity !== 'NOT_SET') {
    // 1 and 2
    const tooltipMessage =
      exclusionStatusVerity === 'EXCLUDE'
        ? 'Drones will not visit this location (User)'
        : 'Drones will visit this location (User)';

    return {
      content: EXCLUSION_ICONS[exclusionStatusVerity],
      tooltip: tooltipMessage,
    };
  }

  if (exclusionStatusWMS) {
    // 3 and 4
    const tooltipMessage =
      exclusionStatusWMS === 'EXCLUDE'
        ? 'Drones will not visit this location (WMS)'
        : 'Drones will visit this location (WMS)';

    return {
      content: EXCLUSION_ICONS[exclusionStatusWMS],
      tooltip: tooltipMessage,
    };
  }

  // 5 and 6
  return {
    content: EXCLUSION_ICONS.NOT_SET,
    tooltip: 'There is no WMS data available for this location',
  };
};

export const ExclusionStatusCell = ({ row }: ContentCellProps) => {
  const wmsStatus = row.wms_status as IWMSSlotStatusST | undefined;

  const exclusionStatusWMS = wmsStatus?.exclusion_status;
  const exclusionStatusVerity = row.slot_settings?.exclusion_status;

  const { content, tooltip } = getCellContent({ exclusionStatusWMS, exclusionStatusVerity });

  return (
    <Tooltip title={tooltip} className="c-data-grid-cell c-data-grid-cell-exclusion-status">
      <Typography
        variant="body2"
        component="span"
        sx={{ width: '100%', textAlign: 'center', cursor: 'pointer' }}
      >
        {content}
      </Typography>
    </Tooltip>
  );
};
