import {
  IInventoryRequestST,
  IReportParameterPredefinedValuesInputSettingsST,
  IReportParameterST,
} from 'codegen/report';
import { IInventoryRequestParamST } from '../../interfaces';
import { PARAM_TYPES, WIDGET_TYPES } from '../reportSpecifications';

/**
 * Get inventory request parameter label
 * @param param
 * @param inventoryRequest
 * @returns Returns a string with the parameter label
 */
export const getReqParamUserFacingName = (
  param: IInventoryRequestParamST,
  inventoryRequest: IInventoryRequestST,
) =>
  inventoryRequest.report_spec.params.find((el) => el.unique_name === param.unique_name)
    ?.user_facing_name;

/**
 * Get inventory request value labels for a given inv. request parameter
 * @param param
 * @param inventoryRequest
 * @returns string with the parameter label or an empty string, when parameters have no values
 */
export const getReqParamUserFacingValues = (
  param: IInventoryRequestParamST,
  inventoryRequest: IInventoryRequestST,
) => {
  if (inventoryRequest.report_spec.report_spec_name.includes('Location list report')) {
    // FIX-ME::TR2021-07-08 basing the above if on the report spec name seems very wrong
    // we need a more elegant way of achieving this
    return param.values.join(', ');
  }
  const valuesUserFacingNames: string[] = [];
  param.values.forEach((paramValue: string) => {
    inventoryRequest.report_spec.params.forEach((par: IReportParameterST) => {
      const parInputSettings =
        par.input_settings as IReportParameterPredefinedValuesInputSettingsST;
      if (parInputSettings && parInputSettings.options && param.unique_name === par.unique_name) {
        // SG-2021-03-11-FIXME: This ternary operator is a patch for the issue that occurres on udb-prod.verity.ch.
        // It was added because in some cases the find method returns undefined (which it shouldn't)
        // This should be investigated further
        const option = parInputSettings.options.find((opt) => opt.value === paramValue);
        const label = option?.label ? option.label : 'n.a.';
        valuesUserFacingNames.push(label);
      } else if (
        par.input_settings &&
        par.input_settings.ui_widget_type === WIDGET_TYPES.TEXT_FIELD &&
        param.unique_name === par.unique_name
      ) {
        if (param.unique_name === PARAM_TYPES.LOCATIONS_PERCENTAGE) {
          valuesUserFacingNames.push((parseFloat(paramValue) * 100).toFixed(2).toString());
        } else {
          valuesUserFacingNames.push(paramValue);
        }
      }
    });
  });
  return valuesUserFacingNames.join(', ');
};

/**
 * Get inventory request labels to display on tables
 * @returns the parameter and value labels. In some cases aliased (e.g. file)
 */
export const getReqParamsStringForTablesFromInventoryRequest = (
  inventoryRequest: IInventoryRequestST,
) => {
  if (inventoryRequest.report_spec.report_spec_name.includes('Automatic')) {
    return 'from uploaded file';
  }
  const requestParams: string[] = [];
  inventoryRequest.params.forEach((param) => {
    const paramUserFacingValues = getReqParamUserFacingValues(param, inventoryRequest);
    // We ignore request params that have no values or aliases for their values
    // for example PALLET_LOCATION, on an Empty-Locations inventory request
    if (paramUserFacingValues !== '') {
      requestParams.push(
        `${getReqParamUserFacingName(param, inventoryRequest)}: ${paramUserFacingValues}`,
      );
    }
  });
  return requestParams.join('\n');
};
