import { useRef } from 'react';
import moment from 'moment';
import * as THREE from 'three';
import { MeshNodeLabel } from 'shared/map-container/features/mesh-node-label/MeshNodeLabel';
import { MapOptions } from 'shared/map-container/reducer/MapOptionsState';
import { IObstacleInformation1ST } from 'codegen/flight_domain';
import { geometryToPosAndSize } from './geometryToPosAndSize';

export const EstimatedObstacle = (props: {
  obstacleState: IObstacleInformation1ST;
  options: MapOptions;
}) => {
  const ref = useRef<THREE.Mesh>(null);
  const { obstacleState, options } = props;

  const { pos, size } = geometryToPosAndSize(obstacleState.geometry);

  const labelTextLines = [
    `Discovered by drone ${obstacleState.discovered_by_drone}`,
    `at ${moment(obstacleState.discovery_time).format('MMM DD h:mm AZ')}`,
    obstacleState.times_out_at
      ? `times out at ${moment(obstacleState.times_out_at).format('MMM DD h:mm AZ')}`
      : 'does not time out',
  ];

  const estimatedObstacleMaterial = new THREE.MeshBasicMaterial({
    color: 'red',
    transparent: true,
    opacity: 0.5,
    depthTest: false,
  });

  return (
    <mesh ref={ref} position={pos} renderOrder={5} material={estimatedObstacleMaterial}>
      <boxGeometry args={[size.width, size.length, size.height]} />
      <MeshNodeLabel w={size.width} l={size.length} options={options} textLines={labelTextLines} />
    </mesh>
  );
};
