import { IUserLevelData } from './IUserLevelStore';

export const initialUserLevelState: IUserLevelData = {
  isAuth: false,
  username: '',
  usernameHashed: '',
  isDataLoaded: false,
  isWebSocketAuthorized: false,
  authProcess: false,
  isMfaAuth: false,
  details: {
    attrName: '',
    challengeName: null,
  },
};
