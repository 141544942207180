import { IFacilitySettingsST } from 'codegen/facility_settings';
import { SettingsItem } from '../models/settingsCard.model';

export const getExternalReviewsOptions = (
  facilitySettings: IFacilitySettingsST,
  handleValuesChange: (prop: string, value: number | boolean | string) => void,
  errors: any,
): SettingsItem[] => [
  {
    type: 'switch',
    label: 'External review enabled',
    testId: 'c-external-review-enabled',
    description: `Specifies whether verity slot statuses with issues should be reviewed externally before
                    being available to the system. If 'Auto approve verity status' is also set to true, external
                    review will still be generated, but will not affect the results. The review data will be
                    available in the internal report/warehouse status exports.`,
    value: facilitySettings.external_review_enabled,
    action: (e: React.ChangeEvent<HTMLInputElement>) =>
      handleValuesChange('external_review_enabled', e.target.checked),
  },
  {
    type: 'number',
    width: 'small',
    inputProps: { min: 1, step: '1' },
    label: 'Number of assignments per review',
    testId: 'c-number-of-assignments-per-review',
    description: `Specifies the number of assignments to create for each review. For each assignment that
                    is rejected, a new assignment will be created (up to the maximum of 9).`,
    value: facilitySettings.external_review_num_assignments_per_review,
    errors: errors.validExternalReviewNumAssignments,
    action: (e: React.ChangeEvent<HTMLInputElement>) =>
      handleValuesChange('external_review_num_assignments_per_review', Number(e.target.value)),
  },
  {
    type: 'number',
    width: 'small',
    inputProps: { min: 1, step: '1' },
    label: 'Desired batch size',
    testId: 'c-desired-batch-size',
    description: `Specifies the number of locations that should be part of a single review. If not enough
                    results are available to review within the specified wait time, the review will be submitted
                    with the available locations.`,
    value: facilitySettings.external_review_batch_size_desired,
    errors: errors.validExternalReviewBatchSize,
    action: (e: React.ChangeEvent<HTMLInputElement>) =>
      handleValuesChange('external_review_batch_size_desired', Number(e.target.value)),
  },
  {
    type: 'number',
    width: 'small',
    inputProps: { min: 1, step: '1' },
    label: 'Batch wait time (in seconds)',
    testId: 'c-batch-wait-time',
    description: `Specifies the time to wait in seconds to collect the desired batch size of locations to review as
                    part of a single review. Once the time expires, the review will be submitted with the available locations.`,
    value: facilitySettings.external_review_batch_max_wait_time_s,
    errors: errors.validExternalReviewBatchWaitTime,
    action: (e: React.ChangeEvent<HTMLInputElement>) =>
      handleValuesChange('external_review_batch_max_wait_time_s', Number(e.target.value)),
  },
  {
    type: 'switch',
    label: 'Override Verity data',
    testId: 'c-override-verity-data',
    description: `Specifies whether to incorporate the reviewer amendments in the Verity data.
                    if not enabled, review data will still be available in internal report/warehouse status exports.`,
    value: facilitySettings.external_review_overrides_verity_data,
    action: (e: React.ChangeEvent<HTMLInputElement>) =>
      handleValuesChange('external_review_overrides_verity_data', e.target.checked),
  },
  {
    type: 'select',
    label: 'Show Verity prior',
    selectOptions: [
      { value: 'NEVER', label: 'Never' },
      { value: 'NOT_FOR_CONTROL', label: 'Not for control' },
      { value: 'ALWAYS', label: 'Always' },
    ],
    testId: 'c-show-verity-prior',
    description: `Specifies whether Verity's interpretation of the images are shown to the external reviewers
                    as a prior they can simply accept. If set to 'Not for control', control questions
                    (where we know Verity data are correct) will not contain the prior, but other questions will.`,
    value: facilitySettings.external_review_show_prior,
    action: (e: React.ChangeEvent<HTMLInputElement>) =>
      handleValuesChange('external_review_show_prior', e.target.value),
  },
  {
    type: 'number',
    width: 'small',
    inputProps: { min: 1, step: '1' },
    label: 'MTurk HIT lifetime (in seconds)',
    testId: 'c-mturk-hit-lifetime',
    description: `An amount of time, in seconds, after which the Amazon MTurk HIT is no longer available for reviewers
                    to accept. After the lifetime of the HIT elapses, the HIT no longer appears in HIT searches,
                    even if not all of the assignments for the HIT have been accepted.`,
    value: facilitySettings.mturk_hit_lifetime_in_seconds,
    errors: errors.validMturkLifetime,
    action: (e: React.ChangeEvent<HTMLInputElement>) =>
      handleValuesChange('mturk_hit_lifetime_in_seconds', Number(e.target.value)),
  },
  {
    type: 'text',
    multiline: true,
    rows: 3,
    label: 'MTurk HIT type Id',
    width: 'larger',
    testId: 'c-mturk-hit-type-id',
    description: `Creating an Amazon MTurk HIT with a HIT Type allows one to be explicit about which HITs
                    ought to be the same type and is a best practice for customers creating large numbers of HITs.

                    The value can be obtained by a logged-in user by accessing
                    https://requestersandbox.mturk.com/create/projects
                    for the sandbox environment, and
                    https://requester.mturk.com/create/projects
                    for the production environment.

                    Clicking on any project name will reveal the corresponding
                    HITType ID, Layout ID and Layout Parameters.

                    The value changes whenever the HIT parameters change.`,
    value: facilitySettings.mturk_hit_type_id,
    action: (e: React.ChangeEvent<HTMLInputElement>) =>
      handleValuesChange('mturk_hit_type_id', e.target.value),
  },
  {
    type: 'text',
    multiline: true,
    rows: 3,
    label: 'MTurk HIT layout Id',
    width: 'larger',
    testId: 'c-mturk-hit-layout-id',
    description: `The HIT Layout Id allows one to use a pre-existing HIT design with placeholder values for HIT creation.
                    https://docs.aws.amazon.com/AWSMechTurk/latest/AWSMturkAPI/ApiReference_HITLayoutArticle.html.


                    The value can be obtained by a logged-in user by accessing
                    https://requestersandbox.mturk.com/create/projects
                    for the sandbox environment, and
                    https://requester.mturk.com/create/projects
                    for the production environment.

                    Clicking on any project name will reveal the corresponding
                    HITType ID, Layout ID and Layout Parameters.

                    The value changes whenever the HIT parameters change.`,
    value: facilitySettings.mturk_hit_layout_id,
    action: (e: React.ChangeEvent<HTMLInputElement>) =>
      handleValuesChange('mturk_hit_layout_id', e.target.value),
  },
];
