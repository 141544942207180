import { DroneZones } from '../../model/drone-zones.model';
import { Point } from '../../model/geometry.model';
import { bottomLeftDimensionsToSizeAndPos } from '../../../map-container/utils/creation.util';

export const findCenterPositionOfOverlappingZones = (droneZones: DroneZones[]): Point => {
  let totalX = 0;
  let totalY = 0;
  let totalZ = 0;
  let overlappingCount = 0;
  droneZones.forEach((zone) => {
    const offset = bottomLeftDimensionsToSizeAndPos(zone.sizeAndPosition);
    totalX += offset.x;
    totalY += offset.y;
    totalZ += offset.z;
    overlappingCount += 1;
  });

  const overlapCenterX = totalX / overlappingCount;
  const overlapCenterY = totalY / overlappingCount;
  const overlapCenterZ = totalZ / overlappingCount;

  return { x: overlapCenterX, y: overlapCenterY, z: overlapCenterZ };
};
