import { sort } from 'common/functions/otherFunctions';
import { reportServices } from '../../../api/ReportServices';

/**
 * GET ALL REPORT SPECIFICATIONS
 * @param systemId ID of the facility the specification are read for
 * @returns fullResponse - route raw response
 * reportSpecifications - all specifications sorted in ascending order by request_default_name
 */
export const getReportSpecifications = (systemId: string) =>
  reportServices.getReportSpecifications(systemId).then((r) => {
    const reportSpecifications = sort({
      array: r.data.items,
      sortingOrder: 'asc',
      sortBy: 'request_default_name',
    });

    return {
      fullResponse: r,
      reportSpecifications,
    };
  });
