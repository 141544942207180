import { INoFlyZonesResponseST } from 'codegen/no_fly_zone';
import { groundControlServices } from '../../../../services/GroundControlServices';
import { GetDroneZonesParams } from '../../../../udb/ground-control/drone-zones/api/getDroneZone.model';

export const getNoFlyZones = ({
  requestController,
  flightDomainId,
  systemId,
  onSuccess,
  onBeforeSend,
  onFinally,
}: Omit<GetDroneZonesParams<INoFlyZonesResponseST>, 'isAdditionalDroneZonesEnabled'>) => {
  const reservation = requestController.reserveSlotForRequest();

  requestController.doRequest({
    request: groundControlServices.getNoFlyZones,
    requestParams: [systemId, flightDomainId, reservation.signal],
    callbackBeforeSend: onBeforeSend,
    callbackFinally: onFinally,
    callbackSuccess: ({ data }: { data: INoFlyZonesResponseST }) => onSuccess(data),
  });
};
