import { IRequestController } from '../../../../hooks';
import { getLogPrefixForType } from '../../../../common/functions/logFunctions';
import { developerServices } from '../../../../services/DeveloperServices';
import { ServiceVersionItem } from '../../../../udb/admin/system-details/model/serviceVersionItem.model';

/**
 * Get the current version of the OC for the Flight Domain
 * @param requestController Request Controller instance
 * @param systemId System ID of the current facility
 * @param fdName name of the currently selected Flight Domain
 * @returns Version number
 */
export const getCurrentOCVersion = async (
  requestController: IRequestController,
  systemId: string,
  fdName: string,
) => {
  const lp = getLogPrefixForType('FUNCTION', 'getCurrentFdVersion');
  console.debug(lp, 'looking up SW Version of FD ', fdName);

  const r: { data: { apps: ServiceVersionItem[] } } = await requestController.doRequest({
    request: developerServices.getAppVersions,
    requestParams: [systemId],
    messageErrorFallback: 'Failed to fetch the BE Version',
  });

  const fdVersion = r?.data?.apps
    .filter((s: any) => s.name.includes(fdName))
    // BUGBUG / FIXME: FSM is not yet delivering any version number
    // hence we limit the check to OC service, see UD-4367 for more contest
    // eno: 2023-05-31
    .filter((s: any) => s.name.includes('OC'));
  if (fdVersion?.length) {
    return fdVersion[0].version;
  }
  return '';
};
