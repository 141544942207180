import { IGroundControlState } from '../../../../../store/GroundControl/groundControlLevelStore.model';

export function noFlyZoneAlertState(
  stateGroundControl: IGroundControlState,
  isStatusChangeEnabled: boolean,
) {
  return {
    state: {
      label: 'NoFlyZones',
      status: stateGroundControl.activeAlertVariant,
      preventClose: true,
      show: !isStatusChangeEnabled,
      message:
        'The management of no-fly zones is not allowed while the system is unlocked or while drones are flying.',
    },
  };
}
