import { Container } from '@mui/material';

import { useParams } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import { PageHeaderSection } from 'components/Page/PageHeaderSection';
import { AlertComponent } from 'components/common/Alert';
import { BackDropLoader } from 'components/BackDropLoader';
import { useRequestController } from '../../hooks';
import { useClientLevelStore } from '../../store/ClientLevelStore/clientLevelStore';
import {
  getSuperSetSettings as getSuperSetSettingsFunc,
  ISupersetSettings,
} from './functions/GetSupersetSettings';
import { useStyles } from './styles';
import { Dashboard } from './Dashboard';
import { fetchSystemSpecificSuperSetGuestToken } from './functions/FetchSystemSpecificSuperSetGuestToken';
import { MultiDashboardAnalytics } from './MultiDashboardAnalytics';
import { useFacilityLevelStore } from '../../store/FacilityLevelStore/facilityLevelStore';

/**
 * Analytics
 * @returns component
 */
export const Analytics = () => {
  const { classes } = useStyles();
  const { systemId = '' } = useParams();

  const { requestController } = useRequestController('Analytics');
  const { facilityList } = useClientLevelStore().stateClientLevel;
  const { facilitySettings } = useFacilityLevelStore().stateFacilityLevel;

  const [isLoading, setIsLoading] = useState(false);
  const [supersetSettings, setSupersetSettings] = useState<ISupersetSettings | null>(null);

  const shouldRenderMultiFacility = facilityList.length > 1;
  const areAnalyticsEnabled = facilitySettings.enable_superset_integration;

  /**
   * Get superset settings
   */
  const getSuperSetSettings = useCallback(
    () => getSuperSetSettingsFunc({ setIsLoading, setSupersetSettings, requestController }),
    [requestController],
  );

  /**
   * Handle fetch system specific superset guest token
   */
  const handleFetchSystemSpecificSuperSetGuestToken = useCallback(
    () => fetchSystemSpecificSuperSetGuestToken(systemId),
    [systemId],
  );

  /**
   * Render analytics
   * @returns analytics dashboard or alert
   */
  const renderAnalytics = () => {
    if (!areAnalyticsEnabled) {
      return (
        <AlertComponent
          title="Analytics are not available for this facility"
          message=""
          status="info"
          isActive
          preventClose
        />
      );
    }
    if (!supersetSettings) return null;

    if (shouldRenderMultiFacility) {
      return (
        <MultiDashboardAnalytics
          supersetSettings={supersetSettings}
          fetchSystemSpecificSuperSetGuestToken={handleFetchSystemSpecificSuperSetGuestToken}
        />
      );
    }

    return (
      <Dashboard
        dashboardId={supersetSettings.facility_dashboard_id}
        supersetDomain={supersetSettings.base_url}
        fetchGuestToken={handleFetchSystemSpecificSuperSetGuestToken}
      />
    );
  };

  useEffect(() => {
    if (areAnalyticsEnabled) {
      getSuperSetSettings();
    }
  }, [getSuperSetSettings, areAnalyticsEnabled]);

  return (
    <>
      <PageHeaderSection title="Analytics" />

      <Container maxWidth="xl" sx={{ paddingTop: '32px' }} className={classes.container}>
        {isLoading && (
          <div className={classes.loader}>
            <BackDropLoader isVisible={isLoading} />
          </div>
        )}

        {renderAnalytics()}
      </Container>
    </>
  );
};
