import React, { createContext, useReducer, useContext } from 'react';
import { MapAction, mapReducer } from './3DmapReducer';
import { initialMapState, MapState } from './3DmapState';

interface IMapContext {
  mapState: MapState;
  dispatchMapStore: React.Dispatch<MapAction>;
}

const MapStore = createContext<IMapContext | undefined>(undefined);

export const useMapStore = () => {
  const mapContext = useContext(MapStore);

  if (mapContext === undefined) {
    throw new Error('useMapStore has to be used within <MapStore.Provider>');
  }

  return mapContext;
};

export const MapStoreProvider = ({ children }: { children: React.ReactNode }) => {
  const [stateMap, dispatchMapStore] = useReducer(mapReducer, initialMapState);

  return (
    <MapStore.Provider
      value={{
        mapState: stateMap,
        dispatchMapStore,
      }}
    >
      {children}
    </MapStore.Provider>
  );
};
