import { Button } from '@mui/material';
import Add from '@mui/icons-material/Add';
import { PageHeaderSection } from 'components/Page/PageHeaderSection';
import { noFlyZoneAlertState } from './NoFlyZoneAlertState';
import { userHasPermission } from '../../../../../features/permissions/userHasPermission';
import { PERMISSION } from '../../../../../features/permissions/permissions.model';
import { useGroundControlStore } from '../../../../../store/GroundControl/groundControlLevelStore';

export const NoFlyZoneHeader = ({
  onCreateButtonClicked,
  isCreateEnabled,
}: {
  onCreateButtonClicked: () => void;
  isCreateEnabled: boolean;
}) => {
  const { stateGroundControl } = useGroundControlStore();

  const isStatusChangeEnabled: boolean =
    userHasPermission(PERMISSION.NO_FLY_ZONE_MANAGEMENT) ||
    stateGroundControl.flightDomain.flight_domain_status?.locked ||
    (stateGroundControl.flightDomain.fleet_status_summary?.num_drones_flying || -1) < 0;

  const isCreateNoFlyZoneEnabled =
    stateGroundControl.flightDomain.flight_domain_status?.locked && isCreateEnabled;

  return (
    <PageHeaderSection
      title="No-fly zones"
      subtitle="Create, control and preview no-fly zones"
      customBtn={
        <Button
          variant="contained"
          sx={{ mr: 2.5 }}
          onClick={onCreateButtonClicked}
          disabled={isCreateNoFlyZoneEnabled}
          startIcon={<Add />}
          color="primary"
          fullWidth
          size="medium"
        >
          New no-fly zone
        </Button>
      }
      alert={noFlyZoneAlertState(stateGroundControl, isStatusChangeEnabled)}
    />
  );
};
