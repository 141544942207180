import {
  notEver,
  isExecutingEmergencyLanding,
  isExecutingReturnHome,
} from 'common/functions/otherFunctions';
import { MutableState } from 'shared/map-container/reducer/MutableState';
import { IFlightDomainStatusST } from 'codegen/nav_simulation';
import { LiveMapMutable, MapFleet } from '../../pages/GroundControl/pages/LiveMap/liveMap.model';
import { initialGroundControlState } from './groundControlInitialState';
import {
  FleetStatusSummaryST,
  GroundControlActionNames,
  GroundControlLevelAction,
  IGroundControlState,
} from './groundControlLevelStore.model';

export const groundControlReducer = (
  state: IGroundControlState = initialGroundControlState,
  action: GroundControlLevelAction,
): IGroundControlState => {
  switch (action.type) {
    case GroundControlActionNames.SET_FLIGHT_DOMAIN: {
      const flightDomain = {
        ...state.flightDomain,
        ...action.payload,
        fleet_status_summary:
          action.payload.fleet_status_summary || state.flightDomain.fleet_status_summary,
        flight_domain_status:
          action.payload.flight_domain_status || state.flightDomain.flight_domain_status,
      };

      const { flight_domain_status, fleet_status_summary } = flightDomain;

      const setActiveVariant = (
        fds?: IFlightDomainStatusST | null,
        fss?: FleetStatusSummaryST | null,
      ) => {
        if (!fds || !fss) {
          return 'warning';
        }

        if (isExecutingEmergencyLanding(fds, fss)) {
          return 'error';
        }
        if (isExecutingReturnHome(fds, fss) || !fds.locked) {
          return 'warning';
        }
        return 'info';
      };

      return {
        ...state,
        isExecutingEmergencyLanding:
          flight_domain_status && fleet_status_summary
            ? isExecutingEmergencyLanding(flight_domain_status, fleet_status_summary)
            : false,
        isExecutingReturnHome:
          flight_domain_status && fleet_status_summary
            ? isExecutingReturnHome(flight_domain_status, fleet_status_summary)
            : false,
        dronesFlying: flightDomain.fleet_status_summary
          ? flightDomain.fleet_status_summary.num_drones_flying > 0
          : false,
        activeAlertVariant: setActiveVariant(flight_domain_status, fleet_status_summary),
        flightDomain: {
          ...flightDomain,
        },
        flightDomainLoaded: true,
      };
    }

    case GroundControlActionNames.SET_FLEET_OVERVIEW:
      return {
        ...state,
        flightDomain: {
          ...state.flightDomain,
          isLoadingFleetData: false,
        },
        fleetOverview: action.payload,
      };

    case GroundControlActionNames.SET_FLEET_VERSION:
      return {
        ...state,
        fleetVersions: action.payload,
      };

    case GroundControlActionNames.UPDATE_FLEET_OVERVIEW: {
      if (state.flightDomain.flight_domain_id !== action.payload.flight_domain_id) {
        return state;
      }

      const liveMapMutable = MutableState.getState<LiveMapMutable>()?.mapData;
      if (liveMapMutable) {
        liveMapMutable.fleet = action.payload.fleet_status as unknown as MapFleet;
      }

      return {
        ...state,
        fleetOverview: {
          ...state.fleetOverview,
          fleet_status: { ...action.payload.fleet_status },
        },
      };
    }

    case GroundControlActionNames.SET_FLIGHT_DOMAIN_SETTINGS: {
      return {
        ...state,
        flightDomainSchedule: { ...action.payload },
      };
    }

    case GroundControlActionNames.SET_WEBSOCKET_ERROR: {
      return {
        ...state,
        webSocketError: action.payload,
      };
    }

    case GroundControlActionNames.SET_ALERT_INFO:
      return {
        ...state,
        alertInfo: {
          ...state.alertInfo,
          [action.payload.key]: {
            isOpened: Boolean(action.payload.message),
            variant: action.payload?.variant,
            message: action.payload?.message,
          },
        },
      };

    case GroundControlActionNames.SET_IS_LOADING_FLEET_DATA:
      return {
        ...state,
        flightDomain: {
          ...state.flightDomain,
          isLoadingFleetData: action.payload,
        },
      };

    case GroundControlActionNames.CLEAR_GROUND_CONTROL_STORE:
      return initialGroundControlState;

    default:
      notEver(action);
      return state;
  }
};
