import FeedbackIcon from '@mui/icons-material/Feedback';
import { Box, IconButton } from '@mui/material';
import { TooltipedIcon } from 'components/common/TooltipedIcon';
import { PERMISSION } from 'features/permissions/permissions.model';
import { userHasPermission } from 'features/permissions/userHasPermission';
import { useFacilityLevelStore } from 'store/FacilityLevelStore/facilityLevelStore';
import CloseIcon from '@mui/icons-material/Close';
import { isFeedbackShown } from './FeedbackDrawer.utils';

export const FeedbackDrawerToggler = ({
  feedbackDrawerOpen,
  setFeedbackDrawerOpen,
}: {
  feedbackDrawerOpen: boolean;
  setFeedbackDrawerOpen: (value: React.SetStateAction<boolean>) => void;
}) => {
  const { facilitySettings } = useFacilityLevelStore().stateFacilityLevel;

  const showFeedback = isFeedbackShown(facilitySettings);

  facilitySettings.support_email_recipients !== '' &&
    userHasPermission(PERMISSION.SHARE_FEEDBACK_ON_LOCATION);

  const tooltip = feedbackDrawerOpen
    ? 'Collapse the feedback drawer.'
    : 'Expand the feedback drawer.';

  const iconStyle = { height: '32px', transform: 'scaleX(-1)' };

  return showFeedback ? (
    <Box
      sx={{
        zIndex: 9999,
      }}
      component="div"
      position="absolute"
      bottom="20px"
      right="10px"
    >
      <IconButton
        style={{
          boxShadow: '0 0 8px 8px rgba(255, 255, 255, .6)',
          backgroundColor: '#EEEEEE',
        }}
        size="small"
        onClick={() => setFeedbackDrawerOpen(!feedbackDrawerOpen)}
        data-testid="show-feedback-drawer"
      >
        <TooltipedIcon
          tooltip={tooltip}
          icon={feedbackDrawerOpen ? <CloseIcon sx={iconStyle} /> : <FeedbackIcon sx={iconStyle} />}
        />
      </IconButton>
    </Box>
  ) : null;
};
