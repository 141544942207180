import { ChangeEvent } from 'react';
import { useSearchParams } from 'react-router-dom';
import { EnhancedTableCellActionButton } from './ActionButton/EnhancedTableCellActionButton';
import { EnhancedTableCellBadge } from './Badge/EnhancedTableCellBadge';
import { EnhancedTableCellButton } from './Button/EnhancedTableCellButton';
import { EnhancedTableCellCheckbox } from './Checkbox/EnhancedTableCellCheckbox';
import { EnhancedTableCellCustom } from './Custom/EnhancedTableCellCustom';
import { EnhancedTableCellIcon } from './Icon/EnhancedTableCellIcon';
import { EnhancedTableCellItemList } from './ItemList/EnhancedTableCellItemList';
import { EnhancedTableCellString } from './String/EnhancedTableCellString';
import { EnhancedTableCellSwitch } from './Switch/EnhancedTableCellSwitch';
import { EnhancedTableCellSwitchAndString } from './Switch/EnhancedTableCellSwitchAndString';
import { tableStyles } from '../../styles';
import { HeadCellProp, HeadCellType, RowProp } from '../../types/cell';

const CELL_COMPONENTS = {
  checkbox: EnhancedTableCellCheckbox,
  item_list: EnhancedTableCellItemList,
  string: EnhancedTableCellString,
  badge: EnhancedTableCellBadge,
  button: EnhancedTableCellButton,
  switch: EnhancedTableCellSwitch,
  switchAndString: EnhancedTableCellSwitchAndString,
  icon: EnhancedTableCellIcon,
  custom: EnhancedTableCellCustom,
  actionButton: EnhancedTableCellActionButton,
};

type TableCellProps = {
  showSearch?: boolean;
  row: RowProp<any>;
  cell: HeadCellProp;
  onSwitchClick?: (id: string, canFly: boolean) => void;
  onRowSelect: (id: string) => void;
  onRowClick?: (e: KeyboardEvent, row: any) => void;
  getAmendingData: (row: any) => any[];
  isInputDisabled?: () => boolean;
  isItemSelected: boolean;
  refreshData?: { refreshData?: () => void };
};

/**
 * Table cell
 * @param props TableCellProps
 * @returns component
 */
export const TableCell = ({
  cell,
  row,
  onRowSelect,
  showSearch = true,
  isInputDisabled,
  onSwitchClick,
  onRowClick,
  getAmendingData,
  isItemSelected,
  refreshData,
}: TableCellProps) => {
  const [searchParams] = useSearchParams();
  const searchTerm = searchParams.get('searchTerm') || '';
  const { classes } = tableStyles();
  const labelId = `enhanced-table-checkbox-${row.id}`;

  const CellComponent =
    CELL_COMPONENTS[cell.type as keyof typeof CELL_COMPONENTS] || CELL_COMPONENTS.custom;

  const handleClick = (e: ChangeEvent<HTMLInputElement> | KeyboardEvent) => {
    if (onSwitchClick && ['switch' as HeadCellType, 'switchAndString'].includes(cell.type)) {
      return onSwitchClick(row.id, row.canFly);
    }

    if (cell.type === 'checkbox') {
      return onRowSelect(row.id);
    }

    if (onRowClick) {
      return onRowClick(e as KeyboardEvent, row);
    }

    return null;
  };

  return (
    <CellComponent
      row={row}
      cell={cell}
      classes={classes}
      onClick={handleClick}
      isItemSelected={isItemSelected}
      labelId={labelId}
      showSearch={showSearch}
      searchTerm={searchTerm}
      getAmendingData={getAmendingData}
      refreshData={refreshData}
      isInputDisabled={isInputDisabled}
    />
  );
};
