import { Alert, Box } from '@mui/material';

export const ArrivedFromOldDomainAlert = () => (
  <Alert sx={{ fontSize: 'large' }} severity="info" key={0}>
    <Box component="div" padding="1em">
      <Box component="div">
        You are connecting from the old domain <b>verity.ch</b>, please update your bookmarks to{' '}
        <b>verity-app.net</b>.
      </Box>
    </Box>
  </Alert>
);
