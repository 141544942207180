import { IFacilitySettingsST } from 'codegen/facility_settings';
import { SettingsItem } from '../models/settingsCard.model';

export const getUiOptions = (
  facilitySettings: IFacilitySettingsST,
  handleValuesChange: (prop: string, value: boolean) => void,
): SettingsItem[] => [
  {
    type: 'switch',
    label: 'Enable Beta Warehouse status page',
    testId: 'c-enable-experimental-features-input',
    description:
      'If enabled, Beta Warehouse status page is enabled for all the users of the facility.',
    value: facilitySettings.enable_experimental_features,
    action: (e: React.ChangeEvent<HTMLInputElement>) =>
      handleValuesChange('enable_experimental_features', e.target.checked),
  },
  {
    type: 'switch',
    label: 'Enable Beta Reports datagrid',
    testId: 'c-enable-experimental-report-grid',
    description:
      'If enabled, the premium data grid on Reports page is enabled for all the users of the facility.',
    value: facilitySettings.enable_experimental_report_grid,
    action: (e: React.ChangeEvent<HTMLInputElement>) =>
      handleValuesChange('enable_experimental_report_grid', e.target.checked),
  },
  {
    type: 'switch',
    label: 'Show customer to clients',
    testId: 'c-show-customer-input',
    description:
      'If enabled, the customer information provided for each item is displayed on the location modal.',
    value: facilitySettings.show_customer,
    action: (e: React.ChangeEvent<HTMLInputElement>) =>
      handleValuesChange('show_customer', e.target.checked),
  },
  {
    type: 'switch',
    label: 'Show potential issues to clients',
    testId: 'c-show-potential-issue-to-clients',
    description: 'If enabled, potential issues are shown to the user.',
    value: facilitySettings.show_potential_issues,
    action: (e: React.ChangeEvent<HTMLInputElement>) =>
      handleValuesChange('show_potential_issues', e.target.checked),
  },
  {
    type: 'switch',
    label: 'Show Ground Control application',
    testId: 'c-show-ground-control-app',
    description: 'If enabled, Ground Control related pages are shown to the user.',
    value: facilitySettings.show_ground_control_app,
    action: (e: React.ChangeEvent<HTMLInputElement>) =>
      handleValuesChange('show_ground_control_app', e.target.checked),
  },
  {
    type: 'switch',
    label: 'Enable coexistence',
    testId: 'c-show-coexistence',
    description:
      'When enabled will show drone open-fly and controlled zones on client map and will also add a map to delivery allowing  the creation, editing and deleting of respective zones',
    value: facilitySettings.show_coexistence_map,
    action: (e: React.ChangeEvent<HTMLInputElement>) =>
      handleValuesChange('show_coexistence_map', e.target.checked),
  },
  {
    type: 'switch',
    label: 'Show scheduled lock',
    testId: 'c-show-scheduled-lock',
    description: 'If enabled, scheduled lock is shown.',
    value: facilitySettings.show_scheduled_lock,
    action: (e: React.ChangeEvent<HTMLInputElement>) =>
      handleValuesChange('show_scheduled_lock', e.target.checked),
  },
  {
    type: 'switch',
    label: 'Show analytics page',
    testId: 'c-show-superset-analytics-page',
    description: 'If enabled, analytics page is shown.',
    value: facilitySettings.enable_superset_integration,
    action: (e: React.ChangeEvent<HTMLInputElement>) =>
      handleValuesChange('enable_superset_integration', e.target.checked),
  },
  {
    type: 'switch',
    label: 'Highlight detected barcodes',
    testId: 'c-highlight-detected-barcodes',
    description: 'If enabled, detected barcodes are highlighted in the image in the Location View',
    value: facilitySettings.enable_barcode_highlighting,
    action: (e: React.ChangeEvent<HTMLInputElement>) =>
      handleValuesChange('enable_barcode_highlighting', e.target.checked),
  },
  {
    type: 'switch',
    label: 'Disable the found at and should be at',
    testId: 'c-disable-found-and-should-be-at',
    description:
      'Temporary UI setting to disable the found at and should be at in order to avoid showing wrong information in multibarcode facilities.',
    value: facilitySettings.disable_found_and_should_be_at,
    action: (e: React.ChangeEvent<HTMLInputElement>) =>
      handleValuesChange('disable_found_and_should_be_at', e.target.checked),
  },
];
