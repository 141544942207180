import { Grid } from '@mui/material';
import { IFacilitySettingsST } from 'codegen/facility_settings';
import { EmailList } from '../../EmailList';
import { SettingsCard } from '../../SettingsCard';

export const ShareFeedback = ({
  isLoading,
  facilitySettings,
  handleValuesChange,
}: {
  isLoading: boolean;
  facilitySettings: IFacilitySettingsST;
  handleValuesChange: (prop: string, value: string) => void;
}) => (
  <Grid md={12} sm={12} xs={12} item>
    <SettingsCard
      title="Configure Share feedback on a location"
      subtitle="Provide an e-mail address to use the feedback form in the location view"
      testId="c-support-settings-card"
      isLoading={isLoading}
      content={
        <EmailList
          title="Email recipients"
          tooltip="List of email addresses that receive feedback submissions."
          currentList={
            facilitySettings.support_email_recipients
              ? facilitySettings.support_email_recipients.split(',')
              : []
          }
          confirmChanges={(e: string[]) => {
            handleValuesChange('support_email_recipients', e.join(','));
          }}
        />
      }
    />
  </Grid>
);
