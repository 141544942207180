import { Dispatch } from 'react';
import { getLogPrefixForType } from 'common/functions/logFunctions';
import { WARNING_PAGES_URLS } from 'common/pages';
import { IRequestController } from 'hooks';
import { reportStore } from 'udb/inventory/features/reports/reducer/report-store/ReportStore';
import { ReportSummary } from 'udb/inventory/features/reports/reducer/report-store/ReportStore.model';
import {
  ReportContainerActionNames,
  ReportContainerActions,
} from '../reducer/ReportContainerActions';

const logPrefix = getLogPrefixForType('FUNCTION', 'getReportSummary');

type GetReportSummary = {
  reportId: string;
  dispatch: Dispatch<ReportContainerActions>;
  requestController: IRequestController;
  systemId: string;
  navigate: (param: string) => void;
};

/**
 * This function handles get report summary call
 * @returns report summary
 */
export const getReportSummary = ({
  reportId,
  dispatch,
  requestController,
  systemId,
  navigate,
}: GetReportSummary) => {
  const { signal } = requestController.reserveSlotForRequest();

  return requestController.doRequest({
    request: reportStore.getReportSummary,
    requestParams: [systemId, reportId, signal],
    callbackBeforeSend: () =>
      dispatch({ type: ReportContainerActionNames.GET_REPORT_SUMMARY_CALLBACK_BEFORE_SEND }),
    callbackSuccess: (r: ReportSummary) => {
      dispatch({
        type: ReportContainerActionNames.GET_REPORT_SUMMARY_CALLBACK_SUCCESS,
        payload: r.reportLocationData,
      });
      dispatch({
        type: ReportContainerActionNames.GET_REPORT_EXECUTION_CALLBACK_SUCCESS,
        payload: r.reportExecutionData,
      });
      dispatch({
        type: ReportContainerActionNames.GET_REPORT_SPEC_NAME,
        payload: r.fullResponse.request.report_spec.report_spec_name,
      });
    },
    messageErrorFallback: 'The Report Summary could not be fetched.',
    callbackError: (e: { response?: { status: number } }) => {
      if (e?.response?.status === 404) {
        console.debug(logPrefix, `error when fetching report summary: ${e}`);
        // Note: the request returned a 404 not found => the report doesn't exist.
        const url = WARNING_PAGES_URLS.NOT_FOUND;
        console.debug(logPrefix, `report summary not found: redirect to: ${url}`);
        navigate(url);
      }
    },
    callbackFinally: () =>
      dispatch({
        type: ReportContainerActionNames.SET_ANY,
        payload: { fieldName: 'summarySpinner', value: 0 },
      }),
  });
};
