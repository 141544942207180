import { Typography } from '@mui/material';
import { Box } from 'components/common/Box';
import { YesNoModal } from 'components/ModalsAndPopups/YesNoModal';
import { AlertComponent } from 'components/common/Alert';
import { RemoveFlightHoursModalProps } from './RemoveFlightHoursModal.model';

export const RemoveFlightHoursModal = ({
  hoursToRemove,
  remove,
  cancel,
  isAffectedByScheduleLock,
}: RemoveFlightHoursModalProps) => (
  <YesNoModal
    opened={true}
    title="Remove drone flight hours"
    bodyText={
      <Box ml={1} mr={6}>
        <Typography align="left">You are about to remove the drone flight hours:</Typography>
        <Typography align="left" margin="1em 0" fontWeight="bold">
          {hoursToRemove}
        </Typography>
        {isAffectedByScheduleLock && (
          <Box mb={2}>
            <AlertComponent
              isActive
              message=" By deleting the flight hours you will remove the existing schedule lock"
              status="warning"
              preventClose
            />
          </Box>
        )}
        <Typography align="left">Are you sure you want to remove this item?</Typography>
      </Box>
    }
    yesLabel="Remove"
    onYesFunction={remove}
    noLabel="Cancel"
    onNoFunction={cancel}
    subtitle={null}
    loading={false}
    yesCTA={true}
  />
);
