import type CameraControlsImpl from 'camera-controls';
import { CameraControls, OrthographicCamera } from '@react-three/drei';
import {
  Box3,
  Group,
  Vector3,
  OrthographicCamera as ThreeOrthographicCamera,
  MathUtils,
} from 'three';
import { memo, useEffect, useRef } from 'react';
import { useThree } from '@react-three/fiber';
import CameraControlsLib from 'camera-controls';
import { Bin3DContainer } from '../bin3D/Bin3D';
import { AisleViewProps } from './model/aisleViewProps.model';

const FIT_PADDING = {
  paddingTop: 1,
  paddingRight: 1,
  paddingBottom: 1,
  paddingLeft: 1,
};

const LOCK_ANGLE = Math.PI / 2;

export const AisleView = memo(({ bins, fit = 'width', current }: AisleViewProps) => {
  const aisleRef = useRef<Group>(null);

  const camera = useThree((state) => state.camera);
  const size = useThree((state) => state.size);

  useEffect(() => {
    if (!(camera instanceof ThreeOrthographicCamera)) {
      return;
    }

    const halfWidth = size.width / 2;
    const halfHeight = size.height / 2;

    camera.left = -halfWidth;
    camera.right = halfWidth;

    camera.top = halfHeight;
    camera.bottom = -halfHeight;

    camera.updateProjectionMatrix();
  }, [camera, size]);

  const controls = useThree((state) => state.controls);

  useEffect(() => {
    if (controls && aisleRef.current) {
      const cameraControls = controls as unknown as CameraControlsImpl;

      const aisleBox = new Box3().setFromObject(aisleRef.current);
      let center = aisleBox.getCenter(new Vector3());
      const dimensions = aisleBox.getSize(new Vector3());

      if (current) {
        const currentBox = new Box3().setFromCenterAndSize(current.position, current.dimensions);
        center = currentBox.getCenter(new Vector3());
      }

      const verticalBox = new Box3();
      verticalBox.setFromCenterAndSize(center, new Vector3(1, 1, dimensions.z));

      cameraControls.minZoom = dimensions.z;
      cameraControls.maxZoom = Math.max(dimensions.y, dimensions.x);

      const aisleDirection = dimensions.x > dimensions.y ? 'x' : 'y';

      const cameraOffset =
        aisleDirection === 'y'
          ? {
              x: dimensions.y,
              y: 0,
            }
          : {
              x: 0,
              y: dimensions.x,
            };

      cameraControls.setLookAt(
        center.x + cameraOffset.x,
        center.y + cameraOffset.y,
        center.z,
        center.x,
        center.y,
        center.z,
        true,
      );

      const fitBox = fit === 'width' ? aisleBox : verticalBox;
      cameraControls.setBoundary(aisleBox);

      cameraControls.fitToBox(fitBox, true, FIT_PADDING);
      if (current?.normal?.x === -1 || current?.normal?.y === -1) {
        cameraControls.rotate(MathUtils.degToRad(180), 0, true);
      }
    }
  }, [controls, current, fit]);

  return (
    <>
      <ambientLight intensity={1} />
      <CameraControls
        makeDefault
        verticalDragToForward
        boundaryEnclosesCamera
        mouseButtons={{
          left: CameraControlsLib.ACTION.TRUCK,
          right: CameraControlsLib.ACTION.NONE,
          middle: CameraControlsLib.ACTION.NONE,
          wheel: CameraControlsLib.ACTION.ZOOM,
        }}
        minPolarAngle={LOCK_ANGLE}
        maxPolarAngle={LOCK_ANGLE}
      />
      <OrthographicCamera makeDefault near={-50} />
      <Bin3DContainer ref={aisleRef} bins={bins} />
    </>
  );
});

AisleView.displayName = 'AisleView';
