import { Button, TextField } from '@mui/material';
import { KeyboardEvent } from 'react';

// material-ui icons
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

// styles
import { sharedStyles } from './styles';

type MfaFormProps = {
  value: string;
  handleChange: (e: React.ChangeEvent<any>) => void;
  handleSubmit: () => void;
};

export const MfaSubmitForm = ({ handleSubmit, handleChange, value }: MfaFormProps) => {
  const { classes } = sharedStyles();
  return (
    <>
      <TextField
        className={classes.textField}
        variant="outlined"
        required
        fullWidth
        id="code"
        label="Authentication Code"
        name="mfaCode"
        onChange={handleChange}
        autoFocus
        onKeyDown={(e: KeyboardEvent<any>) => e.key === 'Enter' && handleSubmit()}
      />

      <Button
        fullWidth
        variant="contained"
        color="primary"
        disabled={value.length !== 6}
        onClick={handleSubmit}
        endIcon={<KeyboardArrowRightIcon />}
      >
        Submit
      </Button>
    </>
  );
};
