import { createSvgIcon } from '@mui/material/utils';
import { Box } from '../../Box';

export const NoFlyZoneIcon = () => {
  const Icon = createSvgIcon(
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill="currentColor"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21 12C21 16.9706 16.9706 21 12 21C9.76927 21 7.72809 20.1884 6.15562 18.8444L18.8445 6.15567C20.1884 7.72813 21 9.76929 21 12ZM4.78747 17.3842L17.3842 4.78751C15.8827 3.66483 14.019 3 12 3C7.02944 3 3 7.02944 3 12C3 14.019 3.66481 15.8827 4.78747 17.3842ZM23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12Z"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9997 14.9979C11.8108 14.9979 11.6236 14.9904 11.4384 14.9757L16.4163 9.99792H18V8.49792C18 8.46642 18.0029 8.43561 18.0085 8.40573L18.4078 8.0064C18.4377 8.00083 18.4685 7.99792 18.5 7.99792H18.9286C18.9647 8.0603 19 8.12322 19.0344 8.18665C19.6499 9.31965 19.9997 10.618 20 11.9979H17.745C17.4943 12.3573 17.2108 12.6921 16.8987 12.9979C17.7956 13.8767 18.457 14.9949 18.7795 16.249C18.773 16.2595 18.7664 16.2699 18.7598 16.2803C18.7531 16.2909 18.7464 16.3014 18.7397 16.3118C18.3791 16.8743 17.9501 17.3886 17.4643 17.843C17.1782 17.6654 16.9714 17.3534 16.9011 17.0043C16.6765 15.8904 16.0817 14.9107 15.2506 14.1988C14.2792 14.7092 13.1732 14.9979 11.9997 14.9979ZM6 9.99792H10.7594L5.60028 15.157C5.96179 14.3439 6.47373 13.6124 7.10076 12.998C6.78868 12.6921 6.5051 12.3573 6.25439 11.9979H4L4.00004 11.9741C4.00463 10.5258 4.39405 9.1681 5.07142 7.99792L5.5 7.99792C5.77614 7.99792 6 8.22177 6 8.49792V9.99792ZM14.2504 6.50698L15.3994 5.358L14.3602 5.46191C14.1557 5.48236 14 5.65443 14 5.85993V6.13594C14 6.30266 14.1025 6.44738 14.2504 6.50698ZM5.82316 6.91562L9.6398 6.53395C9.84428 6.51351 10 6.34144 10 6.13594V5.85993C10 5.65443 9.84428 5.48236 9.6398 5.46192L7.67934 5.26587L7.66722 5.27368C6.97234 5.72222 6.35143 6.2755 5.82681 6.91119L5.82316 6.91562ZM22.7317 6.62476L23.6398 6.53395C23.8443 6.51351 24 6.34144 24 6.13594V5.85993C24 5.65443 23.8443 5.48236 23.6398 5.46192L21.9523 5.29317C22.2212 5.69139 22.4668 6.10662 22.6872 6.53691C22.7022 6.56612 22.717 6.59541 22.7317 6.62476ZM1.96925 5.41083C1.71292 5.80025 1.47866 6.20549 1.26825 6.62476L0.360199 6.53395C0.155718 6.51351 0 6.34144 0 6.13594V5.85993C0 5.65443 0.155718 5.48236 0.360199 5.46191L2.04769 5.29317C2.02132 5.33222 1.99517 5.37144 1.96925 5.41083Z"
      />
    </svg>,
    'SettingIcon',
  );
  return (
    <Box>
      <Icon />
    </Box>
  );
};
