import { GridInitialStatePremium } from '@mui/x-data-grid-premium/models/gridStatePremium';
import { IIssueSTStateEnum } from 'codegen/report';
import { ISlotSettingsSTExclusionStatusEnum } from 'codegen/warehouse_status';
import { ISSUE_TYPES } from 'common/issueTypesAndStates';
import { initialGridState } from 'udb/inventory/features/premium-grid/defaults/initialGridState.default';

type TabObject = {
  label: string;
  show: boolean;
  gridState: GridInitialStatePremium;
  issueTypeFilters?: ISSUE_TYPES[];
  issueStateFilters?: IIssueSTStateEnum[];
  slotSettingFilter?: ISlotSettingsSTExclusionStatusEnum;
};

export const WAREHOUSE_STATUS_TABS: TabObject[] = [
  {
    label: 'FULL REPORT',
    show: true,
    gridState: initialGridState,
  },
  {
    label: 'ISSUES',
    show: true,
    gridState: {
      ...initialGridState,
      columns: {
        columnVisibilityModel: {
          exclusion_status: false,
          'wms_status.article_nos.0': false,
          'wms_status.qtys.0': false,
          'issues.0.first_found_on': false,
          missing_barcodes: false,
          missing_barcodes_rate: false,
          unexpected_barcodes: false,
          unexpected_barcodes_rate: false,
          matching_barcodes: false,
          matching_barcodes_rate: false,
          collection_delay: false,
          content_found_amended: false,
        },
      },
    },
    issueTypeFilters: [
      ISSUE_TYPES.WMS_BARCODE_VERITY_EMPTY,
      ISSUE_TYPES.WMS_BARCODE_NOT_EQUAL_VERITY_BARCODE,
      ISSUE_TYPES.WMS_EMPTY_VERITY_BARCODE,
      ISSUE_TYPES.WMS_EMPTY_VERITY_NOTEMPTY,
      ISSUE_TYPES.WMS_BARCODE_DUPLICATE,
    ],
    issueStateFilters: [IIssueSTStateEnum.New, IIssueSTStateEnum.Known],
  },
  {
    label: 'POTENTIAL',
    show: true,
    gridState: {
      ...initialGridState,
      columns: {
        columnVisibilityModel: {
          exclusion_status: false,
          'wms_status.state': false,
          wasFoundAt: false,
          'wms_status.article_nos.0': false,
          'wms_status.qtys.0': false,
          'issues.0.first_found_on': false,
          shouldBeAt: false,
          missing_barcodes: false,
          missing_barcodes_rate: false,
          unexpected_barcodes: false,
          unexpected_barcodes_rate: false,
          matching_barcodes: false,
          matching_barcodes_rate: false,
          collection_delay: false,
          content_found_amended: false,
        },
      },
    },
    issueTypeFilters: [
      ISSUE_TYPES.INCONCLUSIVE_WMS_BARCODE_VERITY_NOTEMPTY,
      ISSUE_TYPES.C001C_MISSING_BC,
    ],
    issueStateFilters: [IIssueSTStateEnum.New, IIssueSTStateEnum.Known],
  },
  {
    label: 'INCONCLUSIVE',
    show: true,
    gridState: {
      ...initialGridState,
      columns: {
        columnVisibilityModel: {
          exclusion_status: false,
          'wms_status.state': false,
          wasFoundAt: false,
          'wms_status.article_nos.0': false,
          'wms_status.qtys.0': false,
          missing_barcodes: false,
          missing_barcodes_rate: false,
          unexpected_barcodes: false,
          unexpected_barcodes_rate: false,
          matching_barcodes: false,
          matching_barcodes_rate: false,
          collection_delay: false,
          content_found_amended: false,
        },
      },
    },
    issueTypeFilters: [
      ISSUE_TYPES.INCONCLUSIVE_WMS_BARCODE_VERITY_NORESULT,
      ISSUE_TYPES.INCONCLUSIVE_WMS_EMPTY_VERITY_NORESULT,
    ],
    issueStateFilters: [IIssueSTStateEnum.New, IIssueSTStateEnum.Known],
  },
  {
    label: 'SNOOZED',
    show: true,
    gridState: {
      ...initialGridState,
      columns: {
        columnVisibilityModel: {
          exclusion_status: false,
          wasFoundAt: false,
          'wms_status.article_nos.0': false,
          'wms_status.qtys.0': false,
          'wms_status.changed_at': false,
          missing_barcodes: false,
          missing_barcodes_rate: false,
          unexpected_barcodes: false,
          unexpected_barcodes_rate: false,
          matching_barcodes: false,
          matching_barcodes_rate: false,
          collection_delay: false,
          content_found_amended: false,
        },
      },
    },
    issueStateFilters: [IIssueSTStateEnum.Snoozed],
  },
  {
    label: 'EXCLUDED',
    show: true,
    gridState: {
      ...initialGridState,
      columns: {
        columnVisibilityModel: {
          'wms_status.state': false,
          wasFoundAt: false,
          'wms_status.article_nos.0': false,
          'wms_status.qtys.0': false,
          'wms_status.changed_at': false,
          'verity_status.state': false,
          shouldBeAt: false,
          'verity_status.collected_at': false,
          'issues.0.first_found_on': false,
          'issues.0.type': false,
          'issues.0.state': false,
          collection_delay: false,
          content_found_amended: false,
        },
        orderedFields: ['slot_label', 'exclusion_status'],
        dimensions: {
          slot_label: {
            maxWidth: -1,
            minWidth: 100,
            width: 178.5,
            flex: 0,
          },
        },
      },
    },
    slotSettingFilter: 'EXCLUDE',
  },
  {
    label: 'INVALID',
    show: true,
    gridState: {
      ...initialGridState,
      columns: {
        columnVisibilityModel: {
          exclusion_status: false,
          wasFoundAt: false,
          'wms_status.article_nos.0': false,
          'wms_status.qtys.0': false,
          'wms_status.changed_at': false,
          missing_barcodes: false,
          missing_barcodes_rate: false,
          unexpected_barcodes: false,
          unexpected_barcodes_rate: false,
          matching_barcodes: false,
          matching_barcodes_rate: false,
          collection_delay: false,
          content_found_amended: false,
        },
      },
    },
    issueTypeFilters: [ISSUE_TYPES.INCONCLUSIVE_WMS_INVALID],
  },
];
