import { REPORT_STATES } from 'common/reportStates';
import { Reports } from '../../Reports.model';
import { GetOngoingAndToReviewReportsParams } from './getOngoingAndToReviewReportsParams.model';
import { reportStore } from '../../reducer/report-store/ReportStore';

export const getOngoingAndToReviewReports = ({
  setReportsOngoing,
  setReportsToReview,
  setSpinner,
  systemId,
  requestController,
}: GetOngoingAndToReviewReportsParams) => {
  const requestParams = {
    states: [REPORT_STATES.ONGOING, REPORT_STATES.SCHEDULED],
  };

  const requestReservation = requestController.reserveSlotForRequest();
  return requestController.doRequest({
    callbackBeforeSend: () => setSpinner(() => 1),
    request: reportStore.asyncGetReportSummariesRecursively,
    requestParams: [
      systemId,
      requestParams,
      requestController,
      requestReservation.requestId,
      requestReservation.signal,
      false,
      (r: Reports) => {
        setReportsOngoing(r.reportsOngoingAll);
        setReportsToReview(r.reportsToReviewAll);
      },
    ],
    messageErrorFallback:
      'Something went wrong while getting the summaries of ongoing and to review reports',
    callbackFinally: () => setSpinner(() => 0),
  });
};
