import { IVeritySlotStatus1ST } from 'codegen/report';
import { IVeritySlotStatusUserOverrideST } from 'codegen/warehouse_status';

export const getLatestOverwriteFromSlotStatus = (
  verity_status: IVeritySlotStatus1ST | string | null,
  userType: 'client' | 'verity',
) => {
  let clientUserOverwrite: IVeritySlotStatusUserOverrideST | undefined;

  if (!verity_status || typeof verity_status === 'string') {
    return clientUserOverwrite;
  }

  if (verity_status?.user_overrides?.length) {
    verity_status.user_overrides.forEach((overwrite) => {
      if (
        (userType === 'client' && !overwrite.review) ||
        (userType === 'verity' && overwrite.review)
      ) {
        clientUserOverwrite = overwrite;
      }
    });
  }

  return clientUserOverwrite;
};
