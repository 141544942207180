import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import { CustomMultiSelect } from 'components/common/CustomFormComponents/CustomMultiSelect';
import { CustomSwitch } from 'components/common/CustomFormComponents/CustomSwitch';
import { Box } from 'components/common/Box';
import { useMutation, useQuery } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { ModalBase } from '../../../../../components/ModalsAndPopups/ModalBase';
import { useFacilityLevelStore } from '../../../../../store/FacilityLevelStore/facilityLevelStore';
import { useStyles } from './AddUserModal.styles';
import { QueryNames } from '../../../../../ts-types/QueryNames';
import { IGroupGetAllResponseST } from '../../../../../codegen/user_admin';
import { NewUser } from '../../model/user.model';
import { transformUserGroupsToSelectOptions } from '../AddEditUserModal/api/transformUserGroupsToSelectOptions';
import { getUserGroups } from '../AddEditUserModal/api/getUserGroups.api';
import { transformFacilitiesToSelectOptions } from './model/transformFacilitiesToSelectOptions';
import { Facility } from '../../model/facility.model';
import { addUser } from './api/addUser.api';
import { AddEditUserSkeleton } from '../../../../../components/Skeletons/AddEditUserSkeleton';
import { isInputEmpty } from './utils/isInputEmpty';
import { isEmailAvailable } from './utils/isEmailAvailable';
import { isPhoneValidFormat } from './utils/isPhoneValidFormat';
import { transformNewUserToDTO } from '../../model/transformNewUserToDTO';

export const AddUserModal = ({
  allUserEmails,
  onAdded,
  onClose,
  facilities,
}: {
  allUserEmails: { email: string; username: string }[];
  onClose: () => void;
  onAdded: () => void;
  facilities: Facility[];
}) => {
  const { systemId = '' } = useParams();
  const { classes } = useStyles();

  const {
    stateFacilityLevel: {
      facilityData: { client: clientName },
    },
  } = useFacilityLevelStore();

  const [userForm, setUserForm] = useState<NewUser>({
    systemIds: [],
    lastName: '',
    firstName: '',
    email: '',
    phone: '',
    userGroups: [],
    accessToAllFacilities: false,
  });
  const [previousSystemIds, setPreviousSystemIds] = useState<number[]>([]);

  const [errors, setErrors] = useState({
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    userGroups: '',
  });

  const { data: userGroups, isLoading: isLoadingUserGroups } = useQuery({
    queryKey: [QueryNames.FETCH_USER_GROUPS, systemId],
    queryFn: () => getUserGroups(systemId),
    select: (response: AxiosResponse<IGroupGetAllResponseST>) =>
      transformUserGroupsToSelectOptions(response.data),
  });

  const { enqueueSnackbar } = useSnackbar();

  const postUserMutation = useMutation({
    mutationFn: addUser,
    onSuccess: () => {
      onAdded();
      enqueueSnackbar('User added.', { variant: 'success' });
      onClose();
    },
    onError: () => {
      enqueueSnackbar('User could not be added.', { variant: 'error' });
      onClose();
    },
  });
  const isLoading: () => boolean = () => isLoadingUserGroups || postUserMutation.isPending;

  const isButtonDisabled = () => {
    const fieldsPopulated = Object.keys(userForm).some(
      (key) => key !== 'phone_number' && userForm[key as keyof typeof userForm] === '',
    );
    const errorsCleared = Object.values(errors).every((val) => val === '');

    return !errorsCleared || fieldsPopulated || isLoading();
  };

  const handleChange = (value: any, prop: string) => {
    setUserForm({ ...userForm, [prop]: value });
  };

  const handleAccessToAllFacilitiesChange = (hasAccessToAllFacilities: boolean) => {
    if (hasAccessToAllFacilities) {
      setPreviousSystemIds([...userForm.systemIds]);
    }
    setUserForm({
      ...userForm,
      accessToAllFacilities: hasAccessToAllFacilities,
      systemIds: hasAccessToAllFacilities
        ? facilities.map((facility: Facility) => facility.id)
        : previousSystemIds,
    });
  };

  const handleAddUser = (user: NewUser) => {
    postUserMutation.mutate({
      systemId,
      data: transformNewUserToDTO(user),
    });
  };

  return (
    <ModalBase
      opened={true}
      maxWidth="md"
      testId="AddEditUserModal"
      handleClose={onClose}
      title={
        <Box textAlign="start" p={2}>
          <Typography style={{ fontWeight: 'bold' }} color="secondary" variant="h5">
            Add User
          </Typography>
          <Typography color="secondary" variant="subtitle1">
            {`Adding user to ${clientName}`}
          </Typography>
        </Box>
      }
      actionButtons={
        <>
          <Button
            disabled={postUserMutation.isPending}
            onClick={onClose}
            variant="outlined"
            color="primary"
            fullWidth
          >
            Cancel
          </Button>
          <Button
            onClick={() => handleAddUser(userForm)}
            variant="contained"
            color="primary"
            fullWidth
            disabled={isButtonDisabled()}
          >
            Add user
          </Button>
        </>
      }
    >
      {isLoading() ? (
        <AddEditUserSkeleton />
      ) : (
        <>
          <Box
            className={classes.inputWrapper}
            mt={3}
            mb={4}
            display="flex"
            justifyContent="space-between"
          >
            <TextField
              className={classes.inputField}
              type="text"
              variant="outlined"
              label="First name"
              required
              error={errors.firstName !== ''}
              helperText={errors.firstName}
              value={userForm.firstName ?? ''}
              onBlur={() =>
                setErrors((e) => ({ ...e, firstName: isInputEmpty(userForm.firstName) }))
              }
              onChange={(e) => handleChange(e.target.value, 'firstName')}
            />
            <TextField
              className={classes.inputField}
              type="text"
              variant="outlined"
              label="Last name"
              required
              error={Boolean(errors.lastName)}
              helperText={errors.lastName}
              value={userForm.lastName}
              onBlur={() => setErrors((e) => ({ ...e, lastName: isInputEmpty(userForm.lastName) }))}
              onChange={(e) => handleChange(e.target.value, 'lastName')}
            />
          </Box>
          <Box
            className={classes.inputWrapper}
            mb={2}
            display="flex"
            justifyContent="space-between"
          >
            <TextField
              className={classes.inputField}
              type="email"
              variant="outlined"
              label="User email"
              required
              error={Boolean(errors.email)}
              helperText={errors.email}
              value={userForm.email}
              onChange={(event) => {
                setErrors((e) => ({
                  ...e,
                  email: isEmailAvailable(event.target.value, allUserEmails, false),
                }));
                handleChange(event.target.value, 'email');
              }}
              onBlur={(event) => {
                setErrors((e) => ({
                  ...e,
                  email: isEmailAvailable(event.target.value, allUserEmails, false),
                }));
              }}
            />
            <div style={{ width: '49%' }}>
              <PhoneInput
                autoFormat={true}
                disableDropdown={true}
                containerClass={classes.phoneInputHolder}
                inputClass={classes.phoneInput}
                value={userForm.phone}
                regions={['america', 'europe', 'asia', 'oceania', 'africa']}
                specialLabel={userForm.phone ? 'Phone number *' : ''}
                placeholder="Phone number *"
                onChange={(phone) => {
                  setErrors((e) => ({
                    ...e,
                    phone: isPhoneValidFormat(userForm.phone),
                  }));
                  handleChange(`+${phone}`, 'phone');
                }}
                onBlur={() => {
                  setErrors((e) => ({
                    ...e,
                    phone: isPhoneValidFormat(userForm.phone),
                  }));
                }}
              />
              {!!errors.phone && <p className={classes.phoneInputError}>{errors.phone}</p>}
            </div>
          </Box>
          <Box
            className={classes.inputWrapper}
            mb={2}
            display="flex"
            justifyContent="space-between"
          >
            <div style={{ width: '75%' }}>
              <CustomMultiSelect
                id="system_ids"
                name="SystemIds"
                testId="c-multi-choice-dropdown-"
                variant="outlined"
                margin="normal"
                disabled={userForm.accessToAllFacilities}
                label="Grant access for"
                values={userForm.systemIds.map((ug: number) => ug.toString()) || []}
                valueOptions={transformFacilitiesToSelectOptions(facilities) || []}
                onChange={(event) => {
                  const systemIds = (event.target.value as unknown as string[]).map((systemId) =>
                    Number(systemId),
                  );

                  handleChange(systemIds, 'systemIds');
                }}
                onClear={() => handleChange([], 'systemIds')}
              />
            </div>
            <div
              style={{
                width: '25%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <CustomSwitch
                id="access_to_all_facilities"
                name="AccessToAllFacilities"
                testId="c-switch-access-to-all-facilities"
                label="Grant all facilities"
                checked={userForm.accessToAllFacilities ?? false}
                onChange={handleAccessToAllFacilitiesChange}
              />
            </div>
          </Box>
          <Box>
            <CustomMultiSelect
              id="user_groups"
              name="UserGroupsSelect"
              testId="UserGroupsSelect"
              variant="outlined"
              margin="normal"
              label="User group"
              values={userForm.userGroups || []}
              valueOptions={userGroups || []}
              error={Boolean(errors.userGroups)}
              errorMessage={errors.userGroups || ''}
              onChange={(event) => {
                handleChange(event.target.value, 'userGroups');
              }}
              onClear={() => setUserForm({ ...userForm, userGroups: [] })}
            />
          </Box>
        </>
      )}
    </ModalBase>
  );
};
