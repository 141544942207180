import TuiCalendar from '@toast-ui/react-calendar';
import type { Options, EventObject, ExternalEventTypes } from '@toast-ui/calendar';
import '@toast-ui/calendar/dist/toastui-calendar.min.css';
import { forwardRef, Ref } from 'react';

type ReactCalendarOptions = Omit<Options, 'defaultView'>;
type CalendarView = Required<Options>['defaultView'];
type CalendarExternalEventNames = Extract<keyof ExternalEventTypes, string>;
type ReactCalendarEventNames = `on${Capitalize<CalendarExternalEventNames>}`;
type ReactCalendarEventHandler = ExternalEventTypes[CalendarExternalEventNames];
type ReactCalendarExternalEvents = {
  [events in ReactCalendarEventNames]: ReactCalendarEventHandler;
};
declare type CalendarProps = ReactCalendarOptions & {
  height?: string;
  events?: Partial<EventObject>[];
  view?: CalendarView;
} & ReactCalendarExternalEvents;

/**
 * Calendar
 * @param props CalendarProps
 * @returns component
 */
export const Calendar = forwardRef((props: CalendarProps, ref: Ref<any>) => (
  <TuiCalendar ref={ref} {...props} />
));
