import { ILocationData } from 'store/Modals/facilityModals/IFacilityModalsStore';

import {
  DataGridPremium,
  GridEventListener,
  GridRowParams,
  useGridApiRef,
} from '@mui/x-data-grid-premium';
import { GridInitialStatePremium } from '@mui/x-data-grid-premium/models/gridStatePremium';
import { IFacilitySettingsST } from 'codegen/facility_settings';
import { getIssueAndBarcodeMatchingLogic } from 'common/functions/issueLogic/issueLogicFunctions';
import { useCallback, useEffect, useMemo } from 'react';
import { DISPLAY_VERITY_STATES } from 'common/slotStates';
import { useBarcodesInformationStyles } from './barcodesInformation.style';
import { getBarcodeColumns } from './models/barcodeColumns';
import { transformBarcodeRows } from './utils/transformBarcodeRows.util';

export const BarcodesInformation = ({
  locationData,
  facilitySettings,
  showBarcodeHighlighting,
  highlightedBarcode,
  setHighlightedBarcode,
}: {
  locationData: ILocationData;
  facilitySettings: IFacilitySettingsST;
  showBarcodeHighlighting: boolean;
  highlightedBarcode: string;
  setHighlightedBarcode: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const { classes } = useBarcodesInformationStyles();
  const gridApiRef = useGridApiRef();

  const { barcodeMatchLogic, issueLogic } = getIssueAndBarcodeMatchingLogic(
    locationData,
    facilitySettings,
  );

  const columns = useMemo(
    () =>
      getBarcodeColumns({
        barcodeMatchLogic,
        issueLogic,
        disableFoundAtShouldBeAt: facilitySettings.disable_found_and_should_be_at ?? false,
        hideCustomerColumn: !facilitySettings.show_customer,
      }),
    [
      barcodeMatchLogic,
      facilitySettings.disable_found_and_should_be_at,
      facilitySettings.show_customer,
      issueLogic,
    ],
  );

  const handleStateChange = () => {
    localStorage.setItem('barcodeTableConfig', JSON.stringify(gridApiRef.current.exportState()));
  };

  const storedBarcodeTableConfig = localStorage.getItem('barcodeTableConfig');
  const initialTableConfig: GridInitialStatePremium =
    storedBarcodeTableConfig && JSON.parse(storedBarcodeTableConfig);

  const getRowClassName = (params: GridRowParams<any>) =>
    highlightedBarcode !== '' && params.row.contentFound === highlightedBarcode
      ? classes.hoveredRow
      : '';

  const handleMouseEnter: GridEventListener<'rowMouseEnter'> = useCallback(
    (params) => {
      if (
        !Object.values(DISPLAY_VERITY_STATES).includes(params.row.contentFound) &&
        showBarcodeHighlighting &&
        facilitySettings.enable_barcode_highlighting
      ) {
        setHighlightedBarcode(params.row.contentFound);
      }
    },
    [facilitySettings.enable_barcode_highlighting, setHighlightedBarcode, showBarcodeHighlighting],
  );

  const handleMouseLeave: GridEventListener<'rowMouseLeave'> = useCallback(() => {
    setHighlightedBarcode('');
  }, [setHighlightedBarcode]);

  useEffect(() => {
    const unsubscribeEnter = gridApiRef.current.subscribeEvent('rowMouseEnter', handleMouseEnter);
    const unsubscribeLeave = gridApiRef.current.subscribeEvent('rowMouseLeave', handleMouseLeave);

    return () => {
      unsubscribeEnter();
      unsubscribeLeave();
    };
  }, [gridApiRef, handleMouseEnter, handleMouseLeave]);

  if (!locationData.rowData) {
    return null;
  }

  return (
    <DataGridPremium
      className={classes.root}
      apiRef={gridApiRef}
      rows={transformBarcodeRows(locationData)}
      columns={columns}
      hideFooter
      rowGroupingColumnMode="single"
      disableRowSelectionOnClick
      disableAggregation
      onStateChange={handleStateChange}
      initialState={initialTableConfig}
      autoHeight={true}
      getRowClassName={getRowClassName}
    />
  );
};
