import { DroneZones } from 'shared/map/model/drone-zones.model';
import { allNumbersToFixed2 } from 'utils/numberFormatting';

type CreateDroneZoneArgs = Partial<
  Pick<DroneZones, 'id' | 'name' | 'description' | 'isActive' | 'isSelected'>
> &
  Partial<Pick<DroneZones['sizeAndPosition'], 'minX' | 'minY' | 'minZ' | 'maxZ'>> & {
    type: DroneZones['type'];
    width?: DroneZones['sizeAndPosition']['w'];
    length?: DroneZones['sizeAndPosition']['l'];
    height?: DroneZones['sizeAndPosition']['h'];
  };

const DEFAULT_ZONE_HEIGHT = 1;

export const createDroneZoneObject = ({
  id = '',
  name = '',
  description = '',
  type,
  isActive = false,
  isSelected = false,
  width = 0,
  length = 0,
  height = DEFAULT_ZONE_HEIGHT,
  minX = 0,
  minY = 0,
  minZ = 0,
  maxZ = DEFAULT_ZONE_HEIGHT,
}: CreateDroneZoneArgs): DroneZones => ({
  id,
  description,
  name,
  type,
  isActive,
  isSelected,
  waitingForDronesToComeHome: false,
  sizeAndPosition: allNumbersToFixed2({
    w: width,
    l: length,
    h: height,
    minX,
    minY,
    minZ,
    maxZ,
  }),
});
