import { REPORT_STATES } from 'common/reportStates';
import { reportStore } from 'udb/inventory/features/reports/reducer/report-store/ReportStore';
import { GetPendingReviewAndOngoingReportsParams } from './getPendingReviewAndOngoingReportsParams.model';

export const getPendingReviewAndOngoingReports = ({
  requestController,
  systemId,
  setIsLoading,
  onSuccess,
}: GetPendingReviewAndOngoingReportsParams) => {
  const ongoingReportsReqParams = {
    states: [REPORT_STATES.ONGOING, REPORT_STATES.SCHEDULED],
  };

  // get all ongoing, irrespective of date range
  const reportsRequestReservation = requestController.reserveSlotForRequest();
  requestController.doRequest({
    request: reportStore.asyncGetReportSummariesRecursively,
    requestParams: [
      systemId,
      ongoingReportsReqParams,
      requestController,
      reportsRequestReservation.requestId,
      reportsRequestReservation.signal,
      false,
    ],
    callbackBeforeSend: () => setIsLoading(true),
    callbackFinally: () => setIsLoading(false),
    messageErrorFallback: 'Something went wrong while getting the summaries of ongoing reports',
    callbackSuccess: onSuccess,
  });
};
