// ###########################################################################
// This file contains the functions needed to process data received from
// the backend and displayed on pages:
// - Warehouse status
// - Reports
// - Report
// - Issue
// ###########################################################################

import { IIssueST, ILocationDataST, ILocationDataSTStateEnum } from 'codegen/report';

import { getTableRowForLocation } from './getTableRowForLocation';
import { getLatestIssue } from '../issues/issueFunctions';

export const getRowForFullReportTable = (
  location: string,
  slotStatus: ILocationDataST,
  issuesForLocation: IIssueST[],
  scanState?: ILocationDataSTStateEnum,
) => {
  // Get the most recent issue for this location, when one exists
  // TODO :: check if the issues associated with a location are already ordered, if so, remove the sort

  // Note: the getTableRowForLocation requires an IIssue instance (even though it might be empty)
  // therefore we need to defined locationIssue as an empty IIssue.
  const latestIssue = getLatestIssue(issuesForLocation);

  const rowData = getTableRowForLocation(location, slotStatus, latestIssue, scanState);
  return rowData;
};

/**
 * Get row and issue data to populate issue table rows
 * this type of row is also used on tabs: Snoozed, Inconclusive, Invalid and Potential
 * @param location ID of the location
 * @param locationIssueData Issue
 * @param permissions WH permissions
 */
export const getRowAndSingleIssueForIssueTable = (
  location: string,
  locationIssueData: IIssueST[],
) => {
  // The back-end sorts the issue data appropriately.
  const singleIssueData = locationIssueData[0];

  // Get the slot data related to the latest issue
  const slotStatus = singleIssueData.slot_status as ILocationDataST;

  // Get data needed to populate a table row
  const rowData = getTableRowForLocation(location, slotStatus, singleIssueData);

  return { rowData, singleIssueData };
};
