import { createContext, useContext, useReducer } from 'react';
import { ZoneStatusActions } from './zoneStatusActions';
import { NoFlyZonePageState, noFlyZonePageDefaultState } from './noFlyZonePageDefaultState';
import { noFlyZoneStatusReducer } from './zoneStatusActionsReducer';

type NoFlyZonePage = {
  noFlyZonePageState: NoFlyZonePageState;
  dispatchNoFlyZone: React.Dispatch<ZoneStatusActions>;
};

const NoFlyZoneContext = createContext<NoFlyZonePage | undefined>(undefined);

export const useNoFlyZoneContext = () => {
  const noFlyZoneContext = useContext(NoFlyZoneContext);

  if (noFlyZoneContext === undefined) {
    throw new Error('useNoFlyZoneContext has to be used within <NoFlyZoneContext.Provider>');
  }

  return noFlyZoneContext;
};

export const NoFlyZonePageContext = ({ children }: { children: React.ReactNode }) => {
  const [droneZonePageState, dispatchDroneZone] = useReducer(
    noFlyZoneStatusReducer,
    noFlyZonePageDefaultState,
  );

  return (
    <NoFlyZoneContext.Provider
      value={{
        noFlyZonePageState: droneZonePageState,
        dispatchNoFlyZone: dispatchDroneZone,
      }}
    >
      {children}
    </NoFlyZoneContext.Provider>
  );
};
