import { IGroupGetAllResponseST } from 'codegen/user_admin';
import { IOption } from 'interfaces/inputs';

export const transformUserGroupsToSelectOptions: (
  userGroups: IGroupGetAllResponseST,
) => IOption[] = (userGroups) =>
  userGroups.groups.map((group: string) => ({
    value: group,
    label: group.replace(/([A-Z])/g, ' $1').trim(),
  }));
