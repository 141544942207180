import { makeStyles } from 'tss-react/mui';
import { CANVAS_HEIGHT } from 'shared/map/defaults/map-canvas.defaults';

export const noFlyZoneListStyle = makeStyles()(() => ({
  wrapper: {
    position: 'relative',
    height: '100%',
  },
  nfzsWrapper: {
    maxHeight: CANVAS_HEIGHT,
    overflowY: 'auto',
    padding: '0 12px 12px 12px',

    '&::-webkit-scrollbar': {
      WebkitAppearance: 'none',
      width: 10,
    },

    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0, 0, 0, .5)',
      boxShadow: '0 0 1px rgba(255, 255, 255, .5)',
    },
  },
}));
