// ###############################################################################
//   This file contains the functions needed to generically handle requests to
//   the backend. It generalizes the way we deal with callbacks and error messages
//      TR::TODO::2021-01-29::
//        - make all server requests in the app use this function
//
//      TR::FIXME::2021-03-24::
//        - there is a better way of dealing with params for callbacks, implement it!
//          - use this n the payload when invoking the handler
//              - request: () => requestFunction(param1, param2, parm 3)
// ###############################################################################

import isEmpty from 'lodash/isEmpty';
import isFunction from 'lodash/isFunction';

// services
import { OptionsObject, SnackbarKey, SnackbarMessage } from 'notistack';
import { IDoRequestHandlerData, IRequestControllerState } from '../hooks';
import { NetworkRequest } from '../ts-types/NetworkRequest';
import { getLogPrefixForType } from './functions/logFunctions';
import { errorNotification } from './notifications';

interface IHandlerData extends IDoRequestHandlerData {
  /**
   * the dispatcher of the caller (to show snack bars)
   */
  dispatcher?: (message: SnackbarMessage, options?: OptionsObject | undefined) => SnackbarKey;
  /**
   * Cancellation Controller.
   */
  requestControllerState?: IRequestControllerState;
  /**
   * ID of the request (in the request Controller)
   */
  requestId?: string;
  /**
   * Callback invoked when executing state changes
   */
  onExecutingStateChanged?: (params: { loader: string; value: boolean }) => void;
}

const dispatchExecutingStateChange = (handlerData: IHandlerData, isExecuting: boolean) => {
  if (handlerData.onExecutingStateChanged) {
    handlerData.onExecutingStateChanged({
      loader: handlerData.requestId || handlerData.request.name,
      value: isExecuting,
    });
  }
};

export const singleRequestHandler = (handlerData: IHandlerData) => {
  const logPrefix = getLogPrefixForType(
    'FUNCTION',
    `singleRequestHandler (request id: ${handlerData.requestId}) - ${handlerData.request.name} `,
    handlerData.requestControllerState?.componentName || '',
  );

  const topicCan = getLogPrefixForType('TOPIC', 'CANCEL', logPrefix);
  const topicErr = getLogPrefixForType('TOPIC', 'ERROR', logPrefix);

  if (!handlerData) {
    console.error(topicErr, 'invoked without handlerData');
    return undefined;
  }
  // Set the default value of cancellable to true (if not defined)
  handlerData = { cancellableCallbacks: true, ...handlerData };
  console.debug(logPrefix, 'invoked');

  if (handlerData?.dispatcher) {
    console.debug(logPrefix, 'invoked without a dispatcher => feedback in the console only');
  }
  /**
   * Notify the user of a given message via the dispatcher (if available) or the console.
   * @param message message to be notified
   * @param variant variant of the notification
   */
  const notify = (message: string, variant: 'success' | 'error') => {
    if (handlerData.dispatcher) {
      handlerData.dispatcher(message, { variant });
    } else {
      const msg = `${logPrefix} ${variant} => ${message}`;
      variant === 'error' ? console.error(msg) : console.debug(msg);
    }
  };

  /**
   * Verify whether the request has been cancelled (and is cancellable)
   * @returns true if the request is cancelled and cancellable
   */
  const isCancelled = () =>
    handlerData.requestId !== undefined &&
    handlerData.requestControllerState?.isRequestCancelled(handlerData.requestId) &&
    handlerData.cancellableCallbacks;

  dispatchExecutingStateChange(handlerData, true);
  // execute callback right before the request is sent
  isFunction(handlerData.callbackBeforeSend) && handlerData.callbackBeforeSend();
  // notify the user (if a before send message has been given)
  handlerData.messageBeforeSend && notify(handlerData.messageBeforeSend, 'success');

  // sanitize request params
  const reqParams = handlerData.requestParams ? handlerData.requestParams : [];

  return handlerData
    .request(...reqParams)
    .then((r: any) => {
      if (isCancelled()) {
        console.debug(topicCan, 'skip callbackSuccess');
        return undefined;
      }
      console.debug(logPrefix, 'callbackSuccess successfully INVOKED');

      // execute callback after getting server response
      isFunction(handlerData.callbackSuccess) && handlerData.callbackSuccess(r);
      // notify the user
      handlerData.messageSuccess && notify(handlerData.messageSuccess, 'success');

      return r;
    })
    .catch((e: any) => {
      console.debug(logPrefix, 'could not be finished, verify if it has been CANCELLED');
      let isRequestCancelled = true;
      // dis-assembling the conditions for better logging purposes
      // EN: 2022-04-13: this is alas a need for investigating the behavior on remote
      // system which tend to behave slightly differently than local ones
      // i.e.: do not re-factor it, please.
      if (handlerData.requestId === undefined) {
        console.debug(logPrefix, 'the request has no id => not CANCELLED');
        isRequestCancelled = false;
      } else if (!handlerData.requestControllerState) {
        console.debug(logPrefix, 'the request has no requestControllerState => not CANCELLED');
        isRequestCancelled = false;
      } else if (!handlerData.requestControllerState.isRequestCancelled(handlerData.requestId)) {
        console.debug(logPrefix, 'the request has not been CANCELLED');
        isRequestCancelled = false;
      }

      if (isRequestCancelled) {
        console.debug(topicCan, 'skip callbackError');
      } else {
        // A Cancellation has not been issued => report the diagnostics as errors
        console.error(topicErr, e);
        if (e.response?.data) {
          // If there is a proper error message from the back-end, shows it
          if (handlerData.dispatcher) {
            !handlerData.hideNotifications &&
              errorNotification(e.response?.data, handlerData.dispatcher);
          }
        } else if (handlerData.messageErrorFallback) {
          !handlerData.hideNotifications && notify(handlerData.messageErrorFallback, 'error');
        }
      }
      // if the request hasn't been cancelled and an error call-back has been defined, execute it
      !isCancelled() && isFunction(handlerData.callbackError) && handlerData.callbackError(e);
    })
    .finally(() => {
      if (isCancelled()) {
        console.debug(topicCan, 'skip callbackFinally');
        dispatchExecutingStateChange(handlerData, false);
        return;
      }
      // execute finally callback
      isFunction(handlerData.callbackFinally) && handlerData.callbackFinally();
      dispatchExecutingStateChange(handlerData, false);
      // notify the user
      handlerData.messageFinally && notify(handlerData.messageFinally, 'success');
    });
};

/**
 * We are defaulting numberOfConcurrentRequests to 6 as this is a maximum
 * number of concurrent http requests that most browsers will make to the same domain
 * @param requests array of requests
 * @param numberOfConcurrentRequests max number of concurrent requests
 * @param requestControllerState Request Controller
 */
export const requestQueueHandler = (
  requests: NetworkRequest[],
  numberOfConcurrentRequests = 6,
  requestControllerState?: IRequestControllerState,
) => {
  const logPrefix = getLogPrefixForType('FUNCTION', 'requestQueueHandler');
  const networkRequests = [...requests];
  const initialSubsetOfRequests = networkRequests.splice(0, numberOfConcurrentRequests);

  const sendNetworkRequest = (req: { request: any; requestId: string }) => {
    // If the current request has already been cancelled then we just skip it
    if (requestControllerState?.isRequestCancelled(req.requestId)) {
      console.debug(logPrefix, `the request with id ${req.requestId} has already been CANCELLED`);
      return;
    }
    // request is an instance of singleRequestHandler which by itself handles
    // errors - that's why we don't need catch block here.
    req
      .request()
      .then((r: any) => r)
      .finally(() => {
        if (!isEmpty(networkRequests)) {
          const nextRequest = networkRequests.splice(0, 1)[0];
          sendNetworkRequest(nextRequest);
        }
      });
  };

  initialSubsetOfRequests.forEach((request) => {
    sendNetworkRequest(request);
  });
};
