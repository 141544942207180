import { useLoader } from '@react-three/fiber';
import { useEffect, useMemo } from 'react';
import { DoubleSide, Mesh, MeshBasicMaterial, PlaneGeometry, ShapeGeometry } from 'three';
import { SVGLoader, mergeBufferGeometries } from 'three-stdlib';

export function useSvgMesh(src: string) {
  const svg = useLoader(SVGLoader, src);
  const strokeGeometries = useMemo(
    () =>
      svg.paths.map((path) => {
        const hasNoStroke =
          path.userData?.style.stroke === undefined || path.userData.style.stroke === 'none';
        return hasNoStroke
          ? null
          : path.subPaths.map((subPath) =>
              SVGLoader.pointsToStroke(subPath.getPoints(), path.userData?.style),
            );
      }),
    [svg],
  );

  useEffect(
    () => () => strokeGeometries.forEach((group) => group && group.map((g) => g.dispose())),
    [strokeGeometries],
  );

  const mergedSvgMesh = useMemo(() => {
    const pathGeometries = svg.paths.flatMap((path) =>
      SVGLoader.createShapes(path).map((shape) => new ShapeGeometry(shape)),
    );

    const geometry = mergeBufferGeometries(pathGeometries) ?? new PlaneGeometry();

    const fill = svg.paths.find((p) => p.userData?.style.fill)?.userData?.style.fill ?? 'white';
    const fillOpacity =
      svg.paths.find((p) => p.userData?.style.fillOpacity)?.userData?.style.fillOpacity ?? 1;

    const material = new MeshBasicMaterial({
      color: fill,
      opacity: fillOpacity,
      transparent: true,
      side: DoubleSide,
      depthWrite: false,
      toneMapped: false,
    });
    return new Mesh(geometry, material);
  }, [svg]);

  return mergedSvgMesh;
}
