import { useState } from 'react';
import { useTheme } from '@emotion/react';

import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import LockIcon from '@mui/icons-material/Lock';

import { useLocation } from 'react-router-dom';
import { COLOR_PALETTE } from 'common/colors';
import { getLogPrefixForType } from 'common/functions/logFunctions';
import { Box } from 'components/common/Box';
import { ModalBase } from 'components/ModalsAndPopups/ModalBase';
import { Spinner } from 'components/common/Spinner';
import { GroundControlActionNames } from 'store/GroundControl/groundControlLevelStore.model';
import { DroneArrowDownIcon } from '../Icons/DroneArrowDownIcon';

import { useGroundControlStore } from '../../../store/GroundControl/groundControlLevelStore';
import { groundControlServices } from '../../../services/GroundControlServices';
import { useGroundControlModalsStore } from '../../../store/Modals/groundControlModals/groundControlModalsStore';

import { useRequestController } from '../../../hooks';
import { GroundControlModalsActionNames } from '../../../store/Modals/groundControlModals/groundControlModalsActions';
import { userHasPermission } from '../../../features/permissions/userHasPermission';
import { PERMISSION } from '../../../features/permissions/permissions.model';
import { HighlightedIconItem } from './HighlightedItem';

interface IEmergencyModalProps {
  opened: boolean;
  nDronesFlying: number;
  nDronesLandedInError: number;
  flightDomainId: string;
  flightDomainName: string;
  isExecutingEmergencyLanding: boolean;
  facilityName: string;
}

export const EmergencyLandingModal = (props: IEmergencyModalProps) => {
  const {
    opened,
    nDronesFlying,
    nDronesLandedInError,
    flightDomainId,
    facilityName,
    flightDomainName,
    isExecutingEmergencyLanding,
  } = props;
  const theme = useTheme() as any;

  const [loading, setLoading] = useState(false);
  const { stateGroundControl, dispatchGroundControlLevel } = useGroundControlStore();
  const { dispatchGroundControlModals } = useGroundControlModalsStore();

  const systemId = useLocation().pathname.split('/')[1];
  const { requestController } = useRequestController('Emergency Landing Modal');

  /**
   * Handle emergency landing procedure
   */
  const handleEmergencyLanding = () => {
    const lp = getLogPrefixForType('FUNCTION', 'handleEmergencyLanding');
    const reservation = requestController.reserveSlotForRequest();

    return requestController.doRequest({
      request: groundControlServices.emergencyLanding,
      requestParams: [systemId, flightDomainId, reservation.signal],
      callbackBeforeSend: () => {
        console.debug(lp, 'Safety landing triggered');
        setLoading(true);
      },
      messageErrorFallback: 'Error occurred while triggering safety landing.',
      messageSuccess: 'Safety landing triggered successfully.',
      callbackSuccess: async () => {
        console.debug(lp, 'Safety landing triggered successfully.');
        dispatchGroundControlLevel({
          type: GroundControlActionNames.SET_ALERT_INFO,
          payload: {
            key: 'main',
            variant: stateGroundControl.activeAlertVariant,
            message: `${facilityName} ${flightDomainName} WARNING: Safety landing in progress.`,
          },
        });
        dispatchGroundControlLevel({
          type: GroundControlActionNames.SET_ALERT_INFO,
          payload: {
            key: 'statusCard',
            variant: stateGroundControl.activeAlertVariant,
            message: 'System has been locked due to a safety landing.',
          },
        });
        dispatchGroundControlModals({
          type: GroundControlModalsActionNames.EMERGENCY_LANDING_MODAL,
        });
      },
      callbackFinally: () => setLoading(false),
    });
  };

  return (
    <ModalBase
      opened={opened}
      closeButton={true}
      disableGutters
      disableBackdropClick={true}
      testId="c-emergency-landing-modal"
      title={
        <Box display="flex" width="100%" sx={{ p: 2, background: 'white' }}>
          <Box mt={0.6} mr={1}>
            <DroneArrowDownIcon
              fontSize="2rem"
              theme={theme}
              color={COLOR_PALETTE.RED}
              active={false}
            />
          </Box>
          <Box textAlign="left">
            <Typography
              data-testid="c-emergency-landing-modal-title"
              style={{ padding: 0, fontWeight: 'bold' }}
              color="inherit"
              variant="h5"
            >
              Safety landing
            </Typography>
            <Typography
              data-testid="c-emergency-landing-modal-subtitle"
              color="inherit"
              variant="subtitle1"
              textTransform="uppercase"
            >
              {`${facilityName} - ${flightDomainName}`}
            </Typography>
          </Box>
        </Box>
      }
      handleClose={() =>
        dispatchGroundControlModals({
          type: GroundControlModalsActionNames.EMERGENCY_LANDING_MODAL,
        })
      }
      actionButtons={
        <>
          <Button
            data-testid="c-emergency-landing-modal-btn-cancel"
            onClick={() =>
              dispatchGroundControlModals({
                type: GroundControlModalsActionNames.EMERGENCY_LANDING_MODAL,
              })
            }
            variant="outlined"
            fullWidth
            sx={{ marginRight: 2 }}
            color="error"
          >
            Cancel
          </Button>
          <Button
            data-testid="c-emergency-landing-modal-btn-confirm"
            onClick={handleEmergencyLanding}
            disabled={!userHasPermission(PERMISSION.INITIATE_SAFETY_LANDING)}
            variant="contained"
            fullWidth
            color="error"
          >
            Request safety landing
          </Button>
        </>
      }
    >
      <Box p={0}>
        <>
          <Typography
            data-testid="c-emergency-landing-modal-info"
            px={3}
            mb={4}
            align="left"
            component="div"
          >
            {!isExecutingEmergencyLanding
              ? 'Safety landing will initiate the following processes:'
              : 'Safety landing is in progress and cannot be canceled until all the drones land in place.'}
          </Typography>

          <Box
            px={3}
            py={2}
            display="flex"
            flexDirection="column"
            gap={2}
            sx={{ background: COLOR_PALETTE.RED }}
          >
            <HighlightedIconItem title="System will be locked">
              <LockIcon sx={{ color: 'white' }} fontSize="medium" />
            </HighlightedIconItem>

            <HighlightedIconItem
              title="Drones will perform a safety landing"
              dataTestId="c-emergency-landing-modal-drones-will-land-number"
              amount={`${
                isExecutingEmergencyLanding ? `${nDronesLandedInError}/` : ''
              }${nDronesFlying}`}
            >
              <DroneArrowDownIcon color="white" theme={theme} active={false} />
            </HighlightedIconItem>
          </Box>

          {!isExecutingEmergencyLanding && (
            <Typography
              data-testid="c-emergency-landing-modal-you-will-not-be-able-info"
              p={3}
              variant="body1"
              color="textSecondary"
            >
              You will not be able to cancel this operation. Would you like to proceed with the
              safety landing?
            </Typography>
          )}
        </>
      </Box>
      {loading && <Spinner />}
    </ModalBase>
  );
};
