import React, { useEffect } from 'react';

// mui core
import { makeStyles } from 'tss-react/mui';
import Container from '@mui/material/Container';

// styles
import './style.css';

import { getLogPrefixForType } from 'common/functions/logFunctions';
import { Header } from 'components/Header/Header';

// hooks
import { Spinner } from 'components/common/Spinner';
import { useLoadClientLevelData, useWebSocketNotifications } from '../../hooks';

// client level modals
import { useClientModalsStore } from '../../store/Modals/clientModals/clientModalsStore';
import { ClientModalsActionTypes } from '../../store/Modals/clientModals/clientModalsActionTypes';
import { useClientLevelStore } from '../../store/ClientLevelStore/clientLevelStore';

const useStyles = makeStyles()(() => ({
  root: {
    padding: '0 !important',
  },
}));

export const ClientPage = (props: { children: React.ReactNode }) => {
  // styles
  const { classes, cx } = useStyles();

  // Client level modals
  const { dispatchClientModals } = useClientModalsStore() as any;

  const logRoutePrefix = getLogPrefixForType('ROUTE', window.location.pathname);
  const logPrefix = getLogPrefixForType('COMPONENT', 'ClientPage', logRoutePrefix);
  const { isDataReady: isClientDataReady } = useClientLevelStore() as any;

  useLoadClientLevelData(logPrefix);

  useEffect(
    /**
     * close all client level modals on mount and on unmount
     */
    () => {
      dispatchClientModals({ type: ClientModalsActionTypes.RESET });
      return () => dispatchClientModals({ type: ClientModalsActionTypes.RESET });
    },
    [dispatchClientModals],
  );

  // This hook manages the displaying of notifications
  useWebSocketNotifications();

  if (isClientDataReady()) {
    return (
      <>
        <Header />
        <Container maxWidth={false} className={cx(classes.root, 'c-page-wrapper')}>
          {props.children}
        </Container>
      </>
    );
  }
  return <Spinner label="loading client data ..." />;
};
