import { Dispatch } from 'react';
import {
  IExclusionRequestResponseST,
  IExclusionStatusPostRequestLocationsInnerST,
  ISlotSettingsSTExclusionStatusEnum,
} from 'codegen/warehouse_status';
import { IRequestController } from 'hooks';
import {
  LocationsActionNames,
  LocationsActions,
} from 'udb/inventory/features/locations-management/reducer/LocationsActions';
import { WarehouseService } from 'services/Warehouse';
import { UpdateLocationsDataExclusionStatus } from 'services/Warehouse/UpdateLocationsDataExclusionStatus';
import { createExclusionSuccessMessage } from '../utils/createSuccessMessage';

type UpdateExclusionStatusParams = {
  locationsIds: string[];
  reason: string;
  exclusionStatus: ISlotSettingsSTExclusionStatusEnum;
  requestController: IRequestController;
  dispatch: Dispatch<LocationsActions>;
  systemId: string;
};

/**
 * Triggers api call for updating excluded locations
 * @param params UpdateExclusionStatusParams
 */
export const updateExclusionStatus = ({
  locationsIds,
  reason,
  exclusionStatus,
  requestController,
  dispatch,
  systemId,
}: UpdateExclusionStatusParams) => {
  const locations: IExclusionStatusPostRequestLocationsInnerST[] = locationsIds.map((id) => ({
    label: id,
    exclusion: exclusionStatus,
  }));

  // As per issue UD-4556: the UDB BE cannot manage to update the exclusion state for more
  // than a couple of hundreds of locations at once (otherwise the corresponding AWS lambda
  // times out). For this reason we are sending the update orders in bunches
  // eno 2023-06-29
  const BATCH_SIZE = 150;

  const totalBatches = Math.ceil(locations.length / BATCH_SIZE);
  let batchCount = 0;

  const reqIds = [];

  while (locations.length) {
    batchCount += 1;
    const locationsChunk = locations.splice(0, BATCH_SIZE);

    const { signal, requestId } = requestController.reserveSlotForRequest();

    reqIds.push(requestId);

    const requestParams: UpdateLocationsDataExclusionStatus = {
      signal,
      systemId,
      payload: {
        locations: locationsChunk,
        reason,
      },
    };

    requestController.doRequest(
      {
        request: WarehouseService.updateLocationsDataExclusionStatus,
        requestParams: [requestParams],
        messageSuccess: createExclusionSuccessMessage(
          locationsChunk.length,
          batchCount,
          totalBatches,
          exclusionStatus,
        ),
        messageErrorFallback: 'Locations could not be updated.',
        callbackSuccess: ({ data }: { data: IExclusionRequestResponseST }) =>
          dispatch({ type: LocationsActionNames.SET_UPDATE_LOCATIONS, payload: data }),
      },
      requestId,
    );
  }

  return reqIds;
};
