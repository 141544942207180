import { GridFilterItem, GridLogicOperator } from '@mui/x-data-grid-premium';
import { WarehouseStatsDataSerie } from '../WarehouseStats.model';

export const getDataSerie = (
  id: string | number,
  value: number | undefined,
  label: string,
  description: string,
  items?: GridFilterItem[],
): WarehouseStatsDataSerie => ({
  id,
  value: value || 0,
  label,
  description,
  gridState: items
    ? {
        filter: {
          filterModel: {
            items,
            logicOperator: GridLogicOperator.And,
            quickFilterValues: [],
            quickFilterLogicOperator: GridLogicOperator.And,
          },
        },
      }
    : undefined,
});
