import { IRequestController } from 'hooks';
import { IBaseLevelContext } from 'interfaces/inputs/storeInterfaces';
import { ClientLevelActionNames } from './ClientLevelActions';

/**
 * Type of payload for UPDATE_FACILITY_ATTRIBUTE client level action
 */
interface UpdateFacilityAttributePayload<T extends keyof IClientLevelStateFacility> {
  facilityId?: string;
  id: string; // duplicate of facilityId ??? used in store
  attribute?: keyof IClientLevelStateFacility;
  key: T; // duplicate with attribute ??? used in store and reducer
  value: typeof noClientLevelStateFacility[T];
}
/**
 * Types of client level actions
 */

export type ClientLevelAction =
  | { type: ClientLevelActionNames.RESET; payload: null }
  | { type: ClientLevelActionNames.SET_FACILITY_LIST; payload: IClientLevelStateFacility[] }
  | { type: ClientLevelActionNames.SET_FAILED_TO_LOAD_FACILITY_LIST; payload: boolean }
  | { type: ClientLevelActionNames.SET_FACILITY_LIST_POPULATED; payload: boolean }
  | { type: ClientLevelActionNames.SET_FACILITY_LIST_LOADING; payload: boolean }
  | {
      type: ClientLevelActionNames.UPDATE_FACILITY_ATTRIBUTE;
      payload: UpdateFacilityAttributePayload<keyof IClientLevelStateFacility>;
    };

/**
 * Interface defining the Client Level Context
 */
export interface IClientLevelContext extends IBaseLevelContext {
  /**
   * Store state
   */
  stateClientLevel: IClientLevelState;
  /**
   * Verifies whether the store contains data ready to be used.
   */
  isDataReady: () => boolean;
  /**
   * Dispatcher
   */
  dispatchClientLevel?: React.Dispatch<ClientLevelAction>;
  /**
   * Retrieve the data related with the facilities and populate facility list.
   * @param {IRequestController} requestController cancellation controller.
   * @param {boolean} populateIssues flag indicating whether the active issues per facility will
   * be retrieved and stored.
   * @returns a promise which will resolve if the operation is successful.
   */
  asyncPopulateFacilities: (
    requestController: IRequestController,
    populateIssues: boolean,
  ) => Promise<void>;
  /**
   * Retrieve the issue counts for the given facilities
   * @param {IRequestController} requestController cancellation controller.
   * @param {IClientLevelStateFacility[]} facilityList list of facilities.
   */
  asyncPopulateActiveIssues: (
    requestController: IRequestController,
    facilityList: IClientLevelStateFacility[],
  ) => Promise<void>;
  /**
   * todo - ss
   */
  asyncRefreshFacilities: (
    requestController: IRequestController,
    populateIssues: boolean,
  ) => Promise<void>;
}

/**
 * Interface describing the State of the Client Level Store
 */
export interface IClientLevelState {
  /**
   * Facility list
   */
  facilityList: IClientLevelStateFacility[];
  /**
   * Flag which is true when there is more than one facility available.
   */
  isMultiFacility: null | boolean;
  /**
   * Flag which is true when the facility list is populated.
   */
  isFacilityListPopulated: boolean;
  /**
   * Flag which is true when the facility list is being loaded.
   */
  isFacilityListLoading: boolean;
  /**
   * Flag which is true if the facility list could not be retrieved.
   */
  failedToLoadFacilityList: boolean;
}

/**
 * Interface describing a facility in the facility list.
 */
export interface IClientLevelStateFacility {
  /**
   * (System) ID of the facility.
   */
  id: string;
  /**
   * Name of the facility.
   */
  name: string;
  /**
   * Number of issue currently open in the facility.
   */
  issueCounter: string | number;
  /**
   * Flag which is true when the issue counter for the facility is being loaded.
   */
  isIssueCounterLoading: boolean;
  /**
   * Flag which is true if the facility has already been configured.
   */
  isConfigured: boolean;
}
export const noClientLevelStateFacility: IClientLevelStateFacility = {
  id: '',
  name: '',
  issueCounter: '',
  isIssueCounterLoading: false,
  isConfigured: false,
};
