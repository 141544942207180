interface IGetLogPrefixParams {
  /**
   * Current Prefix: it will be pre-pended to the additional prefix
   */
  currentPrefix?: string;
  /**
   * ANSI Escape sequence (for styling)
   */
  ansiEscapeSequence?: string;
  /**
   * message to be added to the prefix
   */
  message: string;
}

// Stop the previous style (if any) so, that if prefixes are being
// composed then styles of previous parts of the prefix are not carried over.
const stopPreviousStyle = '\x1b[0m';

/**
 * Get a log prefix string chaining the different components names
 */
const getLogPrefix = ({ currentPrefix = '', ansiEscapeSequence, message }: IGetLogPrefixParams) => {
  // strip trailing spaces and "=>"
  // NOTE: this is done to uniform the prefix when a component passes
  // down as component name a string in the format "component_1 - component_2 =>"
  let prefix = currentPrefix.replace(/=>+$/, '').replace(/\s+$/, '');
  prefix = prefix ? `${prefix} - ` : ''; // add the trailing dash "-" if the current prefix is not empty
  const style = ansiEscapeSequence || '';
  prefix = `${prefix}${style}${message}${stopPreviousStyle} =>`;
  return prefix;
};

/**
 * Font style for the console log
 */
enum sgrFontStyle {
  NORMAL = '',
  BOLD = ';1',
  ITALIC = ';3',
  UNDERLINE = ';4',
}

/**
 * Interface with the parameters for the getAnsiEscapeSequence function.
 */
interface IGetAnsiEscapeSequenceParams {
  /**
   * Foreground color
   */
  foregroundColor?: ansiForegroundColors | { red: number; green: number; blue: number };
  backgroundColor?: ansiBackgroundColors | { red: number; green: number; blue: number };
  /**
   * Font style
   */
  fontStyle?: sgrFontStyle;
}
/**
 * Generate a SGR style string (to be used in the log prefix)
 * refer to https://simplernerd.com/js-console-colors/ for more details
 */
const getAnsiEscapeSequence = ({
  foregroundColor,
  backgroundColor,
  fontStyle = sgrFontStyle.NORMAL,
}: IGetAnsiEscapeSequenceParams) => {
  let colorString = '';
  if (foregroundColor && typeof foregroundColor === 'number') {
    colorString = foregroundColor.toString();
  } else if (foregroundColor && typeof foregroundColor !== 'number') {
    // not null or number => is RGB
    // NOTE: the prefix for RGB foreground color is 38,2
    colorString = `38;2;${foregroundColor.red};${foregroundColor.green};${foregroundColor.blue}`;
  }
  if (foregroundColor && backgroundColor) {
    // add the ";" between the two color definition
    colorString += ';';
  }
  if (backgroundColor && typeof backgroundColor === 'number') {
    colorString += backgroundColor;
  } else if (backgroundColor && typeof backgroundColor !== 'number') {
    // not null or number => is RGB
    // NOTE: the prefix for RGB background color is 48,2
    colorString = `48;2;${backgroundColor.red};${backgroundColor.green};${backgroundColor.blue}`;
  }
  // Compose the new style
  const newStyle = `\x1b[${colorString}${fontStyle}m`;
  return `${stopPreviousStyle}${newStyle}`;
};

/**
 * Remove ANSI Escape Sequences from the given string
 * @param sequence sequence to be stripped of ANSI Escape Sequences
 * @returns a string without ANSI Escape Sequences
 */
export const removeAnsiEscapeSequences = (sequence: string) =>
  sequence.replace(
    // eslint-disable-next-line no-control-regex
    /[\u001b\u009b][[()#;?]*(?:[0-9]{1,4}(?:;[0-9]{0,4})*)?[0-9A-ORZcf-nqry=><]/g,
    '',
  );

enum ansiBasicColors {
  RED = 1,
  GREEN = 2,
  BLUE = 4,
  CYAN = 6,
}
/**
 * Starting value for the Ansi basic palette for foreground
 */
const ANSI_FG_COLOR_BASIC_BASE = 30;
/**
 * Starting value for the ANSI bright palette for foreground
 */
const ANSI_FG_COLOR_BRIGHT_BASE = 90;

/**
 * ANSI foreground color palette (Basic + Bright)
 */
enum ansiForegroundColors {
  RED = ANSI_FG_COLOR_BASIC_BASE + ansiBasicColors.RED,
  GREEN = ANSI_FG_COLOR_BASIC_BASE + ansiBasicColors.GREEN,
  CYAN = ANSI_FG_COLOR_BASIC_BASE + ansiBasicColors.CYAN,
  BRIGHT_RED = ANSI_FG_COLOR_BRIGHT_BASE + ansiBasicColors.RED,
  BRIGHT_BLUE = ANSI_FG_COLOR_BRIGHT_BASE + ansiBasicColors.BLUE,
}

/**
 * Starting value for the Ansi basic palette for foreground
 */
const ANSI_BG_COLOR_BASIC_BASE = 40;

/**
 * ANSI background color palette (Basic + Bright)
 */
enum ansiBackgroundColors {
  RED = ANSI_BG_COLOR_BASIC_BASE + ansiBasicColors.RED,
}

/**
 * Selection of named HTML color in RGB format
 * see https://www.w3schools.com/colors/colors_names.asp
 */
const HTML_COLORS_RGB = {
  DARK_ORCHID: { red: 153, green: 50, blue: 204 },
  DODGER_BLUE: { red: 30, green: 144, blue: 255 },
  DARK_ORANGE: { red: 255, green: 148, blue: 0 },
};

/**
 * Return a log prefix string formatted for the given component.
 * @param type type of the component using the logger
 * @param message message to be added to the prefix
 * @param currentPrefix current (inherited) prefix
 * @returns a log prefix string.
 */
export const getLogPrefixForType = (
  type: 'ROUTE' | 'PAGE' | 'HOOK' | 'FUNCTION' | 'STORE' | 'COMPONENT' | 'TOPIC' | 'CLASS',
  message: string,
  currentPrefix?: string,
) => {
  const logPrefixParams: IGetLogPrefixParams = {
    message,
    currentPrefix,
  };

  switch (type) {
    case 'ROUTE':
      logPrefixParams.ansiEscapeSequence = getAnsiEscapeSequence({
        fontStyle: sgrFontStyle.BOLD,
      });
      break;
    case 'PAGE':
      logPrefixParams.ansiEscapeSequence = getAnsiEscapeSequence({
        foregroundColor: HTML_COLORS_RGB.DARK_ORCHID,
      });
      break;
    case 'HOOK':
      logPrefixParams.ansiEscapeSequence = getAnsiEscapeSequence({
        foregroundColor: ansiForegroundColors.GREEN,
        fontStyle: sgrFontStyle.ITALIC,
      });
      break;
    case 'FUNCTION':
      logPrefixParams.ansiEscapeSequence = getAnsiEscapeSequence({
        foregroundColor: HTML_COLORS_RGB.DODGER_BLUE,
        fontStyle: sgrFontStyle.BOLD,
      });
      break;
    case 'STORE':
      logPrefixParams.ansiEscapeSequence = getAnsiEscapeSequence({
        foregroundColor: HTML_COLORS_RGB.DARK_ORANGE,
        fontStyle: sgrFontStyle.BOLD,
      });
      break;
    case 'COMPONENT':
      logPrefixParams.ansiEscapeSequence = getAnsiEscapeSequence({
        foregroundColor: ansiForegroundColors.CYAN,
        fontStyle: sgrFontStyle.UNDERLINE,
      });
      break;
    case 'TOPIC':
      logPrefixParams.ansiEscapeSequence = getAnsiEscapeSequence({
        foregroundColor: ansiForegroundColors.BRIGHT_BLUE,
        fontStyle: sgrFontStyle.BOLD,
      });
      break;
    case 'CLASS':
      logPrefixParams.ansiEscapeSequence = getAnsiEscapeSequence({
        foregroundColor: ansiForegroundColors.BRIGHT_RED,
        fontStyle: sgrFontStyle.UNDERLINE,
      });
      break;
    default:
      break;
  }

  return getLogPrefix(logPrefixParams);
};
