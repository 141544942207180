import { ILocationDataST, IWMSSlotStatusST } from 'codegen/warehouse_status';
import { findLast } from 'lodash';

export const shouldBeAtValueGetter = (row: ILocationDataST): string => {
  const issue = row.issues && row.issues[0];
  const slotStatus: IWMSSlotStatusST | null =
    issue && issue.wms_slot_status_with_verity_bc !== 'string'
      ? (issue.wms_slot_status_with_verity_bc as IWMSSlotStatusST)
      : null;

  const clientOverride =
    issue && issue.slot_status.verity_status?.user_overrides
      ? findLast(issue.slot_status.verity_status?.user_overrides, (uo) => !uo.review)
      : null;

  return !clientOverride && slotStatus?.slot_label ? slotStatus.slot_label : '';
};
