import { gridFilteredSortedRowIdsSelector, gridRowTreeSelector } from '@mui/x-data-grid-premium';
import { GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium';
import { getRowForFullReportTable } from 'common/functions/locationRows/locationRowsFunctions';

export const getFilteredLocationsFromGridApi = (
  gridApiRef: React.MutableRefObject<GridApiPremium>,
) => {
  const tree = gridRowTreeSelector(gridApiRef);

  const filteredLocations = gridFilteredSortedRowIdsSelector(gridApiRef)
    .filter((rowId) => tree[rowId].type !== 'group') // removes the grouping rows
    .map((rowId) => gridApiRef.current.getRow(rowId)) // mapsrow ids to rows
    .map((row) => getRowForFullReportTable(row.slot_label, row, row.issues)); // transform into flattened data

  return filteredLocations;
};
