import { useState, useEffect, useCallback } from 'react';
import { useNavigate, useLocation, useParams, useSearchParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { changeTab } from 'common/functions/navigationFunctions';
import { NETWORK_REQUEST_SETTINGS, SHOW_PAGE_HEADER_MENU_ICON } from 'common/settings';
import { REPORT_STATES } from 'common/reportStates';
import * as tables from 'common/Tables';
import { OrderValue } from 'components/EnhancedTable/types/rows';
import { Spinner } from 'components/common/Spinner';
import { PageHeaderSection } from 'components/Page/PageHeaderSection';
import { IReportST } from 'codegen/report';
import { useAlert, useRequestController } from 'hooks';
import { PERMISSION } from 'features/permissions/permissions.model';
import { userHasPermission } from 'features/permissions/userHasPermission';
import { useFacilityLevelStore } from 'store/FacilityLevelStore/facilityLevelStore';
import { useClientModalsStore, useFacilityModalsStore } from 'store/Modals';
import { ClientModalsActionTypes } from 'store/Modals/types';
import { useSettingsStore } from 'store/Settings/settingsStore';
import { useUserLevelStore } from 'store/UserLevelStore/userLevelStore';
import { ILocationReportData } from 'udb/inventory/features/warehouse-status/warehouseStatus.model';
import { useGetTabData } from './utils/GetTabData';
import { getReportSummary as getReportSummaryFunc } from './utils/GetReportSummary';
import { getReport as getReportFunc } from './utils/GetReport';
import { approveSlotsStatus as approveSlotsStatusFunc } from './utils/ApproveSlotsStatus';
import { useSetTabs } from './utils/SetTabs';
import { CustomReportButton } from './CustomReportButton';
import { ReportPage } from './ReportPage';
import { useReportContainerReducer } from './reducer/useReportContainerReducer';
import { ReportContainerActionNames } from './reducer/ReportContainerActions';
import { handleLocationModalOpening } from './utils/HandleLocationModalOpening';
import { handleAmendModalOpening } from './utils/HandleAmendModalOpening';
import { reportStore } from '../../reducer/report-store/ReportStore';
import { ReportSummary } from '../../reducer/report-store/ReportStore.model';
import { REPORT_SPEC_NAME } from '../../../../../../common/reportSpecifications';
/**
 * Report container
 * @returns component
 */
export const ReportContainer = () => {
  const location = useLocation();

  const [searchParams] = useSearchParams();

  const { REPORTS_FOR_REVIEW_TABLE_TABS } = useSettingsStore();

  const { reviewLocationsTab } = tables.reviewReportTabsMappings(REPORTS_FOR_REVIEW_TABLE_TABS);

  const [activeTab, setActiveTab] = useState(Number(searchParams.get('activeTab')) || 0);

  const [
    {
      fullReportSpinner,
      tableSpinner,
      issuesSpinner,
      summarySpinner,
      spinner,
      issues,
      amended,
      snoozed,
      potential,
      inconclusive,
      invalid,
      fullReport,
      pageSubtitle,
      reportLoaded,
      issuesSummary,
      reportLocationData,
      reportExecutionData,
      reportState,
      reportName,
      reportSpecName,
      locationsToReview,
      reportNeedsReview,
      reportNumLocations,
      rawReportData,
      isDownloading,
      requestsToSend,
      repliesReceived,
      allRequestsFinished,
      displayRefreshOption,
    },
    dispatch,
  ] = useReportContainerReducer();

  const archivedReportNote =
    reportState === REPORT_STATES.DELETED ? 'Locations cannot be edited for archived reports.' : '';

  const { enqueueSnackbar } = useSnackbar();

  const { alertState, showAlert } = useAlert();

  const navigate = useNavigate();
  const { stateUserLevel } = useUserLevelStore();
  const { stateFacilityLevel } = useFacilityLevelStore();
  const { dispatchClientModals } = useClientModalsStore();
  const { facilityModalsState, dispatchFacilityModals } = useFacilityModalsStore();

  const { reportId = '', systemId = '' } = useParams();
  const amendLocation = searchParams.get('amendLocation') || '';
  const orderBy = (searchParams.get('orderBy') as keyof ILocationReportData) || '';
  const order = (searchParams.get('order') as OrderValue) || '';

  const canReviewReport = userHasPermission(PERMISSION.REPORTS_FOR_REVIEW_MANAGEMENT);
  const showDownloadButton =
    !reportNeedsReview &&
    userHasPermission(PERMISSION.EXPORT_FINISHED_REPORTS) &&
    reportState === REPORT_STATES.FINISHED;

  const currentTabData = useGetTabData({
    activeTab,
    archivedReportNote,
    locationsToReview,
    issues,
    amended,
    snoozed,
    potential,
    inconclusive,
    invalid,
    fullReport,
    canReviewReport,
    reportNeedsReview,
  });

  const tabs = useSetTabs({
    canReviewReport,
    reportNeedsReview,
    numberOfLocationsToReview: locationsToReview.length,
    numberOfOtherLocations: fullReport.length,
    numberOfIssues: issues.length,
    numberOfAmended: amended.length,
    numberOfIssuesSnoozed: snoozed.length,
    numberOfIssuesPotential: potential.length,
    numberOfIssuesInconclusive: inconclusive.length,
    numberOfIssuesInvalid: invalid.length,
    numberOfFullReport: fullReport.length,
  });

  const { requestController } = useRequestController('Report');

  const getReportSummary = useCallback(
    (reportId: string) =>
      getReportSummaryFunc({
        reportId,
        dispatch,
        requestController,
        systemId,
        navigate,
      }),
    [navigate, requestController, dispatch, systemId],
  );

  const getReport = useCallback(
    (
      reportId: string,
      fullReportSummary: IReportST,
      nLocationsPerRequest: number,
      locations: Array<string>,
    ) =>
      getReportFunc({
        dispatch,
        fullReportSummary,
        locations,
        nLocationsPerRequest,
        systemId,
        reportId,
        requestController,
      }),
    [requestController, dispatch, systemId],
  );

  /**
   * Get report data: 1st getReportSummary, 2nd based on summary get report data
   */
  const getReportData = useCallback(
    (reportId: string, nLocationsPerRequest: number) => {
      dispatch({
        type: ReportContainerActionNames.SET_ANY,
        payload: { fieldName: 'fullReportSpinner', value: 1 },
      });

      getReportSummary(reportId).then((r: ReportSummary) => {
        if (!r) return;
        // Note: this try-catch is a mitigation of the general issue that promises' call-back
        // are being executed after a component has been unmounted.
        // TODO: integrate cancellable promises.
        try {
          const { fullResponse: fullReportSummary } = r;
          const { locations = [] } = fullReportSummary;
          getReport(reportId, fullReportSummary, nLocationsPerRequest, locations);
        } catch (error) {
          console.error(error);
        }
      });
    },
    [getReportSummary, dispatch, getReport],
  );

  /**
   * Send the report excel file via email
   */
  const sendReportEmail = useCallback(() => {
    requestController.doRequest({
      request: reportStore.sendReportEmail,
      requestParams: [systemId, reportId, requestController.signal],
      callbackBeforeSend: () =>
        dispatch({
          type: ReportContainerActionNames.SET_ANY,
          payload: { fieldName: 'isDownloading', value: true },
        }),
      messageSuccess: 'The report shall be in your inbox in a few minutes.',
      messageErrorFallback: 'The Report could not be sent by email.',
      callbackFinally: () =>
        dispatch({
          type: ReportContainerActionNames.SET_ANY,
          payload: { fieldName: 'isDownloading', value: false },
        }),
    });
  }, [reportId, requestController, dispatch, systemId]);

  /**
   * Get user confirmation (via dialog) to send the report excel file via email
   */
  const handleSendReportEmail = useCallback(() => {
    dispatchClientModals({
      type: ClientModalsActionTypes.TOGGLE_CONFIRM_MODAL,
      payload: {
        title: 'Send report via e-mail?',
        message: `This report will be sent to ${stateUserLevel.username} via email. The process may take a few minutes.`,
        onConfirm: sendReportEmail,
      },
    });
  }, [dispatchClientModals, sendReportEmail, stateUserLevel.username]);

  /**
   * Approve a set of Slot Statuses
   */
  const approveSlotsStatus = useCallback(
    (reviewLocations: ILocationReportData[]) =>
      approveSlotsStatusFunc({
        reviewLocations,
        requestController,
        getReportData,
        enqueueSnackbar,
        reportId,
        systemId,
        dispatch,
      }),
    [requestController, getReportData, enqueueSnackbar, reportId, systemId, dispatch],
  );

  /**
   * Update the report progress with progress that is received from the WebSocket
   */
  const updateReportProgress = useCallback(
    () =>
      dispatch({
        type: ReportContainerActionNames.UPDATE_REPORT_PROGRESS,
        payload: {
          reportId,
          reportUpdateId: stateFacilityLevel.inventory.reportUpdate?.data?.report_id,
          reportStatus: stateFacilityLevel.inventory.reportUpdate?.data?.report_state,
          reportProgress: stateFacilityLevel.inventory.reportUpdate?.data?.progress,
        },
      }),
    [stateFacilityLevel.inventory.reportUpdate, dispatch, reportId],
  );

  const handleChangeTab = (tabValue: number) =>
    changeTab({
      tabValue,
      state: location.state,
      navigate,
      setActiveTab,
    });

  /**
   * Handle opening of location modal based on url params provided
   */
  const handleModalOpening = useCallback(
    () =>
      handleLocationModalOpening({
        isModalOpened: facilityModalsState.locationModalOpened,
        searchParams,
        headCells: currentTabData.headCells,
        rows: currentTabData.rows,
        dispatchFacilityModals,
        refreshData: getReportData,
        reportId,
      }),
    [
      facilityModalsState.locationModalOpened,
      searchParams,
      currentTabData.headCells,
      currentTabData.rows,
      dispatchFacilityModals,
      getReportData,
      reportId,
    ],
  );

  /**
   * Handle opening of Amend modal based on url params provided
   */
  const handleAmendOpening = useCallback(
    () =>
      handleAmendModalOpening({
        amendLocationName: amendLocation,
        isAmendModalOpened: facilityModalsState.amendModalOpened,
        rows: currentTabData.rows,
        dispatchFacilityModals,
        refreshData: getReportData,
        orderBy,
        order,
        reportId,
      }),
    [
      amendLocation,
      currentTabData.rows,
      dispatchFacilityModals,
      facilityModalsState.amendModalOpened,
      getReportData,
      order,
      orderBy,
      reportId,
    ],
  );

  useEffect(() => {
    handleAmendOpening();
  }, [handleAmendOpening]);

  // Show warning message on the report page if all locations are not yet available for review
  useEffect(() => {
    // Special case where a report's setup resulted in  0 locations
    // For example the regular expression returns no value
    const hasNoEmptyLocations = reportNumLocations === 0;
    if (hasNoEmptyLocations) {
      showAlert.warning({
        title: 'This report’s setup resulted in 0 locations, therefore the report is empty.',
        label: 'Report',
      });
    }

    const isReportSpecEmptyLocation = reportSpecName === REPORT_SPEC_NAME.EMPTY_LOCATION;

    // Special case where an Empty Slot report results in 0 locations
    // because there where no empty locations on the area selected by the user
    if (hasNoEmptyLocations && isReportSpecEmptyLocation) {
      showAlert.warning({
        label: '',
        title: 'This report is empty.',
        message:
          'This means that the areas selected have no empty locations and therefore there was nothing to scan.',
      });
    }

    if (allRequestsFinished && reportLoaded) {
      const allLocationsAvailable =
        locationsToReview.length + fullReport.length === reportNumLocations;

      // close showReportMsg only if report’s setup did not result in 0 locations
      if (reportNeedsReview && !allLocationsAvailable) {
        showAlert.warning({
          title: 'Not all locations are available yet and further review might be needed later',
          label: 'Report',
        });
      } else if (reportNumLocations !== 0) {
        showAlert.close('Report');
      }
    }
  }, [
    allRequestsFinished,
    reportLoaded,
    fullReportSpinner,
    reportNeedsReview,
    showAlert,
    locationsToReview.length,
    fullReport.length,
    reportNumLocations,
    reportSpecName,
  ]);

  // This effect keeps track of the amount of requests
  // that have been replied (regardless of whether the reply is successful or not)
  // the purpose is to keep the page aware of whether or not the loading work is finished
  useEffect(() => {
    if (requestsToSend !== 0 && requestsToSend === repliesReceived) {
      dispatch({ type: ReportContainerActionNames.ALL_REQUESTS_FINISHED });
    }
  }, [requestsToSend, dispatch, repliesReceived]);

  useEffect(() => {
    updateReportProgress();
  }, [updateReportProgress]);

  useEffect(() => {
    if (reportId) {
      getReportData(reportId, NETWORK_REQUEST_SETTINGS.REPORT_PAGE_SIZE_FOR_FULL_REPORT_TAB);
    }
  }, [reportId, getReportData]);

  useEffect(() => {
    handleModalOpening();
  }, [handleModalOpening]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <PageHeaderSection
        title={reportName}
        subtitle={pageSubtitle}
        showMenuIcon={SHOW_PAGE_HEADER_MENU_ICON.REPORT}
        isReportPage
        customBtn={
          <CustomReportButton
            rawReportData={rawReportData}
            locationsToReview={locationsToReview}
            dispatch={dispatch}
            approveSlotsStatus={approveSlotsStatus}
            reportState={reportState}
            canReviewReport={canReviewReport}
            reportNeedsReview={reportNeedsReview}
            displayRefreshOption={displayRefreshOption}
            allRequestsFinished={allRequestsFinished}
            displayUpdateReportButton={userHasPermission(PERMISSION.UPDATE_REPORTS)}
            getReportData={getReportData}
            reportName={reportName}
            displayAbortReportButton={userHasPermission(PERMISSION.ABORT_ONGOING_REPORTS)}
          />
        }
        showDownloadBtn={showDownloadButton}
        onClickDownload={handleSendReportEmail}
        downloadSpinning={isDownloading}
        showLoadedSince={false}
        alert={{
          state: alertState.Report,
          label: 'Report',
          close: showAlert.close,
        }}
      />

      <ReportPage
        canReviewReport={canReviewReport}
        reportNeedsReview={reportNeedsReview}
        issuesSpinner={issuesSpinner}
        issuesSummary={issuesSummary}
        summarySpinner={summarySpinner}
        reportLocationData={reportLocationData}
        reportExecutionData={reportExecutionData}
        fullReport={fullReport}
        currentTabData={currentTabData}
        reportState={reportState}
        tabs={tabs}
        getReportData={getReportData}
        changeTab={handleChangeTab}
        activeTabRLG={activeTab}
        reviewLocationsTab={reviewLocationsTab}
        fullReportSpinner={fullReportSpinner}
        tableSpinner={tableSpinner}
        loadLocationData={() =>
          getReportData(reportId, NETWORK_REQUEST_SETTINGS.REPORT_PAGE_SIZE_FOR_FULL_REPORT_TAB)
        }
      />
      {spinner ? <Spinner fixed={true} /> : null}
    </>
  );
};
