// services & store
import { ISlotsToApproveParam } from '../interfaces/reviewInterfaces';
import { reviewServices } from '../services/ReviewServices';

/**
 * Approve the given slots
 * @param systemId system ID of the Facility
 * @param slotsToApprove slots to be approved
 * @returns promise which will resolve if the operation is successful
 */
const approveSlotsStatus = (systemId: string, slotsToApprove: ISlotsToApproveParam) =>
  reviewServices.approveSlotsStatus(systemId, slotsToApprove).then((r) => r);

export const reviewStore = { approveSlotsStatus };
