import { Box } from '@mui/material';
import { IRuleST } from 'codegen/facility_settings';
import { RulePhrase } from './RulePhrase';
import { hasCatchAllRule } from './utils/rulesFunctions';

export const RulesText = ({ rules }: { rules: IRuleST[] }) => {
  const isCatchAllRuleDefined = hasCatchAllRule(rules);

  return (
    <Box component="div">
      {rules.map((rule, index, allRules) => {
        const condition = rule.conditions?.[0];
        const key = `${condition?.field}-${condition?.operator?.type}-${condition?.operator?.value}`;
        return (
          <RulePhrase
            key={key}
            rule={rule}
            isNotFirstRule={!!index}
            isLastRule={index === allRules.length - 1}
          />
        );
      })}
      {!isCatchAllRuleDefined && (
        <RulePhrase
          rule={{
            conditions: [
              {
                field: 'LOCATION_NAME',
                operator: {
                  type: 'MATCH',
                  value: '.*',
                },
                quantifier: 'ANY',
              },
            ],
            action: {
              issue_logic: 'DEFAULT',
            },
          }}
          isNotFirstRule={true}
          isLastRule={true}
        />
      )}
    </Box>
  );
};
