// services & store
import { ISSUE_ACTIONS } from 'common/Actions/actionTypes';
import { IUserOverrideParams } from '../interfaces';
import { issueServices } from '../services/IssuesServices';

const getImages = (systemId: string, slotLabel: string, slotVersion: number, isReview = false) =>
  issueServices.getImages(systemId, slotLabel, slotVersion, isReview).then((r) => r);

// TR::TODO::2021-04-08:: Move this to a location store,
// since it overwrites locations, not issues
const userOverride = ({
  systemId,
  data,
  slot_label,
  version,
  isReview = false,
}: IUserOverrideParams) =>
  issueServices.userOverride({ systemId, data, slot_label, version, isReview }).then((r) => r);

/**
 * Snooze / Un-snooze issues
 * @param systemId System ID for the current Facility
 * @param issueId ID of the Issue
 * @param type action type (snooze/un-snooze)
 * @returns a promise which will resolve if the operation is successful
 */
const toggleSnoozeIssue = (systemId: string, issueId: string, type: ISSUE_ACTIONS) =>
  issueServices.toggleSnoozeIssue(systemId, issueId, type).then((r) => r);

export const issuesStore = {
  getImages,
  userOverride,
  toggleSnoozeIssue,
};
