import { Typography } from '@mui/material';

import { ActionsMenu } from 'components/common/ActionsMenu';
import { DroneZoneListItemProps } from './droneZoneListItem.model';
import { styles } from './droneZoneListItem.styles';

export const DroneZoneListItem = ({
  id,
  name,
  options,
  isSelected,
  actionArea,
  onClick,
}: DroneZoneListItemProps) => {
  const { classes } = styles();

  const classNames = isSelected
    ? `${classes.itemWrapper} ${classes.itemWrapperActive}`
    : classes.itemWrapper;

  return (
    <li className={classNames} aria-labelledby={`drone-zone-list-item-${id}`}>
      <button id={id} type="button" aria-label={name} className={classes.details} onClick={onClick}>
        <Typography id={`drone-zone-list-item-${id}`} className={classes.detailsName}>
          {name}
        </Typography>
      </button>

      {!!actionArea && <div className={classes.actionArea}>{actionArea}</div>}

      {!!options?.length && (
        <div className={classes.optionsArea}>
          <ActionsMenu options={options} size="small" />
        </div>
      )}
    </li>
  );
};
