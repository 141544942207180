import { IVeritySlotStatus1ST } from 'codegen/report';
import { IVeritySlotStatusStateST } from 'codegen/warehouse_status';
import { DISPLAY_VERITY_STATES } from 'common/slotStates';
import { getLatestOverwriteFromSlotStatus } from './getLatestOverwriteFromSlotStatus';

/**
 * Get the verity value from location data (to render on the screen)
 * The verity value should become the verity user value in case there is one
 * This is done so that the client user sees the human reviewed value as a system value
 * @param slotStatus
 * @returns the verity user overwrite, if it exists
 * the verity value, if it exists and there is no verity user overwrite
 * "-" , in case none of the above apply
 */

export const getVerityValueFromSlotStatus = (
  verity_status: IVeritySlotStatus1ST | string | null,
) => {
  const verityUserOverwrite = getLatestOverwriteFromSlotStatus(verity_status, 'verity');
  let valueArray: string[] = [];

  if (verity_status && typeof verity_status !== 'string') {
    if (verityUserOverwrite) {
      if (verityUserOverwrite.state === IVeritySlotStatusStateST.Barcode) {
        valueArray = verityUserOverwrite.barcodes;
      } else {
        valueArray = [DISPLAY_VERITY_STATES[verityUserOverwrite.state]];
      }
    } else if (verity_status.state === IVeritySlotStatusStateST.Barcode) {
      valueArray = verity_status.barcodes;
    } else {
      valueArray = [DISPLAY_VERITY_STATES[verity_status.state]];
    }
  }

  return valueArray;
};
