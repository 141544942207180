import { useState } from 'react';
import {
  Button,
  Card,
  useTheme,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Typography,
} from '@mui/material';
import capitalize from 'lodash/capitalize';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { Box } from 'components/common/Box';
import { baseCardStyle } from 'components/BaseCard/styles';
import { IActionButton, IInstructionsData } from './helpers';

import { connectDroneStyles } from './styles';

interface IInstructionsStepsProps {
  activeStep: IInstructionsData;
  handleDirection: (direction: string) => void;
  isInstructionsStarted: boolean;
  direction: {
    next: string;
    back: string;
  };
}

/**
 * Instructions for connecting drone to the WiFi
 */
export const InstructionsSteps = ({
  activeStep,
  isInstructionsStarted,
  handleDirection,
  direction,
}: IInstructionsStepsProps) => {
  const [helperText, setHelperText] = useState('');
  const theme = useTheme();
  const { classes: droneClasses } = connectDroneStyles();
  const { classes: baseCardClasses } = baseCardStyle();

  /**
   * Handle instruction step button action
   * @param {IActionButton} button button data
   * @param index key for displaying helperText
   */
  const handleButtonAction = (button: IActionButton, index: number) => {
    if (button.helperText) {
      setHelperText(activeStep.helperText?.[index] ?? '');
    } else {
      handleDirection(direction.next);
      setHelperText('');
    }
  };

  const mdBreakpoint = (buttons?: IActionButton[]) => {
    if (buttons?.length === 1) {
      return 'span 12 ';
    }
    if (buttons?.length === 3) {
      return 'span 4';
    }
    return 'span 6';
  };
  const xsBreakpoint = (buttons?: IActionButton[], index?: number) => {
    if (buttons?.length === 2) {
      return index === 0 ? 'span 12' : 'span 14';
    }
    return index === 0 ? 'span 12' : 'span 7';
  };

  return (
    <Card
      data-testid="c-connect-drone-to-wifi-card"
      className={droneClasses.cardWrapper}
      elevation={3}
    >
      <CardHeader
        data-testid="c-connect-drone-to-wifi-card-title"
        title="Connect drone to WiFi"
        classes={{
          title: baseCardClasses.title,
          subheader: baseCardClasses.subheader,
        }}
      />
      <Divider />
      <CardContent
        data-testid="c-connect-drone-to-wifi-card-content"
        className={droneClasses.cardContentSection}
      >
        <Box width="100%">
          <Typography
            data-testid="c-current-active-step-title"
            p={2}
            variant="body1"
            textTransform="uppercase"
          >
            {activeStep.title}
          </Typography>
          <Box width="100%" height={300} display="flex" alignItems="center" justifyContent="center">
            {activeStep.instructions?.image}
          </Box>
          <Typography p={2} color="textSecondary">
            {activeStep.instructions?.secondaryText}
          </Typography>
          <Typography p={2} sx={{ color: theme.palette.warning.main }}>
            {helperText}
          </Typography>
        </Box>
      </CardContent>
      <CardActions sx={{ width: '100%' }}>
        <Box display="grid" gridTemplateColumns="repeat(14, auto)" p={1} gap={1} width="100%">
          {isInstructionsStarted && (
            <Box
              justifyContent="space-between"
              gridColumn="span 2"
              display="flex"
              alignItems="stretch"
            >
              <Button
                data-testid="c-connect-drone-to-wifi-card-back-button"
                variant="outlined"
                size="medium"
                fullWidth={true}
                startIcon={<ArrowBackIcon sx={{ marginLeft: 1 }} />}
                onClick={() => {
                  handleDirection(direction.back);
                  setHelperText('');
                }}
              />
            </Box>
          )}
          {activeStep.buttons?.map((button: IActionButton, index: number) => (
            <Box
              key={`step-button-${button.id}`}
              gridColumn={{
                md: mdBreakpoint(activeStep.buttons),
                xs: xsBreakpoint(activeStep.buttons, index),
              }}
            >
              <Button
                data-testid="c-connect-drone-to-wifi-card-next-button"
                variant={button.variant || 'contained'}
                fullWidth={true}
                size="medium"
                endIcon={button.endIcon}
                onClick={() => handleButtonAction(button, index)}
                sx={{ textTransform: 'none' }}
              >
                {capitalize(button.label)}
              </Button>
            </Box>
          ))}
        </Box>
      </CardActions>
    </Card>
  );
};
