import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import { makeStyles } from 'tss-react/mui';
import { Theme, SelectChangeEvent } from '@mui/material';
import { Box } from '../Box';
import { ICustomSelectProps } from '../../../interfaces/inputs';

const useStyles = makeStyles()((theme: Theme) => ({
  errorText: {
    color: theme.palette.error.main,
  },
  formInput: {
    width: '100%',
  },
}));

export const CustomSelect = forwardRef(
  (props: ICustomSelectProps, ref: React.ForwardedRef<void>) => {
    const {
      variant,
      margin,
      id,
      label,
      name,
      size = 'medium',
      onChange,
      value,
      defaultValue,
      valueOptions,
      error = false,
      errorMessage = '',
      style,
      disabled,
      customClasses,
      testId = '',
      sx,
    } = props;

    const { classes } = useStyles();
    const [internalValue, setInternalValue] = useState(value);
    const clear = () => setInternalValue(() => '');

    // This is needed so that this component can expose methods to its parent components
    useImperativeHandle(ref, () => ({ clear }));

    useEffect(() => {
      setInternalValue(() => value);
    }, [value]);
    return (
      <FormControl size={size} variant={variant} margin={margin} className={classes.formInput}>
        {label && <InputLabel id={`label-for-${id}`}> {label} </InputLabel>}
        <Select
          labelId={`label-for-${id}`}
          id={id}
          disabled={disabled}
          data-testid={testId}
          name={name}
          label={label}
          value={internalValue}
          error={error}
          defaultValue={defaultValue}
          style={style}
          sx={sx}
          className={customClasses}
          onChange={(e: SelectChangeEvent<string>) => {
            setInternalValue(e.target.value);
            onChange(e); // call the onChange method received on the props
          }}
        >
          {Array.isArray(valueOptions) &&
            valueOptions?.map((el: any, index: number) => (
              <MenuItem
                disabled={el.disabled}
                value={el.value}
                key={`${el.value}-${el.label}`}
                data-testid={`${testId}-menu-item-${index}`}
                style={{
                  maxWidth: '450px',
                  whiteSpace: 'wrap',
                }}
              >
                {el.label}
              </MenuItem>
            ))}
          {
            // Note: this is to avoid ui-material rendering warning in case the select drop-down is rendered while the
            // list of the valueOptions hasn't been populated yet (e.g. it hasn't been retrieved from the back-end)
            // => in this case we do create a list with just a single value (the currently selected one).
            // This get rids of warnings of the like of:
            //    Material-UI: You have provided an out-of-range value undefined for the select component.
            //    Consider providing a value that matches one of the available options or ''. The available values are:
            !valueOptions && <MenuItem value={internalValue}>{internalValue}</MenuItem>
          }
        </Select>
        {errorMessage && (
          <Box>
            <FormHelperText className={classes.errorText} data-testid={`${testId}-helper-text`}>
              {errorMessage}
            </FormHelperText>
          </Box>
        )}
      </FormControl>
    );
  },
);

CustomSelect.displayName = 'CustomSelect';
