import { SortDirection } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import MuiTableContainer from '@mui/material/TableContainer';
import MuiTableCell from '@mui/material/TableCell';
import { useSearchParams } from 'react-router-dom';
import { Spinner } from 'components/common/Spinner';
import { EnhancedTableHead } from './HeadCell/EnhancedTableHead';
import { tableStyles } from '../styles';
import { TableCell } from './TableCell/TableCell';
import { HeadCellProp } from '../types/cell';
import { BackDropLoader } from '../../BackDropLoader';
import { ITableContainerProps } from '../types/tableContainerProps.model';

/**
 * Table container
 * @param props ITableContainerProps
 * @returns component
 */
export const TableContainer = <T extends object>({
  headCells,
  allSelected,
  rows,
  selected,
  allowSorting = true,
  isLoading,
  isDisabled = false,
  enableHoverOnRows,
  showSearch,
  onSelectAllClick,
  onRequestSort,
  onRowSelect,
  onSwitchClick,
  onRowClick,
  isSelected,
  isInputDisabled,
  getAmendingData,
  rowCount,
  refreshData,
  currentActiveSpinner,
  tableFor,
}: ITableContainerProps<T>) => {
  const [searchParams] = useSearchParams();
  const { classes } = tableStyles();
  const order = searchParams.get('order');
  const orderBy = searchParams.get('orderBy') || '';

  return (
    <MuiTableContainer sx={{ position: 'relative' }}>
      <BackDropLoader isVisible={isDisabled} showLoader={false} />
      <Table aria-labelledby="tableTitle" aria-label="enhanced sticky table" stickyHeader>
        <EnhancedTableHead
          headCells={headCells}
          numSelected={selected.length}
          allSelected={allSelected}
          order={order as SortDirection}
          orderBy={orderBy}
          onSelectAllClick={(e: React.ChangeEvent<HTMLInputElement>) => onSelectAllClick(e, rows)}
          onRequestSort={onRequestSort}
          rowCount={rowCount}
          allowSorting={allowSorting}
        />
        <TableBody className={classes.tableBody}>
          {!isLoading &&
            rows.map((row: any, index) => {
              const isItemSelected = isSelected(row.id);
              const filteredHeadCells = headCells.filter((hc: HeadCellProp) => hc?.show);

              return (
                <TableRow
                  data-testid={`c-table-row-${index}`}
                  classes={{ hover: classes.hover }}
                  className={enableHoverOnRows ? classes.tableRow : classes.hoverDisabled}
                  hover
                  role="checkbox"
                  aria-checked={isItemSelected}
                  tabIndex={-1}
                  key={`row-${row.id}`}
                  selected={isItemSelected}
                >
                  {filteredHeadCells.map((cell: HeadCellProp) => (
                    <TableCell
                      key={`${row.id}-${cell.id}`}
                      cell={cell}
                      row={row}
                      showSearch={showSearch}
                      onSwitchClick={onSwitchClick}
                      onRowClick={onRowClick}
                      onRowSelect={onRowSelect}
                      getAmendingData={getAmendingData}
                      refreshData={refreshData}
                      isItemSelected={isItemSelected}
                      isInputDisabled={isInputDisabled}
                    />
                  ))}
                </TableRow>
              );
            })}
          {isLoading ? (
            <TableRow className={classes.emptyTable}>
              <MuiTableCell
                colSpan={headCells?.length ? headCells.length + 1 : undefined}
                align="center"
                className={classes.tableCell}
              >
                <Spinner table={true} />
              </MuiTableCell>
            </TableRow>
          ) : (
            !rows.length && (
              <TableRow className={classes.emptyTable}>
                <MuiTableCell
                  colSpan={headCells?.length ? headCells.length + 1 : undefined}
                  align="center"
                  className={classes.tableCell}
                >
                  {currentActiveSpinner && !currentActiveSpinner[tableFor] ? (
                    `No ${tableFor} to show`.toUpperCase()
                  ) : (
                    <Spinner table={true} />
                  )}
                </MuiTableCell>
              </TableRow>
            )
          )}
        </TableBody>
      </Table>
    </MuiTableContainer>
  );
};
