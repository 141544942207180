import moment from 'moment-timezone';
import { TABLE_DATETIME_FORMAT_SERVICES_VERSION } from 'common/datetimeFormats';

export const renderDateForTable = (date: string, timezone?: string) => {
  if (!date || ['not available', 'N.A.', '', '-'].includes(date)) {
    return 'not available';
  }
  const unformattedDate = timezone ? moment(date).tz(timezone) : moment.utc(date);
  return unformattedDate.format(TABLE_DATETIME_FORMAT_SERVICES_VERSION);
};
