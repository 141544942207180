import { warehouseStatusReset } from './WarehouseStatusReset';
import { getActiveIssueCount } from './GetActiveIssueCount';
import { getLocationsData, getLocationsMetadata, getLocationsWithIssues } from './GetLocationsData';
import { getOccupancy } from './GetOccupancy';
import { sendWarehouseExportEmail } from './SendWarehouseExportEmail';
import { getLocationExclusionStatusHistory } from './GetLocationExclusionStatusHistory';
import { updateLocationsDataExclusionStatus } from './UpdateLocationsDataExclusionStatus';

export const WarehouseService = {
  getActiveIssueCount,
  getLocationsData,
  getLocationsMetadata,
  getLocationsWithIssues,
  getOccupancy,
  warehouseStatusReset,
  getLocationExclusionStatusHistory,
  updateLocationsDataExclusionStatus,
  sendWarehouseExportEmail,
};
