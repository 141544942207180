export const calendarDroneIcon = `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path
  d="M10 5.86V6.13601C10 6.34151 9.84428 6.51358 9.6398 6.53402L5.0398 6.99402C5.01333 6.99667 4.98667 6.99667 4.9602 6.99402L0.360199 6.53402C0.155718 6.51358 0 6.34151 0 6.13601V5.86C0 5.6545 0.155718 5.48243 0.360199 5.46199L4.9602 5.00199C4.98667 4.99934 5.01333 4.99934 5.0398 5.00199L9.6398 5.46199C9.84428 5.48243 10 5.6545 10 5.86Z"
  fill="white"
/>
<path
  d="M24 5.86V6.13601C24 6.34151 23.8443 6.51358 23.6398 6.53402L19.0398 6.99402C19.0133 6.99667 18.9867 6.99667 18.9602 6.99402L14.3602 6.53402C14.1557 6.51358 14 6.34151 14 6.13601V5.86C14 5.6545 14.1557 5.48243 14.3602 5.46199L18.9602 5.00199C18.9867 4.99934 19.0133 4.99934 19.0398 5.00199L23.6398 5.46199C23.8443 5.48243 24 5.6545 24 5.86Z"
  fill="white"
/>
<path
  fillRule="evenodd"
  clipRule="evenodd"
  d="M4.5 7.99799C4.22386 7.99799 4 8.22184 4 8.49799V9.99799C3.44772 9.99799 3 10.4457 3 10.998C3 11.5503 3.44772 11.998 4 11.998H6.25439C6.5051 12.3574 6.78868 12.6922 7.10076 12.998C6.0283 14.049 5.29253 15.4422 5.07025 17.001C4.99229 17.5477 5.4475 17.998 5.99979 17.998C6.55207 17.998 6.98936 17.5458 7.09851 17.0044C7.32309 15.8905 7.91784 14.9108 8.74889 14.199C9.72028 14.7093 10.8263 14.998 11.9997 14.998C13.1732 14.998 14.2792 14.7092 15.2506 14.1989C16.0817 14.9107 16.6765 15.8905 16.9011 17.0044C17.0102 17.5458 17.4475 17.998 17.9998 17.998C18.5521 17.998 19.0073 17.5477 18.9293 17.001C18.707 15.4422 17.9712 14.0489 16.8987 12.9979C17.2108 12.6922 17.4943 12.3574 17.745 11.998H20C20.5523 11.998 21 11.5503 21 10.998C21 10.4457 20.5523 9.99799 20 9.99799V8.49799C20 8.22184 19.7761 7.99799 19.5 7.99799H18.5C18.2239 7.99799 18 8.22184 18 8.49799V9.99799H6V8.49799C6 8.22184 5.77614 7.99799 5.5 7.99799H4.5Z"
  fill="white"
/>
</svg>`;
