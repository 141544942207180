import isEmpty from 'lodash/isEmpty';

import { ISSUE_TYPES } from 'common/issueTypesAndStates';
import {
  getRowForFullReportTable,
  getRowAndSingleIssueForIssueTable,
} from 'common/functions/locationRows/locationRowsFunctions';
import {
  ISSUE_TAB_ALLOWED_DATA,
  SOLVED_TAB_ALLOWED_DATA,
  SNOOZED_TAB_ALLOWED_DATA,
  POTENTIAL_TAB_ALLOWED_DATA,
  INCONCLUSIVE_TAB_ALLOWED_DATA,
  INVALID_TAB_ALLOWED_DATA,
} from 'common/tabDataDefinitions';
import { ILocationDataST, ILocationsMetaDataGetResponseST } from 'codegen/warehouse_status';
import { ILocationReportData } from 'udb/inventory/features/warehouse-status/warehouseStatus.model';
import { getLatestOverwriteFromSlotStatus } from 'common/functions/slot/utils/getLatestOverwriteFromSlotStatus';
import { IActiveIssueCount, IFacility } from '../interfaces';
import { facilityServices } from '../services/FacilityServices';
import { warehouseServices } from '../services/WarehouseServices';

/**
 * Get active issue count
 * @param systemId system ID of the facility
 * @param signal Abort Signal
 * @returns fullResponse - route raw response
 *  warehouseStatus - data for the status card
 */
const getActiveIssueCount = (systemId: string, signal: AbortSignal): Promise<IActiveIssueCount> =>
  warehouseServices.getActiveIssueCount(systemId, signal).then((r) => {
    const issuesCount = r.data.issue_count;

    const foundDifferentBarcode =
      issuesCount.totals[ISSUE_TYPES.WMS_BARCODE_NOT_EQUAL_VERITY_BARCODE];
    const emptyInsteadOfBarcode = issuesCount.totals[ISSUE_TYPES.WMS_BARCODE_VERITY_EMPTY];
    const barcodeInsteadOfEmpty = issuesCount.totals[ISSUE_TYPES.WMS_EMPTY_VERITY_BARCODE];
    const nonEmptyNoBarcodeInsteadOfEmpty =
      issuesCount.totals[ISSUE_TYPES.WMS_EMPTY_VERITY_NOTEMPTY];
    const totalIssues =
      foundDifferentBarcode +
      emptyInsteadOfBarcode +
      barcodeInsteadOfEmpty +
      nonEmptyNoBarcodeInsteadOfEmpty;

    // ######################################################
    // Data for the Warehouse status card
    // ######################################################
    const warehouseStatus = {
      id: 'warehouse-status',
      title: 'Warehouse status',
      counter: totalIssues,
      counterSubject: 'issues',
      subtitle: 'An overview of the current issues in the warehouse.',
      sections: [
        {
          id: 'expected-barcode',
          title: 'Expected to find barcode and:',
          sectionItems: [
            {
              id: 'different-barcode',
              label: 'found a different barcode',
              value: foundDifferentBarcode.toString(),
            },
            {
              id: 'empty-barcode',
              label: 'found an empty location',
              value: emptyInsteadOfBarcode.toString(),
            },
          ],
        },
        {
          id: 'expected-empty',
          title: 'Expected to find empty location and:',
          sectionItems: [
            {
              id: 'barcode-on-empty',
              label: 'found a barcode',
              value: barcodeInsteadOfEmpty.toString(),
            },
            {
              id: 'missing-barcode-on-empty',
              label: 'found non-empty location, with no barcode',
              value: nonEmptyNoBarcodeInsteadOfEmpty.toString(),
            },
          ],
        },
      ],
    };

    return {
      fullResponse: r,
      warehouseStatus,
    };
  });

/**
 * GET locations data
 * @param systemId system ID of the facility
 * @param fromSlot the name of the slot from where to start the list
 * @param nLocations the number of location to retrieve, starting from fromSlot
 * @param signal Request Controller
 * @returns dictionary with requested locations
 */
const getLocationsData = (
  systemId: string,
  fromSlot: string,
  nLocations: number,
  signal: AbortSignal,
) =>
  warehouseServices.getLocationsData(systemId, fromSlot, nLocations, signal).then((r) => ({
    locationsData: r.data,
  }));

/**
 * GET locations data
 * @param systemId system ID of the facility
 * @param fromSlot the name of the slot from where to start the list
 * @param nLocations the number of location to retrieve, starting from fromSlot
 * @param signal Request Controller
 * @returns dictionary with requested locations
 */
const getLocationsMetadata = (
  systemId: string,
  fromSlot: string,
  nLocations: number,
  signal: AbortSignal,
): Promise<{ locationsData: ILocationsMetaDataGetResponseST }> =>
  warehouseServices.getLocationsMetadata(systemId, fromSlot, nLocations, signal).then((r) => ({
    locationsData: r.data,
  }));

/**
 * GET facility
 * @param systemId system ID of the facility
 * @param signal Request Controller
 * @returns data structure comprising:
 * fullResponse - route raw response
 * facility - facility information
 * slots - facility slots information
 * settings - facility settings
 */
const getFacility = (systemId: string, signal: AbortSignal): Promise<IFacility> => {
  let facility: any;
  return facilityServices
    .getFacility(systemId, signal)
    .then((r) => {
      facility = {
        fullResponse: r,
        facility: r.data.facility,
        slots: r.data.slots,
      };
    })
    .then(() =>
      warehouseServices.getFacilitySettings(systemId).then((r) => {
        facility.settings = r.data.settings;
        return { ...facility };
      }),
    );
};

/**
 * GET data for full report and amended results
 * @param systemId System ID of the facility
 * @param slot id of the first slot
 * @param nLocationsPerRequest the number of locations per request
 * @param signal Axios Abort Signal
 * @returns fullReport - the full report
 * amended - the list of amended locations
 */
const loadFullReportAndAmendedLocations = (
  systemId: string,
  slot: any,
  nLocationsPerRequest: any,
  signal: AbortSignal,
) => {
  const fullReport: ILocationReportData[] = [];
  const amended: ILocationReportData[] = [];
  const issues: ILocationReportData[] = [];
  const solved: ILocationReportData[] = [];
  const snoozed: ILocationReportData[] = [];
  const inconclusive: ILocationReportData[] = [];
  const potential: ILocationReportData[] = [];
  const invalid: ILocationReportData[] = [];
  let allLocations: { [key: string]: ILocationDataST } = {};

  return getLocationsData(systemId, slot, nLocationsPerRequest, signal).then((r) => {
    allLocations = { ...allLocations, ...r.locationsData };

    Object.entries(allLocations).forEach(([location, locationData]: [string, ILocationDataST]) => {
      const rowData = getRowForFullReportTable(location, locationData, locationData.issues);

      fullReport.push(rowData);

      // Add locations for amended tab
      if (getLatestOverwriteFromSlotStatus(locationData.verity_status, 'client')) {
        amended.push(rowData);
      }

      if (!isEmpty(locationData.issues)) {
        const { rowData, singleIssueData } = getRowAndSingleIssueForIssueTable(
          location,
          locationData.issues,
        );

        // Add issues to respective tabs
        if (
          ISSUE_TAB_ALLOWED_DATA.STATES.includes(singleIssueData.state) &&
          ISSUE_TAB_ALLOWED_DATA.ISSUE_TYPES.includes(singleIssueData.type as ISSUE_TYPES)
        ) {
          issues.push(rowData);
        } else if (SOLVED_TAB_ALLOWED_DATA.includes(singleIssueData.state)) {
          solved.push(rowData);
        } else if (SNOOZED_TAB_ALLOWED_DATA.includes(singleIssueData.state)) {
          snoozed.push(rowData);
        } else if (POTENTIAL_TAB_ALLOWED_DATA.includes(singleIssueData.type as ISSUE_TYPES)) {
          potential.push(rowData);
        } else if (INCONCLUSIVE_TAB_ALLOWED_DATA.includes(singleIssueData.type as ISSUE_TYPES)) {
          inconclusive.push(rowData);
        } else if (INVALID_TAB_ALLOWED_DATA.includes(singleIssueData.type as ISSUE_TYPES)) {
          invalid.push(rowData);
        }
      }
    });

    return {
      fullReport,
      amended,
      issues,
      solved,
      snoozed,
      inconclusive,
      potential,
      invalid,
    };
  });
};

/**
 * Format the numbers for occupancy
 * @param maybeInt value
 * @param maybeIntTotal total
 * @returns value and percentage if the input are numeric
 */
const auxGetValues = (
  maybeInt: any,
  maybeIntTotal: any,
): { value: number | string; percentage: number | string } => {
  const value = Number.isInteger(maybeInt) ? maybeInt : 'n.a';
  const percentage =
    Number.isInteger(maybeInt) && Number.isInteger(maybeIntTotal)
      ? `${((maybeInt / maybeIntTotal) * 100).toFixed(1)}%`
      : 'n.a';

  return { value, percentage };
};

/**
 * Get occupancy for the given facility
 * @param systemId System ID of the facility
 * @param signal Axios Abort Signal
 * @returns Occupancy data ready to render
 */
const getOccupancy = (systemId: string, signal: AbortSignal) =>
  warehouseServices.getOccupancy(systemId, signal).then((r) => {
    const occupancy = r.data?.occupancy;

    if (isEmpty(occupancy)) {
      throw new Error('Occupancy object arrived empty @WarehouseStore.getOccupancy()');
    }

    const totalLocations = auxGetValues(occupancy.total, occupancy.total);
    const barcodeLocations = auxGetValues(occupancy.locs_with_barcode, occupancy.total);
    const emptyLocations = auxGetValues(occupancy.locs_empty, occupancy.total);
    const notEmptyNoBarcodeLocations = auxGetValues(
      occupancy.locs_not_empty_no_barcode,
      occupancy.total,
    );
    const blockedLocations = auxGetValues(occupancy.locs_blocked, occupancy.total);
    const unknownLocations = auxGetValues(occupancy.locs_unknown, occupancy.total);
    const verityCoveredLocations = auxGetValues(
      occupancy.locs_inferred_using_verity_data,
      occupancy.total,
    );

    const summaryData = {
      title: 'Occupancy summary',
      subtitle: 'An overview of the current occupancy status.',
      counter: totalLocations.value,
      counterSubject: 'locations',
      sections: [
        {
          id: 'occupancy-summary-section',
          sectionItems: [
            {
              id: 'warehousecard-barcode-locations',
              label: 'Barcode locations',
              value: `${barcodeLocations.value} (${barcodeLocations.percentage})`,
            },
            {
              id: 'warehousecard-empty-locations',
              label: 'Empty locations',
              value: `${emptyLocations.value} (${emptyLocations.percentage})`,
            },
            {
              id: 'warehousecard-not-empty-no-barcod-locations',
              label: 'Not empty, but no barcode',
              value: `${notEmptyNoBarcodeLocations.value} (${notEmptyNoBarcodeLocations.percentage})`,
            },
            {
              divider: true,
              id: 'warehousecard-covered-by-verity-locations',
              label: 'Locations covered by Verity',
              value: `${verityCoveredLocations.value} (${verityCoveredLocations.percentage})`,
            },
            {
              id: 'warehousecard-excluded-locations',
              label: 'Excluded locations',
              value: `${blockedLocations.value} (${blockedLocations.percentage})`,
            },
            {
              id: 'warehousecard-unknown-locations',
              label: 'Unknown locations',
              value: `${unknownLocations.value} (${unknownLocations.percentage})`,
            },
          ],
        },
      ],
    };

    return summaryData;
  });
/**
 * Send the warehouse report per email
 * @param systemId system ID of the facility
 * @param signal Axios Abort Signal
 * @returns Promise which will resolve if the email is successfully sent
 */
const sendWarehouseExportEmail = (systemId: string, signal: AbortSignal) =>
  warehouseServices.sendWarehouseExportEmail(systemId, signal);

/**
 * Reset the Warehouse
 * @param systemId system ID of the facility
 * @param signal Axios Abort Signal
 * @returns Promise which will resolve if the warehouse is successfully reset
 */
const warehouseStatusReset = (systemId: string, signal: AbortSignal) =>
  warehouseServices.warehouseStatusReset(systemId, signal);

export const warehouseStore = {
  warehouseStatusReset,
  getActiveIssueCount,
  getFacility,
  loadFullReportAndAmendedLocations,
  getLocationsMetadata,
  getLocationsData,
  getOccupancy,
  sendWarehouseExportEmail,
};
