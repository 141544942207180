import { IUserPostRequestSTUserGroupsEnum } from 'codegen/user_admin';
import { UserServices } from '../services/UserServices';
import { IGetUserSystemIds, IUpdateUserRestRequestParamST } from '../interfaces';

const getUserFacilities = (signal: AbortSignal) =>
  UserServices.getUserFacilities(signal).then((r) => {
    const userFacilities: IGetUserSystemIds[] = Object.entries(r.data).map((userFacility) => {
      const [systemId, facilityDetails] = userFacility;
      const client = facilityDetails?.client;
      const facility = facilityDetails?.facility;

      return { systemId, client, facility };
    });

    return userFacilities;
  });

interface IUpdateUserDataParameter extends IUpdateUserRestRequestParamST {
  user_id: string;
  email: string;
  user_groups: IUserPostRequestSTUserGroupsEnum[];
}

const updateUser = (systemId: string, data: IUpdateUserDataParameter, signal: AbortSignal) => {
  const { user_groups, user_id, ...rest } = data;

  const promise1 = UserServices.updateUserGroups(systemId, { user_id, user_groups }, signal);
  const promise2 = UserServices.updateUser(systemId, { user_id, rest }, signal);

  return Promise.all([promise1, promise2]);
};

const deleteUser = (systemId: string, data: string, signal: AbortSignal) =>
  UserServices.deleteUser(systemId, data, signal).then((r) => r);

// Get Facilities and user groups
const getFacilitiesAndGroups = (systemId: string, signal: AbortSignal) => {
  const facilitiesPromise = UserServices.getFacilities(signal).then((r) => {
    const facilities: { value: string; label: string }[] = [];
    Object.keys(r.data).forEach((key: string) => {
      const value = r.data[key];
      if (value === null) {
        facilities.push({ value: key, label: key });
      } else {
        facilities.push({ value: key, label: value.facility ?? '' });
      }
    });
    return facilities.filter((item) => item.label !== 'undefined');
  });

  const userGroupsPromise = UserServices.getUserGroups(systemId, signal).then(
    ({ data: { groups } }) =>
      groups.map((group: string) => ({
        value: group,
        label: group.replace(/([A-Z])/g, ' $1').trim(),
      })),
  );

  return Promise.all([facilitiesPromise, userGroupsPromise]).then((r) => ({
    facilities: r[0],
    groups: r[1],
  }));
};

export const userStore = {
  deleteUser,
  updateUser,
  getUserFacilities,
  getFacilitiesAndGroups,
};
