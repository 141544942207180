import { ChipPropsColorOverrides, Theme } from '@mui/material';
import { OverridableStringUnion } from '@mui/types';
import {
  IIssue1STTypeEnum as IssueType,
  IIssue1STResolvedTypeEnum as IssueResolvedType,
} from 'codegen/report';

const issueTypeEnumToStringMap: {
  [Key in AllIssueTypes]: string;
} = {
  [IssueType.Issue1WBcVEmpty]: 'Empty location',
  [IssueType.Issue2WBcNeVBc]: 'Barcode mismatch',
  [IssueType.Issue3WEmptyVBc]: 'Non-empty (barcode)',
  [IssueType.Issue4WEmptyVNotempty]: 'Non-empty (no barcode)',
  [IssueType.Issue5WBcDuplicate]: 'Duplicate barcodes',
  [IssueType.C001CMissingBc]: 'Missing Barcode',
  [IssueType.Inconclusive1WBcVNotempty]: 'No barcode',
  [IssueType.Inconclusive2WBcVNoresult]: 'No information',
  [IssueType.Inconclusive3WEmptyVNoresult]: 'No information',
  [IssueType.Inconclusive4WInvalid]: 'Invalid WMS data',
  [IssueType.Inconclusive5VInvalid]: 'Invalid Verity data',
  [IssueType.C0014DoubleDepth]: 'Double depth location',
  [IssueResolvedType.Correct1WEmptyVEmpty]: 'None',
  [IssueResolvedType.Correct2WBcEqVBc]: 'None',
  [IssueResolvedType.NoneVDataOutdated]: 'None',
  [IssueResolvedType.NoneSlotBlocked]: 'None',
  [IssueResolvedType.NoneWDataMissing]: 'None',
  [IssueResolvedType.NoneVDataMissing]: 'None',
  [IssueResolvedType.C001BCorrectWBcNaVEmpty]: 'None',
  [IssueResolvedType.C001BCorrectWBcNaVBc]: 'None',
  [IssueResolvedType.C001BCorrectWBcNaVNotempty]: 'None',
  [IssueResolvedType.NonePictureOnly]: 'Image only',
  [IssueResolvedType.Null]: 'None',
} as const;

export const issueTypeEnumToColor: {
  [Key in AllIssueTypes]: MuiColor;
} = {
  [IssueType.Issue1WBcVEmpty]: 'error',
  [IssueType.Issue2WBcNeVBc]: 'error',
  [IssueType.Issue3WEmptyVBc]: 'error',
  [IssueType.Issue4WEmptyVNotempty]: 'error',
  [IssueType.Issue5WBcDuplicate]: 'error',
  [IssueType.C001CMissingBc]: 'warning',
  [IssueType.Inconclusive1WBcVNotempty]: 'warning',
  [IssueType.Inconclusive2WBcVNoresult]: 'warning',
  [IssueType.Inconclusive3WEmptyVNoresult]: 'warning',
  [IssueType.Inconclusive4WInvalid]: 'warning',
  [IssueType.Inconclusive5VInvalid]: 'warning',
  [IssueType.C0014DoubleDepth]: 'secondary',
  [IssueResolvedType.Correct1WEmptyVEmpty]: 'default',
  [IssueResolvedType.Correct2WBcEqVBc]: 'default',
  [IssueResolvedType.NoneVDataOutdated]: 'default',
  [IssueResolvedType.NoneSlotBlocked]: 'default',
  [IssueResolvedType.NoneWDataMissing]: 'default',
  [IssueResolvedType.NoneVDataMissing]: 'default',
  [IssueResolvedType.C001BCorrectWBcNaVEmpty]: 'default',
  [IssueResolvedType.C001BCorrectWBcNaVBc]: 'default',
  [IssueResolvedType.C001BCorrectWBcNaVNotempty]: 'default',
  [IssueResolvedType.NonePictureOnly]: 'default',
  [IssueResolvedType.Null]: 'default',
};

export const getIssueType = (issue: string): { label: string; color: MuiColor } => {
  let label = 'Unknown';
  let color: MuiColor = 'info';
  if (Object.keys(issueTypeEnumToStringMap).includes(issue)) {
    const key = issue as AllIssueTypes;
    label = issueTypeEnumToStringMap[key];
    color = issueTypeEnumToColor[key];
  }
  return { label, color };
};
export const getIssueTypeColor = (issueType: string): MuiColor => {
  switch (issueType) {
    case 'Empty location':
    case 'Barcode mismatch':
    case 'Non-empty (barcode)':
    case 'Non-empty (no barcode)':
    case 'Duplicate barcodes':
      return 'error';
    case 'No barcode':
    case 'No information':
    case 'Invalid WMS data':
    case 'Invalid Verity data':
    case 'Missing Barcode':
      return 'warning';
    case 'Double depth location':
      return 'secondary';
    default:
      return 'default';
  }
};

export type AllIssueTypes = IssueType | IssueResolvedType;

export type MuiColor = OverridableStringUnion<
  'error' | 'default' | 'primary' | 'secondary' | 'info' | 'success' | 'warning',
  ChipPropsColorOverrides
>;

export const muiColorToTheme = (issueType: MuiColor, theme: Theme) => {
  switch (issueType) {
    case 'error':
      return {
        main: theme.palette.error.main,
        text: theme.palette.error.contrastText,
      };
    case 'warning':
      return {
        main: theme.palette.warning.main,
        text: theme.palette.warning.contrastText,
      };
    case 'success':
      return {
        main: theme.palette.success.main,
        text: theme.palette.success.contrastText,
      };
    case 'info':
      return {
        main: theme.palette.info.main,
        text: theme.palette.info.contrastText,
      };
    case 'secondary':
      return {
        main: theme.palette.secondary.main,
        text: theme.palette.secondary.contrastText,
      };
    case 'primary':
      return {
        main: theme.palette.primary.main,
        text: theme.palette.primary.contrastText,
      };
    case 'default':
    default:
      return {
        main: theme.palette.info.main,
        text: theme.palette.info.contrastText,
      };
  }
};
