import { GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium';
import { GridInitialStatePremium } from '@mui/x-data-grid-premium/models/gridStatePremium';
import { initialGridState } from '../defaults/initialGridState.default';

export const onLoadCustomGrid = ({
  gridName,
  activeTab,
  gridApiRef,
}: {
  gridName: string;
  activeTab: number;
  gridApiRef: React.MutableRefObject<GridApiPremium>;
}) => {
  const gridStateString = localStorage.getItem(`savedGridState-${gridName}`);

  if (gridStateString) {
    const stateObject = <Record<string, GridInitialStatePremium>>(
      JSON.parse(gridStateString ?? '{}')
    );
    const activeGridState = stateObject?.[activeTab] ?? {};

    if (Object.keys(activeGridState).length > 0) {
      gridApiRef.current.restoreState({ ...initialGridState, ...activeGridState });
    }
  }
};
