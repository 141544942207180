import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  ButtonGroup,
  IconButton,
  TextareaAutosize,
  Tooltip,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import { makeStyles } from 'tss-react/mui';
import RefreshIcon from '@mui/icons-material/Refresh';

const useCustomIssueLogicCodeEditorStyles = (isValidJson: boolean) =>
  makeStyles()(() => ({
    validationContainer: {
      display: 'flex',
      justifyContent: isValidJson === undefined ? 'flex-end' : 'space-between',
      alignItems: 'center',
      gap: '16px',
      width: '100%',
      marginTop: '16px',
    },
  }));

interface CustomIssueLogicCodeEditorProps {
  testId: string;
  json?: string;
  handleReset: () => void;
  handleCopy: () => void;
  handlePaste: () => void;
  isValidJson?: boolean;
  disabled?: boolean;
}

export const CustomIssueLogicCodeEditor = ({
  testId,
  json,
  handleCopy,
  handlePaste,
  handleReset,
  isValidJson,
  disabled = false,
}: CustomIssueLogicCodeEditorProps) => {
  const { classes } = useCustomIssueLogicCodeEditorStyles(!!isValidJson)();
  return (
    <Box
      component="div"
      display="flex"
      flexDirection="column"
      data-testid={`settings-item-content-${testId}`}
    >
      <Accordion className="customAccordion">
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography
            sx={{
              '& .MuiAccordionSummary-content': {
                display: 'flex',
                justifyContent: 'flex-end',
              },
            }}
          >
            JSON Code
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
          <ButtonGroup>
            <IconButton
              onClick={handleReset}
              disabled={disabled}
              data-testid="issue-editor-reset-button"
            >
              <Tooltip title="Reset json content to the default">
                <RefreshIcon />
              </Tooltip>
            </IconButton>
            <IconButton
              onClick={handleCopy}
              disabled={disabled}
              data-testid="issue-editor-copy-button"
            >
              <Tooltip title="Copy json content to the clipboard">
                <ContentCopyIcon />
              </Tooltip>
            </IconButton>
            <IconButton
              onClick={handlePaste}
              name="issue-editor-paste"
              disabled={disabled}
              data-testid="issue-editor-paste-button"
            >
              <Tooltip title="Paste json content from the clipboard">
                <ContentPasteIcon />
              </Tooltip>
            </IconButton>
          </ButtonGroup>

          <TextareaAutosize data-testid={testId} style={{ width: '100%' }} value={json} disabled />

          <div className={classes.validationContainer}>
            {isValidJson !== undefined && (
              <Alert severity={isValidJson ? 'success' : 'error'} sx={{ flexGrow: 1 }}>
                {isValidJson ? 'Validation success' : 'Validation failed'}
              </Alert>
            )}
          </div>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};
