import { TokenManager } from 'common/tokenManager';
import { getLogPrefixForType } from 'common/functions/logFunctions';
import {
  WebSocketClient,
  WebSocketReadyState,
  WebSocketSubscriber,
  WebSocketSubscription,
} from './web-socket.model';

const logPrefix = getLogPrefixForType('FUNCTION', 'WebSocket');

let socket: WebSocket | null;
const subscribers: WebSocketSubscriber[] = [];

export const webSocket = (): WebSocketClient => {
  const onOpen = (): void => {
    const tokenManager = TokenManager.getInstance();
    const accessToken = tokenManager.getAccessToken();
    if (accessToken && socket) {
      console.debug(logPrefix, 'WebSocket connection opened, sending access token');
      socket.send(
        JSON.stringify({
          topic: 'authorization',
          data: { access_token: accessToken },
        }),
      );
    } else {
      console.error(logPrefix, 'Can not open connection - No access token found');
    }
  };

  const publishToSubscribers = (channel: string, data: any) => {
    const subs = subscribers.filter((s) => s.channel === channel);
    subs.forEach((s) => s.onMessage(data, channel));
  };

  const onMessage = (event: any): void => {
    let parsedEvent = null;
    try {
      parsedEvent = JSON.parse(event.data);
      publishToSubscribers(parsedEvent.topic, parsedEvent.data);
    } catch {
      console.error('WebSocket: Error loading message data');
    }
  };

  const disconnect = () => {
    if (socket && socket.readyState === WebSocketReadyState.Open) {
      socket.onclose = () => null;
      socket.close();
      socket = null;
    }
  };

  const connect = () => {
    if (socket) return;

    console.debug(logPrefix, 'WebSocket: opening connection');
    const webSocketUrl = new URL(import.meta.env.VITE_APP_WS_API_ENDPOINT as string);
    socket = new WebSocket(webSocketUrl);
    socket.onopen = onOpen;
    socket.onmessage = onMessage;
    // both functions seem to have cyclical deps with connect
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    socket.onerror = onError;
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    socket.onclose = onClose;

    window.onbeforeunload = () => {
      console.debug(logPrefix, 'WebSocket: closing connection');
      disconnect();
    };
  };
  const onError = (error: any): void => {
    console.error('WebSocket Error:', error);
    if (socket) {
      socket.close();
      socket = null;
      setTimeout(connect, 5000);
    }
  };

  const onClose = (event: any): void => {
    console.debug('WebSocket Closed:', event);
    setTimeout(connect, 5000);
  };

  const subscribe = (subscriber: WebSocketSubscriber) => {
    if (
      !subscribers.find(
        (s) => s.channel === subscriber.channel && s.delegate === subscriber.delegate,
      )
    ) {
      subscribers.push(subscriber);
    }
  };

  const unsubscribe = (unsubscriber: WebSocketSubscription) => {
    const index = subscribers.findIndex(
      (s) => s.channel === unsubscriber.channel && s.delegate === unsubscriber.delegate,
    );
    if (index !== -1) {
      subscribers.splice(index, 1);
    }
  };

  const unsubscribeAll = (unsubscriber: Record<string, WebSocketSubscription>) => {
    Object.keys(unsubscriber).forEach((key: string) => {
      unsubscribe(unsubscriber[key]);
    });
  };

  return Object.freeze({
    connect,
    disconnect,
    subscribe,
    unsubscribe,
    unsubscribeAll,
  });
};
