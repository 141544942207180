import moment from 'moment';
import { createElement } from 'react';
import capitalize from 'lodash/capitalize';
import { COMMISSIONING_TASK_ACTIONS } from 'common/Actions/actionTypes';
import { CommissioningTask, CommissioningTaskState } from '../model/commissioningTask.model';
import { CommissioningTaskProgressIndicator } from '../features/CommissioningTaskProgressIndicator';
import { CommissioningTaskTableRow } from '../model/commissioningTaskTableRow.model';
import { dateToTableFormat } from '../utils/dateToTableFormat.utils';

const taskHasAbortAction = (state: CommissioningTaskState) =>
  state === CommissioningTaskState.CREATED || state === CommissioningTaskState.ONGOING;

export const transformTasksToTableRows = (
  commissioningTasks: CommissioningTask[],
  system_id: string,
  abortCommissioningTasksCallback: (commissioningTask: CommissioningTask) => void,
): CommissioningTaskTableRow[] =>
  commissioningTasks.map((task) => {
    const timeElapsed =
      task.finishedAt && task.startedAt
        ? moment
            .utc(moment.duration(task.finishedAt.diff(task.startedAt)).asMilliseconds())
            .format('HH:mm:ss')
        : '';

    const progress =
      task.totalLocations !== 0 ? (task.finishedLocations / task.totalLocations) * 100 : 100;

    const progressIndicator = createElement(CommissioningTaskProgressIndicator, {
      value: progress,
      state: task.state,
    });
    return {
      id: task.id,
      state: task.state === CommissioningTaskState.CREATED ? 'Upcoming' : capitalize(task.state),
      progressIndicator,
      task: task.name,
      started: task.startedAt ? dateToTableFormat(task.startedAt) : '',
      objectives: `${task.finishedLocations}/${task.totalLocations}`,
      elapsed_time: timeElapsed,
      finished: task.finishedAt ? dateToTableFormat(task.finishedAt) : '',
      actions: taskHasAbortAction(task.state)
        ? {
            data: {
              id: task.id,
              system_id,
              abortTaskFunction: () => abortCommissioningTasksCallback(task),
            },
            actions: [
              {
                label: COMMISSIONING_TASK_ACTIONS.ABORT.action,
                disabled: false,
              },
            ],
          }
        : undefined,
    };
  });
