import { AxiosPromise } from 'axios';
import { IExclusionRequestResponseST } from 'codegen/warehouse_status';
import { initWarehouseStatusService } from './warehouseStatusService';

export type GetLocationExclusionStatusHistory = {
  systemId: string;
  exclusionRequestId: string;
  signal: AbortSignal;
};

/**
 * Get detailed information about the exclusion status change
 * @param params GetLocationExclusionStatusHistory
 * @returns AxiosPromise<IExclusionRequestResponseST>
 */
export const getLocationExclusionStatusHistory = ({
  systemId,
  exclusionRequestId,
  signal,
}: GetLocationExclusionStatusHistory): AxiosPromise<IExclusionRequestResponseST> =>
  initWarehouseStatusService().getLocationExclusionStatusHistory(systemId, exclusionRequestId, {
    signal,
  });
