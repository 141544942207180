import { AlertColor } from '@mui/material';
import moment from 'moment-timezone';
import { useEffect } from 'react';
import { BaseCard, IBaseCardProps } from 'components/BaseCard/BaseCard';
import { BaseCardSimpleItem } from 'components/BaseCard/BaseCardSimpleItem';
import { AlertComponent } from 'components/common/Alert';
import { LocalStore } from 'common/functions/storageFunctions';
import { IDroneOperatingHoursST } from 'codegen/nav_simulation';
import { GroundControlActionNames } from 'store/GroundControl/groundControlLevelStore.model';
import { useGroundControlStore } from '../../../../store/GroundControl/groundControlLevelStore';

interface IDroneShiftsCardProps {
  title: IBaseCardProps['title'];
  subtitle: IBaseCardProps['subtitle'] | Element;
  testId: string;
  counter: string;
  counterSubject: string;
  actionButtons: any;
  sections: any;
}

export const DroneShiftsCard = ({
  droneShiftsData,
  noData,
}: {
  droneShiftsData: IDroneShiftsCardProps | null;
  noData: boolean;
}) => {
  const { stateGroundControl, dispatchGroundControlLevel } = useGroundControlStore();
  const timezone = LocalStore.getTimezone();

  const { isOpened, message } = stateGroundControl.alertInfo.flightHoursCard;

  const currentShiftStart = (
    stateGroundControl.flightDomain.flight_domain_status
      ?.current_drone_operating_hours as IDroneOperatingHoursST
  )?.start;
  const currentShiftEnd = (
    stateGroundControl.flightDomain.flight_domain_status
      ?.current_drone_operating_hours as IDroneOperatingHoursST
  )?.end;

  const isCurrentShiftStarted =
    currentShiftStart && moment(currentShiftStart).tz(timezone).isBefore(moment.tz(timezone));

  const isCurrentShiftEnded =
    currentShiftStart && moment(currentShiftEnd).tz(timezone).isBefore(moment.tz(timezone));

  useEffect(() => {
    if (isCurrentShiftStarted) {
      dispatchGroundControlLevel({
        type: GroundControlActionNames.SET_ALERT_INFO,
        payload: {
          key: 'flightHoursCard',
          variant: stateGroundControl.activeAlertVariant,
          message: 'Drone flight hours have started.',
        },
      });
    }

    if (isCurrentShiftEnded) {
      dispatchGroundControlLevel({
        type: GroundControlActionNames.SET_ALERT_INFO,
        payload: {
          key: 'flightHoursCard',
          variant: stateGroundControl.activeAlertVariant,
          message: 'Drone flight hours have ended.',
        },
      });
    }
  }, [
    dispatchGroundControlLevel,
    isCurrentShiftEnded,
    isCurrentShiftStarted,
    stateGroundControl.activeAlertVariant,
  ]);

  return (
    <BaseCard
      cardFor={(droneShiftsData?.title as string) || ''}
      customEmptyState="No flight hours scheduled."
      dataTestId={droneShiftsData?.testId}
      title={droneShiftsData?.title || ''}
      subtitle={droneShiftsData?.subtitle as string}
      counter={droneShiftsData?.counter}
      counterSubject={!noData ? droneShiftsData?.counterSubject : ''}
      actionButtons={droneShiftsData?.actionButtons}
      noData={noData}
      alertElement={
        <AlertComponent
          isActive={!noData && Boolean(currentShiftStart) && isOpened}
          icon={false}
          status={stateGroundControl.activeAlertVariant as AlertColor}
          message={message || ''}
          handleClose={() =>
            dispatchGroundControlLevel({
              type: GroundControlActionNames.SET_ALERT_INFO,
              payload: {
                key: 'flightHoursCard',
              },
            })
          }
          preventClose={false}
          testId="c-flight-hours-card-info-box"
        />
      }
    >
      {droneShiftsData?.sections
        .filter((i: any) => i.isVisible)
        .map((item: any) => (
          <div key={`item-${item.sectionTitle}`}>
            <BaseCardSimpleItem
              sectionItems={item.sectionItems}
              title={item.sectionTitle}
              noData={noData}
            />
          </div>
        ))}
    </BaseCard>
  );
};
