import { ILocationDataST } from 'codegen/warehouse_status';
import { getUnknownBarcodes, getFoundBarcodes } from '../barcodeFunctions';
import { emptyCellString } from './collectionDelayValueGetter';

export const unknownBarcodesPercentageValueGetter = (row: ILocationDataST): number | '-' => {
  const unknown = getUnknownBarcodes(row);
  const found = getFoundBarcodes(row);

  return unknown && found ? unknown.length / found.length : emptyCellString;
};
