import { Chip, Tooltip } from '@mui/material';
import { getIssueTypeColor } from 'common/functions/issues/issueColorFunctions';

type IssueCellProps = {
  /**
   * Type of the issue
   */
  issueType: string;
};

/**
 * Component rendering the content of a Data Grid Cell
 * containing the type of an issue
 * @param props
 * @returns JSX rendering of the component
 */
export const IssueCell = (props: IssueCellProps) => {
  if (props.issueType === '-' || props.issueType === 'No Issue') {
    return (
      <Tooltip className="c-data-grid-cell" title="No Issue" sx={{ cursor: 'default' }}>
        <Chip
          sx={{
            borderRadius: '5px',
          }}
          size="small"
          variant="outlined"
          color="info"
          label="No Issue"
        />
      </Tooltip>
    );
  }

  return (
    <Tooltip title={props.issueType} className="c-data-grid-cell">
      <Chip
        sx={{
          borderRadius: '5px',
        }}
        size="small"
        color={getIssueTypeColor(props.issueType)}
        label={props.issueType}
      />
    </Tooltip>
  );
};
