/**
 * Validate the password
 * @param value Password value
 * @returns Error text
 */
export const validatePassword = (value?: string) => {
  // RegEx
  const number = /\d/;
  const lowerCase = /[a-z]/;
  const upperCase = /[A-Z]/;
  const specialCharacter = /.*[=+\-^$*.[\]{}()?"!@#%&/\\,><':;|_~`]/;

  let errorText = '';

  if (value && value.length < 10) {
    errorText = 'Minimum password length is 10 characters';
  } else if (value && !number.test(value)) {
    errorText = 'Password must contain at least one number';
  } else if (value && !lowerCase.test(value)) {
    errorText = 'Password must contain at least one lowercase letter';
  } else if (value && !upperCase.test(value)) {
    errorText = 'Password must contain at least one uppercase letter';
  } else if (value && !specialCharacter.test(value)) {
    errorText = 'Password must contain at least one special character';
  }

  return errorText;
};
