import { useMemo } from 'react';
import { IVeritySlotStatusST } from 'codegen/location_information';
import { NodeST } from 'shared/map-container/MapContainer.model';
import { groupBy } from 'lodash';
import { ILocationData1ST } from 'codegen/report';
import { Box3, Vector3 } from 'three';
import { ISSUE_TYPES } from 'common/issueTypesAndStates';
import { Bin3DProps, Vec6 } from '../../bin3D/model/bin3DProps.model';
import { AisleViewProps } from '../model/aisleViewProps.model';

const SCALE_FACTOR = 0.95;

const flattenNodes = (node: NodeST): NodeST[] => {
  let result: NodeST[] = [node];

  if (node.nodes) {
    node.nodes.forEach((childNode) => {
      result = result.concat(flattenNodes(childNode));
    });
  }

  return result;
};

function calculateDimensions(vec6: Vec6) {
  const min = new Vector3(vec6[0], vec6[1], vec6[2]);
  const max = new Vector3(vec6[3], vec6[4], vec6[5]);
  const size = new Vector3();
  new Box3(min, max).getSize(size);
  return size;
}

function calculatePosition(vec6: Vec6) {
  return new Vector3((vec6[0] + vec6[3]) / 2, (vec6[1] + vec6[4]) / 2, (vec6[2] + vec6[5]) / 2);
}

function findNodesByChildName(
  root: NodeST,
  targetType: NodeST['type'],
  childName: string,
): NodeST | null {
  let result = null;

  function hasDescendantWithName(node: NodeST, name: string): boolean {
    if (node.name === name) {
      return true;
    }
    if (node.nodes) {
      return node.nodes.some((child) => hasDescendantWithName(child, name));
    }
    return false;
  }

  function dfs(node: NodeST): void {
    if (node.type === targetType) {
      if (hasDescendantWithName(node, childName)) {
        result = node;
      }
    }
    if (node.nodes) {
      node.nodes.forEach(dfs);
    }
  }

  dfs(root);
  return result;
}

const getLocationsBy = <T>(predicate: (a: T) => boolean, flatLocations: T[]) =>
  groupBy(flatLocations.filter(predicate), 'slot_label');

const count = (locations: Record<string, unknown>) => Object.keys(locations).length;

const ISSUE_MASK: string[] = [
  ISSUE_TYPES.WMS_BARCODE_VERITY_EMPTY,
  ISSUE_TYPES.WMS_BARCODE_NOT_EQUAL_VERITY_BARCODE,
  ISSUE_TYPES.WMS_EMPTY_VERITY_BARCODE,
  ISSUE_TYPES.WMS_EMPTY_VERITY_NOTEMPTY,
  ISSUE_TYPES.WMS_BARCODE_DUPLICATE,
];

const POTENTIAL_ISSUE_MASK: string[] = [
  ISSUE_TYPES.INCONCLUSIVE_WMS_BARCODE_VERITY_NOTEMPTY,
  ISSUE_TYPES.C001C_MISSING_BC,
];

export function useAisleView({
  facilityMap,
  allLocations,
  currentLocationName,
}: {
  allLocations?: ILocationData1ST[];
  facilityMap?: NodeST;
  currentLocationName: string;
}) {
  return useMemo(() => {
    if (facilityMap && allLocations) {
      const aisleNode = findNodesByChildName(facilityMap, 'AISLE', currentLocationName);
      if (!aisleNode) {
        return { aisleBins: [] };
      }
      const bins = flattenNodes(aisleNode)
        .filter((node) => node.type === 'BIN')
        .map((node) => ({
          ...node,
          position: calculatePosition(node.box),
          dimensions: calculateDimensions(node.box).multiplyScalar(SCALE_FACTOR),
        }));
      const binNames = bins.map((bin) => bin.name);
      const aisleLocations = allLocations.filter((location) =>
        binNames.includes(location.slot_label),
      );
      const issues = getLocationsBy(
        (location) => location.issues?.some((issue) => ISSUE_MASK.includes(issue.type)),
        aisleLocations,
      );
      const excluded = getLocationsBy(
        (location) => location.slot_settings?.exclusion_status === 'EXCLUDE',
        aisleLocations,
      );
      const notScanned = getLocationsBy((location) => !location.verity_status, aisleLocations);
      const empty = getLocationsBy(
        (location) => (location.verity_status as IVeritySlotStatusST)?.state === 'EMPTY',
        aisleLocations,
      );
      const barcode = getLocationsBy(
        (location) => (location.verity_status as IVeritySlotStatusST)?.state === 'BARCODE',
        aisleLocations,
      );
      const potential = getLocationsBy(
        (location) =>
          location.issues?.some((issue) => POTENTIAL_ISSUE_MASK.includes(issue.type)) &&
          !location.issues?.some((issue) => ISSUE_MASK.includes(issue.type)),
        aisleLocations,
      );
      const binStates: Array<{
        type: Bin3DProps['status'];
        lookup: Record<string, ILocationData1ST[]>;
      }> = [
        {
          type: 'ISSUE',
          lookup: issues,
        },
        {
          type: 'EXCLUDED',
          lookup: excluded,
        },
        {
          type: 'NOT_SCANNED',
          lookup: notScanned,
        },
        {
          type: 'EMPTY',
          lookup: empty,
        },
        {
          type: 'MATCH',
          lookup: barcode,
        },
        {
          type: 'POTENTIAL_ISSUE',
          lookup: potential,
        },
      ];
      const binsWithStatus: AisleViewProps['bins'] = bins.map(({ normal, ...bin }) => ({
        ...bin,
        ...(normal && {
          normal: new Vector3(normal[0], normal[1], normal[2]),
        }),
        status: binStates.find((binState) => binState.lookup[bin.name])?.type ?? 'NOT_SCANNED',
        current: currentLocationName === bin.name,
      }));

      const summary = binStates.map((binState) => ({
        status: binState.type,
        count: count(binState.lookup),
      }));
      return {
        aisleBins: binsWithStatus,
        current: binsWithStatus.find((bin) => bin.current),
        summary,
      };
    }
    return { aisleBins: [] };
  }, [facilityMap, allLocations, currentLocationName]);
}
