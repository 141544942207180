/*
 * Enumerated client level store action types
 */
export enum ClientLevelActionNames {
  RESET = 'RESET',
  SET_FACILITY_LIST = 'SET_FACILITY_LIST',
  SET_FAILED_TO_LOAD_FACILITY_LIST = 'SET_FAILED_TO_LOAD_FACILITY_LIST',
  SET_FACILITY_LIST_POPULATED = 'SET_FACILITY_LIST_POPULATED',
  SET_FACILITY_LIST_LOADING = 'SET_FACILITY_LIST_LOADING',
  UPDATE_FACILITY_ATTRIBUTE = 'UPDATE_FACILITY_ATTRIBUTE',
}
