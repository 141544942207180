import { IIssueST } from 'codegen/report';
import { getRowAndSingleIssueForIssueTable } from 'common/functions/locationRows/locationRowsFunctions';
import { ISSUE_TYPES } from 'common/issueTypesAndStates';
import {
  ISSUE_TAB_ALLOWED_DATA,
  SNOOZED_TAB_ALLOWED_DATA,
  POTENTIAL_TAB_ALLOWED_DATA,
  INCONCLUSIVE_TAB_ALLOWED_DATA,
  INVALID_TAB_ALLOWED_DATA,
} from 'common/tabDataDefinitions';
import { ILocationReportData } from 'udb/inventory/features/warehouse-status/warehouseStatus.model';

export const getIssuesPotentialInconclusiveInvalid = (issuesData: Record<string, IIssueST[]>) => {
  const issues: ILocationReportData[] = [];
  const snoozed: ILocationReportData[] = [];
  const potential: ILocationReportData[] = [];
  const inconclusive: ILocationReportData[] = [];
  const invalid: ILocationReportData[] = [];

  Object.entries(issuesData).forEach(([location, locationIssueData]) => {
    const rowDataAndSingleIssueData = getRowAndSingleIssueForIssueTable(
      location,
      locationIssueData,
    );
    const { rowData, singleIssueData } = rowDataAndSingleIssueData;

    if (
      ISSUE_TAB_ALLOWED_DATA.STATES.includes(singleIssueData.state) &&
      ISSUE_TAB_ALLOWED_DATA.ISSUE_TYPES.includes(singleIssueData.type as ISSUE_TYPES)
    ) {
      issues.push(rowData);
    } else if (SNOOZED_TAB_ALLOWED_DATA.includes(singleIssueData.state)) {
      snoozed.push(rowData);
    } else if (POTENTIAL_TAB_ALLOWED_DATA.includes(singleIssueData.type as ISSUE_TYPES)) {
      potential.push(rowData);
    } else if (INCONCLUSIVE_TAB_ALLOWED_DATA.includes(singleIssueData.type as ISSUE_TYPES)) {
      inconclusive.push(rowData);
    } else if (INVALID_TAB_ALLOWED_DATA.includes(singleIssueData.type as ISSUE_TYPES)) {
      invalid.push(rowData);
    }
  });

  return { issues, snoozed, potential, inconclusive, invalid };
};
