import { AxiosResponse } from 'axios';
// services & store

// variables, functions, configurations
import { CHALLENGE_NAME } from 'common/authChallengeNames';
import { AUTH_PAGES_URLS, CLIENT_PAGES_URLS } from 'common/pages';
import { TokenManager } from 'common/tokenManager';
import {
  IAuthenticationResponseST,
  IChallengeResponseST,
  IAuthChallengeMFAAndSMSST,
  IAuthChallengeNewPasswordST,
  IChangePasswordPostRequestST,
} from 'codegen/authentication';
import { authServices } from '../services/AuthServices';

const tokenManager = TokenManager.getInstance();

// #SG-20201115-EXPLANATION:
// Upon successful signing in, three tokens are returned in the response, which we store in the localStorage
// Tokens explanations and usage:
// - refresh_token is used to acquire new id_token and access_token,
//   after they expire (expiration time defined in response's expires_in)
// - id_token is used in APIs that read/write to DB (creating inv requests, getting reports etc.)
//   It is passed in Authorization header
// - access_token is used when changing user attributes (ex. changing phone number, email address etc.)
//   It is passed in payload body
const signIn = (username: string, password: string) =>
  authServices.signIn(username, password).then((r) => {
    const authData: IAuthenticationResponseST | IChallengeResponseST = r.data;

    let redirectRoute = '';
    let isNewUser = false;
    let isMfaAuth = false;

    tokenManager.setUsername(username);

    // The three code blocks below correspond to:
    // 1. Case when a known user tries to log in and hasn't set mfa auth
    //    (get's redirected to the page on which he is asked to set mfa)
    // 2. Case when a new user logs in for the first time
    //    (get's redirected to the page on which he is forced to change his temp password)
    // 3. Case when a known user tries to log in and has set mfa auth
    //    (get's redirected to the page on which he needs to enter a code from mfa app)
    if (r.status === 200) {
      // If user doesn't have active MFA he will be redirected to select facility
      // otherwise the select facility page will come after success MF auth
      tokenManager.setAuthData(authData as IAuthenticationResponseST);
      redirectRoute = CLIENT_PAGES_URLS.SELECT_FACILITY;
    } else if ('challenge_name' in authData) {
      const challengeResponse: IChallengeResponseST = authData as any;
      if (challengeResponse.challenge_name === CHALLENGE_NAME.NEW_PASSWORD) {
        tokenManager.setAuthChallengeData(challengeResponse);
        isNewUser = true;
        redirectRoute = AUTH_PAGES_URLS.CHANGE_TEMPORARY_PASSWORD;
      } else if (challengeResponse.challenge_name === CHALLENGE_NAME.SOFTWARE) {
        tokenManager.setAuthChallengeData(challengeResponse);
        isMfaAuth = challengeResponse.challenge_name === CHALLENGE_NAME.SOFTWARE;
        redirectRoute = AUTH_PAGES_URLS.MFA_AUTHENTICATION;
      }
    }

    return {
      username,
      authData,
      redirectRoute,
      isNewUser,
      isMfaAuth,
    };
  });

const authChallenge = ({ ...data }: IAuthChallengeMFAAndSMSST | IAuthChallengeNewPasswordST) =>
  authServices.authChallenge({ ...data }).then((r) => {
    const authData = r.data;
    let redirectRoute = '';

    if (r.status === 200) {
      tokenManager.setAuthData(authData);

      redirectRoute = CLIENT_PAGES_URLS.SELECT_FACILITY;
    }

    return {
      authData,
      redirectRoute,
    };
  });

const refreshToken = (username: string, refreshToken: string): Promise<AxiosResponse> =>
  authServices.refreshToken(username, refreshToken);

const revokeToken = (refreshToken: string): Promise<AxiosResponse> =>
  authServices.revokeToken(refreshToken);

/**
 * Start the forgot password procedure
 * @param username username
 * @returns a promise which resolves if the operation is successful.
 */
const forgotPassword = (username: string) => authServices.forgotPassword(username).then((r) => r);

const confirmForgotPassword = (username: string, password: string, code: string) =>
  authServices.confirmForgotPassword(username, password, code).then((r) => r);

const resendCode = (username: string) => authServices.resendCode(username).then((r) => r);

const changePassword = (data: IChangePasswordPostRequestST) =>
  authServices.changePassword(data).then((r) => r);

tokenManager.setRefreshTokenFunction(refreshToken);

export const authStore = {
  signIn,
  authChallenge,
  refreshToken,
  revokeToken,
  forgotPassword,
  changePassword,
  confirmForgotPassword,
  resendCode,
};
