import { useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import { makeStyles } from 'tss-react/mui';

import { Theme } from '@mui/material';

import { INVENTORY_PAGES_URLS, WARNING_PAGES_URLS } from 'common/pages';
import { getLogPrefixForType } from 'common/functions/logFunctions';
import { Box } from 'components/common/Box';
import { Copyright } from 'components/common/Copyright';
import { isSSOUser } from 'features/permissions/isSSOUser';
import { FacilitiesList } from './FacilitiesList';
import { useClientLevelStore } from '../../store/ClientLevelStore/clientLevelStore';
import { useUserLevelStore } from '../../store/UserLevelStore/userLevelStore';
import { getHomeRouteBasedOnPermissions } from './getHomeRouteBasedOnPermissions';

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    maxWidth: '600px',
    overflowY: 'hidden',
    width: '100%',
    margin: '0 auto',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(3),
    height: 'calc(100vh - 64px)',
  },

  title: {
    fontWeight: 'bold',
    color: theme.palette.textSecondary.main,
  },
}));

const logPrefix = getLogPrefixForType('COMPONENT', 'SelectFacility');

export const SelectFacility = () => {
  const { classes } = useStyles();

  const navigate = useNavigate();

  const { stateUserLevel } = useUserLevelStore();
  const { stateClientLevel } = useClientLevelStore();

  localStorage.removeItem('systemId');

  const handleFacilitySelect = useCallback(
    /**
     * Handle the selection of a given facility
     * @param {*} systemId ID of the selected facility.
     */
    async (systemId: string) => {
      const isSelectedFacilityActive = stateClientLevel.facilityList.filter(
        (facility) => systemId === facility.id,
      )[0].isConfigured;

      if (stateUserLevel.isMfaAuth || isSSOUser()) {
        if (!isSelectedFacilityActive) {
          navigate(`/${systemId}${WARNING_PAGES_URLS.NO_FACILITY}`);
        } else {
          const url = await getHomeRouteBasedOnPermissions(systemId);
          navigate(url);
        }
      } else {
        navigate(`/${systemId}${INVENTORY_PAGES_URLS.SET_MFA_PREFERENCE}`);
      }
    },
    [stateUserLevel.isMfaAuth, navigate, stateClientLevel.facilityList],
  );

  // Handle automatic facility selection when the user only has access to one facility
  useEffect(() => {
    if (
      !stateClientLevel.isFacilityListLoading &&
      stateClientLevel.isFacilityListPopulated &&
      stateClientLevel.facilityList.length === 1
    ) {
      console.debug(logPrefix, 'there is only one facility => select it');
      handleFacilitySelect(stateClientLevel.facilityList[0].id);
    }
  }, [
    stateClientLevel.facilityList,
    stateClientLevel.isFacilityListPopulated,
    stateClientLevel.isFacilityListLoading,
    handleFacilitySelect,
  ]);

  return stateClientLevel.isFacilityListPopulated && stateClientLevel.isMultiFacility ? (
    <Box className={classes.wrapper}>
      <Box>
        <Box mb={5} textAlign="center">
          <Typography data-testid="c-choose-facility-title" className={classes.title} variant="h4">
            Choose the facility you want to load
          </Typography>
        </Box>
        <Card className={classes.root} elevation={4}>
          <FacilitiesList onSelect={handleFacilitySelect} />
        </Card>
      </Box>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Box>
  ) : null;
};
