import { Box } from '@mui/material';
import { IIssueLogicRulesST } from 'codegen/facility_settings';
import { RulesText } from './RulesText';

export const CustomIssueLogicVisualizer = ({
  issueLogicRules,
}: {
  issueLogicRules: IIssueLogicRulesST;
}) => {
  const { rules } = issueLogicRules;

  return (
    <Box component="div" paddingTop="1em" display="flex" flexDirection="column" gap={2} px={2}>
      <RulesText rules={rules || []} />
    </Box>
  );
};
