import { getLogPrefixForType } from 'common/functions/logFunctions';
import { IGetEstimatedObstaclesResponseST } from 'codegen/nav_simulation';
import { groundControlServices } from '../../../services/GroundControlServices';
import { GetEstimatedObstaclesParams } from './getEstimatedObstaclesParams.model';

const logPrefix = getLogPrefixForType('FUNCTION', 'getEstimatedObstacles');

export const getEstimatedObstacles = ({
  systemId,
  flightDomainId,
  requestController,
  onSuccess,
}: GetEstimatedObstaclesParams): Promise<{ data: IGetEstimatedObstaclesResponseST }> => {
  const reservation = requestController.reserveSlotForRequest();
  console.debug(logPrefix, `getEstimatedObstacles ${requestController.componentName || ''}`);
  return requestController.doRequest({
    request: groundControlServices.getEstimatedObstacles,
    requestParams: [systemId, flightDomainId, reservation.signal],
    callbackSuccess: ({ data }: { data: IGetEstimatedObstaclesResponseST }) => onSuccess(data),
  });
};
