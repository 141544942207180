import { useTheme } from '@mui/material';
import { muiColorToTheme } from 'common/functions/issues/issueColorFunctions';
import { IBarcodeST } from '../../../../codegen/location_information';
import { calculateBarcodeFrameDimensions } from './utils/calculateBarcodeFrameDimensions';
import { getBarcodeOnImageBorderColor } from './utils/getBarcodeOnImageColor';

export const BarcodeFrame = ({
  barcodeMetadata,
  color,
  isStale,
  highlightedBarcode,
  setHighlightedBarcode,
}: {
  barcodeMetadata: IBarcodeST;
  color?: 'success' | 'error' | 'warning';
  isStale?: boolean;
  highlightedBarcode: string;
  setHighlightedBarcode: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const theme = useTheme();
  const themeColor = color
    ? muiColorToTheme(color, theme)
    : {
        main: 'GrayText',
      };

  const dimensions = calculateBarcodeFrameDimensions(barcodeMetadata);

  return (
    <button
      type="button"
      aria-label="Interactive button"
      onMouseEnter={() => setHighlightedBarcode(barcodeMetadata.udb_value)}
      onMouseLeave={() => setHighlightedBarcode('')}
      style={{
        position: 'absolute',
        top: `${dimensions.topPc}%`,
        left: `${dimensions.leftPc}%`,
        height: `${dimensions.heightPc}%`,
        width: `${dimensions.widthPc}%`,
        border: getBarcodeOnImageBorderColor(!!isStale, barcodeMetadata.status, themeColor, theme),
        borderWidth: highlightedBarcode === barcodeMetadata.udb_value ? '4px' : '1px',
        outline: 'none',
        background: 'none',
      }}
    />
  );
};
