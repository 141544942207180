import { IVeritySlotStatus1ST, ILocationDataSTStateEnum } from 'codegen/report';
import { IVeritySlotStatusStateST } from 'codegen/warehouse_status';
import { getLatestOverwriteFromSlotStatus } from './getLatestOverwriteFromSlotStatus';

/**
 * Get the "content found" from location data (to render on the screen)
 * Content found returns a value and a date for:
 * @param verity_status
 * @param scanState
 * @returns data introduced by the client user
 * data introduced by the verity user (when the one above does not exist)
 * data introduced  by the verity drones (when the ones above do not exist)
 */

export const getContentFoundFromSlotStatus = (
  verity_status: IVeritySlotStatus1ST | string | null,
  scanState?: ILocationDataSTStateEnum,
) => {
  let contentFoundValues: string[] = [];
  let contentFoundState: IVeritySlotStatusStateST | ILocationDataSTStateEnum | undefined;
  let contentFoundDateString: string = '-';
  let contentFoundChangedByTheClient: boolean = false;

  // Store latest overwrite, regardless of it being from a verity oor client user
  // removed variable latestUserOverwrite because clientUserOverwrite is also returning the latest changes
  // in response to bug: https://verity-ag.atlassian.net/browse/UD-3348
  const clientUserOverwrite = getLatestOverwriteFromSlotStatus(verity_status, 'client');

  contentFoundChangedByTheClient = clientUserOverwrite !== undefined;

  if (verity_status && typeof verity_status !== 'string') {
    // the date of interest for content found is always the verity date
    contentFoundDateString = verity_status.collected_at;

    // replaced variable latestUserOverwrite with clientUserOverwrite because clientUserOverwrite is also returning the latest changes
    // in response to bug: https://verity-ag.atlassian.net/browse/UD-3348
    if (clientUserOverwrite) {
      // parse user overwrite
      contentFoundState = clientUserOverwrite.state;
      if (clientUserOverwrite.state === IVeritySlotStatusStateST.Barcode) {
        contentFoundValues = clientUserOverwrite.barcodes;
      }
    } else {
      // parse original verity data overwrite
      const verityStatys = verity_status;
      contentFoundState = verityStatys.state;
      if (verityStatys.state === IVeritySlotStatusStateST.Barcode) {
        contentFoundValues = verityStatys.barcodes;
      }
    }
  } else if (scanState && scanState !== ILocationDataSTStateEnum.Scanned) {
    contentFoundState = scanState;
  }

  return {
    contentValue: contentFoundValues,
    contentFoundState,
    contentDate: contentFoundDateString,
    contentFoundChangedByTheClient,
  };
};
