import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import { Button } from '@mui/material';
import { formatDateInTimezone } from 'common/functions/dateTimeFunctions';
import { ILocationDataST, ISlotSettingsSTExclusionStatusEnum } from 'codegen/warehouse_status';
import { ExclusionHistory } from 'udb/inventory/features/locations-management/models/ExclusionHistory';
import { LocationRow } from '../models/LocationsRow';
import { slotUsageToExclusionStatus } from './slotUsageToExclusionStatus';

const EXCLUSION_ICONS = {
  INCLUDE: <CheckIcon />,
  EXCLUDE: <ClearIcon />,
  NOT_SET: null,
};

/**
 * Transforms locations to table rows
 */
export const locationsToRows = (
  locations: {
    [key: string]: ILocationDataST;
  },
  setIdForReset: (id: string) => void,
  exclusionHistory: ExclusionHistory,
): { rows: LocationRow[]; rowsWithWmsData: boolean } => {
  let allLocations = Object.values(locations).map((l: ILocationDataST) => {
    // Currently verity_status and wms_status can be IVeritySlotStatusST | string so we need to check first the string in order to shield ourself
    const udbExclusionStatus =
      typeof l?.slot_settings === 'string'
        ? 'NOT_SET'
        : l?.slot_settings?.exclusion_status ?? 'NOT_SET';

    const wmsExclusionStatus: ISlotSettingsSTExclusionStatusEnum =
      typeof l?.wms_status === 'string'
        ? 'NOT_SET'
        : slotUsageToExclusionStatus(l?.wms_status?.slot_usage);

    const exclusionRequestId =
      typeof l?.slot_settings === 'string' ? '' : l?.slot_settings?.exclusion_request_id || '';

    const exclusionLocationHistory = exclusionHistory ? exclusionHistory[exclusionRequestId] : null;
    const createdAt = exclusionLocationHistory?.created_at
      ? formatDateInTimezone(exclusionLocationHistory?.created_at, 'YYYY/MM/DD')
      : '';
    const updatedBy = exclusionLocationHistory?.email || '';
    const reason = exclusionLocationHistory?.reason || '';

    return {
      id: l.slot_label,
      slotLabel: l.slot_label,
      udbExclusionStatus: EXCLUSION_ICONS[udbExclusionStatus],
      wmsExclusionStatus: EXCLUSION_ICONS[wmsExclusionStatus],
      exclusionStatus: udbExclusionStatus === 'NOT_SET' ? wmsExclusionStatus : udbExclusionStatus,
      exclusionRequestId,
      createdAt,
      updatedBy,
      reason,
      action:
        udbExclusionStatus !== 'NOT_SET' ? (
          <Button variant="text" size="small" onClick={() => setIdForReset(l.slot_label)}>
            Remove override
          </Button>
        ) : null,
    };
  });

  let rowsWithWmsData = true;

  const filteredLocations = allLocations.filter((l: LocationRow) => l.wmsExclusionStatus);

  if (!filteredLocations.length) {
    rowsWithWmsData = false;
    allLocations = allLocations.map((l: LocationRow) => ({
      ...l,
      udbExclusionStatus:
        l.exclusionStatus === 'NOT_SET' ? EXCLUSION_ICONS.INCLUDE : l.udbExclusionStatus,
    }));
  }

  return { rows: allLocations, rowsWithWmsData };
};
