import { ILocationDataST, IVeritySlotStatusST, IWMSSlotStatusST } from 'codegen/warehouse_status';
import { difference, intersection } from 'lodash';

export const getExpectedBarcodes = (row: ILocationDataST) => {
  const wms = row.wms_status as IWMSSlotStatusST;
  if (!wms || wms.state !== 'BARCODE') {
    return null;
  }
  return wms.barcodes;
};

export const getFoundBarcodes = (row: ILocationDataST) => {
  const verityStatus = row.verity_status as IVeritySlotStatusST;
  if (
    verityStatus &&
    verityStatus?.user_overrides !== null &&
    verityStatus?.user_overrides?.length > 0
  ) {
    const lastOverride = verityStatus.user_overrides[verityStatus.user_overrides.length - 1];
    if (lastOverride.state !== 'BARCODE') {
      return null;
    }
    return lastOverride.barcodes;
  }
  if (!verityStatus || verityStatus.state !== 'BARCODE') {
    return null;
  }
  return verityStatus.barcodes;
};

export const getMissingBarcodes = (row: ILocationDataST) => {
  const expected = getExpectedBarcodes(row);
  if (!expected) {
    return null;
  }
  const found = getFoundBarcodes(row);
  if (!found) {
    return expected;
  }
  return difference(expected, found);
};

export const getUnknownBarcodes = (row: ILocationDataST) => {
  const found = getFoundBarcodes(row);
  if (!found) {
    return null;
  }
  const expected = getExpectedBarcodes(row);
  if (!expected) {
    return found;
  }
  return difference(found, expected);
};

export const getMatchingBarcodes = (row: ILocationDataST) => {
  const expected = getExpectedBarcodes(row);
  if (!expected) {
    return [];
  }
  const found = getFoundBarcodes(row);
  if (!found) {
    return [];
  }
  return intersection(expected, found);
};
