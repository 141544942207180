import { styled } from '@mui/material/styles';
import List from '@mui/material/List';

export const DrawerList = styled(List)(({ theme, multiplefacilitiesacces, stickyelement }) => ({
  marginTop: !multiplefacilitiesacces && !stickyelement ? 60 : 0,
  paddingBottom: 60,
  overflowX: 'hidden',
  position: 'relative',
  visibility: 'visible',
  paddingTop: 0,
  transition: theme.transitions.create(['margin', 'visibility'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}));
