export const toFixed2: (value: number) => number = (value) => parseFloat(Number(value).toFixed(2));

/**
 - * Takes an object with number properties and turns them to fixed 2
 - * @param obj object whose properties will turn to fixed 2
 - * @returns copy of given object with numbers to fixed 2
 - */
export const allNumbersToFixed2 = <G extends { [key: string]: number }>(obj: G): G =>
  Object.keys(obj).reduce((acc, key) => ({ ...acc, [key]: toFixed2(obj[key]) }), {} as G);

export const formatNumberWithSeparators = (num: number): string =>
  num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
