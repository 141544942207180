import { IConditionOrLogicalOperatorST, IRuleST } from 'codegen/facility_settings';

export const isCatchAllCondition = (condition: IConditionOrLogicalOperatorST | string) =>
  typeof condition !== 'string' &&
  condition.operator?.value === '.*' &&
  condition.operator.type !== 'NOT_IN' &&
  condition.operator.type !== 'IS_BARCODE' &&
  condition.operator.type !== 'IS_DOUBLE_DEPTH' &&
  condition.operator.type !== 'IS_EMPTY' &&
  condition.operator.type !== 'IS_INVALID';

export const isCatchAllRule = (rule: IRuleST) => {
  const catchAllCondition = rule.conditions?.filter((c) => isCatchAllCondition(c));

  return !!catchAllCondition?.length;
};

export const hasCatchAllRule = (rules: IRuleST[]) => {
  const catchAllRule = rules.filter((r) => isCatchAllRule(r));

  return !!catchAllRule.length;
};
