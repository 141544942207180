/* tslint:disable */
/* eslint-disable */
/**
 * Location Information
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 *
 * @export
 * @interface IBarcodeDiagnosticST
 */
export interface IBarcodeDiagnosticST {
  /**
   *
   * @type {Array<string>}
   * @memberof IBarcodeDiagnosticST
   */
  activityGroupId: Array<string>;
  /**
   *
   * @type {{ [key: string]: IBarcodeInfoST; }}
   * @memberof IBarcodeDiagnosticST
   */
  activityToBarcodeInfo: { [key: string]: IBarcodeInfoST };
  /**
   *
   * @type {object}
   * @memberof IBarcodeDiagnosticST
   */
  slotInfoGroup: object;
  /**
   *
   * @type {boolean}
   * @memberof IBarcodeDiagnosticST
   */
  barcodeRecognitionWasSkipped: boolean;
}
/**
 *
 * @export
 * @interface IBarcodeInfoST
 */
export interface IBarcodeInfoST {
  /**
   *
   * @type {Array<IBarcodeST>}
   * @memberof IBarcodeInfoST
   */
  localizedBarcodes: Array<IBarcodeST>;
  /**
   *
   * @type {Array<IBarcodeST>}
   * @memberof IBarcodeInfoST
   */
  mismatchedBarcodes: Array<IBarcodeST>;
  /**
   *
   * @type {Array<IBarcodeST>}
   * @memberof IBarcodeInfoST
   */
  recognizedBarcodes: Array<IBarcodeST>;
}
/**
 *
 * @export
 * @interface IBarcodeST
 */
export interface IBarcodeST {
  /**
   *
   * @type {string}
   * @memberof IBarcodeST
   */
  btype: string;
  /**
   *
   * @type {ILocationST}
   * @memberof IBarcodeST
   */
  location: ILocationST;
  /**
   *
   * @type {string}
   * @memberof IBarcodeST
   */
  udb_value: string;
  /**
   *
   * @type {string}
   * @memberof IBarcodeST
   */
  value: string;
  /**
   *
   * @type {string}
   * @memberof IBarcodeST
   */
  status: IBarcodeSTStatusEnum;
}

export const IBarcodeSTStatusEnum = {
  Recognized: 'BARCODE_RECOGNIZED',
  PatternMismatch: 'BARCODE_PATTERN_MISMATCH',
  Localized: 'BARCODE_LOCALIZED',
} as const;

export type IBarcodeSTStatusEnum = typeof IBarcodeSTStatusEnum[keyof typeof IBarcodeSTStatusEnum];

/**
 *
 * @export
 * @interface ICoordinateST
 */
export interface ICoordinateST {
  /**
   *
   * @type {Array<number>}
   * @memberof ICoordinateST
   */
  coordinate: Array<number>;
}
/**
 *
 * @export
 * @interface IErrorResponseErrorsInnerST
 */
export interface IErrorResponseErrorsInnerST {
  /**
   * Error code
   * @type {string}
   * @memberof IErrorResponseErrorsInnerST
   */
  code: string;
}
/**
 *
 * @export
 * @interface IErrorResponseST
 */
export interface IErrorResponseST {
  /**
   * Error description
   * @type {string}
   * @memberof IErrorResponseST
   */
  message: string;
  /**
   * Error timestamp
   * @type {string}
   * @memberof IErrorResponseST
   */
  timestamp: string;
  /**
   * User triggering the request
   * @type {string}
   * @memberof IErrorResponseST
   */
  user_name: string;
  /**
   * The request trace id
   * @type {string}
   * @memberof IErrorResponseST
   */
  trace_id: string;
  /**
   * The URL that can be used to report the error
   * @type {string}
   * @memberof IErrorResponseST
   */
  report_url: string | null;
  /**
   * List of Error objects.
   * @type {Array<IErrorResponseErrorsInnerST>}
   * @memberof IErrorResponseST
   */
  errors: Array<IErrorResponseErrorsInnerST>;
}
/**
 *
 * @export
 * @interface IInputDataST
 */
export interface IInputDataST {
  /**
   *
   * @type {IBarcodeDiagnosticST}
   * @memberof IInputDataST
   */
  act_group_barcode_diagnostic: IBarcodeDiagnosticST;
  /**
   *
   * @type {string}
   * @memberof IInputDataST
   */
  activity_group_id: string;
  /**
   *
   * @type {Array<string>}
   * @memberof IInputDataST
   */
  activity_id_list: Array<string>;
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof IInputDataST
   */
  act_id_to_udb_image_guid: { [key: string]: string };
  /**
   *
   * @type {object}
   * @memberof IInputDataST
   */
  insight_parameters: object;
  /**
   *
   * @type {object}
   * @memberof IInputDataST
   */
  act_id_to_act_metadata: object;
}
/**
 * @type ILocationHistoryItemDataST
 * @export
 */
export type ILocationHistoryItemDataST = ISlotSettingsST | IVeritySlotStatusST | IWMSSlotStatusST;

/**
 *
 * @export
 * @interface ILocationHistoryItemST
 */
export interface ILocationHistoryItemST {
  /**
   *
   * @type {ILocationHistoryTypeST}
   * @memberof ILocationHistoryItemST
   */
  item_type?: ILocationHistoryTypeST;
  /**
   *
   * @type {ILocationHistoryItemDataST}
   * @memberof ILocationHistoryItemST
   */
  data: ILocationHistoryItemDataST;
}

/**
 *
 * @export
 * @interface ILocationHistoryResponseST
 */
export interface ILocationHistoryResponseST {
  /**
   *
   * @type {string}
   * @memberof ILocationHistoryResponseST
   */
  next_timepoint?: string | null;
  /**
   *
   * @type {Array<ILocationHistoryItemST>}
   * @memberof ILocationHistoryResponseST
   */
  data: Array<ILocationHistoryItemST>;
}
/**
 *
 * @export
 * @enum {string}
 */

export const ILocationHistoryTypeST = {
  WmsUpdate: 'WMS_UPDATE',
  DroneReport: 'DRONE_REPORT',
  ExclusionStatusUpdate: 'EXCLUSION_STATUS_UPDATE',
} as const;

export type ILocationHistoryTypeST =
  typeof ILocationHistoryTypeST[keyof typeof ILocationHistoryTypeST];

/**
 *
 * @export
 * @interface ILocationST
 */
export interface ILocationST {
  /**
   *
   * @type {ICoordinateST}
   * @memberof ILocationST
   */
  bottomLeft: ICoordinateST;
  /**
   *
   * @type {ICoordinateST}
   * @memberof ILocationST
   */
  bottomRight: ICoordinateST;
  /**
   *
   * @type {ICoordinateST}
   * @memberof ILocationST
   */
  topLeft: ICoordinateST;
  /**
   *
   * @type {ICoordinateST}
   * @memberof ILocationST
   */
  topRight: ICoordinateST;
}
/**
 *
 * @export
 * @interface IReportLookupResponseST
 */
export interface IReportLookupResponseST {
  /**
   *
   * @type {string}
   * @memberof IReportLookupResponseST
   */
  report_id: string;
}
/**
 *
 * @export
 * @interface IResultMetadataResponseST
 */
export interface IResultMetadataResponseST {
  /**
   *
   * @type {string}
   * @memberof IResultMetadataResponseST
   */
  ag_result_id: string;
  /**
   *
   * @type {Array<string>}
   * @memberof IResultMetadataResponseST
   */
  activity_group_ids: Array<string>;
  /**
   *
   * @type {{ [key: string]: IInputDataST; }}
   * @memberof IResultMetadataResponseST
   */
  activity_group_id_to_input_data: { [key: string]: IInputDataST };
  /**
   *
   * @type {string}
   * @memberof IResultMetadataResponseST
   */
  location_name: string;
  /**
   *
   * @type {number}
   * @memberof IResultMetadataResponseST
   */
  system_id: number;
}
/**
 * Slot settings.
 * @export
 * @interface ISlotSettingsST
 */
export interface ISlotSettingsST {
  /**
   * Label of the slot
   * @type {string}
   * @memberof ISlotSettingsST
   */
  slot_label: string;
  /**
   * Exclusion Status of this slot defined in UDB.
   * @type {string}
   * @memberof ISlotSettingsST
   */
  exclusion_status: ISlotSettingsSTExclusionStatusEnum;
  /**
   *
   * @type {string}
   * @memberof ISlotSettingsST
   */
  exclusion_request_id: string | null;
  /**
   * Time when this information was created / last updated
   * @type {string}
   * @memberof ISlotSettingsST
   */
  updated_at: string | null;
}

export const ISlotSettingsSTExclusionStatusEnum = {
  Include: 'INCLUDE',
  Exclude: 'EXCLUDE',
  NotSet: 'NOT_SET',
} as const;

export type ISlotSettingsSTExclusionStatusEnum =
  typeof ISlotSettingsSTExclusionStatusEnum[keyof typeof ISlotSettingsSTExclusionStatusEnum];

/**
 *
 * @export
 * @interface IVeritySlotStatusST
 */
export interface IVeritySlotStatusST {
  /**
   * Label of the slot
   * @type {string}
   * @memberof IVeritySlotStatusST
   */
  slot_label: string;
  /**
   *
   * @type {IVeritySlotStatusStateST}
   * @memberof IVeritySlotStatusST
   */
  state: IVeritySlotStatusStateST;
  /**
   * Indicates wether the slot information needs to be refined with a backup flight
   * @type {boolean}
   * @memberof IVeritySlotStatusST
   */
  pending_backup: boolean;
  /**
   * Time when Verity collected the data
   * @type {string}
   * @memberof IVeritySlotStatusST
   */
  collected_at: string;
  /**
   * List of barcodes that were detected
   * @type {Array<string>}
   * @memberof IVeritySlotStatusST
   */
  barcodes: Array<string>;
  /**
   * Ids of images collected
   * @type {Array<string>}
   * @memberof IVeritySlotStatusST
   */
  image_ids: Array<string>;
  /**
   * Result ID for this Verity slot status
   * @type {string}
   * @memberof IVeritySlotStatusST
   */
  result_id: string | null;
  /**
   * Time when Verity will next start collecting data for this slot
   * @type {string}
   * @memberof IVeritySlotStatusST
   */
  next_scheduled_at: string | null;
  /**
   * List of overrides to Verity data
   * @type {Array<IVeritySlotStatusUserOverrideST>}
   * @memberof IVeritySlotStatusST
   */
  user_overrides: Array<IVeritySlotStatusUserOverrideST> | null;
  /**
   * Version of the slot status
   * @type {number}
   * @memberof IVeritySlotStatusST
   */
  version: number;
  /**
   * Version of the slot status in the staging table
   * @type {number}
   * @memberof IVeritySlotStatusST
   */
  staging_version: number | null;
  /**
   * Time when this information was created/last updated
   * @type {string}
   * @memberof IVeritySlotStatusST
   */
  updated_at: string;
  /**
   * Indicates if the slot status was approved as part of a review
   * @type {boolean}
   * @memberof IVeritySlotStatusST
   */
  approved: boolean;
  /**
   * Indicates the username of the person that approved the slot status
   * @type {string}
   * @memberof IVeritySlotStatusST
   */
  approved_by: string | null;
}

/**
 * State of the slot
 * @export
 * @enum {string}
 */

export const IVeritySlotStatusStateST = {
  Empty: 'EMPTY',
  Barcode: 'BARCODE',
  NotEmpty: 'NOT_EMPTY',
  Unreachable: 'UNREACHABLE',
  NoResult: 'NO_RESULT',
} as const;

export type IVeritySlotStatusStateST =
  typeof IVeritySlotStatusStateST[keyof typeof IVeritySlotStatusStateST];

/**
 *
 * @export
 * @interface IVeritySlotStatusUserOverrideST
 */
export interface IVeritySlotStatusUserOverrideST {
  /**
   * Id of user making the override
   * @type {string}
   * @memberof IVeritySlotStatusUserOverrideST
   */
  user_id: string;
  /**
   * Name of user making the override
   * @type {string}
   * @memberof IVeritySlotStatusUserOverrideST
   */
  user_name: string;
  /**
   * Timestamp of when the user submitted the override
   * @type {string}
   * @memberof IVeritySlotStatusUserOverrideST
   */
  timestamp: string;
  /**
   * User-provided flag indicating if original Verity data were correct
   * @type {boolean}
   * @memberof IVeritySlotStatusUserOverrideST
   */
  verity_correct: boolean;
  /**
   * True, if override corresponds to data provided by WMS
   * @type {boolean}
   * @memberof IVeritySlotStatusUserOverrideST
   */
  set_to_expected: boolean;
  /**
   *
   * @type {IVeritySlotStatusStateST}
   * @memberof IVeritySlotStatusUserOverrideST
   */
  state: IVeritySlotStatusStateST;
  /**
   * List of barcodes that should override those found by Verity
   * @type {Array<string>}
   * @memberof IVeritySlotStatusUserOverrideST
   */
  barcodes: Array<string>;
  /**
   * Free-text comment by user
   * @type {string}
   * @memberof IVeritySlotStatusUserOverrideST
   */
  comment: string | null;
  /**
   * Indicates if the update is part of a review
   * @type {boolean}
   * @memberof IVeritySlotStatusUserOverrideST
   */
  review: boolean;
}

/**
 *
 * @export
 * @interface IWMSSlotStatusST
 */
export interface IWMSSlotStatusST {
  /**
   * Label of the slot
   * @type {string}
   * @memberof IWMSSlotStatusST
   */
  slot_label: string;
  /**
   * State of the slot
   * @type {string}
   * @memberof IWMSSlotStatusST
   */
  state: IWMSSlotStatusSTStateEnum;
  /**
   * Timestamp of when the content of this slot changed
   * @type {string}
   * @memberof IWMSSlotStatusST
   */
  changed_at: string;
  /**
   * ID of the WMS snapshot providing these data
   * @type {string}
   * @memberof IWMSSlotStatusST
   */
  snapshot_id: string;
  /**
   * List of barcodes expected at location
   * @type {Array<string>}
   * @memberof IWMSSlotStatusST
   */
  barcodes: Array<string>;
  /**
   * List of article numbers expected at location
   * @type {Array<string>}
   * @memberof IWMSSlotStatusST
   */
  article_nos: Array<string>;
  /**
   *
   * @type {string}
   * @memberof IWMSSlotStatusST
   */
  slot_usage: IWMSSlotStatusSTSlotUsageEnum;
  /**
   *
   * @type {Array<string>}
   * @memberof IWMSSlotStatusST
   */
  customers: Array<string>;
  /**
   *
   * @type {Array<number>}
   * @memberof IWMSSlotStatusST
   */
  unit_load_dimensions: Array<number> | null;
  /**
   * Version of the slot status
   * @type {number}
   * @memberof IWMSSlotStatusST
   */
  version: number;
  /**
   * Time when this information was created/last updated
   * @type {string}
   * @memberof IWMSSlotStatusST
   */
  updated_at: string;
  /**
   * List of quantity of each article
   * @type {Array<number>}
   * @memberof IWMSSlotStatusST
   */
  qtys: Array<number>;
  /**
   *
   * @type {string}
   * @memberof IWMSSlotStatusST
   */
  query_time: string;
  /**
   * RAW data
   * @type {string}
   * @memberof IWMSSlotStatusST
   */
  raw_data: string | null;
  /**
   * An identifier for the given location check in the client system (Optional)
   * @type {string}
   * @memberof IWMSSlotStatusST
   */
  client_location_check_id?: string | null;
  /**
   * Exclusion Status of this slot defined in WMS data.
   * @type {string}
   * @memberof IWMSSlotStatusST
   */
  exclusion_status: IWMSSlotStatusSTExclusionStatusEnum;
}

export const IWMSSlotStatusSTStateEnum = {
  Empty: 'EMPTY',
  Barcode: 'BARCODE',
  Invalid: 'INVALID',
} as const;

export type IWMSSlotStatusSTStateEnum =
  typeof IWMSSlotStatusSTStateEnum[keyof typeof IWMSSlotStatusSTStateEnum];
export const IWMSSlotStatusSTSlotUsageEnum = {
  Blocked: 'BLOCKED',
  Normal: 'NORMAL',
  Null: 'null',
} as const;

export type IWMSSlotStatusSTSlotUsageEnum =
  typeof IWMSSlotStatusSTSlotUsageEnum[keyof typeof IWMSSlotStatusSTSlotUsageEnum];
export const IWMSSlotStatusSTExclusionStatusEnum = {
  Include: 'INCLUDE',
  Exclude: 'EXCLUDE',
} as const;

export type IWMSSlotStatusSTExclusionStatusEnum =
  typeof IWMSSlotStatusSTExclusionStatusEnum[keyof typeof IWMSSlotStatusSTExclusionStatusEnum];

/**
 * LocationApi - axios parameter creator
 * @export
 */
export const LocationApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Returns the history of slot statuses including verity and WMS data.
     * @summary Return slot history based on time constraints.
     * @param {string} systemIdStr
     * @param {string} locationLabel
     * @param {number} [limit]
     * @param {string} [timepoint]
     * @param {boolean} [review]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLocationHistory: async (
      systemIdStr: string,
      locationLabel: string,
      limit?: number,
      timepoint?: string,
      review?: boolean,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'systemIdStr' is not null or undefined
      assertParamExists('getLocationHistory', 'systemIdStr', systemIdStr);
      // verify required parameter 'locationLabel' is not null or undefined
      assertParamExists('getLocationHistory', 'locationLabel', locationLabel);
      const localVarPath = `/{system_id_str}/location/{location_label}/history`
        .replace(`{${'system_id_str'}}`, encodeURIComponent(String(systemIdStr)))
        .replace(`{${'location_label'}}`, encodeURIComponent(String(locationLabel)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication VerityUserDashboard required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (timepoint !== undefined) {
        localVarQueryParameter['timepoint'] =
          (timepoint as any) instanceof Date ? (timepoint as any).toISOString() : timepoint;
      }

      if (review !== undefined) {
        localVarQueryParameter['review'] = review;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Return slot metadata based on the result_id.
     * @summary Return slot metadata based on the result_id.
     * @param {string} systemIdStr
     * @param {string} locationLabel
     * @param {number} [version]
     * @param {boolean} [review]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLocationMetadata: async (
      systemIdStr: string,
      locationLabel: string,
      version?: number,
      review?: boolean,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'systemIdStr' is not null or undefined
      assertParamExists('getLocationMetadata', 'systemIdStr', systemIdStr);
      // verify required parameter 'locationLabel' is not null or undefined
      assertParamExists('getLocationMetadata', 'locationLabel', locationLabel);
      const localVarPath = `/{system_id_str}/location/{location_label}/metadata`
        .replace(`{${'system_id_str'}}`, encodeURIComponent(String(systemIdStr)))
        .replace(`{${'location_label'}}`, encodeURIComponent(String(locationLabel)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication VerityUserDashboard required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      if (version !== undefined) {
        localVarQueryParameter['version'] = version;
      }

      if (review !== undefined) {
        localVarQueryParameter['review'] = review;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Return report id that generated the result, none if not found
     * @summary Return report id that generated the result
     * @param {string} systemIdStr
     * @param {string} locationLabel
     * @param {number} [version]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getReportFromLocation: async (
      systemIdStr: string,
      locationLabel: string,
      version?: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'systemIdStr' is not null or undefined
      assertParamExists('getReportFromLocation', 'systemIdStr', systemIdStr);
      // verify required parameter 'locationLabel' is not null or undefined
      assertParamExists('getReportFromLocation', 'locationLabel', locationLabel);
      const localVarPath = `/{system_id_str}/location/{location_label}/report-lookup`
        .replace(`{${'system_id_str'}}`, encodeURIComponent(String(systemIdStr)))
        .replace(`{${'location_label'}}`, encodeURIComponent(String(locationLabel)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication VerityUserDashboard required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      if (version !== undefined) {
        localVarQueryParameter['version'] = version;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Return slot\'s raw wms data based on version, latest if none is specified
     * @summary Return slot\'s raw wms data
     * @param {string} systemIdStr
     * @param {string} locationLabel
     * @param {number} [version]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getWmsRawData: async (
      systemIdStr: string,
      locationLabel: string,
      version?: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'systemIdStr' is not null or undefined
      assertParamExists('getWmsRawData', 'systemIdStr', systemIdStr);
      // verify required parameter 'locationLabel' is not null or undefined
      assertParamExists('getWmsRawData', 'locationLabel', locationLabel);
      const localVarPath = `/{system_id_str}/location/{location_label}/wms-data`
        .replace(`{${'system_id_str'}}`, encodeURIComponent(String(systemIdStr)))
        .replace(`{${'location_label'}}`, encodeURIComponent(String(locationLabel)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication VerityUserDashboard required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      if (version !== undefined) {
        localVarQueryParameter['version'] = version;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * LocationApi - functional programming interface
 * @export
 */
export const LocationApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = LocationApiAxiosParamCreator(configuration);
  return {
    /**
     * Returns the history of slot statuses including verity and WMS data.
     * @summary Return slot history based on time constraints.
     * @param {string} systemIdStr
     * @param {string} locationLabel
     * @param {number} [limit]
     * @param {string} [timepoint]
     * @param {boolean} [review]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getLocationHistory(
      systemIdStr: string,
      locationLabel: string,
      limit?: number,
      timepoint?: string,
      review?: boolean,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ILocationHistoryResponseST>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getLocationHistory(
        systemIdStr,
        locationLabel,
        limit,
        timepoint,
        review,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Return slot metadata based on the result_id.
     * @summary Return slot metadata based on the result_id.
     * @param {string} systemIdStr
     * @param {string} locationLabel
     * @param {number} [version]
     * @param {boolean} [review]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getLocationMetadata(
      systemIdStr: string,
      locationLabel: string,
      version?: number,
      review?: boolean,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<IResultMetadataResponseST>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getLocationMetadata(
        systemIdStr,
        locationLabel,
        version,
        review,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Return report id that generated the result, none if not found
     * @summary Return report id that generated the result
     * @param {string} systemIdStr
     * @param {string} locationLabel
     * @param {number} [version]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getReportFromLocation(
      systemIdStr: string,
      locationLabel: string,
      version?: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<IReportLookupResponseST>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getReportFromLocation(
        systemIdStr,
        locationLabel,
        version,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Return slot\'s raw wms data based on version, latest if none is specified
     * @summary Return slot\'s raw wms data
     * @param {string} systemIdStr
     * @param {string} locationLabel
     * @param {number} [version]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getWmsRawData(
      systemIdStr: string,
      locationLabel: string,
      version?: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: any }>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getWmsRawData(
        systemIdStr,
        locationLabel,
        version,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * LocationApi - factory interface
 * @export
 */
export const LocationApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = LocationApiFp(configuration);
  return {
    /**
     * Returns the history of slot statuses including verity and WMS data.
     * @summary Return slot history based on time constraints.
     * @param {string} systemIdStr
     * @param {string} locationLabel
     * @param {number} [limit]
     * @param {string} [timepoint]
     * @param {boolean} [review]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLocationHistory(
      systemIdStr: string,
      locationLabel: string,
      limit?: number,
      timepoint?: string,
      review?: boolean,
      options?: any,
    ): AxiosPromise<ILocationHistoryResponseST> {
      return localVarFp
        .getLocationHistory(systemIdStr, locationLabel, limit, timepoint, review, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Return slot metadata based on the result_id.
     * @summary Return slot metadata based on the result_id.
     * @param {string} systemIdStr
     * @param {string} locationLabel
     * @param {number} [version]
     * @param {boolean} [review]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLocationMetadata(
      systemIdStr: string,
      locationLabel: string,
      version?: number,
      review?: boolean,
      options?: any,
    ): AxiosPromise<IResultMetadataResponseST> {
      return localVarFp
        .getLocationMetadata(systemIdStr, locationLabel, version, review, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Return report id that generated the result, none if not found
     * @summary Return report id that generated the result
     * @param {string} systemIdStr
     * @param {string} locationLabel
     * @param {number} [version]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getReportFromLocation(
      systemIdStr: string,
      locationLabel: string,
      version?: number,
      options?: any,
    ): AxiosPromise<IReportLookupResponseST> {
      return localVarFp
        .getReportFromLocation(systemIdStr, locationLabel, version, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Return slot\'s raw wms data based on version, latest if none is specified
     * @summary Return slot\'s raw wms data
     * @param {string} systemIdStr
     * @param {string} locationLabel
     * @param {number} [version]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getWmsRawData(
      systemIdStr: string,
      locationLabel: string,
      version?: number,
      options?: any,
    ): AxiosPromise<{ [key: string]: any }> {
      return localVarFp
        .getWmsRawData(systemIdStr, locationLabel, version, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * LocationApi - object-oriented interface
 * @export
 * @class LocationApi
 * @extends {BaseAPI}
 */
export class LocationApi extends BaseAPI {
  /**
   * Returns the history of slot statuses including verity and WMS data.
   * @summary Return slot history based on time constraints.
   * @param {string} systemIdStr
   * @param {string} locationLabel
   * @param {number} [limit]
   * @param {string} [timepoint]
   * @param {boolean} [review]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LocationApi
   */
  public getLocationHistory(
    systemIdStr: string,
    locationLabel: string,
    limit?: number,
    timepoint?: string,
    review?: boolean,
    options?: AxiosRequestConfig,
  ) {
    return LocationApiFp(this.configuration)
      .getLocationHistory(systemIdStr, locationLabel, limit, timepoint, review, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Return slot metadata based on the result_id.
   * @summary Return slot metadata based on the result_id.
   * @param {string} systemIdStr
   * @param {string} locationLabel
   * @param {number} [version]
   * @param {boolean} [review]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LocationApi
   */
  public getLocationMetadata(
    systemIdStr: string,
    locationLabel: string,
    version?: number,
    review?: boolean,
    options?: AxiosRequestConfig,
  ) {
    return LocationApiFp(this.configuration)
      .getLocationMetadata(systemIdStr, locationLabel, version, review, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Return report id that generated the result, none if not found
   * @summary Return report id that generated the result
   * @param {string} systemIdStr
   * @param {string} locationLabel
   * @param {number} [version]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LocationApi
   */
  public getReportFromLocation(
    systemIdStr: string,
    locationLabel: string,
    version?: number,
    options?: AxiosRequestConfig,
  ) {
    return LocationApiFp(this.configuration)
      .getReportFromLocation(systemIdStr, locationLabel, version, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Return slot\'s raw wms data based on version, latest if none is specified
   * @summary Return slot\'s raw wms data
   * @param {string} systemIdStr
   * @param {string} locationLabel
   * @param {number} [version]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LocationApi
   */
  public getWmsRawData(
    systemIdStr: string,
    locationLabel: string,
    version?: number,
    options?: AxiosRequestConfig,
  ) {
    return LocationApiFp(this.configuration)
      .getWmsRawData(systemIdStr, locationLabel, version, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
