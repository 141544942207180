import React from 'react';
import { useNavigate } from 'react-router-dom';
import { styled, useTheme } from '@mui/material';

// mui core
import List from '@mui/material/List';
import Collapse from '@mui/material/Collapse';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import Avatar from '@mui/material/Avatar';

// mui icons
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

import isNil from 'lodash/isNil';

// components
import { Box } from '../Box';

// Customized Tooltip component
const ListItemTooltip = styled(({ className, children, title, ...props }) => (
  <Tooltip title={title} {...props} placement="right" classes={{ popper: className }}>
    {children}
  </Tooltip>
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    opacity: 1,
    padding: 8,
    borderRadius: 0,
    backgroundColor: theme.palette.grey[100],
    color: theme.palette.primary.main,
    fontSize: 16,
    fontWeight: 400,
  },
}));

export const FacilityMenuItem = ({
  items,
  divider,
  opened,
  id,
  icon,
  handleAccordions,
  text,
  subtext,
  isDrawerOpened,
  systemId,
}) => {
  const navigate = useNavigate();
  const theme = useTheme();

  const listItemStyle = {
    height: '50px',
    pl: isDrawerOpened ? 4 : 2,
    '& svg': {
      opacity: 0.55,
    },
    '&.Mui-selected svg': {
      opacity: 1,
    },
    '&.Mui-selected': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      '&:hover': {
        background: theme.palette.primary.main,
        opacity: 1,
      },
    },
    transition: theme.transitions.create('padding', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  };

  const accordionHeaderStyle = {
    height: '50px',
    pl: isDrawerOpened ? '20px' : 2,
    pr: isDrawerOpened ? 1.5 : 3.5,
    transition: theme.transitions.create('padding', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  };

  const nestedAccordionHeaderStyle = {
    height: '50px',
    pl: isDrawerOpened ? 2 : 2,
    pr: isDrawerOpened ? 0.5 : 3,
    transition: theme.transitions.create('padding', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  };

  const nestedAccordionItemStyle = {
    height: '50px',
    pl: isDrawerOpened ? 6 : 2,
    '& svg': {
      opacity: 0.55,
    },
    '&.Mui-selected svg': {
      opacity: 1,
    },
    '&.Mui-selected': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      '&:hover': {
        background: theme.palette.primary.main,
        opacity: 1,
      },
    },
    transition: theme.transitions.create('padding', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  };

  return (
    <Box width="100%" key={id}>
      <ListItemTooltip title={!isDrawerOpened ? text : ''}>
        <ListItemButton
          data-testid={`c-nav-accordion-item-${id}`}
          sx={accordionHeaderStyle}
          selected={opened}
          onClick={() => handleAccordions(id)}
        >
          {icon && (
            <ListItemIcon>
              <Avatar>{icon}</Avatar>
            </ListItemIcon>
          )}

          <ListItemText
            sx={{
              visibility: isDrawerOpened ? 'visible' : 'hidden',
              transition: theme.transitions.create(['visibility'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
              }),
            }}
            primary={<Typography variant="h6">{text}</Typography>}
            secondary={<Typography variant="body2">{subtext}</Typography>}
          />
          {items &&
            (opened ? (
              <ExpandLess sx={{ color: theme.palette.grey[600] }} />
            ) : (
              <ExpandMore sx={{ color: theme.palette.grey[600] }} />
            ))}
        </ListItemButton>
      </ListItemTooltip>
      {items && (
        <Collapse
          sx={{ borderBottom: divider ? `1px solid ${theme.palette.grey[300]}` : 'none' }}
          in={opened}
          timeout="auto"
          unmountOnExit
        >
          <List data-testid="c-accordion-content" component="div" disablePadding>
            {items
              .filter((el) => el.visible)
              .map((item, index) => {
                //  If menu item has children as nested links
                //  then we render an additional accordion level
                if (item.items) {
                  /**
                   * This boolean indicates if the nested accordion
                   * item should trigger navigation
                   */
                  const shouldNavigate = item.path && !item?.opened;

                  return (
                    <div key={item.testId}>
                      <ListItemTooltip title={!isDrawerOpened ? item?.label : ''}>
                        <ListItemButton
                          data-testid={`c-nav-accordion-item-${item.testId}`}
                          sx={nestedAccordionHeaderStyle}
                          selected={item?.opened}
                          onClick={() => {
                            shouldNavigate && navigate(item.path);
                            handleAccordions(item.testId);
                          }}
                        >
                          {item?.icon && isDrawerOpened && (
                            <ListItemIcon
                              sx={{
                                minWidth: 35,
                                color: 'currentColor',
                              }}
                            >
                              {item.icon}
                            </ListItemIcon>
                          )}
                          <ListItemText
                            sx={{
                              visibility: isDrawerOpened ? 'visible' : 'hidden',
                              transition: theme.transitions.create(['visibility'], {
                                easing: theme.transitions.easing.sharp,
                                duration: theme.transitions.duration.leavingScreen,
                              }),
                            }}
                            primary={
                              <Typography
                                sx={{
                                  width: '200px',
                                  textOverflow: 'ellipsis',
                                  overflowX: 'hidden',
                                }}
                              >
                                {item.label}
                              </Typography>
                            }
                            secondary={item?.subtext}
                          />
                          <Box pr={1}>
                            {items &&
                              (item?.opened ? (
                                <ExpandLess sx={{ color: theme.palette.grey[600] }} />
                              ) : (
                                <ExpandMore sx={{ color: theme.palette.grey[600] }} />
                              ))}
                          </Box>
                        </ListItemButton>
                      </ListItemTooltip>

                      {/* Nested link items */}
                      <Collapse in={item.opened} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                          {item.items
                            .filter((el) => el.visible)
                            .map((nestedLink, index) => (
                              <ListItemTooltip
                                key={nestedLink.testId}
                                title={!isDrawerOpened ? nestedLink?.label : ''}
                              >
                                <ListItemButton
                                  data-testid={`${nestedLink.testId}`}
                                  selected={nestedLink.selected}
                                  onClick={() => {
                                    navigate(`/${systemId}${nestedLink.path}`);
                                  }}
                                  sx={{
                                    ...nestedAccordionItemStyle,
                                    pointerEvents: nestedLink.selected ? 'none' : 'auto',
                                    '& svg': {
                                      opacity: 0.55,
                                    },
                                    '&.Mui-selected svg': {
                                      opacity: 1,
                                    },
                                    '&.Mui-selected': {
                                      backgroundColor:
                                        item.statusColor || theme.palette.primary.main,
                                      color: theme.palette.primary.contrastText,
                                      '&:hover': {
                                        background: item.statusColor || theme.palette.primary.main,
                                        opacity: 1,
                                      },
                                    },
                                  }}
                                >
                                  {nestedLink?.icon && (
                                    <ListItemIcon
                                      sx={{
                                        minWidth: 35,
                                        color: 'currentColor',
                                      }}
                                    >
                                      {nestedLink.icon}
                                    </ListItemIcon>
                                  )}
                                  <ListItemText
                                    sx={{
                                      visibility: isDrawerOpened ? 'visible' : 'hidden',
                                      transition: theme.transitions.create(['visibility'], {
                                        easing: theme.transitions.easing.sharp,
                                        duration: theme.transitions.duration.leavingScreen,
                                      }),
                                    }}
                                    primary={nestedLink?.label}
                                    secondary={nestedLink?.subtext}
                                  />
                                </ListItemButton>
                              </ListItemTooltip>
                            ))}
                        </List>
                      </Collapse>
                    </div>
                  );
                }
                //  If menu item doesn't have nested links then
                //  we render it as default link item
                return (
                  <ListItemTooltip key={item.testId} title={!isDrawerOpened ? item?.label : ''}>
                    <ListItemButton
                      data-testid={item.testId}
                      selected={item.selected}
                      onClick={() => {
                        // If item path is undefined then 'action' function will be fired
                        // NOTE: Currently we have that only for DEV-TOOLS
                        if (!isNil(item.path)) {
                          navigate(`/${systemId}${item.path}`);
                        } else {
                          item?.action();
                        }
                      }}
                      sx={{
                        ...listItemStyle,
                        pointerEvents: () => {
                          if (item.pointerEvents) {
                            return item.pointerEvents || 'auto';
                          }
                          return item.selected ? 'none' : 'auto';
                        },
                      }}
                    >
                      {item?.icon && (
                        <ListItemIcon
                          sx={{
                            minWidth: 35,
                            color: 'currentColor',
                          }}
                        >
                          {item.icon}
                        </ListItemIcon>
                      )}
                      <ListItemText
                        sx={{
                          visibility: isDrawerOpened ? 'visible' : 'hidden',
                          transition: theme.transitions.create(['visibility'], {
                            easing: theme.transitions.easing.sharp,
                            duration: theme.transitions.duration.leavingScreen,
                          }),
                        }}
                        primary={item?.label}
                        secondary={item?.subtext}
                      />
                    </ListItemButton>
                  </ListItemTooltip>
                );
              })}
          </List>
        </Collapse>
      )}
    </Box>
  );
};
