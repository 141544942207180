import { useState } from 'react';
import Grid from '@mui/material/Grid';

import { Spinner } from 'components/common/Spinner';

import { useSearchParams } from 'react-router-dom';
import { useFacilityLevelStore } from 'store/FacilityLevelStore/facilityLevelStore';
import { ILocationData1ST } from 'codegen/report';
import { getMostRecent } from 'common/functions/dateTimeFunctions';
import { ModalBase } from '../ModalBase';
import {
  IFacilityModalsState,
  ILocationData,
} from '../../../store/Modals/facilityModals/IFacilityModalsStore';
import { LocationModalHelp } from './LocationModalHelp';
import { LocationModalHeader } from './header/LocationModalHeader';
import { ImageGrid } from './image-grid/ImageGrid';
import { FeedbackDrawer } from './feedback-drawer/FeedbackDrawer';
import { FeedbackDrawerToggler } from './feedback-drawer/FeedbackDrawerToggler';
import { LocationModalSubHeader } from './header/LocationModalSubHeader';
import { BarcodesInformation } from './barcodes/BarcodesInformation';
import { LocationHistory } from './location-history/LocationHistory';
import { WMSRawData } from './wms-raw-data/WMSRawData';
import { useLocationModal } from './hooks/useLocationModal';
import { FEATURE_FLAG_MAP_3D } from './map-3d/map3DCard.model';
import { Map3DCard } from './map-3d/Map3DCard';
import { useLocationImages } from './hooks/useLocationImages';

export const LocationModal = ({
  opened,
  closeModal,
  refreshDataOnClose,
  filteredLocationList,
  parentPage,
  reportId,
  allLocations,
}: {
  opened: boolean;
  closeModal: () => void;
  refreshDataOnClose: IFacilityModalsState['refreshDataOnClose'];
  filteredLocationList: ILocationData[];
  allLocations?: ILocationData1ST[];
  parentPage: 'WarehouseStatus' | 'Report';
  reportId: string | null | undefined;
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [feedbackDrawerOpen, setFeedbackDrawerOpen] = useState(false);
  const [didUserOverwrite, setDidUserOverwrite] = useState(false);
  const [highlightedBarcode, setHighlightedBarcode] = useState('');

  const { currentSystemId: systemId = '', facilitySettings } =
    useFacilityLevelStore().stateFacilityLevel;

  const {
    sendLocationInfo,
    refreshLocationData,
    spinnerLoading,
    currentLocationData,
    currentLocationIndex,
    setCurrentLocationIndex,
  } = useLocationModal(systemId, filteredLocationList, parentPage, reportId);

  const locationImages = useLocationImages(systemId ?? '', currentLocationData);

  const isSnoozeEnabled = parentPage === 'WarehouseStatus';

  const handleCloseModal = () => {
    if (didUserOverwrite) {
      refreshDataOnClose?.refreshData();
    }
    searchParams.delete('location');
    setSearchParams(searchParams);
    closeModal();
  };

  const historyLimitTimePoint =
    parentPage === 'Report'
      ? getMostRecent(
          currentLocationData.rowData.wmsDate,
          currentLocationData.rowData.contentFoundDate,
        )
      : undefined;

  return (
    <ModalBase
      help={LocationModalHelp}
      testId="c-location-modal"
      opened={opened}
      maxWidth={false}
      fullScreen
      contentPadding="20px 60px"
      disableBackdropClick={true}
      title={
        <LocationModalHeader
          enableSnooze={isSnoozeEnabled}
          locationData={currentLocationData}
          filteredLocationList={filteredLocationList}
          refreshLocationData={refreshLocationData}
          currentLocationIndex={currentLocationIndex}
          setCurrentLocationIndex={setCurrentLocationIndex}
          facilitySettings={facilitySettings}
          locationImages={locationImages}
        />
      }
      handleClose={handleCloseModal}
    >
      {spinnerLoading && <Spinner />}

      {currentLocationData && (
        <LocationModalSubHeader
          locationData={currentLocationData}
          facilitySettings={facilitySettings}
          parentPage={parentPage}
        />
      )}

      <Grid container>
        <Grid item lg={feedbackDrawerOpen ? 9 : 12} sm={feedbackDrawerOpen ? 8 : 12}>
          <Grid spacing={1} container sx={{ overflowX: 'auto' }}>
            <Grid lg={12} md={12} sm={12} item sx={{ minWidth: 1100 }}>
              <BarcodesInformation
                locationData={currentLocationData}
                facilitySettings={facilitySettings}
                showBarcodeHighlighting={locationImages.showBarcodeHighlighting}
                highlightedBarcode={highlightedBarcode}
                setHighlightedBarcode={setHighlightedBarcode}
              />
            </Grid>
          </Grid>
          <Grid lg={12} md={12} sm={12} item paddingTop={2}>
            <ImageGrid
              systemId={systemId ?? ''}
              locationData={currentLocationData}
              locationImages={locationImages}
              highlightedBarcode={highlightedBarcode}
              setHighlightedBarcode={setHighlightedBarcode}
            />
          </Grid>
          {searchParams.has(FEATURE_FLAG_MAP_3D) ? (
            <Grid xs={12} item paddingTop={3} position="relative">
              <Map3DCard
                allLocations={allLocations}
                systemId={systemId ?? ''}
                currentLocationName={currentLocationData.location}
              />
            </Grid>
          ) : null}
          <Grid container spacing={1} columnSpacing={2} paddingTop={3}>
            <Grid lg={6} sm={12} item>
              {currentLocationData.location && systemId && (
                <WMSRawData
                  slotLabel={currentLocationData.location}
                  systemId={systemId}
                  key={currentLocationData.location}
                />
              )}
            </Grid>
            <Grid lg={6} sm={12} item>
              {currentLocationData.location && systemId && (
                <LocationHistory
                  slotLabel={currentLocationData.location}
                  systemId={systemId}
                  timePoint={historyLimitTimePoint}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
        {feedbackDrawerOpen && (
          <Grid item>
            <FeedbackDrawer
              isOpen={feedbackDrawerOpen}
              locationData={currentLocationData}
              enableSnooze={isSnoozeEnabled}
              refreshLocationData={refreshLocationData}
              setDidUserOverwrite={setDidUserOverwrite}
              sendLocationInfo={sendLocationInfo}
            />
          </Grid>
        )}
      </Grid>
      <FeedbackDrawerToggler
        feedbackDrawerOpen={feedbackDrawerOpen}
        setFeedbackDrawerOpen={setFeedbackDrawerOpen}
      />
    </ModalBase>
  );
};
