import { Container } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getLogPrefixForType } from 'common/functions/logFunctions';
import { PageHeaderSection } from 'components/Page/PageHeaderSection';
import { ILocationDataST, ISlotSettingsSTExclusionStatusEnum } from 'codegen/warehouse_status';
import { useTypedReducer } from 'hooks/useTypedReducer';
import { useFacilityLevelStore } from 'store/FacilityLevelStore/facilityLevelStore';
import { useRequestController } from 'hooks';
import { LocationsTable } from './features/locations-table/LocationsTable';
import { LocationsActionNames } from './reducer/LocationsActions';
import { getLocations } from './api/GetLocations';
import { updateExclusionStatus } from './api/UpdateExclusionStatus';
import { LocationsDrawer } from './features/locations-drawer/LocationsDrawer';
import { LocationsUpdateModal } from './features/modals/LocationsUpdateModal';
import { RemoveExclusionModal } from './features/modals/RemoveExclusionModal';
import { LocationRow } from './models/LocationsRow';
import { locationsReducer } from './reducer/LocationsReducer';
import { locationsInitialState } from './reducer/LocationsState';

const lp = getLogPrefixForType('COMPONENT', 'Locations');

/**
 * Finds first unknown label of selection in known locations
 * @param knownLocations All locations
 * @param testSelection Labels of locations to be selected
 * @returns null if all are known, otherwise the of first 100 unknown locations
 */
const checkForUnknownId = (
  knownLocations: ILocationDataST[],
  testSelection: string[],
): string | null => {
  const unknownIds: Array<string> = [];
  testSelection.some((id) => {
    const loc = knownLocations.findIndex((l) => l.slot_label === id) < 0 ? id : null;

    if (loc) unknownIds.push(loc);

    // Stop iterating if we have collected 100 unknown IDs
    // NOTE: after 100 unknown locations we stopped: this should be enough to verify a file
    // of a facility whose map has been updated. At the same time we don't want this list to
    // explode if the user uploads an exclusion list related to a completely different facility.
    // eno 2023-11-22
    return unknownIds.length >= 100;
  });

  return unknownIds.join(', ');
};

/**
 * Locations
 * @returns component
 */
export const Locations = () => {
  const { stateFacilityLevel } = useFacilityLevelStore();
  const { requestController } = useRequestController('Locations');
  const { systemId = '' } = useParams();

  const [
    {
      selectedIds,
      locations,
      uploadedFile,
      idSelectedForReset,
      idSelectedForDetails,
      exclusionHistory,
    },
    dispatch,
  ] = useTypedReducer(locationsReducer, locationsInitialState);

  const [tableLoadingRequestIds, setTableLoadingRequestIds] = useState<string[]>([]);
  const [locationsUpdateRequestIds, setLocationsUpdateRequestIds] = useState<string[]>([]);

  const isTableLoading: boolean = useMemo(
    () =>
      tableLoadingRequestIds.some((r) => requestController.isExecuting[r]) ||
      locationsUpdateRequestIds.some((r) => requestController.isExecuting[r]),
    [locationsUpdateRequestIds, requestController.isExecuting, tableLoadingRequestIds],
  );

  const numberOfSelectedRows: number = selectedIds.length;
  const location = locations[idSelectedForDetails];
  const excludedLocationHistory =
    exclusionHistory && location?.slot_settings?.exclusion_request_id
      ? exclusionHistory[location.slot_settings?.exclusion_request_id]
      : null;

  /**
   * Tries to select location ids, will fail in case unknown id is supplied
   * @param selectedIds location ids to be selected
   * @returns null if OK, string of unknown id otherwise
   */
  const handleSelectedIds = (selectedIds: string[]): string | null => {
    const unknownId = checkForUnknownId(Object.values(locations), selectedIds);

    if (unknownId) {
      console.warn(lp, `Trying to select unknown location ${unknownId}`);
    } else {
      dispatch({ type: LocationsActionNames.SET_SELECTED_IDS, payload: selectedIds });
    }

    return unknownId;
  };

  const handleUploadedFile = (file: File | null) =>
    dispatch({ type: LocationsActionNames.SET_UPLOADED_FILE, payload: file });

  const setIdForDetails = (id: string) => {
    console.debug(lp, `setIdForDetails(${id})`);
    dispatch({ type: LocationsActionNames.SET_ID_FOR_DETAILS, payload: id });
  };

  const handleRowClick = (e: KeyboardEvent, row: LocationRow) => setIdForDetails(row.id);

  const handleCloseDrawer = () => setIdForDetails('');

  const handleUpdateExclusionStatus = (
    reason: string,
    action: ISlotSettingsSTExclusionStatusEnum,
    locationsIds: string[] = selectedIds,
  ) => {
    setLocationsUpdateRequestIds([]);

    const reqIds = updateExclusionStatus({
      locationsIds,
      reason,
      exclusionStatus: action,
      dispatch,
      requestController,
      systemId,
    });
    setLocationsUpdateRequestIds((availableIDs: string[]) => [...availableIDs, ...reqIds]);
  };

  const setIdForReset = (id: string) =>
    dispatch({ type: LocationsActionNames.SET_ID_FOR_RESET, payload: id });

  const loadLocations = useCallback(() => {
    const ids: string[] = getLocations({
      slots: stateFacilityLevel.slots,
      requestController,
      dispatch,
      systemId,
    });
    setTableLoadingRequestIds((availableIDs: string[]) => [...availableIDs, ...ids]);
  }, [stateFacilityLevel.slots, requestController, dispatch, systemId]);

  useEffect(loadLocations, [loadLocations]);

  console.debug(lp, `rendering with ${numberOfSelectedRows} selected rows`);

  return (
    <>
      <PageHeaderSection title="Locations management" />

      <Container maxWidth="xl" sx={{ paddingTop: '32px' }}>
        <LocationsTable
          exclusionHistory={exclusionHistory}
          isLoading={isTableLoading}
          locations={locations}
          onUpdateExclusionStatus={handleUpdateExclusionStatus}
          onRowClick={handleRowClick}
          onSelectedIds={handleSelectedIds}
          selectedLocationIds={selectedIds}
          onUploadedFile={handleUploadedFile}
          setIdForReset={setIdForReset}
          numberOfSelectedRows={numberOfSelectedRows}
          uploadedFile={uploadedFile}
        />
      </Container>
      {idSelectedForReset && (
        <RemoveExclusionModal
          onSubmit={() => handleUpdateExclusionStatus('', 'NOT_SET', [idSelectedForReset])}
          onCancel={() => setIdForReset('')}
        />
      )}
      {locationsUpdateRequestIds.some((r) => requestController.isExecuting[r]) && (
        <LocationsUpdateModal
          numOfLocations={selectedIds.length}
          totalRequests={locationsUpdateRequestIds.length}
          pendingRequests={
            locationsUpdateRequestIds.filter((r) => requestController.isExecuting[r]).length
          }
        />
      )}
      <LocationsDrawer
        isOpen={!!idSelectedForDetails}
        onClose={handleCloseDrawer}
        exclusionHistory={excludedLocationHistory}
        location={location}
      />
    </>
  );
};
