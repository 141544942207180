import { GridColDef } from '@mui/x-data-grid-premium';
import { Chip } from '@mui/material';
import { IRuleActionSTIssueLogicEnum } from 'codegen/facility_settings';
import { BarcodeRow, barcodeStatusEnum } from './BarcodeRow.model';
import { BarcodeText } from '../components/BarcodeText';
import {
  BarcodeMatchLogic,
  getBarcodeMuiColor,
} from '../../../../../common/functions/barcodes/getBarcodeMuiColor.util';
import { HeaderCell } from '../components/HeaderCell';
import { LinkCell } from '../components/LinkCell';
import { IssueHighlightedContent } from '../components/IssueHighlightedContent';
import { getIsVerityDataStale } from '../../utils/getIsVerityDataStale';
import { hasLocationEverBeScanned } from '../../utils/hasLocationEverBeScanned';

export const getBarcodeColumns = ({
  barcodeMatchLogic,
  issueLogic,
  disableFoundAtShouldBeAt,
  hideCustomerColumn,
}: {
  barcodeMatchLogic?: BarcodeMatchLogic | null;
  issueLogic?: IRuleActionSTIssueLogicEnum;
  disableFoundAtShouldBeAt: boolean;
  hideCustomerColumn?: boolean;
}): GridColDef<BarcodeRow>[] => [
  {
    field: 'barcodeStatus',
    headerName: 'Barcode status',
    renderHeader: HeaderCell,
    groupable: true,
    renderCell: (params: any) => {
      if (!params.value) {
        return null;
      }
      const row = params.row as BarcodeRow;
      if (row.locationData && !hasLocationEverBeScanned(row.locationData)) {
        return null;
      }
      const isGrouped = params.rowNode?.type === 'group';
      const rowData = row.locationData?.rowData;
      const isVerityDataStale = rowData && getIsVerityDataStale(rowData);
      const color = isVerityDataStale
        ? undefined
        : getBarcodeMuiColor(params.value, barcodeMatchLogic, issueLogic);

      if (isGrouped && params.value) {
        return (
          <Chip
            label={params.value}
            variant="outlined"
            color={color}
            sx={{
              marginRight: '5px',
            }}
          />
        );
      }

      if (
        row.barcodeStatus !== barcodeStatusEnum.None &&
        (row.locationData?.wmsState === 'BARCODE' || row.locationData?.verityState === 'BARCODE')
      ) {
        return (
          <Chip
            label={params.value}
            variant="outlined"
            color={color}
            sx={{
              marginRight: '5px',
            }}
          />
        );
      }
      return null;
    },
    flex: 1,
  },
  {
    field: 'expectedContent',
    headerName: 'Expected content',
    renderHeader: HeaderCell,
    renderCell: (params: any) => {
      if (!params.row.barcodeStatus) {
        return params.value;
      }

      const isStale =
        getIsVerityDataStale(params.row.locationData.rowData) ||
        !hasLocationEverBeScanned(params.row.locationData);

      const row = params.row as BarcodeRow;
      if (row.locationData?.wmsState === 'BARCODE') {
        return (
          <BarcodeText
            params={params}
            barcodeMatchLogic={barcodeMatchLogic}
            issueLogic={issueLogic}
            isStale={isStale}
          />
        );
      }

      return (
        <IssueHighlightedContent
          value={params.value}
          locationData={row.locationData}
          isStale={isStale}
        />
      );
    },
    flex: 2,
  },
  {
    field: 'contentFound',
    headerName: 'Content found',
    renderHeader: HeaderCell,
    flex: 2,
    renderCell: (params: any) => {
      if (!params.row.barcodeStatus) {
        return params.vale;
      }

      const isStale = getIsVerityDataStale(params.row.locationData.rowData);

      const row = params.row as BarcodeRow;

      const userOverride = row.locationData.userOverride || null;

      const verityState = userOverride ? userOverride.state : row.locationData.verityState;

      if (verityState === 'BARCODE') {
        return (
          <BarcodeText
            params={params}
            barcodeMatchLogic={barcodeMatchLogic}
            issueLogic={issueLogic}
            isStale={isStale}
          />
        );
      }

      return (
        <IssueHighlightedContent
          value={params.value}
          locationData={row.locationData}
          isStale={isStale}
        />
      );
    },
  },
  ...(disableFoundAtShouldBeAt
    ? []
    : ([
        {
          field: 'foundAt',
          headerName: 'Found at',
          renderHeader: HeaderCell,
          renderCell: (params) => <LinkCell location={params.value} />,
          flex: 1,
        },
        {
          field: 'shouldBeAt',
          headerName: 'Should be at',
          renderCell: (params) => <LinkCell location={params.value} />,
          renderHeader: HeaderCell,
          flex: 1,
        },
      ] as GridColDef<BarcodeRow>[])),
  { field: 'wmsQuantity', headerName: 'WMS quantity', renderHeader: HeaderCell, flex: 1 },
  { field: 'articleNumber', headerName: 'Article number', renderHeader: HeaderCell, flex: 1 },
  ...(hideCustomerColumn
    ? []
    : ([
        {
          field: 'customer',
          headerName: 'Customer',
          renderHeader: HeaderCell,
          flex: 1,
        },
      ] as GridColDef<BarcodeRow>[])),
];
