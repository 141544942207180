import React from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { WeekDayShort } from 'common/datetimeFormats';

interface IWeekdaysFormProps {
  days: WeekDayShort[];
  setDays: (days: WeekDayShort[]) => void;
}

export const WeekdaysForm = ({ days, setDays }: IWeekdaysFormProps) => {
  const handleDays = (e: React.SyntheticEvent, newDays: WeekDayShort[]) => {
    setDays(newDays);
  };

  const weekDays = [
    {
      value: 'Mo',
      label: 'MON',
    },
    {
      value: 'Tu',
      label: 'TUE',
    },
    {
      value: 'We',
      label: 'WED',
    },
    {
      value: 'Th',
      label: 'THU',
    },
    {
      value: 'Fr',
      label: 'FRI',
    },
    {
      value: 'Sa',
      label: 'SAT',
    },
    {
      value: 'Su',
      label: 'SUN',
    },
  ];

  return (
    <ToggleButtonGroup
      data-testid="weekdays-toggle-group"
      value={days}
      size="large"
      fullWidth
      onChange={handleDays}
      aria-label="days of the week"
      color="primary"
    >
      {weekDays.map((day) => (
        <ToggleButton key={day.value} value={day.value} data-value={day.label}>
          {day.label}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};
