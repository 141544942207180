import { ReactElement } from 'react';
import CardContent from '@mui/material/CardContent';
import Alert from '@mui/material/Alert';
import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material';
import { Box } from 'components/common/Box';

import { Spinner } from 'components/common/Spinner';
import { baseCardStyle } from './styles';
import '../facilityCardStyle.css';

import { SettingsListItem } from './settings-list-item/SettingsListItem';
import { SettingsItem } from './models/settingsCard.model';
import {
  renderSwitchInput,
  renderButton,
  renderNumberInput,
  renderInputWithActivation,
  renderTextInput,
  renderSelectInput,
  renderMultiSelectInput,
  renderMultiSelectInputOptionsDescriptions,
} from './SettingsCardFunctions';
import { CustomIssueLogicEditor } from './sections/issue-logic/custom-issue-logic-editor/CustomIssueLogicEditor';

const useStyles = makeStyles()((theme: Theme) => ({
  ...baseCardStyle(theme),
}));

export const SettingsCardContent = ({
  isLoading = false,
  items = [],
  content,
}: {
  isLoading?: boolean;
  items?: SettingsItem[];
  content?: ReactElement;
}) => {
  const { classes } = useStyles();

  return (
    <CardContent className={classes.cardContentSection}>
      {!isLoading ? (
        items &&
        items
          .filter((item) => !item.hidden)
          .map((item) => {
            switch (item.type) {
              case 'switch':
                return (
                  <SettingsListItem
                    classes={classes}
                    item={item}
                    key={item.label}
                    testId={item.testId}
                  >
                    {renderSwitchInput(item)}
                  </SettingsListItem>
                );
              case 'button':
                return (
                  <SettingsListItem
                    classes={classes}
                    item={item}
                    key={item.label}
                    testId={item.testId}
                  >
                    {renderButton(item)}
                  </SettingsListItem>
                );

              case 'number':
                return (
                  <SettingsListItem
                    classes={classes}
                    item={item}
                    key={item.label}
                    testId={item.testId}
                  >
                    {renderNumberInput(item, classes)}
                  </SettingsListItem>
                );

              case 'number with activation':
                return (
                  <SettingsListItem
                    classes={classes}
                    item={item}
                    key={item.label}
                    testId={item.testId}
                  >
                    {renderInputWithActivation(item, classes)}
                  </SettingsListItem>
                );

              case 'text':
                return (
                  <SettingsListItem
                    classes={classes}
                    item={item}
                    key={item.label}
                    testId={item.testId}
                  >
                    {renderTextInput(item, classes)}
                  </SettingsListItem>
                );

              case 'json-textarea':
                return <CustomIssueLogicEditor classes={classes} item={item} key={item.label} />;

              case 'select':
                return (
                  <SettingsListItem
                    classes={classes}
                    item={item}
                    key={item.label}
                    testId={item.testId}
                  >
                    {renderSelectInput(item)}
                  </SettingsListItem>
                );

              case 'multi-select':
                return (
                  <div key={item.label}>
                    <SettingsListItem classes={classes} item={item} testId={item.testId}>
                      {renderMultiSelectInput(item)}
                    </SettingsListItem>
                    <Alert severity="info">{renderMultiSelectInputOptionsDescriptions(item)}</Alert>
                  </div>
                );

              default:
                return null;
            }
          })
      ) : (
        <Spinner />
      )}
      {!isLoading ? (
        content && content
      ) : (
        <Box py={5}>
          <Spinner />
        </Box>
      )}
    </CardContent>
  );
};
