import { ILocationDataST } from 'codegen/report';
import { STATE_TO_MESSAGE_MAP } from '../models/stateToMessageMap.model';

export const stateToMessageMapper = (locationData: ILocationDataST) => {
  const stateCanBeMappedToMessage =
    !locationData.verity_status &&
    locationData.state &&
    Object.keys(STATE_TO_MESSAGE_MAP).includes(locationData.state);

  const verityStatus =
    locationData.state && stateCanBeMappedToMessage
      ? STATE_TO_MESSAGE_MAP[locationData.state]
      : locationData.verity_status;

  return {
    ...locationData,
    verity_status: verityStatus,
  };
};
