import { createSvgIcon } from '@mui/material/utils';

interface IIconProps {
  color: string;
  fontSize?: string;
}

export const PackageIcon = ({ color, fontSize }: IIconProps) => {
  const Icon = createSvgIcon(
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="box">
        <path
          id="Vector"
          d="M20.4901 7.52045C20.4844 7.49408 20.4844 7.46681 20.4901 7.44045C20.4852 7.41737 20.4852 7.39353 20.4901 7.37045V7.28045L20.4301 7.13045C20.4057 7.08952 20.3754 7.05244 20.3401 7.02045L20.2501 6.94045H20.2001L16.2601 4.45045L12.5401 2.15045C12.454 2.08218 12.3555 2.03124 12.2501 2.00045H12.1701C12.0807 1.98553 11.9894 1.98553 11.9001 2.00045H11.8001C11.6839 2.02614 11.5725 2.07001 11.4701 2.13045L4.00007 6.78045L3.91007 6.85045L3.82007 6.93045L3.72007 7.00045L3.67007 7.06045L3.61007 7.21045V7.30045V7.36045C3.60035 7.42676 3.60035 7.49413 3.61007 7.56045V16.2904C3.60973 16.4604 3.6527 16.6276 3.73494 16.7763C3.81717 16.9251 3.93596 17.0504 4.08007 17.1404L11.5801 21.7804L11.7301 21.8404H11.8101C11.9792 21.8941 12.1609 21.8941 12.3301 21.8404H12.4101L12.5601 21.7804L20.0001 17.2104C20.1442 17.1204 20.263 16.9951 20.3452 16.8463C20.4274 16.6976 20.4704 16.5304 20.4701 16.3604V7.63045C20.4701 7.63045 20.4901 7.56045 20.4901 7.52045ZM12.0001 4.17045L13.7801 5.27045L8.19007 8.73045L6.40007 7.63045L12.0001 4.17045ZM11.0001 19.1704L5.50007 15.8104V9.42045L11.0001 12.8204V19.1704ZM12.0001 11.0604L10.0901 9.91045L15.6801 6.44045L17.6001 7.63045L12.0001 11.0604ZM18.5001 15.7804L13.0001 19.2004V12.8204L18.5001 9.42045V15.7804Z"
          fill={color}
        />
      </g>
    </svg>,
    'PackageIcon',
  );
  return <Icon sx={{ fontSize }} aria-label="package-icon" />;
};
