import { IReviewApproveSlotStatusPutRequestInnerInnerST } from 'codegen/review';
import { ISlotsToApproveParam } from '../interfaces/reviewInterfaces';
import { getReviewService } from './services';

/**
 * Approve the given slots
 * @param systemId system ID of the Facility
 * @param slotsToApprove slots to be approved
 * @returns nothing
 */
const approveSlotsStatus = (systemId: string, slotsToApprove: ISlotsToApproveParam) =>
  getReviewService().approveSlotStatus(
    systemId,
    slotsToApprove as unknown as Array<Array<IReviewApproveSlotStatusPutRequestInnerInnerST>>,
  );

export const reviewServices = { approveSlotsStatus };
