import { useCallback, useState } from 'react';
import BarChartIcon from '@mui/icons-material/BarChart';
import DonutSmallIcon from '@mui/icons-material/DonutSmall';
import { IconButton, Tooltip } from '@mui/material';

type ChartType = 'progress' | 'pie';

export const WarehouseStatsWidgetToggle = ({
  initialMode,
  onToggle,
}: {
  initialMode: ChartType;
  onToggle: (chartType: ChartType) => void;
}) => {
  const [mode, setMode] = useState<'progress' | 'pie'>(initialMode);

  const setChartType = useCallback(
    (chartType: ChartType) => {
      setMode(chartType);
      onToggle(chartType);
    },
    [onToggle],
  );

  if (mode === 'pie') {
    return (
      <IconButton sx={{ marginTop: '-3px' }} onClick={() => setChartType('progress')}>
        <Tooltip title="toggle chart type">
          <BarChartIcon fontSize="medium" />
        </Tooltip>
      </IconButton>
    );
  }

  return (
    <IconButton sx={{ marginTop: '-3px' }} onClick={() => setChartType('pie')}>
      <Tooltip title="toggle chart type">
        <DonutSmallIcon fontSize="medium" />
      </Tooltip>
    </IconButton>
  );
};
