import { GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium';
import { ILocationData1ST } from 'codegen/report';
import { getLocationListData } from 'common/Tables/tableFunctions';
import { IFacilityModalsAction } from 'store/Modals/facilityModals/IFacilityModalsActions';
import { FacilityModalsActionTypes } from 'store/Modals/types';
import { getFilteredLocationsFromGridApi } from './getFilteredLocationsFromGridApi.util';

export const openLocationModal = (
  selectedLocationName: string,
  gridApiRef: React.MutableRefObject<GridApiPremium>,
  dispatchFacilityModals: React.Dispatch<IFacilityModalsAction>,
  parentPage: 'WarehouseStatus' | 'Report',
  loadLocationData?: () => void,
  locationData?: ILocationData1ST[],
) => {
  const filteredLocations = getFilteredLocationsFromGridApi(gridApiRef);

  const [selectedRow] = filteredLocations.filter((l) => l.location === selectedLocationName);

  if (selectedRow) {
    dispatchFacilityModals({
      type: FacilityModalsActionTypes.TOGGLE_LOCATION_MODAL,
      payload: getLocationListData(filteredLocations, selectedRow),
      refreshDataOnClose: {
        refreshData: loadLocationData,
      },
      parentPage,
      reportId: undefined,
      allLocations: locationData,
    });
  }
};
