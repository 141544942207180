export const formatShortDate = (date: Date): string => {
  const dateFormatter = new Intl.DateTimeFormat('ja-JP', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });

  return dateFormatter.format(date).replace(/\//g, '/');
};

export const formatShortDateTime = (date: Date): string => {
  // NOTE: the ja-JP gives the format YYYY/MM/DD
  // this is imho an acceptable workaround until we'll support localization
  // see BUG https://verity-ag.atlassian.net/browse/PI-925
  // eno 2024-09-03
  const dateFormatter = new Intl.DateTimeFormat('ja-JP', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });

  const timeFormatter = new Intl.DateTimeFormat('ja-JP', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  });

  const formattedDate = dateFormatter.format(date).replace(/\//g, '/');

  const formattedTime = timeFormatter.format(date);

  const formattedDateTime = `${formattedDate} - ${formattedTime}`;

  return formattedDateTime;
};
