import { IRequestController } from '../../../../hooks';
import { ServiceVersionItem } from '../model/serviceVersionItem.model';
import { getLogPrefixForType } from '../../../../common/functions/logFunctions';
import { developerServices } from '../../../../services/DeveloperServices';
import { getVersionIfAvailable } from '../../../../common/functions/otherFunctions';

import { renderDateForTable } from '../utils/renderDateForTable.utils';

/**
 * Get the latest versions from the BE.
 * @param requestController Request Controller
 * @param systemId ID of the facility
 * @param setData Callback setting the fetched data
 * @param timezone timezone string
 * @param setIsLoading setter for the spinner
 * @returns a promise which resolves in the list of service versions
 */
export const getLatestBeVersion = (
  requestController: IRequestController,
  systemId: string,
  setData: (serviceVersionItems: ServiceVersionItem[]) => void,
  setIsLoading: (isLoading: boolean) => void,
  timezone?: string,
): Promise<ServiceVersionItem[]> => {
  const lp = getLogPrefixForType('FUNCTION', 'getLatestBeVersion');
  console.debug(lp, `invoked with systemId => ${systemId}, timezone => ${timezone}`);

  setIsLoading(true);
  return requestController.doRequest({
    request: developerServices.getAppVersions,
    requestParams: [systemId],
    messageErrorFallback: 'Failed to fetch the BE Version',
    callbackSuccess: (r: {
      data: {
        apps: ServiceVersionItem[];
      };
    }) => {
      console.debug(lp, 'received Services version info', r.data.apps);
      const services: ServiceVersionItem[] = r.data.apps.map((s) => ({
        id: s.name,
        name: s.name,
        version: s.version,
        last_updated: renderDateForTable(s.last_updated, timezone),
      }));

      const feVersion = getVersionIfAvailable();
      services.unshift({
        id: 'UDB Frontend',
        name: 'UDB Frontend',
        version: feVersion.version,
        last_updated: renderDateForTable(feVersion.feLastUpdated, timezone),
      });

      setData(services);
    },
    callbackFinally: () => {
      setIsLoading(false);
    },
  });
};
