import React from 'react';

// material-ui
import Skeleton from '@mui/material/Skeleton';
import Grid from '@mui/material/Grid';

export const AddEditUserSkeleton = () => {
  const skeletonWrapper = {
    marginBottom: '12px',
  };
  const skeletonStyle = {
    width: '250px',
    height: '50px',
    marginRight: '16px',
    borderRadius: '30px',
  };
  const fullWidthSkeleton = {
    width: '100%',
    height: '50px',
    borderRadius: '30px',
  };

  return (
    <>
      <Grid style={skeletonWrapper} spacing={4} container>
        <Grid sm={6} xs={12} item>
          <Skeleton style={skeletonStyle} variant="rectangular" />
        </Grid>
        <Grid sm={6} xs={12} item>
          <Skeleton style={skeletonStyle} variant="rectangular" />
        </Grid>
      </Grid>
      <Grid style={skeletonWrapper} spacing={4} container>
        <Grid sm={6} xs={12} item>
          <Skeleton style={skeletonStyle} variant="rectangular" />
        </Grid>
        <Grid sm={6} xs={12} item>
          <Skeleton style={skeletonStyle} variant="rectangular" />
        </Grid>
      </Grid>
      <Grid container>
        <Grid style={skeletonWrapper} sm={12} xs={12} item>
          <Skeleton variant="rectangular" style={fullWidthSkeleton} />
        </Grid>
        <Grid sm={12} xs={12} item>
          <Skeleton variant="rectangular" style={fullWidthSkeleton} />
        </Grid>
      </Grid>
    </>
  );
};
