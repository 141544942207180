import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import { AisleSummaryProps } from './model/aisleSummaryProps.model';
import { useSummaryStyles } from './styles/aisleSummary.styles';
import { Badge } from './Badge';
import { aisleSummaryDefaults } from './defaults/aisleSummary.defaults';

export const AisleSummary = ({ issues, label, className }: AisleSummaryProps) => {
  const { classes, cx } = useSummaryStyles();

  return (
    <Paper className={cx(classes.paper, className)}>
      {label ? (
        <Typography variant="body1" component="h4" className={classes.label}>
          {label}
        </Typography>
      ) : null}
      {issues.map((issue) => (
        <Box component="span" key={issue.status} className={classes.issue}>
          <Badge status={issue.status} />
          <Typography>
            {aisleSummaryDefaults[issue.status].label}: {issue.count}
          </Typography>
        </Box>
      ))}
    </Paper>
  );
};
