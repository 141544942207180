import { useState } from 'react';
import { BaseCard } from 'components/BaseCard/BaseCard';
import { Grid } from '@mui/material';
import { GridInitialStatePremium } from '@mui/x-data-grid-premium/models/gridStatePremium';
import { formatNumberWithSeparators } from 'utils/numberFormatting';
import { Box } from 'components/common/Box';
import { WarehouseStatsBar } from './WarehouseStatsBar';
import { WarehouseStatsDataSerie } from './WarehouseStats.model';
import { WarehouseStatsPie } from './WarehouseStatsPie';
import { WarehouseStatsWidgetToggle } from './WarehouseStatsWidgetToggle';

type ChartType = 'progress' | 'pie';

export const WarehouseStatsWidget = ({
  id,
  title,
  subTitle,
  unit,
  unitPrefix,
  data,
  counter,
  colors = ['red', 'blue', 'green', 'yellow', 'orange', 'grey', 'pink'],
  cols,
  onGridStateSelect,
}: {
  id: string;
  title: string;
  subTitle?: string;
  unit: string;
  unitPrefix?: string;
  data: WarehouseStatsDataSerie[];
  colors: string[];
  cols: 3 | 4 | 6;
  counter: number;
  onGridStateSelect?: (state: GridInitialStatePremium) => void;
}) => {
  const localStorageKey = `warehouse-stats-widget-${id}`;

  const [mode, setMode] = useState<'progress' | 'pie'>(
    (localStorage.getItem(localStorageKey) as ChartType) || 'pie',
  );

  return (
    <BaseCard
      cardFor="warehouse-stats"
      dataTestId="c-warehouse-stat-card"
      showHeader={true}
      showContent={true}
      showActionButtons={false}
      showHeaderDivider={true}
      title={
        <>
          {title}
          {'  '}
          <WarehouseStatsWidgetToggle
            initialMode={(localStorage.getItem(localStorageKey) as ChartType) || 'pie'}
            onToggle={(chartType: ChartType) => {
              localStorage.setItem(localStorageKey, chartType);
              setMode(chartType);
            }}
          />
        </>
      }
      subtitle={subTitle}
      counter={formatNumberWithSeparators(counter)}
      counterSubject={unitPrefix ? `${unitPrefix} ${unit}` : unit}
      hideCounterSubject={true}
    >
      {mode === 'progress' && (
        <Grid container spacing={2.5}>
          {data
            .filter((serie) => !!serie.value)
            .map((serie, index) => (
              <WarehouseStatsBar
                key={crypto.randomUUID()}
                cols={12}
                label={serie.label}
                description={serie.description}
                total={counter}
                unit={unit}
                showProgressBar={true}
                value={serie.value}
                color={colors[index]}
                onGridStateSelect={onGridStateSelect}
                gridState={serie.gridState}
              />
            ))}
        </Grid>
      )}
      {mode === 'pie' && (
        <Box alignItems="center">
          <Box width="100%" justifyContent="center" display="flex">
            <WarehouseStatsPie colors={colors} data={data} />
          </Box>
          <Grid container spacing={2.5}>
            {data.map((serie, index) => {
              if (serie.value) {
                return (
                  <WarehouseStatsBar
                    key={crypto.randomUUID()}
                    cols={cols}
                    label={serie.label}
                    description={serie.description}
                    total={counter}
                    value={serie.value}
                    unit={unit}
                    color={colors[index]}
                    showProgressBar={false}
                    gridState={serie.gridState}
                    onGridStateSelect={onGridStateSelect}
                  />
                );
              }
              return null;
            })}
          </Grid>
        </Box>
      )}
    </BaseCard>
  );
};
