import { useMemo } from 'react';
import { BoxGeometry, Mesh, MeshStandardMaterial } from 'three';

export function useBin3DMesh() {
  return useMemo(() => {
    const geometry = new BoxGeometry();
    const materialStandard = new MeshStandardMaterial({ toneMapped: false });
    const mesh = new Mesh(geometry, materialStandard);
    return mesh;
  }, []);
}
