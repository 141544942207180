import { useEffect, useState } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';

import { areAccessAndIdTokensExpired } from 'common/functions/tokenFunctions';
import { INVENTORY_PAGES_URLS } from 'common/pages';
import { getLogPrefixForType } from 'common/functions/logFunctions';
import * as tables from 'common/Tables';
import { BaseCard } from 'components/BaseCard/BaseCard';
import { BaseCardSimpleItem } from 'components/BaseCard/BaseCardSimpleItem';
import { IActiveIssueCount, IWarehouseStatus } from '../../interfaces';
import { warehouseStore } from '../../store/WarehouseStore';
import { useSettingsStore } from '../../store/Settings/settingsStore';
import { IRequestController, useRequestController } from '../../hooks';

const logPrefix = getLogPrefixForType('COMPONENT', 'WarehouseStatusCard');

/**
 * Retrieve the Warehouse Status Data
 * @param issuesTab Issues tab to be opened on click
 * @param requestController Request Controller
 * @param navigate navigate function
 * @param setSpinner react state dispatcher for the spinner
 * @param setValue react state dispatcher for the status data
 */
const getWarehouseStatusData = (
  systemId: string,
  issuesTab: number,
  requestController: IRequestController,
  navigate: NavigateFunction,
  setSpinner: React.Dispatch<React.SetStateAction<boolean>>,
  setValue: React.Dispatch<React.SetStateAction<IWarehouseStatus>>,
) => {
  const { signal } = requestController.reserveSlotForRequest();

  requestController.doRequest({
    request: warehouseStore.getActiveIssueCount,
    requestParams: [systemId, signal],
    callbackBeforeSend: () => setSpinner(true),
    callbackFinally: () => setSpinner(false),
    messageErrorFallback: 'Something went wrong when loading data for the warehouse status card',
    callbackSuccess: (r: IActiveIssueCount) => {
      console.debug(logPrefix, 'warehouseStore.getActiveIssueCount:then');
      let { warehouseStatus } = r;

      warehouseStatus = {
        ...warehouseStatus,
        actionButtons: [
          {
            variant: 'contained',
            color: 'primary',
            fullWidth: true,
            label: 'See all issues',
            clickHandler: () =>
              navigate(`/${systemId}${INVENTORY_PAGES_URLS.WAREHOUSE_STATUS}`, {
                state: { activeTab: issuesTab },
              }),
          },
        ],
      };

      setValue(() => warehouseStatus);
    },
  });
};

export const WarehouseStatusCard = (props: { systemId: string }) => {
  const { WAREHOUSE_TABLE_TABS } = useSettingsStore();
  const { issuesTab } = tables.warehouseTabsMappings(WAREHOUSE_TABLE_TABS);

  const { systemId } = props;

  const [spinner, setSpinner] = useState(false);
  const [warehouseStatus, setWarehouseStatus] = useState<IWarehouseStatus>({
    id: 'warehouse-status-card',
    title: 'Warehouse status',
    counter: 0,
    counterSubject: 'issues',
    subtitle: 'An overview of the current issues in the warehouse.',
    sections: [],
  });

  const navigate = useNavigate();
  const { requestController } = useRequestController(logPrefix);

  useEffect(() => {
    if (!areAccessAndIdTokensExpired()) {
      getWarehouseStatusData(
        systemId,
        issuesTab,
        requestController,
        navigate,
        setSpinner,
        setWarehouseStatus,
      );
    }
  }, [issuesTab, navigate, requestController, systemId]);

  return (
    <BaseCard
      cardFor="warehouse status"
      dataTestId="c-warehouse-status-card"
      showHeader={true}
      showContent={true}
      showActionButtons={true}
      showHeaderDivider={true}
      isLoading={spinner}
      title={warehouseStatus.title}
      subtitle={warehouseStatus.subtitle}
      counter={warehouseStatus.counter}
      counterSubject={warehouseStatus.counterSubject}
      actionButtons={warehouseStatus.actionButtons}
      sx={{ order: 1 }}
    >
      {warehouseStatus.sections &&
        warehouseStatus.sections.map((section) => (
          <BaseCardSimpleItem
            key={section.id}
            title={section.title}
            sectionItems={section.sectionItems}
          />
        ))}
    </BaseCard>
  );
};
