import { Alert, Box } from '@mui/material';

export const DomainSwitchAlert = () => (
  <Alert sx={{ fontSize: 'large' }} severity="info" key={0}>
    <Box component="div" padding="1em">
      <Box component="div">
        The Verity Dashboard will switch domains from <b>verity.ch</b> to <b>verity-app.net</b>.
      </Box>
      <Box component="div" marginTop={3}>
        Your browser will automatically redirect you to the new domain if you try to access the
        Verity Dashboard via the previous domain.
      </Box>
    </Box>
  </Alert>
);
