import React, { useState } from 'react';
import { Theme } from '@mui/material';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { makeStyles } from 'tss-react/mui';
import { handleChangePassword } from 'common/functions/userFunctions';
import { TokenManager } from 'common/tokenManager';
import { Box } from 'components/common/Box';
import { Spinner } from 'components/common/Spinner';
import { InputPassword } from 'udb/sign-in/features/InputPassword/InputPassword';
import { useRequestController } from 'hooks';
import { useClientModalsStore } from 'store/Modals';
import { PasswordHelper } from '../../PasswordHelper/PasswordHelper';
import { ModalBase } from '../ModalBase';

const tokenManager = TokenManager.getInstance();

const useStyles = makeStyles()((theme: Theme) => ({
  title: {
    fontWeight: 'bold',
  },
  inputField: {
    width: '100%',
    marginBottom: theme.spacing(3),
  },
}));

interface IChangePasswordModalProps {
  opened: boolean;
  closeModal: () => void;
}

export const ChangePasswordModal = ({ opened, closeModal }: IChangePasswordModalProps) => {
  const { classes } = useStyles();

  const accessToken = tokenManager.getAccessToken() || '';

  // NOTE: this component never gets unmounted and, hence, it doesn't matter
  // whether requests are set as cancellable or not.
  const { requestController } = useRequestController('ChangePasswordModal');
  const { dispatchClientModals } = useClientModalsStore();

  const [values, setValues] = useState({
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
  });
  const [passwordError, setPasswordError] = useState(false);
  const [loading, setLoading] = useState(false);

  /**
   * Handle change password field value
   * @param prop field name
   *
   */
  const handleChange = (prop: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, [prop]: e.target.value });
  };

  /**
   * Submit password function
   */
  const submitPassword = () => {
    const data = {
      previous_password: values.currentPassword,
      proposed_password: values.newPassword,
      access_token: accessToken,
    };
    handleChangePassword({ data, requestController, setSpinner: setLoading, dispatchClientModals });
  };

  const buttonDisabled =
    Boolean(!values.currentPassword || !values.newPassword) ||
    values.newPassword !== values.confirmPassword ||
    passwordError;

  return (
    <ModalBase
      opened={opened}
      testId="c-change-password-modal"
      maxWidth="xs"
      title={
        <Box textAlign="left" p={2}>
          <Typography className={classes.title} color="secondary" variant="h5">
            Change password
          </Typography>
          <Typography color="secondary" variant="subtitle1">
            Here you can modify your current password
          </Typography>
        </Box>
      }
      handleClose={closeModal}
      actionButtons={
        <>
          <Button onClick={closeModal} variant="outlined" fullWidth color="primary">
            Cancel
          </Button>
          <Button
            data-testid="c-submit-change-password-button"
            onClick={submitPassword}
            disabled={buttonDisabled}
            variant="contained"
            fullWidth
            color="primary"
          >
            Submit
          </Button>
        </>
      }
    >
      <InputPassword
        id="currentPassword"
        inputProps={{ 'data-testid': 'c-current-password-input' }}
        variant="outlined"
        label="Current password"
        customClasses={classes.inputField}
        value={values.currentPassword}
        validate={false}
        activeError={setPasswordError}
        onChange={handleChange('currentPassword')}
      />
      <InputPassword
        id="newPassword"
        inputProps={{ 'data-testid': 'c-new-password-input' }}
        variant="outlined"
        label="New password"
        customClasses={classes.inputField}
        value={values.newPassword}
        validate={true}
        activeError={setPasswordError}
        disabled={!values.currentPassword}
        onChange={handleChange('newPassword')}
      />
      <InputPassword
        id="confirmPassword"
        inputProps={{ 'data-testid': 'c-confirm-password-input' }}
        variant="outlined"
        label="Confirm password"
        confirmPassword={values.newPassword}
        customClasses={classes.inputField}
        value={values.confirmPassword}
        validate={true}
        activeError={setPasswordError}
        disabled={!values.newPassword}
        onChange={handleChange('confirmPassword')}
      />
      {loading && <Spinner />}
      <PasswordHelper />
    </ModalBase>
  );
};
