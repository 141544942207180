import { AxiosResponse } from 'axios';
import { getLogPrefixForType } from 'common/functions/logFunctions';
import { requestQueueHandler } from 'common/requestHelpers';
import { NETWORK_REQUEST_SETTINGS } from 'common/settings';
import { zeroTo } from 'shared/map-container/utils/3DmapFunctions';
import { ILocationDataST } from 'codegen/warehouse_status';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useFacilityLevelStore } from 'store/FacilityLevelStore/facilityLevelStore';
import { NetworkRequest } from 'ts-types/NetworkRequest';
import { ReservedSlot, useRequestController } from '../../../../../hooks';
import { warehouseServices } from '../../../../../services/WarehouseServices';
import { transformLocationData } from '../utils/transformLocationData';

const logPrefix = getLogPrefixForType('HOOK', 'useLocationData');

export const useLocationData = (systemId: string) => {
  const { stateFacilityLevel } = useFacilityLevelStore();
  const { slots } = stateFacilityLevel;

  const { requestController } = useRequestController('WarehouseStatusX');
  const [locationData, setLocationData] = useState<ILocationDataST[]>([]);

  const requestPageSize = NETWORK_REQUEST_SETTINGS.WAREHOUSE_PAGE_SIZE_FOR_FULL_REPORT_TAB;
  const requestsCount = Math.ceil(slots && slots.length / requestPageSize);
  const requestList = useRef(zeroTo(requestsCount).map(() => false));

  const isEverythingLoaded = () => {
    const areLoaded = requestList.current.every((p) => p);
    console.debug(logPrefix, `are loaded: ${areLoaded}`);
    return areLoaded;
  };

  const isLoading = () => !isEverythingLoaded();

  const loadingString = !isEverythingLoaded
    ? `- Loading... (${locationData.length} of ${slots.length} locations)`
    : '';

  const loadInBatches = useCallback(
    (makeRequestObject: (fromSlot: string, reqNr: [number, number]) => NetworkRequest) => {
      const allRequests: NetworkRequest[] = [];

      for (let i = 0; i < requestsCount; i += 1) {
        console.debug(
          logPrefix,
          `loadFullReportAndAmendedLocations for systemId: ${systemId}, page: ${i}, slot: ${
            i * requestPageSize
          }`,
        );
        const fromSlot = slots[i * requestPageSize];
        const networkRequest = makeRequestObject(fromSlot, [i, requestsCount]);

        allRequests.push(networkRequest);
      }

      requestQueueHandler(
        allRequests,
        NETWORK_REQUEST_SETTINGS.MAX_CONCURRENT_REQUESTS,
        requestController,
      );
    },
    [requestsCount, requestController, requestPageSize, slots, systemId],
  );

  const loadLocationData = useCallback(() => {
    const lp = getLogPrefixForType('FUNCTION', 'loadLocationData', logPrefix);

    setLocationData([]);
    const reservation: ReservedSlot = requestController.reserveSlotForRequest();
    requestList.current = requestList.current.map(() => false);

    const networkRequest = (fromSlot: string, reqNr: [number, number]) => ({
      requestId: reservation.requestId,
      request: () =>
        requestController.doRequest({
          request: warehouseServices.getLocationsData,
          requestParams: [systemId, fromSlot, requestPageSize, reservation.signal],
          callbackSuccess: (res: AxiosResponse<{ [key: string]: ILocationDataST }>) => {
            const newBatch = Object.values(res.data || {});
            const [batchNr, numBatches] = reqNr;

            console.debug(lp, `received batch ${batchNr}/${numBatches} of location data`, newBatch);

            setLocationData((prevState: ILocationDataST[]) => [
              ...prevState,
              ...transformLocationData(newBatch),
            ]);
          },
          callbackFinally: () => {
            const [batchNr] = reqNr;
            requestList.current[batchNr] = true;
          },
          messageErrorFallback: 'Location data could not be fetched.',
        }),
    });

    loadInBatches(networkRequest);
  }, [loadInBatches, requestController, requestPageSize, systemId]);

  useEffect(() => {
    loadLocationData();
  }, [loadLocationData]);

  return {
    isEverythingLoaded,
    loadLocationData,
    locationData,
    isLoadingLocations: isLoading(),
    loadingString,
    slots,
  };
};
