import { IOpenFlyZoneStatus1ST } from 'codegen/open_fly_zone';
import { allNumbersToFixed2 } from 'utils/numberFormatting';
import { OpenFlyZone } from '../open-fly-zone.model';
import { DroneZoneTypes } from '../drone-zones.model';
import { geometryToBottomLeftDimensions } from './geometry-to-bottom-left-dimensions';

const openFlyZoneFromDTO = (zone: IOpenFlyZoneStatus1ST & { id: string }): OpenFlyZone => {
  const { w, l, h, minX, minY, minZ, maxZ } = geometryToBottomLeftDimensions(zone.geometry);

  return {
    id: zone.id,
    type: DroneZoneTypes.openFlyZone,
    description: zone.description,
    name: zone.name,
    sizeAndPosition: allNumbersToFixed2({ w, l, h, minX, minY, minZ, maxZ }),
    isSelected: false,
    isActive: true,
  };
};

export const openFlyZonesFromDTO: (
  openFlyZones: Record<string, IOpenFlyZoneStatus1ST>,
) => Record<string, OpenFlyZone> = (openFlyZones) =>
  Object.keys(openFlyZones).reduce(
    (acc, zoneId) => ({
      ...acc,
      [zoneId]: openFlyZoneFromDTO({ id: zoneId, ...openFlyZones[zoneId] }),
    }),
    {},
  );
