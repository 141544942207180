import { Box, CircularProgress, Grid, Skeleton, Typography } from '@mui/material';

export const WarehouseStatusOverviewLoader = ({
  isLoadingLocations,
  alreadyLoaded,
  total,
}: {
  isLoadingLocations?: boolean;
  alreadyLoaded?: number;
  total?: number;
}) => {
  if (!isLoadingLocations) {
    return null;
  }

  const progress = alreadyLoaded && total ? (100 * alreadyLoaded) / total : undefined;

  return (
    <Grid
      minHeight="400px"
      item
      md={12}
      display="flex"
      marginTop="20px"
      justifyContent="center"
      position="relative"
    >
      <Grid
        container
        position="absolute"
        spacing={3}
        width="100%"
        top={0}
        left={20}
        right={0}
        bottom={0}
      >
        <Grid item xl={4} lg={6} md={12} sm={12} xs={12}>
          <Skeleton
            variant="rounded"
            width="100%"
            height="88px"
            sx={{
              borderTopLeftRadius: '16px',
              borderTopRightRadius: '16px',
              borderBottomLeftRadius: '0px',
              borderBottomRightRadius: '0px',
              bgcolor: 'white',
            }}
          />
          <Skeleton
            variant="rounded"
            width="100%"
            height="310px"
            sx={{
              marginTop: '2px',
              borderTopLeftRadius: '0px',
              borderTopRightRadius: '0px',
              borderBottomLeftRadius: '16px',
              borderBottomRightRadius: '16px',
              bgcolor: 'white',
            }}
          />
        </Grid>
        <Grid item xl={4} lg={6} md={12} sm={12} xs={12}>
          <Skeleton
            variant="rounded"
            width="100%"
            height="88px"
            sx={{
              borderTopLeftRadius: '16px',
              borderTopRightRadius: '16px',
              borderBottomLeftRadius: '0px',
              borderBottomRightRadius: '0px',
              bgcolor: 'white',
            }}
          />
          <Skeleton
            variant="rounded"
            width="100%"
            height="310px"
            sx={{
              marginTop: '2px',
              borderTopLeftRadius: '0px',
              borderTopRightRadius: '0px',
              borderBottomLeftRadius: '16px',
              borderBottomRightRadius: '16px',
              bgcolor: 'white',
            }}
          />
        </Grid>
        <Grid item xl={4} lg={6} md={12} sm={12} xs={12}>
          <Skeleton
            variant="rounded"
            width="100%"
            height="88px"
            sx={{
              borderTopLeftRadius: '16px',
              borderTopRightRadius: '16px',
              borderBottomLeftRadius: '0px',
              borderBottomRightRadius: '0px',
              bgcolor: 'white',
            }}
          />
          <Skeleton
            variant="rounded"
            width="100%"
            height="310px"
            sx={{
              marginTop: '2px',
              borderTopLeftRadius: '0px',
              borderTopRightRadius: '0px',
              borderBottomLeftRadius: '16px',
              borderBottomRightRadius: '16px',
              bgcolor: 'white',
            }}
          />
        </Grid>
      </Grid>

      <Box component="div" alignItems="center" display="flex" flexDirection="row">
        <Box component="div" display="inline-flex" position="relative">
          <CircularProgress />
          {progress && (
            <Box
              component="div"
              position="absolute"
              top={0}
              left={0}
              right={0}
              bottom={0}
              display="flex"
              alignItems="center"
              justifyContent="center"
              data-testid="c-whs-loader-percent"
            >
              <Typography
                variant="caption"
                fontWeight="bold"
                component="div"
                sx={{ color: 'text.secondary' }}
              >
                {`${Math.round(progress)}%`}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    </Grid>
  );
};
