import { GridInitialStatePremium } from '@mui/x-data-grid-premium/models/gridStatePremium';
import { initialGridState } from 'udb/inventory/features/premium-grid/defaults/initialGridState.default';
import { ISSUE_TYPES } from 'common/issueTypesAndStates';
import { IIssueSTStateEnum, ISlotSettingsSTExclusionStatusEnum } from 'codegen/report';
import { ILocationDataST } from 'codegen/warehouse_status';
import { getLatestOverwriteFromSlotStatus } from 'common/functions/slot/utils/getLatestOverwriteFromSlotStatus';

type TabObject = {
  label: string;
  show: boolean;
  gridState: GridInitialStatePremium;
  issueTypeFilters?: ISSUE_TYPES[];
  issueStateFilters?: IIssueSTStateEnum[];
  slotSettingFilter?: ISlotSettingsSTExclusionStatusEnum;
  filter?: (location: ILocationDataST) => boolean;
};

export const REPORT_TABS: TabObject[] = [
  {
    label: 'FULL REPORT',
    show: true,
    gridState: {
      ...initialGridState,
      columns: {
        columnVisibilityModel: {
          exclusion_status: false,
          'issues.0.first_found_on': false,
          expected_barcodes: false,
          found_barcodes: false,
          missing_barcodes: false,
          missing_barcodes_rate: false,
          unexpected_barcodes: false,
          unexpected_barcodes_rate: false,
          matching_barcodes: false,
          matching_barcodes_rate: false,
          content_found_amended: false,
        },
        orderedFields: [
          'slot_label',
          'wms_status.state',
          'wasFoundAt',
          'wms_status.article_nos.0',
          'wms_status.qtys.0',
          'wms_status.customers.0',
          'wms_status.changed_at',
          'verity_status.state',
          'shouldBeAt',
          'contentFoundDate',
          'issues.0.type',
          'issues.0.state',
        ],
      },
    },
  },
  {
    label: 'ISSUES',
    show: true,
    gridState: {
      ...initialGridState,
      columns: {
        columnVisibilityModel: {
          exclusion_status: false,
          'issues.0.first_found_on': true,
          expected_barcodes: false,
          found_barcodes: false,
          missing_barcodes: false,
          missing_barcodes_rate: false,
          unexpected_barcodes: false,
          unexpected_barcodes_rate: false,
          matching_barcodes: false,
          matching_barcodes_rate: false,
          contentFoundDate: false,
          'wms_status.changed_at': false,
          'wms_status.qtys.0': false,
          'wms_status.article_nos.0': false,
          wasFoundAt: false,
          content_found_amended: false,
        },
        orderedFields: [
          'slot_label',
          'issues.0.type',
          'wms_status.state',
          'verity_status.state',
          'wms_status.customers.0',
          'shouldBeAt',
          'issues.0.first_found_on',
          'issues.0.state',
        ],
      },
    },
    issueTypeFilters: [
      ISSUE_TYPES.WMS_BARCODE_VERITY_EMPTY,
      ISSUE_TYPES.WMS_BARCODE_NOT_EQUAL_VERITY_BARCODE,
      ISSUE_TYPES.WMS_EMPTY_VERITY_BARCODE,
      ISSUE_TYPES.WMS_EMPTY_VERITY_NOTEMPTY,
      ISSUE_TYPES.WMS_BARCODE_DUPLICATE,
    ],
    issueStateFilters: [IIssueSTStateEnum.New, IIssueSTStateEnum.Known],
  },
  {
    label: 'POTENTIAL',
    show: true,
    gridState: {
      ...initialGridState,
      columns: {
        columnVisibilityModel: {
          exclusion_status: false,
          wasFoundAt: false,
          'wms_status.article_nos.0': false,
          'wms_status.qtys.0': false,
          shouldBeAt: false,
          missing_barcodes: false,
          missing_barcodes_rate: false,
          unexpected_barcodes: false,
          unexpected_barcodes_rate: false,
          matching_barcodes: false,
          matching_barcodes_rate: false,
          'issues.0.type': false,
          'wms_status.changed_at': false,
          contentFoundDate: false,
          expected_barcodes: false,
          found_barcodes: false,
          content_found_amended: false,
        },
        orderedFields: [
          'slot_label',
          'wms_status.state',
          'verity_status.state',
          'wms_status.customers.0',
          'issues.0.first_found_on',
          'issues.0.state',
        ],
      },
    },
    issueTypeFilters: [
      ISSUE_TYPES.INCONCLUSIVE_WMS_BARCODE_VERITY_NOTEMPTY,
      ISSUE_TYPES.C001C_MISSING_BC,
    ],
    issueStateFilters: [IIssueSTStateEnum.New, IIssueSTStateEnum.Known],
  },
  {
    label: 'SNOOZED',
    show: true,
    gridState: {
      ...initialGridState,
      columns: {
        columnVisibilityModel: {
          exclusion_status: false,
          wasFoundAt: false,
          'wms_status.article_nos.0': false,
          'wms_status.qtys.0': false,
          'wms_status.changed_at': false,
          missing_barcodes: false,
          missing_barcodes_rate: false,
          unexpected_barcodes: false,
          unexpected_barcodes_rate: false,
          matching_barcodes: false,
          matching_barcodes_rate: false,
          contentFoundDate: false,
          expected_barcodes: false,
          found_barcodes: false,
          content_found_amended: false,
        },
        orderedFields: [
          'slot_label',
          'issues.0.type',
          'wms_status.state',
          'verity_status.state',
          'wms_status.customers.0',
          'shouldBeAt',
          'issues.0.first_found_on',
          'issues.0.state',
        ],
      },
    },
    issueStateFilters: [IIssueSTStateEnum.Snoozed],
  },
  {
    label: 'AMENDED',
    show: true,
    gridState: {
      ...initialGridState,
      columns: {
        columnVisibilityModel: {
          content_found_amended: true,
          exclusion_status: false,
          shouldBeAt: false,
          'issues.0.first_found_on': false,
          'issues.0.state': false,
          wasFoundAt: false,
          'wms_status.article_nos.0': false,
          'wms_status.qtys.0': false,
          expected_barcodes: false,
          found_barcodes: false,
          missing_barcodes: false,
          missing_barcodes_rate: false,
          unexpected_barcodes: false,
          unexpected_barcodes_rate: false,
          matching_barcodes: false,
          matching_barcodes_rate: false,
        },
        orderedFields: [
          'slot_label',
          'wms_status.state',
          'verity_status.state',
          'wms_status.customers.0',
          'wms_status.changed_at',
          'contentFoundDate',
          'issues.0.type',
          'content_found_amended',
        ],
      },
    },
    filter: (locationData: ILocationDataST) =>
      !!getLatestOverwriteFromSlotStatus(locationData.verity_status, 'client'),
  },
  {
    label: 'INCONCLUSIVE',
    show: true,
    gridState: {
      ...initialGridState,
      columns: {
        columnVisibilityModel: {
          exclusion_status: false,
          wasFoundAt: false,
          'wms_status.article_nos.0': false,
          'wms_status.qtys.0': false,
          'wms_status.changed_at': false,
          shouldBeAt: false,
          expected_barcodes: false,
          found_barcodes: false,
          missing_barcodes: false,
          missing_barcodes_rate: false,
          unexpected_barcodes: false,
          unexpected_barcodes_rate: false,
          matching_barcodes: false,
          matching_barcodes_rate: false,
          contentFoundDate: false,
          'issues.0.first_found_on': true,
          'issues.0.type': false,
          content_found_amended: false,
        },
        orderedFields: [
          'slot_label',
          'wms_status.state',
          'verity_status.state',
          'wms_status.customers.0',
          'issues.0.first_found_on',
          'issues.0.state',
        ],
      },
    },
    issueTypeFilters: [
      ISSUE_TYPES.INCONCLUSIVE_WMS_BARCODE_VERITY_NORESULT,
      ISSUE_TYPES.INCONCLUSIVE_WMS_EMPTY_VERITY_NORESULT,
    ],
    issueStateFilters: [IIssueSTStateEnum.New, IIssueSTStateEnum.Known],
  },
  {
    label: 'INVALID',
    show: true,
    gridState: {
      ...initialGridState,
      columns: {
        columnVisibilityModel: {
          exclusion_status: false,
          wasFoundAt: false,
          'wms_status.article_nos.0': false,
          'wms_status.qtys.0': false,
          shouldBeAt: false,
          'issues.0.type': false,
          expected_barcodes: false,
          found_barcodes: false,
          missing_barcodes: false,
          missing_barcodes_rate: false,
          unexpected_barcodes: false,
          unexpected_barcodes_rate: false,
          matching_barcodes: false,
          matching_barcodes_rate: false,
          content_found_amended: false,
        },
        orderedFields: [
          'slot_label',
          'wms_status.state',
          'wms_status.customers.0',
          'wms_status.changed_at',
          'verity_status.state',
          'contentFoundDate',
          'issues.0.first_found_on',
          'issues.0.state',
        ],
      },
    },
    issueTypeFilters: [ISSUE_TYPES.INCONCLUSIVE_WMS_INVALID],
  },
];
