import { IImageGetResponseST } from 'codegen/image';
import { singleRequestHandler } from '../requestHelpers';
import { issuesStore } from '../../store/IssuesStore';

export const openImageInNewTab = (
  systemId: string,
  location: string,
  setImagesDownloading: (download: boolean) => any,
  slotStatusVersion: number,
  isReview: boolean,
  imgIndex: number,
) => {
  singleRequestHandler({
    request: issuesStore.getImages,
    requestParams: [systemId, location, slotStatusVersion, isReview],
    callbackBeforeSend: () => setImagesDownloading(true),
    callbackSuccess: (r: { data: IImageGetResponseST }) => {
      const locationImageURLs = r.data?.urls ? r.data?.urls : [];
      const imageURL = locationImageURLs[imgIndex];

      // Open image on new tab
      if (imageURL) {
        window.open(imageURL, '_blank');
      }
    },
    callbackFinally: () => setImagesDownloading(false),
    messageErrorFallback: `An error occurred while opening images for ${location}`,
  });
};
