export const isPhoneValidFormat = (phoneNumber: string) => {
  // regex source: https://stackoverflow.com/questions/16699007/regular-expression-to-match-standard-10-digit-phone-number
  const phoneRegExp =
    /^\s*(?:\+?(\d{1,3}))?[W\D\s]*(\d[\W\D\s]*?\d[\D\W\s]*?\d)[\W\D\s]*(\d[\W\D\s]*?\d[\D\W\s]*?\d)[\W\D\s]*(\d[\W\D\s]*?\d[\D\W\s]*?\d[\W\D\s]*?\d)(?: *x(\d+))?\s*$/g;

  let errorText = '';

  if (!phoneNumber) {
    errorText = 'This field is required.';
  } else if (phoneNumber && !phoneRegExp.test(phoneNumber)) {
    errorText = 'Invalid phone number format';
  }
  return errorText;
};
