// material-ui lab
import Skeleton from '@mui/material/Skeleton';

// material-ui core
import { Box } from 'components/common/Box';

export const SkeletonPageHeader = () => (
  <Box justifyContent="space-between" width="100%" display="flex">
    <Box display="flex">
      <Box>
        <Skeleton variant="rectangular" style={{ marginBottom: '5px' }} width={250} height={15} />
        <Skeleton style={{ marginBottom: '5px' }} variant="rectangular" width={200} height={20} />
        <Skeleton variant="rectangular" width={200} height={40} />
      </Box>
    </Box>
  </Box>
);
