import { useQuery } from '@tanstack/react-query';
import { AxiosRequestTransformer } from 'axios';
import { apiServices as Api } from 'services/Api';
import { getMap3DService } from 'services/services';
import { NodeST } from 'shared/map-container/MapContainer.model';
import { QueryNames } from 'ts-types/QueryNames';

const removeAuthHeader: AxiosRequestTransformer = (data, headers) => {
  delete headers.Authorization;
  if (headers.common) delete headers.common.Authorization;
  return data;
};

const getMap = async (systemId: string): Promise<NodeST> => {
  const mapInfo = await getMap3DService().getFacilityMap3d(systemId);

  const mapResponse = await Api().get(
    mapInfo.data.url,

    { transformRequest: [removeAuthHeader] },
  );
  const mapJSON =
    typeof mapResponse.data === 'string' ? JSON.parse(mapResponse.data) : mapResponse.data;

  return mapJSON;
};

export function useFacilityMap(systemId: string) {
  return useQuery({
    queryKey: [QueryNames.GET_FACILITY_MAP_3D, systemId],
    queryFn: () => getMap(systemId ?? ''),
    staleTime: 24 * 60 * 1000,
  });
}
