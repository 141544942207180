import { BaseCard, IBaseCardActionButton } from 'components/BaseCard/BaseCard';
import {
  BaseCardSimpleItem,
  IBaseCardSimpleItemSectionItems,
} from 'components/BaseCard/BaseCardSimpleItem';

export type IssueSummary = {
  title: string;
  subtitle: string;
  counter: number;
  counterSubject?: string;
  actionButtons?: IBaseCardActionButton[];
  sections: {
    id: string;
    title: string;
    sectionItems: IBaseCardSimpleItemSectionItems[];
  }[];
};

export const IssueSummaryCard = (props: { issuesSummary: IssueSummary; isLoading: boolean }) => {
  const { issuesSummary, isLoading } = props;

  return (
    <BaseCard
      cardFor="issues"
      showHeader={true}
      showContent={true}
      showActionButtons={false}
      showHeaderDivider={true}
      isLoading={isLoading}
      title={issuesSummary.title}
      subtitle={issuesSummary.subtitle}
      counter={issuesSummary.counter}
      counterSubject={issuesSummary.counterSubject}
      actionButtons={issuesSummary.actionButtons}
      dataTestId="c-report-issue-summary-card"
    >
      {issuesSummary.sections &&
        issuesSummary.sections.map((section) => (
          <BaseCardSimpleItem
            key={section.id}
            title={section.title}
            sectionItems={section.sectionItems}
          />
        ))}
    </BaseCard>
  );
};
