import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';

import { Button, CardHeader, Divider, SelectChangeEvent } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Skeleton from '@mui/material/Skeleton';
import { getLogPrefixForType } from 'common/functions/logFunctions';
import { Box } from 'components/common/Box';
import { PageHeaderSection } from 'components/Page/PageHeaderSection';
import { Spinner } from 'components/common/Spinner';
import { CustomSelect } from 'components/common/CustomFormComponents/CustomSelect';
import { INVENTORY_PAGES_URLS } from 'common/pages';
import { IInventoryRequestPostRequestST } from 'codegen/inventory_request/api';
import { reportStore } from 'udb/inventory/features/reports/reducer/report-store/ReportStore';
import './customStyles.css';
import { useRequestController } from 'hooks';
import { useComponentDidMount } from 'hooks/useComponentDidMount';
import { useTypedReducer } from 'hooks/useTypedReducer';
import { InventoryActionNames } from 'store/FacilityLevelStore/facilityLevelActions';
import { useFacilityLevelStore } from 'store/FacilityLevelStore/facilityLevelStore';
import { requestStore } from 'store/RequestStore/RequestStore';
import { IReportSpecification1ST } from 'codegen/report_specification';
import { SchedulerCalendar } from './features/SchedulerCalendar';
import {
  getInitialReportSchedulerState,
  reportSchedulerReducer,
} from './reducers/ReportSchedulerReducer';
import { ScheduleForm } from './ScheduleForm';
import { schedulerStyles } from './styles';

/**
 * Report scheduler
 * @returns component
 */
export const ReportScheduler = () => {
  const navigate = useNavigate();

  const { classes } = schedulerStyles();

  const [reportSchedulerState, reportSchedulerDispatch] = useTypedReducer(
    reportSchedulerReducer,
    getInitialReportSchedulerState(),
  );

  const selected = reportSchedulerState.selectedReportSpec;

  const { requestController } = useRequestController('ReportScheduler');

  const { stateFacilityLevel, dispatchFacilityLevel, isDataReady } = useFacilityLevelStore();

  const timezone = stateFacilityLevel.facilityData?.timezone;

  const { systemId = '' } = useParams();

  const getReportSpecifications = useCallback(() => {
    if (!isDataReady(systemId) || isEmpty(stateFacilityLevel.facilityData)) {
      const logPrefix = getLogPrefixForType('FUNCTION', 'getReportSpecifications');
      console.debug(
        logPrefix,
        "The facility data hasn't been loaded yet, so, it doesn't make sense to try to load the rest.",
      );

      return;
    }

    requestController.doRequest({
      request: reportStore.getReportSpecifications,
      requestParams: [systemId],
      callbackBeforeSend: () => reportSchedulerDispatch({ type: 'INCREMENT_SPINNER' }),
      callbackSuccess: (r) => {
        if (isEmpty(r.reportSpecifications)) {
          reportSchedulerDispatch({ type: 'SET_REPORT_SPEC_AVAILABLE', payload: false });
        } else {
          dispatchFacilityLevel({
            type: InventoryActionNames.GET_REPORT_SPECIFICATIONS,
            payload: r.reportSpecifications,
          });
          reportSchedulerDispatch({
            type: 'SET_SELECTED_SPEC',
            payload: r.reportSpecifications[0],
          });
        }
      },
      messageErrorFallback: 'The Report Specification could not be fetched.',
      callbackFinally: () => reportSchedulerDispatch({ type: 'DECREMENT_SPINNER' }),
    });
  }, [
    reportSchedulerDispatch,
    dispatchFacilityLevel,
    isDataReady,
    requestController,
    stateFacilityLevel.facilityData,
    systemId,
  ]);

  const submitRequest = (data: IInventoryRequestPostRequestST) => {
    requestController.doRequest({
      request: requestStore.addRequest,
      requestParams: [systemId, data],
      callbackBeforeSend: () => reportSchedulerDispatch({ type: 'INCREMENT_SPINNER' }),
      callbackSuccess: () => reportSchedulerDispatch({ type: 'CLEAR_INPUTS' }),
      messageSuccess: 'Your report was successfully scheduled.',
      messageErrorFallback: 'Your report could not be scheduled.',
      callbackFinally: () => reportSchedulerDispatch({ type: 'RESET_SPINNER' }),
    });
  };

  useComponentDidMount(() => {
    window.scrollTo(0, 0);
    getReportSpecifications();
  });

  const onReportSpecSelect = useCallback(
    (e: SelectChangeEvent) => {
      const selectedReportSpec = stateFacilityLevel.inventory.reportSpecifications.find(
        (rs) => rs.id === e.target.value,
      );
      if (selectedReportSpec) {
        reportSchedulerDispatch({ type: 'SET_SELECTED_SPEC', payload: selectedReportSpec });
      }
    },
    [reportSchedulerDispatch, stateFacilityLevel.inventory.reportSpecifications],
  );

  /**
   * Note: we prevent the page from being rendered if some of the required data is not available.
   * Note 2: if there is no facility data then we can safely assume that the facility is not set
   * and, hence, this page will be redirected anyways.
   */
  if (!isDataReady(systemId) || isEmpty(stateFacilityLevel.facilityData)) {
    return <Spinner />;
  }

  /**
   * Shown when there are no report specifications available.
   */
  const noAvailableReportSpecs = () => (
    <Box data-testid="c-no-report-spec-text" className={classes.noReportSpecsMessageHolder}>
      There are no report specifications available
    </Box>
  );

  /**
   * Panel showing text description of report specification. And related image.
   */
  const reportSpecInfoPanel = () => (
    <Grid xl={6} lg={5} md={5} sm={12} item>
      <Card data-testid="c-report-description" elevation={0} className={classes.reportSpecInfoCard}>
        <CardContent className={classes.reportSpecInfoCardParagraph}>
          <Typography style={{ margin: '20px 0px' }} gutterBottom variant="h5" component="h2">
            {selected ? selected.report_spec_name : <Skeleton height={50} width="50%" />}
          </Typography>
          <Typography variant="body1" color="textSecondary" component="p">
            {selected ? selected.report_description : <Skeleton height={150} />}
          </Typography>
        </CardContent>
        {selected && selected.report_picture ? (
          <CardMedia component="img" alt="pic" height="100%" image={selected.report_picture} />
        ) : (
          <Skeleton variant="rectangular" width={650} height={250} />
        )}
      </Card>
    </Grid>
  );

  const reportSpecOptions = stateFacilityLevel.inventory.reportSpecifications
    ? stateFacilityLevel.inventory.reportSpecifications.map((rs) => ({
        label: rs.report_spec_name,
        value: rs.id,
      }))
    : [];

  const preselectedReportSpecId =
    stateFacilityLevel.inventory.reportSpecifications &&
    stateFacilityLevel.inventory.reportSpecifications[0]
      ? stateFacilityLevel.inventory.reportSpecifications[0].id
      : '';

  /**
   * Show when there is a report specification available.
   */
  const availableReportSpecs = () => (
    <Card elevation={3} className={classes.mainCard}>
      <CardHeader title="New Report" />
      <Divider />
      <Grid spacing={3} container className={classes.mainGrid}>
        <Grid xl={6} lg={7} md={7} sm={12} item>
          <CustomSelect
            name="Report Type"
            variant="outlined"
            margin="normal"
            id="report-spec-select"
            label="Report Type"
            value={preselectedReportSpecId}
            onChange={onReportSpecSelect}
            valueOptions={reportSpecOptions}
            testId="c-report-specification-select"
            error={false}
            errorMessage=""
            defaultValue=""
            disabled={false}
          />
          <Divider className={classes.spaciousDivider} />
          <ScheduleForm
            systemId={systemId}
            timezone={timezone}
            reportSpec={selected as IReportSpecification1ST}
            onSubmit={submitRequest}
            dispatch={reportSchedulerDispatch}
            state={reportSchedulerState.formState}
          />
          {reportSchedulerState.spinner ? <Spinner /> : null}
        </Grid>
        {reportSpecInfoPanel()}
        {reportSchedulerState.formState.interval === 'weekly' &&
          reportSchedulerState.formState.reportDeadline === 'CUSTOM_DEADLINE' && (
            <Grid xl={12} lg={12} md={12} sm={12} item>
              <SchedulerCalendar
                days={reportSchedulerState.formState.days}
                reportDeadlineTimeoutInMinutes={
                  reportSchedulerState.formState.reportDeadlineTimeoutInMinutes
                }
                reportFromDate={reportSchedulerState.formState.dateFrom}
              />
            </Grid>
          )}
      </Grid>
    </Card>
  );

  const markupToRender = reportSchedulerState.reportSpecAvailable
    ? availableReportSpecs()
    : noAvailableReportSpecs();

  return (
    <>
      <PageHeaderSection
        title="Schedule Report"
        subtitle="Here you can schedule single and recurrent reports."
        showMenuIcon={false}
      >
        <Button
          startIcon={<ArrowBackIcon />}
          variant="outlined"
          color="primary"
          onClick={() => navigate(`/${systemId}${INVENTORY_PAGES_URLS.REPORTS}`)}
        >
          Back to reports
        </Button>
      </PageHeaderSection>

      <Container maxWidth="xl" sx={{ paddingTop: '32px' }}>
        {markupToRender}
      </Container>
    </>
  );
};
