import { useEffect, useState } from 'react';
import { BaseCard } from 'components/BaseCard/BaseCard';
import { BaseCardSimpleItem } from 'components/BaseCard/BaseCardSimpleItem';
import { useRequestController } from 'hooks';
import { getLogPrefixForType } from 'common/functions/logFunctions';
import { IWarehouseStatus } from 'interfaces';
import { defaultWarehouseStatus } from '../warehouseStatusFunctions';
import { Section } from '../warehouseStatus.model';
import { getActiveIssueCount } from './utils/getActiveIssueCount';

const logPrefix = getLogPrefixForType('COMPONENT', 'WarehouseStatusCard');

export const WarehouseStatusCard = ({ systemId }: { systemId: string }) => {
  const [spinner, setSpinner] = useState(0);
  const [warehouseStatus, setWarehouseStatus] = useState<IWarehouseStatus>(defaultWarehouseStatus);
  const { requestController } = useRequestController(logPrefix);

  useEffect(() => {
    if (systemId) {
      getActiveIssueCount(systemId, requestController, setSpinner, setWarehouseStatus);
    }
  }, [requestController, systemId]);

  return (
    <BaseCard
      cardFor="warehouse status"
      dataTestId="c-warehouse-status-card"
      showHeader={true}
      showContent={true}
      showActionButtons={false}
      showHeaderDivider={true}
      isLoading={spinner}
      title={warehouseStatus.title}
      subtitle={warehouseStatus.subtitle}
      counter={warehouseStatus.counter}
      counterSubject={warehouseStatus.counterSubject}
      actionButtons={warehouseStatus.actionButtons}
    >
      {warehouseStatus.sections &&
        warehouseStatus.sections.map((section: Section) => (
          <BaseCardSimpleItem
            key={section.id}
            title={section.title}
            sectionItems={section.sectionItems}
          />
        ))}
    </BaseCard>
  );
};
