import Typography from '@mui/material/Typography';
import List from '@mui/material/List';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import { Box } from 'components/common/Box';
import { TooltipedIcon } from 'components/common/TooltipedIcon';
import Alert from '@mui/material/Alert';
import { ReactElement } from 'react';
import { SettingsItem } from '../models/settingsCard.model';

interface ISettingsListItemProps {
  classes: { cardContentList?: string; alertInfo?: string };
  item: SettingsItem;
  children?: ReactElement;
  testId: string;
}

export const SettingsListItem = ({ classes, item, children, testId }: ISettingsListItemProps) => (
  <List
    className={classes.cardContentList}
    component="ul"
    data-testid={`settings-item-container-${testId}`}
  >
    <Box className="v-flex-center" px={2}>
      <Box className="v-flex-center">
        <Typography variant="subtitle1" color="textSecondary" component="p">
          {item.label}
        </Typography>
        {item.description && (
          <TooltipedIcon tooltip={item.description} icon={<InfoOutlinedIcon fontSize="small" />} />
        )}
      </Box>
      <Box className="v-flex-center" data-testid={`settings-item-content-${testId}`}>
        {children}
      </Box>
    </Box>
    {item?.alertInfo && (
      <Alert severity="info" className={classes.alertInfo}>
        {item.alertInfo}
      </Alert>
    )}
  </List>
);
