import React from 'react';
import { Divider, Grid } from '@mui/material';
import { IFacilitySettingsST, IReportDeadlinesST } from 'codegen/facility_settings';
import { ReportDeadlinesCard } from '../../ReportDeadlinesCard';
import { SettingsCardContent } from '../../SettingsCardContent';
import { getReportDeadlinesOptions } from '../../options/getReportDeadlinesOptions';
import {
  getReportsAutoUpdateOption,
  getReportsAutoAbortOptions,
  getReportsAutoApprovalOptions,
} from '../../options/getReportsActionsOptions';

export const ReportDeadlines = ({
  isLoading,
  facilitySettings,
  setFacilitySettings,
  validateInputs,
  handleValuesChange,
  errors,
  setErrors,
  autoUpdateDisabled,
  autoAbortDisabled,
  autoApprovalDisabled,
  setAutoUpdateDisabled,
  setAutoAbortDisabled,
  setAutoApprovalDisabled,
  autoUpdateRef,
  autoAbortRef,
  autoApprovalRef,
}: {
  isLoading: boolean;
  facilitySettings: IFacilitySettingsST;
  setFacilitySettings: (value: React.SetStateAction<IFacilitySettingsST>) => void;
  validateInputs: () => void;
  handleValuesChange: (prop: string, value: any) => void;
  errors: any;
  setErrors: React.Dispatch<any>;
  autoUpdateDisabled: boolean;
  autoAbortDisabled: boolean;
  autoApprovalDisabled: boolean;
  setAutoUpdateDisabled: React.Dispatch<React.SetStateAction<boolean>>;
  setAutoAbortDisabled: React.Dispatch<React.SetStateAction<boolean>>;
  setAutoApprovalDisabled: React.Dispatch<React.SetStateAction<boolean>>;
  autoUpdateRef: React.MutableRefObject<any>;
  autoAbortRef: React.MutableRefObject<any>;
  autoApprovalRef: React.MutableRefObject<any>;
}) => (
  <Grid item>
    <ReportDeadlinesCard
      isLoading={isLoading}
      currentDeadlines={facilitySettings.report_deadlines as IReportDeadlinesST}
      handleDeadlines={(reportDeadlines: IReportDeadlinesST) => {
        setFacilitySettings({
          ...facilitySettings,
          report_deadlines: reportDeadlines,
        });
        validateInputs();
      }}
      content={
        <>
          <Divider />
          <div
            data-testid="c-report-automatic-actions-settings-card"
            style={{ marginTop: '2em' }}
            className="settings-card"
          >
            <SettingsCardContent
              isLoading={isLoading}
              items={[
                getReportsAutoUpdateOption(
                  facilitySettings,
                  handleValuesChange,
                  validateInputs,
                  errors,
                  setErrors,
                  autoUpdateDisabled,
                  setAutoUpdateDisabled,
                  autoUpdateRef,
                ),
                getReportsAutoAbortOptions(
                  facilitySettings,
                  handleValuesChange,
                  validateInputs,
                  errors,
                  setErrors,
                  autoAbortDisabled,
                  setAutoAbortDisabled,
                  autoAbortRef,
                ),
                getReportsAutoApprovalOptions(
                  facilitySettings,
                  handleValuesChange,
                  validateInputs,
                  errors,
                  setErrors,
                  autoApprovalDisabled,
                  setAutoApprovalDisabled,
                  autoApprovalRef,
                ),
              ]}
            />
          </div>
          <Divider />
          <div
            data-testid="c-report-report-specific-deadlines-settings-card"
            style={{ marginTop: '2em' }}
            className="settings-card"
          >
            <SettingsCardContent
              isLoading={isLoading}
              items={getReportDeadlinesOptions(facilitySettings, handleValuesChange) || []}
            />
          </div>
        </>
      }
    />
  </Grid>
);
