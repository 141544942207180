import { Alert, Typography } from '@mui/material';
import { useGroundControlStore } from 'store/GroundControl/groundControlLevelStore';
import { MapContainer } from 'shared/map-container/MapContainer';
import {
  IGetEstimatedObstaclesResponseST,
  IGetSpaceReservationsResponseST,
} from 'codegen/nav_simulation';
import { makeBatteryDetailedStateString } from 'pages/GroundControl/pages/FleetOverview/FleetOverviewFunctions';
import { ModalBase } from 'components/ModalsAndPopups/ModalBase';
import { dateFormatFleet } from '../../utils/dateFormatFleet';
import { LiveMap } from '../../../../../pages/GroundControl/pages/LiveMap/LiveMap';
import { IFleetTableRows } from '../../model/IFleetTableRows';
import { InfoCard } from './InfoCard';
import { useStyles } from './styles/droneErrorModal.styles';
import { BatteryMetrics } from './BatteryMetrics';
import { ChargingStats } from './ChargingStats';
import { ChargingStrategy } from './ChargingStrategy';
import { BatteryCycles } from './BatteryCycles';
import { Metric } from './Metric';
import { MarkdownMetric } from './MarkdownMetric';
import { BatteryStateMetric } from './BatteryState';
import { droneSerialNumberIsValid } from '../../utils/droneSerialNumberIsValid';

export const DroneErrorModal = ({
  systemId,
  flightDomainId,
  droneErrorPayload,
  estimatedObstacles,
  spaceReservations,
  closeModal,
}: {
  systemId: string;
  flightDomainId: string;
  droneErrorPayload: IFleetTableRows;
  estimatedObstacles: IGetEstimatedObstaclesResponseST['estimated_obstacles'];
  spaceReservations: IGetSpaceReservationsResponseST['space_reservations'];
  closeModal: () => void;
}) => {
  const { classes } = useStyles();

  const { stateGroundControl } = useGroundControlStore();

  const { id: droneId, drone_name: droneName } = droneErrorPayload;

  const droneErrorData = stateGroundControl.fleetOverview.fleet_status[droneId] || {};
  const droneVersionData =
    stateGroundControl.fleetVersions.drone_versions[droneErrorData.drone_name] || {};
  return (
    <ModalBase
      opened={true}
      testId="c-drone-error-modal"
      contentClass={classes.root}
      maxWidth="lg"
      title={
        <div className={classes.cardHeader}>
          <Typography
            data-testid="c-choose-the-facility-title"
            style={{ fontWeight: 'bold', textAlign: 'left' }}
            color="secondary"
            variant="h5"
          >
            {droneName}
          </Typography>
        </div>
      }
      handleClose={closeModal}
    >
      <div className={`c-page-content ${classes.cardGrid}`}>
        <InfoCard
          title="Drone Serial Number"
          content={
            <div className={classes.cardContent}>
              <Metric value={droneVersionData.drone_serial || 'Undefined'} />

              {!droneSerialNumberIsValid(droneVersionData.drone_serial || '') && (
                <Alert severity="error">
                  Drone serial number does not match PUP or SPUP format.
                </Alert>
              )}
            </div>
          }
        />

        <InfoCard
          title="Software version"
          content={<Metric value={droneVersionData.version || 'Undefined'} />}
        />

        <InfoCard
          title="Battery Serial Number"
          content={<Metric value={droneErrorPayload.battery_serial_number || 'Undefined'} />}
          direction="column"
        />

        <InfoCard
          title="Battery Management Strategy"
          content={<ChargingStrategy strategy={droneErrorPayload.charging_strategy} />}
        />

        <InfoCard
          title="Battery State"
          content={
            <BatteryStateMetric
              state={droneErrorPayload.battery_state}
              detailedState={makeBatteryDetailedStateString(
                droneErrorPayload.detailed_battery_state,
              )}
            />
          }
          direction="column"
        />

        <InfoCard
          title="Battery Metrics"
          content={
            <BatteryMetrics
              voltage={droneErrorPayload.battery_voltage}
              current={droneErrorPayload.battery_current}
              tempCelsius={droneErrorPayload.battery_temperature}
            />
          }
          direction="column"
        />

        <InfoCard
          title="Charging Metrics"
          content={
            <ChargingStats
              currentLimit={droneErrorPayload.charging_current_limit}
              timeToChargeInSeconds={droneErrorPayload.time_to_optimal_charge}
            />
          }
          direction="column"
        />

        <InfoCard
          title="Battery Cycles"
          content={
            <BatteryCycles
              cycles={droneErrorPayload.num_charging_cycles}
              maxRecommendedCycles={droneErrorPayload.max_recommended_num_charging_cycles}
            />
          }
          direction="column"
        />

        <InfoCard
          title="Operational feedback"
          content={
            <Metric
              value={
                droneErrorData.last_operation_feedback
                  ? droneErrorData.last_operation_feedback
                  : 'None'
              }
            />
          }
        />

        <InfoCard
          title="Position State"
          content={<Metric value={droneErrorData.pose_state || 'Undefined'} />}
        />

        <InfoCard
          title="Last Reliable Position Time"
          content={<Metric value={dateFormatFleet(droneErrorData.last_reliable_pose_time)} />}
        />

        <InfoCard
          title="Not ready to fly Reason"
          content={<Metric value={droneErrorData.not_ready_reason || 'None'} />}
        />

        <div className={classes.cardFullWidth}>
          <InfoCard
            title="Additional diagnostics"
            content={
              <MarkdownMetric
                message={
                  droneErrorData.additional_diagnostics || 'No additional diagnostics available'
                }
              />
            }
          />
        </div>

        <div className={classes.cardFullWidth}>
          <MapContainer systemId={systemId} flightDomainId={flightDomainId}>
            <LiveMap
              namesOfDronesToShow={[droneErrorPayload.drone_name?.toString() || 'Undefined']}
              estimatedObstacles={estimatedObstacles}
              spaceReservations={spaceReservations}
            />
          </MapContainer>
        </div>
      </div>
    </ModalBase>
  );
};
