import { IOption } from 'interfaces/inputs';
import { sort } from 'common/functions/otherFunctions';
import { Facility } from '../../../model/facility.model';

const sortedFacilities = (facilitiesOptions: IOption[]) =>
  sort({
    array: facilitiesOptions,
    sortingOrder: 'asc',
    sortBy: 'label',
  });

export const transformFacilitiesToSelectOptions = (facilities: Facility[]): IOption[] =>
  sortedFacilities(
    facilities.map((f: Facility) => ({
      value: `${f.id}`,
      label: f.name,
    })),
  );
