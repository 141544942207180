import { ILocationDataST } from 'codegen/warehouse_status';
import { useMemo } from 'react';

import { GridInitialStatePremium } from '@mui/x-data-grid-premium/models/gridStatePremium';
import { green } from '@mui/material/colors';
import { getLocationUsage } from './utils/getLocationUsage';
import { WarehouseStatsWidget } from './WarehouseStatsWidget';
import { getDataSerie } from './utils/getDataSeries';

export const WarehouseOccupancy = ({
  locationData,
  onGridStateSelect,
}: {
  locationData?: ILocationDataST[];
  onGridStateSelect?: (state: GridInitialStatePremium) => void;
}) => {
  const usage = useMemo(
    () => (locationData ? getLocationUsage(locationData) : null),
    [locationData],
  );

  const data = [
    getDataSerie(
      0,
      (usage?.barcodes || 0) + (usage?.barcodesAmended || 0),
      'Barcodes',
      'Locations where barcodes have been detected',
      [
        {
          field: 'found_barcodes',
          operator: '>',
          value: '0',
        },
        {
          field: 'exclusion_status',
          operator: 'is',
          value: 'false',
        },
      ],
    ),
    getDataSerie(
      1,
      (usage?.notEmpty || 0) + (usage?.notEmptyAmended || 0),
      'Not empty, but no barcode',
      'Locations which are not empty but no barcode has been detected',
      [
        {
          field: 'verity_status.state',
          operator: 'contains',
          value: 'Non-empty (no barcode)',
        },
        {
          field: 'exclusion_status',
          operator: 'is',
          value: 'false',
        },
      ],
    ),
    getDataSerie(
      2,
      (usage?.empty || 0) + (usage?.emptyAmended || 0),
      'Empty',
      'Locations which have been detected as empty',
      [
        {
          field: 'verity_status.state',
          operator: 'equals',
          value: 'Empty location',
        },
        {
          field: 'exclusion_status',
          operator: 'is',
          value: 'false',
        },
      ],
    ),
    getDataSerie(
      3,
      (usage?.unreachable || 0) + (usage?.unreachableAmended || 0),
      'Unreachable',
      'Locations which cannot be reached by drones',
      [
        {
          field: 'verity_status.state',
          operator: 'contains',
          value: 'Unreachable Location',
        },
        {
          field: 'exclusion_status',
          operator: 'is',
          value: 'false',
        },
      ],
    ),
    getDataSerie(
      4,
      (usage?.noResult || 0) + (usage?.noResultAmended || 0),
      'Inconclusive',
      'Locations which we have no conclusive results for',
      [
        {
          field: 'verity_status.state',
          operator: 'equals',
          value: 'Inconclusive',
        },
        {
          field: 'exclusion_status',
          operator: 'is',
          value: 'false',
        },
      ],
    ),
    getDataSerie(
      5,
      usage?.noState,
      'No State',
      'Locations which we have no no state for (these should not exist',
      [
        {
          field: 'verity_status.state',
          operator: 'isEmpty',
        },
        {
          field: 'content_found_amended',
          operator: 'is',
          value: 'false',
        },
        {
          field: 'exclusion_status',
          operator: 'is',
          value: 'false',
        },
      ],
    ),
  ];

  const colors = [green.A700, green[800], green[900], green[600], green.A100, green[700]];

  const counter = usage?.total || 0;

  return (
    <WarehouseStatsWidget
      id="usage"
      title="Occupancy"
      unit="locations"
      unitPrefix="included"
      data={data}
      counter={counter}
      colors={colors}
      cols={6}
      onGridStateSelect={onGridStateSelect}
    />
  );
};
