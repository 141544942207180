import { IconButton, Paper, Stack } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import React, { useCallback } from 'react';
import { ZoomControlsProps } from './ZoomControls.model';

export const ZoomControls: React.FC<ZoomControlsProps> = ({ onZoom, className }) => {
  const handleZoomOut = useCallback(() => {
    onZoom('out');
  }, [onZoom]);

  const handleZoomIn = useCallback(() => {
    onZoom('in');
  }, [onZoom]);

  return (
    <Stack direction="row" gap={1} className={className}>
      <Paper>
        <IconButton aria-label="zoom out" onClick={handleZoomOut}>
          <RemoveIcon />
        </IconButton>
      </Paper>
      <Paper>
        <IconButton aria-label="zoom in" onClick={handleZoomIn}>
          <AddIcon />
        </IconButton>
      </Paper>
    </Stack>
  );
};
