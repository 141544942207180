import { useEffect, useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { FeedbackForm } from './FeedbackForm';
import { ThanksAndSnooze } from './ThanksAndSnooze';
import { FeedbackReasonType } from './feedbackReason.model';
import { locationStyles } from '../styles';

export const ShareFeedback = ({
  isSnoozable,
  isSnoozed,
  snooze,
  sendLocationInfo,
  isExpanded,
  toggledCallback,
}: {
  isSnoozable: boolean;
  isSnoozed: boolean;
  snooze: () => void;
  sendLocationInfo: (feedbackReasons: FeedbackReasonType[], message: string) => Promise<unknown>;
  isExpanded?: boolean;
  toggledCallback: (isOpen: boolean) => void;
}) => {
  const [isAccordionOpen, setIsAccordionOpen] = useState(true);
  const { classes } = locationStyles();
  const [isFeedbackSent, setIsFeedbackSent] = useState(false);
  const propagateSendLocationInfo = (
    feedbackReasons: FeedbackReasonType[],
    message: string,
  ): Promise<unknown> => {
    setIsFeedbackSent(true);
    return sendLocationInfo(feedbackReasons, message);
  };

  useEffect(() => {
    if (isExpanded !== undefined) {
      setIsAccordionOpen(isExpanded);
    }
  }, [isExpanded]);

  return (
    <Paper className={classes.feedbackDrawerPaper} elevation={0}>
      <Accordion
        className="customAccordion"
        onChange={() => {
          toggledCallback(!isAccordionOpen);
          setIsAccordionOpen((isOpen: boolean) => !isOpen);
        }}
        expanded={isAccordionOpen}
      >
        <AccordionSummary
          className={classes.accordionSummary}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography
            align="right"
            className={classes.accordionTitle}
            color="textSecondary"
            component="p"
          >
            Share feedback on location
          </Typography>
        </AccordionSummary>

        <AccordionDetails>
          {isFeedbackSent ? (
            <ThanksAndSnooze isSnoozed={isSnoozed} isSnoozable={isSnoozable} onSnooze={snooze} />
          ) : (
            <FeedbackForm
              sendLocationInfo={propagateSendLocationInfo}
              isShareFeedbackAccordionOpen={isAccordionOpen}
            />
          )}
        </AccordionDetails>
      </Accordion>
    </Paper>
  );
};
