import { useNavigate, useParams } from 'react-router-dom';
import clsx from 'clsx';

import Paper from '@mui/material/Paper';
import WarningIcon from '@mui/icons-material/Warning';

import { AUTH_PAGES_URLS, FACILITY_SETTINGS_PAGES_URLS } from 'common/pages';
import { Box } from 'components/common/Box';
import { Copyright } from 'components/common/Copyright';
import { EnhancedBox } from 'components/common/EnhancedBox';

import { useSettingsStore } from '../../store/Settings/settingsStore';

import { sharedStyles } from '../Auth/styles';
import { userHasPermission } from '../../features/permissions/userHasPermission';
import { PERMISSION } from '../../features/permissions/permissions.model';

export const NoFacilityWarning = () => {
  const { classes } = sharedStyles();

  const { HEADER_SETTINGS } = useSettingsStore();

  const navigate = useNavigate();

  const { systemId } = useParams();

  const userHasFacilityManagementPermission = userHasPermission(PERMISSION.FACILITY_MANAGEMENT);

  const warningData = {
    icon: (
      <Box className={clsx(classes.cardIcon, classes.errorBorder)}>
        <WarningIcon fontSize="large" color="error" />
      </Box>
    ),
    description: 'To proceed, you need to configure the facility.',
    actionButtons: [
      {
        variant: 'outlined',
        label: 'Logout',
        testid: 'c-logout-from-to-proceed-modal',
        action: () => {
          navigate(AUTH_PAGES_URLS.SIGNOUT);
        },
      },
      {
        variant: 'contained',
        class: classes.submit,
        label: userHasFacilityManagementPermission ? 'Go to Admin page' : 'Support',
        testid: 'c-go-to-admin-from-to-proceed-modal',
        action: () => {
          if (userHasFacilityManagementPermission) {
            navigate(`/${systemId}${FACILITY_SETTINGS_PAGES_URLS.FACILITY_DETAILS}`);
          } else {
            window.open(HEADER_SETTINGS.SUPPORT_URL, '_blank');
          }
        },
      },
    ],
  };

  return (
    <Box mt={12} display="flex" flexDirection="column" alignItems="center" width="100%">
      <Paper elevation={3} className={classes.paper}>
        <EnhancedBox
          classes={classes}
          icon={warningData.icon}
          description={warningData.description}
          actionButtons={warningData.actionButtons}
        />
      </Paper>
      <Box mt={5}>
        <Copyright />
      </Box>
    </Box>
  );
};
