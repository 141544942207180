import {
  IIssueWmsSlotStatusWithBarcodeDuplicateST,
  ILocationDataST,
} from 'codegen/warehouse_status';

const adjustWMSValues = (
  wmsStatus: IIssueWmsSlotStatusWithBarcodeDuplicateST | null,
): IIssueWmsSlotStatusWithBarcodeDuplicateST | null => {
  if (typeof wmsStatus !== 'string' && wmsStatus?.state === 'EMPTY') {
    wmsStatus.barcodes = [];
    wmsStatus.customers = [];
    wmsStatus.qtys = [];
  }
  return wmsStatus;
};

export const transformLocationData = (locations: ILocationDataST[]): ILocationDataST[] =>
  locations.map((location) => ({ ...location, wms_status: adjustWMSValues(location.wms_status) }));
