import { ILocationDataST } from 'codegen/warehouse_status';
import { DISPLAY_WMS_STATES } from 'common/slotStates';

export const expectedContentValueGetter = (row: ILocationDataST): string => {
  const wmsStatus = row.wms_status;

  if (!wmsStatus || typeof wmsStatus === 'string') {
    return wmsStatus || '';
  }

  let content: string = ' ';

  const { barcodes } = wmsStatus;

  const nonEmptyBarcodes = barcodes.filter((b) => !!b);

  if (wmsStatus.state !== 'BARCODE') {
    content = DISPLAY_WMS_STATES[wmsStatus.state];
  } else if (nonEmptyBarcodes.length === 1) {
    const barcode = nonEmptyBarcodes[0];
    content = barcode;
  } else if (nonEmptyBarcodes.length > 1) {
    const list = nonEmptyBarcodes.map((barcode) => barcode);
    content = list.join(', ');
  }

  return content;
};
