import { Box, List } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import { useState } from 'react';
import { SettingsItem } from '../../../models/settingsCard.model';
import { CustomIssueLogicCodeEditor } from './CustomIssueLogicCodeEditor';
import { defaultIssueLogicJson } from './defaults/issueLogic.default';
import { isValidJson } from './utils/isValidJson';

export const CustomIssueLogicEditor = ({
  classes,
  item,
}: {
  classes: { cardContentList?: string };
  item: SettingsItem;
}) => {
  const [json, setJson] = useState(JSON.stringify(item.values, null, 2) || undefined);
  const [validationResult, setValidationResult] = useState<boolean | undefined>(undefined);

  const handleCopy = () => {
    navigator.clipboard.writeText(json ?? '');
    enqueueSnackbar('Content copy to the clipboard', { variant: 'success' });
  };

  const handlePaste = () => {
    navigator.clipboard.readText().then((textFromClipboard: string) => {
      if (isValidJson(textFromClipboard)) {
        item.action(JSON.parse(textFromClipboard));
        enqueueSnackbar('Content copy from the clipboard', { variant: 'success' });
        setJson(textFromClipboard);
        setValidationResult(true);
      } else {
        enqueueSnackbar('The content of the clipboard is not a valid json', { variant: 'error' });
        setValidationResult(false);
      }
    });
  };

  const handleReset = () => {
    setJson(defaultIssueLogicJson);
  };

  return (
    <List
      className={classes.cardContentList}
      component="ul"
      data-testid={`settings-item-container-${item?.testId}`}
    >
      <Box component="div" flexDirection="column" display="flex" px={2}>
        <CustomIssueLogicCodeEditor
          testId={item.testId ?? ''}
          json={json}
          handleCopy={handleCopy}
          handlePaste={handlePaste}
          handleReset={handleReset}
          disabled={!json}
          isValidJson={validationResult}
        />
      </Box>
    </List>
  );
};
