import { useCallback, useEffect } from 'react';
import { AlertColor } from '@mui/material';

import { BaseCard, IBaseCardProps } from 'components/BaseCard/BaseCard';
import { AlertComponent } from 'components/common/Alert';

import {
  BaseCardSimpleItem,
  IBaseCardSimpleItemSectionItems,
} from 'components/BaseCard/BaseCardSimpleItem';
import { useGroundControlStore } from '../../../../store/GroundControl/groundControlLevelStore';
import {
  GroundControlActionNames,
  IAlertInfoData,
} from '../../../../store/GroundControl/groundControlLevelStore.model';
import { landedDronesMessage } from './FlightDomainOverviewFunctions';

interface IFleetData {
  title: IBaseCardProps['title'];
  subtitle: IBaseCardProps['subtitle'];
  counter: IBaseCardProps['counter'];
  testId: string;
  actionButtons: any;
  sectionItems: IBaseCardSimpleItemSectionItems[];
}

interface IFleetCardProps {
  /**
   * Fleet Data
   */
  fleetData: IFleetData;
  /**
   * # of drones landed in place
   */
  nDronesLanded: number;
  /**
   * Whether an emergency landing is in progress
   */
  isExecutingEmergencyLanding: boolean;
  /**
   * Whether the drones are returning home
   */
  isExecutingReturnHome: boolean;
  /**
   * # of drones flying
   */
  dronesFlying: boolean;
  /**
   * Whether there is no data to display
   */
  noData: boolean;
}

export const FleetSummaryCard = (props: IFleetCardProps) => {
  const {
    nDronesLanded,
    isExecutingEmergencyLanding,
    isExecutingReturnHome,
    dronesFlying,
    fleetData,
    noData,
  } = props;

  const { stateGroundControl, dispatchGroundControlLevel } = useGroundControlStore();

  const setAlertInfo = useCallback(
    (variant: IAlertInfoData['variant'], message: IAlertInfoData['message']) =>
      dispatchGroundControlLevel({
        type: GroundControlActionNames.SET_ALERT_INFO,
        payload: {
          key: 'fleetCard',
          variant: stateGroundControl.activeAlertVariant,
          message,
        },
      }),
    [dispatchGroundControlLevel, stateGroundControl.activeAlertVariant],
  );

  const closeAlertInfo = useCallback(() => {
    dispatchGroundControlLevel({
      type: GroundControlActionNames.SET_ALERT_INFO,
      payload: { key: 'fleetCard' },
    });
  }, [dispatchGroundControlLevel]);

  const handleAlert = useCallback(() => {
    if (isExecutingEmergencyLanding) {
      setAlertInfo('error', 'Drones are landing in place now.');
      return;
    }

    if (isExecutingReturnHome) {
      setAlertInfo('warning', 'Drones are returning to their chargers.');
      return;
    }

    if (nDronesLanded) {
      setAlertInfo('info', landedDronesMessage(nDronesLanded));
      return;
    }

    if (dronesFlying) {
      setAlertInfo('warning', 'Drones started flying');
    } else {
      setAlertInfo('info', 'All drones have returned to their chargers.');
    }
  }, [
    dronesFlying,
    isExecutingEmergencyLanding,
    isExecutingReturnHome,
    nDronesLanded,
    setAlertInfo,
  ]);

  useEffect(() => {
    handleAlert();
  }, [handleAlert]);

  return (
    <BaseCard
      cardFor="Fleet summary"
      dataTestId={fleetData.testId}
      title={fleetData.title}
      subtitle={fleetData.subtitle}
      counter={fleetData.counter}
      counterSubject={!noData ? 'drones' : ''}
      actionButtons={fleetData.actionButtons}
      noData={noData}
    >
      <AlertComponent
        isActive={!noData && stateGroundControl.alertInfo.fleetCard.isOpened}
        icon={false}
        status={stateGroundControl.alertInfo.fleetCard.variant as AlertColor}
        message={stateGroundControl.alertInfo.fleetCard.message || ''}
        handleClose={closeAlertInfo}
        preventClose={false}
        testId="c-fleet-summary-card-info-box"
      />
      <BaseCardSimpleItem sectionItems={fleetData.sectionItems} noData={noData} />
    </BaseCard>
  );
};
