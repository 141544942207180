import { Object3D, Scene } from 'three/src/Three';
import { getLogPrefixForType } from 'common/functions/logFunctions';
import { MapRoot } from 'shared/map-container/MapContainer.model';
import { LOD, MapState } from '../../reducer/3DmapState';
import { mapToObjects } from '../../utils/creation.util';

const managerLogPrefix: string = getLogPrefixForType('CLASS', '3D Facility Manager');

export class FacilityManager {
  private static instance: FacilityManager;

  public mapState: MapState;

  private racks: Object3D[] = [];

  private aisles: Object3D[] = [];

  private chargers: Object3D[] = [];

  private scene: Scene | null = null;

  private lod: LOD = 'low';

  public constructor(mapState: MapState) {
    this.mapState = mapState;

    console.debug(
      `${managerLogPrefix} Constructed FacilityManager for flight domain ${this.mapState.flightDomainName}`,
    );
  }

  public setScene = (scene: Scene) => {
    this.scene = scene;
    this.scene.add(...this.racks, ...this.aisles, ...this.chargers);
    console.debug(`${managerLogPrefix} Set scene`);
  };

  public static getInstance(mapState: MapState): FacilityManager {
    this.instance = new FacilityManager(mapState);
    return this.instance;
  }

  public getRacks = () => this.racks;

  private setRacksVisible = (visible: boolean) => {
    this.racks.forEach((rack) => {
      rack.visible = visible;
    });
  };

  public getAisles = () => this.aisles;

  private setAislesVisible = (visible: boolean) => {
    this.aisles.forEach((aisle) => {
      aisle.visible = visible;
    });
  };

  public getChargers = () => this.chargers;

  public updateLOD = (lod: LOD, force: boolean = true) => {
    if ((!this.scene || this.lod === lod) && !force) return;
    this.lod = lod;

    if (this.lod === 'low') {
      this.setRacksVisible(false);
      this.setAislesVisible(true);
      console.debug(
        `${managerLogPrefix} LOD set to low - hiding ${this.racks.length} rack/s and showing ${this.aisles.length} aisle/s`,
      );
    } else {
      this.setRacksVisible(true);
      this.setAislesVisible(false);
      console.debug(
        `${managerLogPrefix} LOD set to high - showing ${this.racks.length} rack/s and hiding ${this.aisles.length} aisle/s}`,
      );
    }
  };

  public update3DObjects = () => {
    const { map, options, flightDomainName } = this.mapState;

    const data = mapToObjects(map as MapRoot, ['RACK', 'AISLE', 'CHARGING_POD'], options);

    this.racks = data.RACK || [];
    this.aisles = data.AISLE || [];
    this.chargers = data.CHARGING_POD || [];

    this.updateLOD(this.lod, true);

    console.debug(
      `${managerLogPrefix} Constructed flight domain ${flightDomainName} with ${this.racks.length} Racks, ${this.aisles.length} Aisles and ${this.chargers.length} Chargers`,
    );
  };
}
