import { IConditionOrLogicalOperatorST } from 'codegen/facility_settings';
import { ConditionText } from './ConditionText';

export const ConditionsPhrase = ({
  conditions,
  isNotFirstRule,
}: {
  conditions: Array<IConditionOrLogicalOperatorST | string>;
  isNotFirstRule: boolean;
}) => {
  let pendingConjunction: string[] = [];

  return (
    <>
      {conditions.map((condition) => {
        if (typeof condition !== 'string') {
          const text = (
            <ConditionText
              key={condition.field}
              condition={condition}
              isNotFirstRule={isNotFirstRule}
              ruleConjunctions={pendingConjunction}
            />
          );
          pendingConjunction = [];
          return text;
        }
        pendingConjunction.push(condition);
        return null;
      })}
    </>
  );
};
