import {
  ILocationDataST,
  IVeritySlotStatusST,
  IVeritySlotStatusStateST,
} from 'codegen/warehouse_status';
import { getIncludedExcluded } from './getLocationDataStats';

export const getAmendedLocationsByState = (
  locationData: ILocationDataST[],
  state: IVeritySlotStatusStateST,
) =>
  locationData.filter((l) => {
    const { user_overrides } = l.verity_status as IVeritySlotStatusST;
    if (user_overrides === null || user_overrides.length === 0) {
      return false;
    }
    const lastOverride = user_overrides[user_overrides.length - 1];
    return lastOverride.state === state;
  });

export const getLocationsByState = (
  locationData: ILocationDataST[],
  state: IVeritySlotStatusStateST,
) => locationData.filter((l) => (l.verity_status as IVeritySlotStatusST)?.state === state);

export const getLocationUsage = (locationData: ILocationDataST[]) => {
  const { included } = getIncludedExcluded(locationData);
  const amended = included.filter((l) => {
    const verityStatus = l.verity_status as IVeritySlotStatusST;
    return (
      verityStatus &&
      verityStatus.user_overrides !== null &&
      verityStatus.user_overrides?.length > 0
    );
  });

  const notAmended = included.filter((l) => !amended.includes(l));

  const empty = getLocationsByState(notAmended, 'EMPTY');
  const barcodes = getLocationsByState(notAmended, 'BARCODE');
  const unreachable = getLocationsByState(notAmended, 'UNREACHABLE');
  const notEmpty = getLocationsByState(notAmended, 'NOT_EMPTY');
  const noResult = getLocationsByState(notAmended, 'NO_RESULT');

  const emptyAmended = getAmendedLocationsByState(amended, 'EMPTY');
  const barcodesAmended = getAmendedLocationsByState(amended, 'BARCODE');
  const unreachableAmended = getAmendedLocationsByState(amended, 'UNREACHABLE');
  const notEmptyAmended = getAmendedLocationsByState(amended, 'NOT_EMPTY');
  const noResultAmended = getAmendedLocationsByState(amended, 'NO_RESULT');

  const noState = notAmended.filter(
    (l) =>
      !['NO_RESULT', 'NOT_EMPTY', 'UNREACHABLE', 'BARCODE', 'EMPTY'].includes(
        (l.verity_status as IVeritySlotStatusST)?.state,
      ),
  );
  const total =
    empty.length +
    barcodes.length +
    unreachable.length +
    notEmpty.length +
    noResult.length +
    noState.length +
    amended.length;
  return {
    empty: empty.length,
    barcodes: barcodes.length,
    unreachable: unreachable.length,
    notEmpty: notEmpty.length,
    noResult: noResult.length,
    noState: noState.length,
    amended: amended.length,
    emptyAmended: emptyAmended.length,
    barcodesAmended: barcodesAmended.length,
    notEmptyAmended: notEmptyAmended.length,
    unreachableAmended: unreachableAmended.length,
    noResultAmended: noResultAmended.length,
    total,
  };
};
