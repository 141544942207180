// lint rule skipped because this is react's reducer way

import {
  isExecutingEmergencyLanding,
  isExecutingReturnHome,
} from 'common/functions/otherFunctions';
import {
  AppActionNames,
  FacilityActionNames,
  FacilitySettingsActionNames,
  InventoryActionNames,
  FacilityLevelAction,
  InventoryLevelAction,
} from './facilityLevelActions';
import { initialFacilityLevelState, initialFacilitySettings } from './facilityLevelInitialState';
import { IFacilityLevelInventoryState, IFacilityLevelState } from './IFacilityLevelStore';

/**
 * Reducer dealing with the inventory state.
 *
 * Note: this is currently part of the facility level store, but it could be moved to an independent
 * store at a later stage.
 * @param state Inventory State
 * @param action Inventory Action
 * @returns Updated state
 */
const inventoryLevelReducer = (
  state: IFacilityLevelInventoryState,
  action: InventoryLevelAction,
) => {
  switch (action.type) {
    // REPORT SPECIFICATIONS
    // FIXME: verify renaming it to SET
    // EN 2022-05-13
    case InventoryActionNames.GET_REPORT_SPECIFICATIONS:
      return {
        ...state,
        reportSpecifications: action.payload,
      };

    case InventoryActionNames.WS_REPORT_STATUS_UPDATE:
      return {
        ...state,
        reportUpdate: action.payload,
      };

    case InventoryActionNames.WS_REPORT_EXPORT:
      return {
        ...state,
        reportExportAvailable: action.payload,
      };

    case InventoryActionNames.WS_IMAGE_STATUS:
      return {
        ...state,
        imageAvailable: action.payload,
      };
    default:
      return state;
  }
};

/**
 * Reducer managing the updated of the facility level state
 * @param state Facility Level State
 * @param action Facility Level Action
 * @returns updated State
 */
export const FacilityLevelReducer = (
  state: IFacilityLevelState,
  action: FacilityLevelAction,
): IFacilityLevelState => {
  state = {
    ...state,
    inventory: { ...inventoryLevelReducer(state.inventory, action as InventoryLevelAction) },
  };

  switch (action.type) {
    // SYSTEM AND FACILITY
    case AppActionNames.SET_SOFTWARE_VERSION:
      return {
        ...state,
        beVersion: action.payload.version,
        beLastUpdated: action.payload.last_updated,
      };

    case AppActionNames.SET_NOT_FOUND:
      return {
        ...state,
        ui: {
          ...state.ui,
          notFound: action.payload,
        },
      };

    case FacilityActionNames.SET_FACILITY_INFO:
      return {
        ...state,
        facilityData: action.payload,
        // Note: when this value is set it means that data for the given facility,
        // if any, has been loaded.
        facilityInfoLoaded: true,
      };
    case FacilityActionNames.SET_IS_FACILITY_INFO_LOADING:
      return {
        ...state,
        isFacilityInfoLoading: action.payload,
      };
    case FacilityActionNames.SET_IS_FACILITY_DATA_INITIATED:
      return {
        ...state,
        isFacilityDataInitiated: action.payload,
      };
    case FacilityActionNames.SET_FACILITY_INFO_LOADED:
      return {
        ...state,
        facilityInfoLoaded: action.payload,
      };
    case FacilityActionNames.SET_FACILITY_MAP_INFO:
      return {
        ...state,
        facilityMapInfo: action.payload,
        facilityMapInfoLoaded: true,
      };
    case FacilityActionNames.SET_FACILITY_MAP_INFO_LOADED:
      return {
        ...state,
        facilityMapInfoLoaded: action.payload,
      };
    case FacilityActionNames.SET_FACILITY_SLOTS:
      return {
        ...state,
        slots: action.payload,
        facilitySlotsLoaded: true,
      };
    case FacilityActionNames.SET_FACILITY_SLOTS_LOADED:
      return {
        ...state,
        facilitySlotsLoaded: action.payload,
      };
    case FacilityActionNames.SET_FLIGHT_DOMAINS: {
      const flightDomains = action.payload.map((flightDomain) => {
        const fleetStatusSummary = flightDomain.fleet_status_summary;
        const flightDomainStatus = flightDomain.flight_domain_status;

        return {
          ...flightDomain,
          fleet_status_summary: fleetStatusSummary,
          flight_domain_status: flightDomainStatus,
          isExecutingEmergencyLanding:
            flightDomainStatus && fleetStatusSummary
              ? isExecutingEmergencyLanding(flightDomainStatus, fleetStatusSummary)
              : false,
          isExecutingReturnHome:
            flightDomainStatus && fleetStatusSummary
              ? isExecutingReturnHome(flightDomainStatus, fleetStatusSummary)
              : false,
        };
      });

      return {
        ...state,
        flightDomains,
      };
    }

    case FacilityActionNames.SET_FLIGHT_DOMAINS_LOADED:
      return {
        ...state,
        flightDomainsLoaded: action.payload,
      };
    case FacilityActionNames.CLEAR_FACILITY_DATA:
      return {
        ...state,
        facilityData: {
          client: '',
          country: '',
          created_at: '',
          latitude: 0,
          logo_url: '',
          longitude: 0,
          name: '',
          store_id: '',
          system_id: 0,
          timezone: '',
          version: 0,
        },
        facilityInfoLoaded: false,
        facilitySlotsLoaded: false,
        facilityMapInfoLoaded: false,
        facilitySettingsLoaded: false,
      };
    case FacilityActionNames.SET_IS_DATA_READY:
      return {
        ...state,
        isDataReady: action.payload,
      };
    case FacilityActionNames.SET_FACILITY_SETTINGS:
      return {
        ...state,
        facilitySettings: action.payload,
        facilitySettingsLoaded: true,
      };
    case FacilityActionNames.SET_FACILITY_SETTINGS_LOADED:
      return {
        ...state,
        facilitySettingsLoaded: action.payload,
      };

    case FacilityActionNames.CLEAR_FACILITY_SETTINGS: {
      return {
        ...state,
        facilitySettings: initialFacilitySettings,
      };
    }
    case FacilityActionNames.SET_CURRENT_SYSTEM_ID: {
      return {
        ...state,
        currentSystemId: action.payload,
      };
    }

    case FacilityActionNames.SET_WEBSOCKET_ERROR: {
      return {
        ...state,
        webSocketError: action.payload,
      };
    }

    case FacilityActionNames.CLEAR_FACILITY_STORE:
      return initialFacilityLevelState;

    case FacilityActionNames.CLEAR_SETTINGS:
      return initialFacilityLevelState;

    case AppActionNames.APP_DATA_LOADED:
      return {
        ...state,
        appDataLoaded: action.payload,
      };

    case FacilitySettingsActionNames.WS_FACILITY_MAP_UPDATE:
      return {
        ...state,
        facilityMapUpdate: action.payload,
      };

    case InventoryActionNames.WS_WAREHOUSE_EXPORT:
      return {
        ...state,
        inventory: {
          ...state.inventory,
          warehouseExportAvailable: action.payload,
        },
      };

    default:
      return state;
  }
};
