import React from 'react';
import Typography from '@mui/material/Typography';

// images
import verityFooter from '../../assets/verity_footer.png';

export const Copyright = () => (
  <Typography align="center">
    <img width="150px" src={verityFooter} alt="verity_footer" />
  </Typography>
);
