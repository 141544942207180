/**
 * Tween between two numbers using some easing function
 */
export class TweenNumber {
  private currentX: number = 0;

  private currentY: number;

  private numberOfUpdates: number = 0;

  private lerp;

  /**
   * Creates a Tween
   * @param startY starting value
   * @param endY end value to be reached
   * @param maxX duration in seconds
   * @param easingFunction one of those
   * @param whenDone to be called once duration in reached
   */
  constructor(
    public readonly startY: number,
    public readonly endY: number,
    public readonly maxX: number,
    public readonly easingFunction: (n: number) => number,
    private readonly whenDone: () => void,
    public readonly label?: string,
  ) {
    this.currentY = this.startY;
    this.lerp = (s1: number, s2: number, t: number): number => (1 - t) * s1 + t * s2;
  }

  public get isDone() {
    return this.currentX >= this.maxX;
  }

  private get normalizedX() {
    return this.currentX / this.maxX;
  }

  private updateCurrentX = (delta: number): boolean => {
    this.currentX += delta;

    if (this.currentX >= this.maxX) {
      this.whenDone();
      return false;
    }
    return true;
  };

  public getNextValue = (delta: number): number => {
    if (!this.updateCurrentX(delta)) return this.endY;

    const easingRatio = this.easingFunction(this.normalizedX);

    this.currentY = this.lerp(this.startY, this.endY, easingRatio);

    this.numberOfUpdates += 1;
    return this.currentY;
  };
}

/**
 * Basic easing functions.
 */
export const Easing = {
  Linear: (x: number) => x,
  EaseInQuad: (x: number) => x ** 2,
  EaseOutQuad: (x: number) => 1 - (x - 1) ** 2,
  EaseInCubic: (x: number) => x ** 3,
  EaseOutCubic: (x: number) => 1 + (x - 1) ** 3,
  SineOut: (x: number) => Math.sin((x * Math.PI) / 2),
};
