import { Button, Typography } from '@mui/material';
import { Form, Formik, FormikProps } from 'formik';
import { useRef } from 'react';
import { FormInput } from 'components/FormFields/FormInput';
import { NoFlyZone } from 'shared/map/model/no-fly-zone.model';
import { zoneValueToLocaleFixedDecimalPlaces } from 'shared/map-container/utils/3DmapFunctions';
import { mapFacilityVectors } from 'shared/map-container/utils/mapFacilityVectors.util';
import { FormInputNumber } from 'components/FormFields/FormInputNumber/FormInputNumber';
import { allNumbersToFixed2, toFixed2 } from 'utils/numberFormatting';
import { validateNFZForm } from './validateNoFlyZoneForm';
import { useStyles } from './styles';
import { NoFlyZoneFormProps } from './noFlyZoneForm.model';

export const NoFlyZoneForm = ({
  noFlyZone,
  worldBox,
  onCancel,
  onUpdate,
  onSubmit,
}: NoFlyZoneFormProps) => {
  const { classes } = useStyles();

  const originalNoFlyZone = useRef(noFlyZone);
  const formValues = useRef<FormikProps<NoFlyZone>>(null);

  const {
    minX: facilityMinX,
    maxX: facilityMaxX,
    minY: facilityMinY,
    maxY: facilityMaxY,
    minZ: facilityMinZ,
    maxZ: facilityMaxZ,
  } = mapFacilityVectors(worldBox);

  const maxHeight = toFixed2(facilityMaxZ - facilityMinZ);

  const defaultValues = {
    ...noFlyZone,
    sizeAndPosition: allNumbersToFixed2(noFlyZone.sizeAndPosition),
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    let fieldName = e.target.name as keyof NoFlyZone;
    let fieldValue: string | number | object = e.target.value;
    const isSizeOrPosition = fieldName.includes('size') || fieldName.includes('position');

    const droneZoneFormValues = formValues?.current?.values;

    if (isSizeOrPosition) {
      const fieldNameSplit = fieldName.split('.');
      fieldName = fieldNameSplit[0] as keyof NoFlyZone;
      const keyName = fieldNameSplit[1] as keyof NoFlyZone['sizeAndPosition'];

      fieldValue = {
        ...(droneZoneFormValues?.sizeAndPosition ?? {}),
        [keyName]: toFixed2(Number(fieldValue)),
      };
    }

    if (droneZoneFormValues) {
      onUpdate({ ...droneZoneFormValues, [fieldName]: fieldValue });
    }
  };

  const inputProps = { step: 0.5, min: 1 };

  return (
    <Formik
      enableReinitialize
      initialValues={defaultValues}
      innerRef={formValues}
      onSubmit={onSubmit}
      validate={validateNFZForm(worldBox)}
    >
      {({ values }) => (
        <Form noValidate className={classes.form}>
          <Typography variant="h1" className={classes.formHeader}>
            No-fly zone details
          </Typography>

          <div className={classes.formBody}>
            <FormInput
              name="name"
              label="No-fly zone name"
              required
              onChange={handleChange}
              fullWidth
            />

            <FormInput
              name="description"
              label="Description"
              multiline
              fullWidth
              onChange={handleChange}
            />

            <FormInputNumber
              inputProps={{ ...inputProps, max: maxHeight }}
              name="sizeAndPosition.h"
              label={`Height (m) - maximum: ${zoneValueToLocaleFixedDecimalPlaces(maxHeight)}m`}
              onChange={handleChange}
              fullWidth
            />

            <FormInputNumber
              name="sizeAndPosition.w"
              label="Width (m)"
              inputProps={{
                ...inputProps,
                max: toFixed2(facilityMaxX - values.sizeAndPosition.minX),
              }}
              fullWidth
              onChange={handleChange}
            />

            <FormInputNumber
              name="sizeAndPosition.l"
              label="Length (m)"
              inputProps={{
                ...inputProps,
                min: 0,
                max: toFixed2(facilityMaxY - values.sizeAndPosition.minY),
              }}
              fullWidth
              onChange={handleChange}
            />

            <div className={classes.formSection}>
              <FormInputNumber
                name="sizeAndPosition.minX"
                inputProps={{
                  step: inputProps.step,
                  min: facilityMinX,
                  max: toFixed2(facilityMaxX - values.sizeAndPosition.w),
                }}
                onChange={handleChange}
                label="X-Position (m)"
                fullWidth
              />

              <FormInputNumber
                name="sizeAndPosition.minY"
                inputProps={{
                  step: inputProps.step,
                  min: facilityMinY,
                  max: toFixed2(facilityMaxY - values.sizeAndPosition.l),
                }}
                fullWidth
                onChange={handleChange}
                label="Y-Position (m)"
              />
            </div>
          </div>

          <div className={classes.formFooter}>
            <Button
              variant="outlined"
              sx={{ flex: 1, mr: 1 }}
              onClick={() => onCancel(originalNoFlyZone.current)}
            >
              Cancel
            </Button>

            <Button variant="contained" sx={{ flex: 1 }} type="submit">
              Save
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};
