import { ILocationDataST, IVeritySlotStatusST } from 'codegen/warehouse_status';

export const amendedValueGetter = (row: ILocationDataST) => {
  const verityStatus = row.verity_status as IVeritySlotStatusST;

  return (
    !!verityStatus &&
    verityStatus.user_overrides !== null &&
    verityStatus.user_overrides?.length > 0
  );
};
