import { IFacilitySettingsST } from 'codegen/facility_settings';
import { SettingsItem } from '../models/settingsCard.model';

export const getReportDeadlinesOptions = (
  facilitySettings: IFacilitySettingsST,
  handleValuesChange: (prop: string, value: boolean) => void,
): SettingsItem[] => [
  {
    type: 'switch',
    label: 'Show report specific deadlines',
    testId: 'c-show-report-specific-deadlines',
    description: `If enabled, we do show the option of setting report specific deadlines
          at the time of scheduling a report.`,
    value: facilitySettings.show_report_deadlines,
    action: (e: React.ChangeEvent<HTMLInputElement>) =>
      handleValuesChange('show_report_deadlines', e.target.checked),
  },
];
