import { ILocationDataST } from 'codegen/warehouse_status';
import { getMissingBarcodes, getExpectedBarcodes } from '../barcodeFunctions';
import { emptyCellString } from './collectionDelayValueGetter';

export const missingBarcodesPercentageValueGetter = (row: ILocationDataST): number | '-' => {
  const missing = getMissingBarcodes(row);
  const expected = getExpectedBarcodes(row);

  return missing && expected ? missing.length / expected.length : emptyCellString;
};
