import { makeStyles } from 'tss-react/mui';

export const useBarcodesInformationStyles = makeStyles()((theme) => ({
  root: {
    border: 'none',
    borderRadius: theme.spacing(1),
    width: '100%',

    '& .MuiDataGrid-container--top [role=row]': {
      background: 'none',
      width: '100%',
    },

    '& .MuiDataGrid-columnHeaders': {
      backgroundColor: theme.palette.shades.white,
      marginBottom: theme.spacing(1),
      borderRadius: theme.spacing(1),
      border: '1px #EEEEEE solid',
    },
    '.MuiDataGrid-columnHeader:focus-within,': {
      outline: 'none !important',
    },

    '& .MuiDataGrid-row': {
      borderRadius: theme.spacing(1),
      border: '1px #EEEEEE solid',
      backgroundColor: theme.palette.shades.white,
      ':hover': {
        backgroundColor: theme.palette.shades.white,
      },
    },
    '& .MuiDataGrid-cell': {
      borderTopStyle: 'none',
      background: 'none',
    },
    '.MuiDataGrid-cell:focus-within,': {
      outline: 'none !important',
    },

    '& ::after': {
      display: 'none',
    },
    '& ::-webkit-scrollbar': {
      height: 9,
      width: 5,
    },
    '& ::-webkit-scrollbar-thumb': {
      backgroundColor: '#BDBDBD',
      borderRadius: 200,
    },
    '& ::-webkit-scrollbar-track:vertical': {
      marginTop: 15,
      marginBottom: 5,
    },
    '& .MuiDataGrid-scrollbar--vertical': {
      marginRight: 8,
    },
  },
  hoveredRow: {
    backgroundColor: '#E0E0E0 !important',
  },
}));
