import { useState, useCallback } from 'react';
import isEmpty from 'lodash/isEmpty';

// mui core
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

// context and store
import { Box } from 'components/common/Box';
import { PageHeaderSection } from 'components/Page/PageHeaderSection';
import { MapIcon } from 'components/common/Icons/MapIcon';
import { EnhancedTable } from 'components/EnhancedTable';
import { ModalBase } from 'components/ModalsAndPopups/ModalBase';
import { HeadCellProp } from 'components/EnhancedTable/types/cell';
import { useGroundControlStore } from '../../../../store/GroundControl/groundControlLevelStore';
import { useFacilityLevelStore } from '../../../../store/FacilityLevelStore/facilityLevelStore';

// components

// functions and hooks
import { makeTableRows } from './FleetOverviewFunctions';
import { IGroundControlState } from '../../../../store/GroundControl/groundControlLevelStore.model';
import { IFacilityLevelState } from '../../../../store/FacilityLevelStore/IFacilityLevelStore';
import { FLEET_OVERVIEW_COLUMNS } from './FleetOverviewTableDefinition';
import { ConnectDroneToWiFiContainer } from './ConnectDroneToWiFi/ConnectDroneToWiFiContainer';
import { userHasPermission } from '../../../../features/permissions/userHasPermission';
import { PERMISSION } from '../../../../features/permissions/permissions.model';

type FleetOverviewComponentProps = {
  stateGroundControl: IGroundControlState;
  stateFacilityLevel: IFacilityLevelState;
};

const FleetOverviewComponent = (props: FleetOverviewComponentProps) => {
  const { stateGroundControl, stateFacilityLevel } = props;
  const [isMapModalOpen, setIsMapModalOpen] = useState(false);

  const showViewMapModal = useCallback(() => {
    setIsMapModalOpen(true);
  }, [setIsMapModalOpen]);

  const isLoading = stateGroundControl.flightDomain.isLoadingFleetData;
  const viewMapDisabled = !stateGroundControl.fleetOverview.fleet_overview_img_url || true;
  const facilityName = stateFacilityLevel.facilityData.name;
  const flightDomain = stateGroundControl.flightDomain.flight_domain_name;
  const renderTable = userHasPermission(PERMISSION.VIEW_FLEET_INFO);

  const noData =
    !isLoading &&
    stateGroundControl.flightDomainLoaded &&
    isEmpty(stateGroundControl.fleetOverview.fleet_status);
  const rows = renderTable ? makeTableRows(stateGroundControl.fleetOverview.fleet_status) : [];

  const viewMapButton = viewMapDisabled ? null : (
    <Button
      endIcon={<MapIcon disabled={viewMapDisabled} />}
      data-testid="c-no-fly-zones-view-zones-button"
      variant="contained"
      color="primary"
      size="small"
      style={{ marginTop: '26px' }}
      disabled={viewMapDisabled}
      onClick={showViewMapModal}
    >
      VIEW MAP
    </Button>
  );

  return (
    <Box>
      <PageHeaderSection
        title="Fleet"
        subtitle={`Here is the fleet for ${flightDomain}`}
        customBtn={viewMapButton}
      />

      {renderTable && (
        <Container maxWidth="xl" sx={{ paddingTop: '32px' }}>
          <EnhancedTable
            tableTitle="Fleet"
            tableSubtitle={`Fleet overview for ${facilityName} - ${flightDomain}`}
            tableFor="Fleet Overview"
            headCells={
              FLEET_OVERVIEW_COLUMNS(stateFacilityLevel.facilitySettings) as HeadCellProp[]
            }
            rows={rows}
            showHeader={true}
            currentActiveSpinner={{
              'Fleet Overview': isLoading,
            }}
            noData={noData}
            enableHoverOnRows={false}
            isLoading={0}
          />
        </Container>
      )}
      {userHasPermission(PERMISSION.GENERATE_QR_CODE) && (
        <Container maxWidth="xl">
          <ConnectDroneToWiFiContainer />
        </Container>
      )}
      <ModalBase
        testId="c-test-view-zones-modal"
        maxWidth="xl"
        title={
          <Box textAlign="left" p={2} mb={1}>
            <Typography style={{ fontWeight: 'bold' }} color="secondary" variant="h5">
              View Map - Charger Positions
            </Typography>
          </Box>
        }
        actionButtons={
          <Button
            data-testid="c-edit-request-cancel"
            onClick={() => setIsMapModalOpen(false)}
            variant="outlined"
            color="primary"
            size="small"
            style={{ maxHeight: '28px', width: '180px' }}
          >
            Close
          </Button>
        }
        opened={isMapModalOpen}
        handleClose={() => setIsMapModalOpen(false)}
      >
        <img src={stateGroundControl.fleetOverview.fleet_overview_img_url} alt="Fleet Overview" />
      </ModalBase>
    </Box>
  );
};

/**
 * Impure wrapper for FleetOverview component.
 * Supplies the store and context to the wrapped component.
 * @returns pure FleetOverview component
 */
export const FleetPage = () => {
  const { stateGroundControl } = useGroundControlStore();
  const { stateFacilityLevel } = useFacilityLevelStore();
  return (
    <FleetOverviewComponent
      stateGroundControl={stateGroundControl}
      stateFacilityLevel={stateFacilityLevel}
    />
  );
};
