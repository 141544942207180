import { IFacilitySettingsST } from 'codegen/facility_settings';
import { SettingsItem } from '../models/settingsCard.model';

export const getReportReviewsOptions = (
  facilitySettings: IFacilitySettingsST,
  handleValuesChange: (prop: string, value: number | boolean) => void,
  errors: any,
): SettingsItem[] => [
  {
    type: 'switch',
    label: 'Use reviewer barcodes only for internal reports',
    testId: 'c-use-reviewer-barcode-only-for-internal-reports',
    description: `If enabled, we do not unconditionally trust the reviewers.
                    The barcodes they enter might not be reliable. The system moves the barcode to the comment
                    field and set the state to 'NOT_EMPTY' instead.
                    We consider reviewer-entered barcodes reliable if they are equal to the barcodes expected
                    by the wms or the ones found by verity.`,
    value: facilitySettings.carefully_trust_reviewers_barcodes,
    action: (e: React.ChangeEvent<HTMLInputElement>) =>
      handleValuesChange('carefully_trust_reviewers_barcodes', e.target.checked),
  },
  {
    type: 'switch',
    label: 'Auto approve verity status',
    testId: 'c-auto-approve-verity-status',
    description: `If enabled, verity status for locations with issues will be automatically approved
                    and will not require review. If 'External review enabled' is set to true, reviews will
                    still be generated, but will not affect the results.`,
    value: facilitySettings.auto_approve_verity_status,
    action: (e: React.ChangeEvent<HTMLInputElement>) =>
      handleValuesChange('auto_approve_verity_status', e.target.checked),
  },
  {
    type: 'number',
    width: 'small',
    inputProps: { min: 0, step: '0.01' },
    label: 'Fraction of correct results to be reviewed',
    testId: 'c-fraction-of-correct-results-to-be-review',
    description: `The fraction of verity status for locations without issues that will
                    require review`,
    errors: errors.validCorrectResults,
    value: facilitySettings.fraction_of_correct_results_to_be_reviewed,
    action: (e: React.ChangeEvent<HTMLInputElement>) =>
      handleValuesChange(
        'fraction_of_correct_results_to_be_reviewed',
        Number.parseFloat(e.target.value),
      ),
  },
];
