import { Typography, useTheme } from '@mui/material';
import { IRuleActionSTIssueLogicEnum } from 'codegen/facility_settings';
import { getBarcodeColor } from 'common/functions/barcodes/getBarcodeColor.util';
import { BarcodeMatchLogic } from 'common/functions/barcodes/getBarcodeMuiColor.util';

import { barcodeStatusEnum } from 'components/ModalsAndPopups/LocationModal/barcodes/models/BarcodeRow.model';

export const BarcodeLine = ({
  barcode,
  barcodeStatus,
  barcodeMatchLogic,
  issueLogic,
  isStale,
  isOverride,
}: {
  barcode: string;
  barcodeStatus: barcodeStatusEnum;
  barcodeMatchLogic?: BarcodeMatchLogic;
  issueLogic?: IRuleActionSTIssueLogicEnum;
  isStale?: boolean;
  isOverride?: boolean;
}) => {
  const theme = useTheme();
  const color = isStale
    ? theme.palette.text.disabled
    : getBarcodeColor(barcodeStatus, barcodeMatchLogic, issueLogic, theme);
  return (
    <Typography
      className="c-barcode-line"
      component="span"
      variant="body2"
      sx={{ color, cursor: 'pointer' }}
    >
      {barcode}
      {isOverride ? ' *' : ''}
    </Typography>
  );
};
