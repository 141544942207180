import { reportServices } from '../../../../api/ReportServices';

// ###########################################################################
// GET report data
//
// Receives:
//    id - id of the report
//    fromSlot - the name of the slot from where to start the list
//    untilSlot - the name of the last slot to retrieve
// Returns:
//   reportData - dictionary with data for all the locations within the given
//                range
// ###########################################################################

export const getReportData = (
  systemId: string,
  id: string,
  fromSlot: string,
  untilSlot: string,
  signal: AbortSignal,
) =>
  reportServices.getReport(systemId, id, fromSlot, untilSlot, signal).then((r) => ({
    reportData: r.data.item,
  }));
