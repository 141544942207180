import { IRuleActionSTIssueLogicEnum } from 'codegen/facility_settings';
import { barcodeStatusEnum } from '../../../components/ModalsAndPopups/LocationModal/barcodes/models/BarcodeRow.model';

export const getMissingMuiColor = (
  barcodeMatchLogic: BarcodeMatchLogic,
  issueLogic: IRuleActionSTIssueLogicEnum | null = null,
) => {
  const areMissingBarcodePotential = issueLogic === 'C001C_MISSING_BC';

  if (areMissingBarcodePotential) {
    if (
      barcodeMatchLogic === 'Set intersection not empty' ||
      barcodeMatchLogic === 'WMS barcodes are superset'
    ) {
      return undefined;
    }
    return 'warning';
  }

  switch (barcodeMatchLogic) {
    case 'Set exact match':
    case 'Verity barcodes are superset':
      return 'error';
    case 'WMS barcodes are superset':
      return undefined;
    case 'Set intersection not empty':
      return undefined;
    default:
      return 'error';
  }
};

export const getUnexpectedMuiColor = (
  barcodeMatchLogic: BarcodeMatchLogic,
  issueLogic: IRuleActionSTIssueLogicEnum | null = null,
) => {
  const areUnexpectedErrors = issueLogic === 'C001C_MISSING_BC';

  if (areUnexpectedErrors) {
    if (
      barcodeMatchLogic === 'Set intersection not empty' ||
      barcodeMatchLogic === 'WMS barcodes are superset'
    ) {
      return undefined;
    }
    return 'error';
  }

  switch (barcodeMatchLogic) {
    case 'Set exact match':
    case 'WMS barcodes are superset':
      return 'error';
    case 'Verity barcodes are superset':
      return undefined;
    case 'Set intersection not empty':
      return undefined;
    default:
      return 'warning';
  }
};

export type BarcodeMatchLogic =
  | 'Verity barcodes are superset'
  | 'WMS barcodes are superset'
  | 'Set intersection not empty'
  | 'Set exact match'
  | null;

export const getBarcodeMuiColor = (
  status: barcodeStatusEnum,
  barcodeMatchLogic: BarcodeMatchLogic = null,
  issueLogic: IRuleActionSTIssueLogicEnum | null = null,
): 'success' | 'error' | 'warning' | undefined => {
  const isPictureOnly = issueLogic === 'PICTURE_ONLY';
  if (isPictureOnly) {
    return undefined;
  }

  if (status === barcodeStatusEnum.Match) {
    return 'success';
  }
  if (status === barcodeStatusEnum.Missing) {
    return getMissingMuiColor(barcodeMatchLogic, issueLogic);
  }
  if (status === barcodeStatusEnum.Unexpected) {
    return getUnexpectedMuiColor(barcodeMatchLogic, issueLogic);
  }

  return undefined;
};
