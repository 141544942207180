import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: Theme) => ({
  wrapper: {
    display: 'grid',
    gridTemplateColumns: 'minmax(0, 1fr)',
    gridAutoRows: 'min-content',
    rowGap: theme.spacing(2),
    paddingBlockStart: theme.spacing(4),
    paddingInline: theme.spacing(3),

    [theme.breakpoints.up('xl')]: {
      gridTemplateColumns: `minmax(0, 1fr) minmax(100px, ${theme.breakpoints.values.xl}px) minmax(0, 1fr)`,
    },
  },
  section: {
    [theme.breakpoints.up('xl')]: {
      gridColumn: '2 / -2',
    },
  },
  sectionColumns: {
    display: 'grid',
    gap: theme.spacing(2),
    gridAutoRows: 'min-content',
    gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
  },
  sectionFullWidth: {
    gridColumn: '1 / -1',
  },
  cardHeader: {
    display: 'flex',
    alignItems: 'flex-end',
    gap: theme.spacing(1),
  },
  cardHeaderSection: {
    alignSelf: 'center',
    padding: theme.spacing(1),
    display: 'flex',
    gap: theme.spacing(2),
    flex: '0 0 auto',
    minWidth: 0,
  },
  cardHeaderActions: {
    marginInlineStart: 'auto',
  },
}));
