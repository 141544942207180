import { FormControl } from '@mui/material';
import { CustomSelect } from 'components/common/CustomFormComponents/CustomSelect';
import { ILocationData } from 'store/Modals/facilityModals/IFacilityModalsStore';
import { locationStyles } from '../../styles';
import { OVERWRITE_MODES } from '../AmendValues.model';
import { getOptionOverwriteWithWms } from './utils/getOptionOverwriteWithWms';
import { getOptionOverwriteWithVerity } from './utils/getOptionOverwriteWithVerity';

export const SelectOverwriteMode = (props: {
  locationData: ILocationData;
  overwriteMode: string;
  setOverwriteMode: React.Dispatch<React.SetStateAction<string>>;
  overwriteVerityCorrect: string;
}) => {
  const { locationData, overwriteMode, setOverwriteMode, overwriteVerityCorrect } = props;
  const { classes } = locationStyles();

  return (
    <FormControl className={classes.formControl} variant="outlined">
      <CustomSelect
        id="SelectModifyValue"
        name="SelectModifyValue"
        testId="SelectModifyValue"
        error={false}
        errorMessage=""
        defaultValue=""
        variant="outlined"
        disabled={overwriteVerityCorrect === '' && !locationData.userOverride}
        label="How do you want to modify this value?"
        value={overwriteMode}
        onChange={(e) => setOverwriteMode(e.target.value)}
        valueOptions={[
          getOptionOverwriteWithWms(locationData),
          getOptionOverwriteWithVerity(locationData),
          {
            value: OVERWRITE_MODES.MANUAL,
            label: 'Enter value manually',
          },
        ]}
      />
    </FormControl>
  );
};
